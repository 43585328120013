import { BnsRequestInfo } from './BnsRequestInfo';
import { BnsResponseInfo } from './BnsResponseInfo';
import { BonesNetworkLogEntry } from './BonesNetworkLogEntry';

/**
 * Object containing overall request, response, and logging information.
 */
export class BnsResponse
{
    /**
     * Web service request.
     */
    bnsRequestInfo: BnsRequestInfo;

    /**
     * Web service response.
     */
    bnsResponseInfo: BnsResponseInfo;

    /**
     * Log entry.
     */
    logEntry: BonesNetworkLogEntry
}
