import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { BonesCoreModule, BonesVersion } from '@bones/core';
import { BonesFormModule } from '@bones/form';
import { BonesEditModule } from '@bones/edit';

import { BonesGalleryRoutingModule } from './routing';
import { BonesGalleryDocumentCardComponent } from './component/bones-gallery-document-card/bones-gallery-document-card';
import { BonesGalleryDocumentFilterImagesPipe } from './pipe/BonesGalleryPipe';
import { BonesGalleryDocumentFilterAttachmentsPipe } from './pipe/BonesGalleryPipe';
import { BonesGalleryDocumentFilterFeaturedPipe } from './pipe/BonesGalleryPipe';
import { BonesGalleryDocumentFilterNotFeaturedPipe } from './pipe/BonesGalleryPipe';
import { BonesGalleryDocumentIdDirective } from './directive/BonesGalleryDocumentID';
import { BonesGalleryCardComponent } from './component/bones-gallery-card/bones-gallery-card';
import { BonesGalleryDocumentListComponent } from './component/bones-gallery-document-list/bones-gallery-document-list';
import { BonesGalleryDocumentEditModal } from './component/bones-gallery-document-edit/bones-gallery-document-edit';
import { BonesGalleryLargeComponent } from './component/bones-gallery-large/bones-gallery-large';

@NgModule({
    declarations:
    [
        BonesGalleryCardComponent,
        BonesGalleryLargeComponent,
        BonesGalleryDocumentCardComponent,
        BonesGalleryDocumentListComponent,
        BonesGalleryDocumentEditModal,
        BonesGalleryDocumentIdDirective,
        BonesGalleryDocumentFilterImagesPipe,
        BonesGalleryDocumentFilterAttachmentsPipe,
        BonesGalleryDocumentFilterFeaturedPipe,
        BonesGalleryDocumentFilterNotFeaturedPipe
    ],
    imports:
    [
        CommonModule,
        FormsModule, ReactiveFormsModule,
        IonicModule,
        BonesCoreModule,
        BonesFormModule,
        BonesEditModule,
        BonesGalleryRoutingModule
    ],
    // Use for services that are referenced only within this module
    providers:
    [
    ],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports:
    [
        BonesGalleryCardComponent,
        BonesGalleryLargeComponent,
        BonesGalleryDocumentCardComponent,
        BonesGalleryDocumentListComponent,
        // BonesGalleryDocumentEditModal,
        BonesGalleryDocumentIdDirective,
        BonesGalleryDocumentFilterImagesPipe,
        BonesGalleryDocumentFilterAttachmentsPipe,
        BonesGalleryDocumentFilterFeaturedPipe,
        BonesGalleryDocumentFilterNotFeaturedPipe
    ]
})
export class BonesGalleryModule
{
    static initialize()
    {
        BonesVersion.map.set('gallery', '1.3.2023-11-24');

        // BonesTestToolsPiglets.tools.push(
        // {
        //     group: 'Gallery',
        //     title: 'Test 1',
        //     icon: 'grid',
        //     path: 'bones/test-tools/gallery/test1'
        // });
    }
}
BonesGalleryModule.initialize();
