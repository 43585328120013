import { Component, OnInit } from '@angular/core';

import { BonesErrorService } from '@bones/core';

import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';
import { IdeaService } from '@BeerMonkey/rate/service/IdeaService';
import { Idea } from '@BeerMonkey/rate/class/Idea';

@Component({
    selector: 'idea',
    templateUrl: 'idea.html'
})
export class IdeaPage implements OnInit
{
    idea?: Idea;

    constructor(
        private es: BonesErrorService,
        private launch: LaunchEditModalService,
        private ideaDB: IdeaService
    )
    {
    }

    async ngOnInit()
    {
        this.launch.editIdea()
        .then(() =>
        {
            this.ideaDB.getIdea()
            .then(idea => this.idea = idea)
            .catch(error => this.es.errorHandler(error));
        });
    }

}
