import { BonesError } from '@bones/core';
import { BonesServiceCacheOptions } from './BonesServiceCacheOptions';
import { BonesServiceCacheServices } from './BonesServiceCacheServices';

/**
 * Maintain a cache of web service results
 */
export class BonesServiceCache<K, T>
{
    private promise: Promise<void>;
    private list: T[] = [ ];
    private map = new Map<K, T>();

    /**
     * Create new cache
     * 
     * @param options Options for creating cache
     */
    constructor(private services: BonesServiceCacheServices, private options: BonesServiceCacheOptions)
    {
    }

    //-----------------------------------------------------------------------

    /**
     * Load cache from DB
     */
    private async load() : Promise<void>
    {
        const promise = this.services.rest.send(this.options.url)
        .then(rows =>
        {
            // Replace list
            this.services.bones.copyInPlace(rows, this.list);

            // Map entries my ID
            this.map.clear();
            this.list.forEach(row => this.map.set(row[this.options.pk], row));

            return;
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'BonesServiceCache',
                methodName: 'load',
                message: 'Unable to load cache',
                error: error
            })
            .add({ url: this.options.url });
        });

        return promise;
    }

    /**
     * Get promise for loading cache
     */
    private async getPromise() : Promise<void>
    {
        if (!this.promise)
        {
            this.promise = this.load();
        }

        return this.promise;
    }

    //-----------------------------------------------------------------------

    /**
     * Reload a single entry from server DB and update cache
     * @param pk primary key of entry to be reloaded
     */
    async reloadOne(pk: K) : Promise<T>
    {
        const args = { };
        args[this.options.pk] = pk;

        return this.services.rest.send(this.options.urlOne, args)
        .then(row =>
        {
            if (this.map.has(pk))
            {
                // Update original object with new contents
                const ori = this.map.get(pk);
                this.services.bones.copyInPlace(row, ori);

                return ori;
            }
            else
            {
                // Add new row to list and map
                this.list.push(row);
                this.map.set(row[this.options.pk], row);

                return row;
            }
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'BonesServiceCache',
                methodName: 'reloadOne',
                message: 'Unable to refresh cache entry',
                error: error
            })
            .add(args);
        });
    }

    /**
     * Remove an entry from the cache
     * @param pk primary key of entry to be removed
     */
    remove(pk: K) : void
    {
        this.list.splice(this.list.findIndex(row => row[this.options.pk] === pk), 1);
        this.map.delete(pk);
    }

    //-----------------------------------------------------------------------

    /**
     * Get list of cached entries.
     * 
     * @returns Entry list
     */
    public async getList() : Promise<T[]>
    {
        // Make sure cache is loaded
        await this.getPromise();

        // Return cached list
        return this.list;
    }

    /**
     * Get map of cached entries.
     * 
     * @returns Entry map
     */
    public async getMap() : Promise<Map<K, T>>
    {
        // Make sure cache is loaded
        await this.getPromise();

        // Return cached list
        return this.map;
    }

    /**
     * Get single entry from cache.
     * 
     * @param key Key to retrieve
     * @returns Single entry
     */
    public async getEntry(key: K) : Promise<T>
    {
        // Make sure cache is loaded
        await this.getPromise();

        // Return map entry
        return this.map.get(key);
    }

    //-----------------------------------------------------------------------

    /**
     * Get map of entry keys to entry property as used by a form picker.
     * 
     * @param propertyName name of property to use to build map.
     * @param filter optional filter to apply to overall cache
     * @returns Entry map
     */
    public async getPickerMap(propertyName: string, filter?: (entry: T) => boolean) : Promise<Map<K, string>>
    {
        // Make sure cache is loaded
        await this.getPromise();

        // Filter the list if required
        const filtered = filter ? this.list.filter(filter) : this.list;

        // Build picker
        const pickerMap = new Map<K, string>();
        filtered.forEach(row => pickerMap.set(row[this.options.pk], row[propertyName]));

        return pickerMap;
    }

    //-----------------------------------------------------------------------
}

