<ion-list>
  <ion-item-sliding *ngFor="let item of inventoryList">

    <ion-item lines="full" [routerLink]="['/rate/inventory', item.inventory_id]">
      <ion-thumbnail style="height: 4em;" slot="start">
        <img [bonesGalleryDocumentID]="beerImage(item)" [bonesGalleryDocumentSize]="gallery.imageSizeThumbnail" loading="lazy" *ngIf="beerImage(item)">
      </ion-thumbnail>

      <ion-label class="ion-text-wrap">
        <div *ngIf="showBreweryName && item.brewery">
          <ion-text color="secondary" [routerLink]="['/rate/brewery', item.brewery.brewery_id]" bonesStopClick>{{ item.brewery.row.name }}</ion-text>
        </div>

        <div *ngIf="showBeerName || hasLegacyName(item)">
          <ion-text color="secondary" [routerLink]="['/rate/beer', item.beer.beer_id]" bonesStopClick *ngIf="item.beer">
            <span>{{ item.beer.row.name }}</span>
            <span *ngIf="hasLegacyName(item)"> ({{ item.row.beer_name }})</span>
          </ion-text>
          <div *ngIf="!item.beer">
            <ion-text color="secondary">{{ item.beerName }}</ion-text>
            <ion-text > (unlinked)</ion-text>
          </div>
        </div>

        <div *ngIf="item.row.version">
          <ion-text [color]="showBeerName || hasLegacyName(item) ? '' : 'secondary'">{{ item.row.version }}</ion-text>
        </div>

        <div *ngIf="item.beer">
          <span>{{ item.beer.row.style }}</span>
          <span *ngIf="item.beer.row.abv"> @ {{ item.beer.row.abv }}%</span>
        </div>

        <div *ngIf="item.row.location">{{ item.row.location }}</div>
        <p class="two-line-notes" *ngIf="item.row.notes">{{ item.row.notes }}</p>
      </ion-label>

      <div class="ion-text-right" slot="end">
        <div>{{ item.row.quantity || 0 }}ea</div>
        <div *ngIf="item.row.size">{{ item.row.size }}</div>
        <div *ngIf="item.row.vintage">{{ item.row.vintage }}</div>
      </div>
    </ion-item>

    <ion-item-options>
      <ion-item-option  (click)="launch.editInventory(item.row)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="pencil"></ion-icon>
        Edit
      </ion-item-option>
      <ion-item-option  (click)="launch.copyInventory(item.row)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="copy"></ion-icon>
        Copy
      </ion-item-option>
      <ion-item-option  (click)="increment(item)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="add"></ion-icon>
        Inc
      </ion-item-option>
      <ion-item-option  (click)="decrement(item)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="remove"></ion-icon>
        Dec
      </ion-item-option>
    </ion-item-options>

  </ion-item-sliding>
</ion-list>