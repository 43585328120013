<th>
  <span (click)="toggleSort($event)">
    <span class="content-title"><ng-content></ng-content></span>
    <span class="attribute-title">{{ title }}</span>
  </span>
  <ion-button size="small" fill="clear" slot="icon-only" (click)="toggleSort($event)" *ngIf="sort === 'asc'">
    <ion-icon name="caret-up"></ion-icon>
  </ion-button>
  <ion-button size="small" fill="clear" slot="icon-only" (click)="toggleSort($event)" *ngIf="sort === 'desc'">
    <ion-icon name="caret-down"></ion-icon>
  </ion-button>
</th>