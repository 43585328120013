<ion-header><bones-tt-page-header title="Bones Rest"></bones-tt-page-header></ion-header>

<ion-content>

  <bones-tt-edsgw-config-card></bones-tt-edsgw-config-card>

  <ion-card class="ion-padding" [formGroup]="directServerForm.form">
    <ion-card-title>Configuration</ion-card-title>
    <ion-list>
      <ion-radio-group formControlName="route">
        <ion-item>
            <ion-label>EDS Gateway</ion-label>
            <ion-radio value="gw"></ion-radio>
        </ion-item>
        <ion-item>
            <ion-label>Server Direct</ion-label>
            <ion-radio value="d"></ion-radio>
        </ion-item>
      </ion-radio-group>

      <bones-form-item [form]="directServerForm.form" [item]="directServerForm.items[1]"
        *ngIf="directServerForm.getValue('route') === 'd'"></bones-form-item>
    </ion-list>
  </ion-card>

  <ion-card class="ion-padding">
    <ion-card-title>Request</ion-card-title>
    <ion-list>
      <bones-form-items [form]="reqForm.form" [items]="reqForm.items"></bones-form-items>
    </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="forwardResults">
    <ion-card-title>Forward Results</ion-card-title>
    <pre>{{forwardResults | json}}</pre>
  </ion-card>

  <bones-tt-error-card [error]="error"></bones-tt-error-card>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary"
        [disabled]="!reqForm.form.valid || (!edsgw.isAuthenticated && directServerForm.getValue('route') === 'gw')"
        (click)="forward()">Send Forward Request</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
