import { Injectable } from '@angular/core';

/**
 * HTTP cookie utility.
 */
@Injectable({
  providedIn: 'root'
})
export class BonesCookieService
{
    private cookies: Map<string, string>;

    /**
     * @ignore
     */
    constructor(
    )
    {
        this.refresh();
    }

    //-----------------------------------------------------------------------

    /**
     * Refresh cookie cache after an action has occurred that may have modified document cookies.
     */
    public refresh() : void
    {
        this.cookies = new Map();
        const ca = document.cookie.split(';');
        ca.forEach(cookie =>
        {
            const idx = cookie.indexOf('=');
            this.cookies.set(cookie.substr(0, idx), cookie.substr(idx + 1));
        });
    }

    /**
     * Get a map of cookie name => value pairs.
     */
    public getCookies() : Map<string, string>
    {
        return this.cookies;
    }

    /**
     * Get a single cookie by name.
     * 
     * @param name Cookie name.
     */
    public getCookie(name: string) : string
    {
        return this.cookies.get(name);
    }

    /**
     * Set the value of a cookie.
     * 
     * @param name Cookie name.
     * @param value Cookie value.
     * @param expireDays Number of days before this cookie should expire.
     * @param path Url path to which the cookie should be available.
     */
    public setCookie(name: string, value: string, expireDays: number, path: string = '') : void
    {
        const d = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        const cpath = path ? `; path=${path}` : '';
        document.cookie = `${name}=${value}; ${expires}${cpath}`;
        this.refresh();
    }

    /**
     * Delete cookie.
     * 
     * @param name Cookie name.
     */
    public deleteCookie(name: string) : void
    {
        this.setCookie(name, '', -1);
    }


    //-----------------------------------------------------------------------

}
