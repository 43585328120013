import { Component, Input } from '@angular/core';

/**
 * Display a link for an email address.
 * 
 * @example
 * <bones-email-link text="Send Email" [address]="variable"></bones-email-link>
 */
@Component({
  selector: 'bones-email-link',
  templateUrl: 'bones-email-link.html'
})
export class BonesEmailLinkComponent
{
    /**
     * Email address.
     */
    @Input() address: string;

    /**
     * Display text (optional; defaults to address).
     */
    @Input() text: string;

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

}
