import { Injectable } from '@angular/core';
import { isDevMode } from '@angular/core';
import { Platform } from '@ionic/angular';
// import { Device } from '@ionic-native/device/ngx';
// import { AppVersion } from '@ionic-native/app-version/ngx';

import { DeviceInfo } from '../model/device-info';
import { BonesError } from '../model/bones-error';
import { BonesErrorService } from '../service/bones-error.service';

/**
 * Device and platform information
 */
@Injectable({
  providedIn: 'root'
})
export class BonesDeviceService
{
    private info: DeviceInfo;
    private ready: Promise<void>;

    /**
     * @ignore
     */
    constructor(
        private platform: Platform,
        // private device: Device,
        // private appVersion: AppVersion,
        private bes: BonesErrorService
    )
    {
        this.ready = this.platform.ready()
        .then(() =>
        {
            this.populate();
        });
    }

    private async populate()
    {
        this.info = new DeviceInfo();

        try
        {
            // Device settings
            this.info.isDevMode = isDevMode();
            this.info.isCordova = this.platform.is('cordova');

            // Platform settings are influanced by the browser settings, either in a native device
            // web container or a desktop browser simulator setting
            this.info.platforms = this.platform.platforms();
            this.info.isAndroidPlatform = this.platform.is('android');
            this.info.isIosPlatform = this.platform.is('ios');

            // // Get information about device (requires cordova)
            // if (this.info.isCordova)
            // {
            //     this.info.nativeDevice = this.device;

            //     // device.platform is a better indicator of the actual device as platform settings
            //     // can be influanced by a browser based simulator and report that it is native
            //     // rather than web based
            //     this.info.isAndroidDevice = this.device.platform === 'Android';
            //     this.info.isIosDevice = this.device.platform === 'iOS';
            //     this.info.isBrowserDevice = this.device.platform === 'browser';

            //     // Unique identifier for this device (and app?)
            //     this.info.uuid = this.device.uuid;
            // }

            // Platform name
            this.info.platformName = this.info.isAndroidDevice ? 'Android'
                : this.info.isIosDevice ? 'iOS'
                : this.info.isBrowserDevice ? 'browser'
                : 'unknown';

            // Is this running under the ionic serve command (or other plain browser experience).
            this.info.isIonicServe = !this.info.isCordova;

            // // Application versioning
            // if (this.info.isCordova && !this.info.isBrowserDevice)
            // {
            //     this.info.appName = await this.appVersion.getAppName();
            //     this.info.packageName = await this.appVersion.getPackageName();
            //     this.info.versionCode = await this.appVersion.getVersionCode();
            //     this.info.versionNumber = await this.appVersion.getVersionNumber();
            // }
        }
        catch (error)
        {
            error = new BonesError(
            {
                className: 'BonesDeviceService',
                methodName: 'populate',
                message: 'Unable to populate device info',
                error: error
            });

            this.bes.errorHandler(error, 'silent');
        }

        // Dump settings to console
        // Object.getOwnPropertyNames(this.info).forEach(propertyName =>
        // {
        //     if (typeof this[propertyName] !== 'object')
        //     {
        //         console.log('bones device config: ' + propertyName + ' = ' + this.info[propertyName]);
        //     }
        // });
        console.log('BonesDeviceService', this.info);
    }

    /**
     * Get information about the device running the application.
     * @returns DeviceInfo object, see {@link DeviceInfo}.
     */
    public async getInfo() : Promise<DeviceInfo>
    {
        return this.ready.then(() => this.info);
    }

}
