import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';

import { BonesTestToolsTestCase } from '../../model/bones-test-tools-test-case';
import { BonesTestToolsPiglets } from '../../model/bones-test-tools-piglets';

@Component({
  templateUrl: 'test-tools-test-cases.html'
})
export class TestToolsTestCasesPage
{
    testCases: BonesTestToolsTestCase[] = [ ];

    constructor(
        private aroute: ActivatedRoute,
        private navCtrl: NavController,
    )
    {
        // this.testCases = this.navParams.get('testCases');
        const group = this.aroute.snapshot.params.groupName || 'Main';

        // Get test cases for this group
        BonesTestToolsPiglets.testCases.forEach(testCase =>
        {
            if (testCase.group === group || (!testCase.group && group === 'Main'))
            {
                this.testCases.push(testCase);
            }
        });
    }

    //-----------------------------------------------------------------------

    set(testCase: BonesTestToolsTestCase) : void
    {
        testCase.vars.forEach(ev =>
        {
            if ('value' in ev)
            {
                window.localStorage.setItem(ev.name, ev.value);
            }
            else if ('copy' in ev)
            {
                window.localStorage.setItem(ev.name, window.localStorage.getItem(ev.copy));
            }
        });

        this.navCtrl.navigateForward(testCase.path);
    }

}
