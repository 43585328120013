<div  *ngIf="!item.hideTitle">
    <ion-label position="stacked">
        <span>{{item.title}}</span>
        <ion-button class="help-button" fill="clear" *ngIf="item.help"
        (click)="showViaClick = true" bonesStopClick (mouseover)="showViaMouse = true" (mouseout)="showViaMouse = false">
            <ion-icon name="information-circle-outline" slot="icon-only"></ion-icon>
        </ion-button>
    </ion-label>
    <div class="help-text" *ngIf="showViaClick || showViaMouse" (click)="showViaClick = false" bonesStopClick>
        <ion-button class="close-button" fill="clear" *ngIf="showViaClick">
            <ion-icon name="close-circle" slot="icon-only"></ion-icon>
        </ion-button>
        {{ item.help }}
    </div>
</div>