import { BonesFormItemTextareaOptions } from './BonesFormItemTextareaOptions';

/**
 * Configuration for textarea form items.
 */
export class BonesFormItemTextarea
{
    /**
     * Number of rows to show in textarea
     * Default is 4.
     */
    rows: number;

    /**
     * Create a textarea form item.
     * 
     * @param config either number of rows or a full blown BonesFormItemTextareaOptions object.
     */
    constructor(config: BonesFormItemTextareaOptions | number)
    {
        if (!config)
        {
            this.rows = 4;
        }
        else if (typeof config === 'number')
        {
            this.rows = config;
        }
        else
        {
            this.rows = config.rows || 4;
        }
    }
}
