<div *ngIf="!item.hidden" [formGroup]="form">

    <ion-item *ngIf="item.type === 'text'">
      <bones-form-label [item]="item"></bones-form-label>
      <ion-input [formControlName]="item.name" [readonly]="item.readonly"></ion-input>
    </ion-item>

    <ion-item *ngIf="item.type === 'password'">
      <bones-form-label [item]="item"></bones-form-label>
      <ion-input type="password" [formControlName]="item.name" [readonly]="item.readonly"></ion-input>
    </ion-item>

    <ion-item *ngIf="item.type === 'number'">
      <bones-form-label [item]="item"></bones-form-label>
      <ion-input [formControlName]="item.name" type="number" [readonly]="item.readonly"></ion-input>
    </ion-item>

    <bones-form-item-picker [form]="form" [item]="item"
      *ngIf="item.type === 'picker'"></bones-form-item-picker>

    <bones-form-item-toggle [form]="form" [item]="item"
      *ngIf="item.type === 'toggle'"></bones-form-item-toggle>

    <bones-form-item-textarea [form]="form" [item]="item"
      *ngIf="item.type === 'textarea'"></bones-form-item-textarea>

    <bones-form-item-date [form]="form" [item]="item"
      *ngIf="item.type === 'date'"></bones-form-item-date>

    <bones-form-item-elapsed-time [form]="form" [item]="item"
      *ngIf="item.type === 'elapsedTime'"></bones-form-item-elapsed-time>

    <bones-form-item-file [form]="form" [item]="item"
      *ngIf="item.type === 'file'"></bones-form-item-file>

    <div *ngFor="let validatorName of validatorNames">
      <bones-form-error [form]="form" [control]="item.name" [error]="validatorName"
        *ngIf="control.hasError(validatorName)">{{ item.errorMessages[validatorName] }}</bones-form-error>
    </div>
    <bones-form-error [form]="form" [control]="item.name" [error]="otherValidatorError"
      *ngIf="otherValidatorError">{{ otherValidatorErrorMessage }}</bones-form-error>

</div>
