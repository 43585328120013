<ion-header>
  <ion-toolbar>
    <ion-title>Edit Document</ion-title>
    <div slot="end">
      <ion-button size="small" fill="clear" color="light" (click)="cancel()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content [formGroup]="form" *ngIf="form">

  <ion-card class="ion-padding">
    <bones-form-items [form]="form" [items]="bonesForm.items"></bones-form-items>
  </ion-card>

</ion-content>

<ion-footer>
  <bones-edit-button-bar [editor]="this"></bones-edit-button-bar>
</ion-footer>
