import { Component } from '@angular/core';

import { BonesGalleryService } from '@bones/gallery';

@Component({
  selector: 'loading-modal',
  templateUrl: 'loading-modal.html',
})
export class LoadingModal
{
    constructor(
        public gallery: BonesGalleryService
    )
    {
    }
}