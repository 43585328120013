import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { BonesErrorService } from '@bones/core';
import { BonesForm } from '@bones/form';

import { MonkeyService } from '@BeerMonkey/core';
import { ChartData, ChartDataSeries, ChartDataSeri, chartColorScheme } from '@BeerMonkey/core';

import { RatingService, RatingFilter } from '@BeerMonkey/rate/service/RatingService';
import { Rating } from '@BeerMonkey/rate/class/Rating';
import { Beer } from '@BeerMonkey/rate/class/Beer';
import { Brewery } from '@BeerMonkey/rate/class/Brewery';

//-----------------------------------------------------------------------

class RatingGroup
{
    ratings: Rating[] = [ ];

    constructor(public title: string)
    {}
}

class RatingsPerPeriod
{
    newRatings: Rating[] = [ ];
    rerates: Rating[] = [ ];

    constructor(public label: string)
    {}
}

class ChartExtra
{
    constructor(public ratings: Rating[], public labels: ChartLabels)
    {}
}

class PieExtra
{
    constructor(public ratings: Rating[], public labels: ChartLabels)
    {}
}

class ChartLabels
{
    constructor(public barChartLabel: string, public pieChartTitle: string, public drillDownTitle: string)
    {}
}

//-----------------------------------------------------------------------

function currentDayOfYear() : number
{
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 0);
    const diff = (now.getTime() - start.getTime()) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
    const oneDay = 1000 * 60 * 60 * 24;
    const day = Math.floor(diff / oneDay);

    return day;
}

function getWeekNumber(d: Date) : [ number, number ]
{
    // Copy date so don't modify original
    const copy = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    copy.setUTCDate(copy.getUTCDate() + 4 - (copy.getUTCDay()||7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(copy.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil(( ( (copy.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
    // Return week number
    return [ copy.getUTCFullYear(), weekNo ];
}

function yyyyww(d: Date) : string
{
    const [ year, week  ] = getWeekNumber(d);
    return year + '-' + (week < 10 ? '0' : '') + week;
}

function yyyymm(d: Date) : string;
function yyyymm(year: number, month: number) : string;
function yyyymm(a1: number | Date, month?: number) : string
{
    if (a1 instanceof Date)
    {
        return yyyymm(a1.getFullYear(), a1.getMonth() + 1);
    }
    else
    {
        return a1 + '-' + ((month ?? 0) < 10 ? '0' : '') + month;
    }
}

//-----------------------------------------------------------------------

@Component({
    selector: 'recent-ratings',
    templateUrl: 'recent-ratings.html'
})
export class RecentRatingsPage implements OnInit, OnDestroy
{
    filterForm: BonesForm;
    fixedYearMode: boolean;
    allRatings: Rating[] = [ ];
    filteredRatings: Rating[] = [ ];
    ratingGroups: RatingGroup[] = [ ];
    beers?: Beer[];
    breweries?: Brewery[];
    ratings?: Rating[];
    chart?: ChartDataSeries[];
    chartTitle?: string;
    chartHeight?: string;
    scheme = chartColorScheme;
    pie?: ChartData[];
    newRatingChartLabels = new ChartLabels('New', 'New Ratings', 'New Ratings');
    rerateChartLabels = new ChartLabels('Rerate', 'Rerates', 'Rerates');
    ratingDrillDownTitle?: string;
    ratingsPerPeriodMap: Map<string, RatingsPerPeriod> = new Map<string, RatingsPerPeriod>();
    showAllRatingsCard = true;
    private nal: (() => void)[] = [ ];

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private es: BonesErrorService,
        public monkey: MonkeyService,
        private ratingDB: RatingService
    )
    {
        // Has a year been passed in from the router? If so, the page is locked into viewing that year
        this.fixedYearMode = this.route.snapshot.paramMap.has('year');

        // Create filter form
        this.filterForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'days',
                    title: 'Days',
                    hidden: this.fixedYearMode,
                    initialValue: this.fixedYearMode ? '' : 90,
                    picker: [ 7, 14, 30, 60, 90, 180, 365, currentDayOfYear() ].sort((a, b) => a-b),
                    onChange: (value) =>
                    {
                        if (value)
                        {
                            this.filterForm.setValue('year', '');
                            this.crunch();
                        }
                    }
                },
                {
                    name: 'year',
                    title: 'Year',
                    hidden: this.fixedYearMode,
                    initialValue: this.fixedYearMode ? +(this.route.snapshot.paramMap.get('year') ?? '') : '',
                    picker: () => this.allYears(),
                    onChange: (value) =>
                    {
                        if (value)
                        {
                            this.filterForm.setValue('days', '');
                            this.crunch();
                        }
                    }
                },
                {
                    name: 'showNew',
                    title: 'Show new ratings',
                    type: 'toggle',
                    initialValue: true,
                    onChange: () =>  this.crunch()
                },
                {
                    name: 'showRerate',
                    title: 'Show rerates',
                    type: 'toggle',
                    initialValue: true,
                    onChange: () =>  this.crunch()
                }
            ]
        });
    }

    async ngOnInit()
    {
        // Load and refresh ratings as needed
        this.nal.push(this.ratingDB.cache.nowAndLater(
        rows =>
        {
            this.allRatings = rows;
            this.crunch()
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    //-----------------------------------------------------------------------

    get days() : number
    {
        return this.filterForm.getValue('days');
    }

    get year() : number
    {
        return this.filterForm.getValue('year');
    }

    get showNew() : boolean
    {
        return this.filterForm.getValue('showNew');
    }

    get showRerate() : boolean
    {
        return this.filterForm.getValue('showRerate');
    }

    get mainTitle() : string
    {
        return this.year ? 'Ratings for ' + this.year : 'Recent Ratings';
    }

    //-----------------------------------------------------------------------

    _searchTerm: string = '';
    set searchTerm(searchTerm: string)
    {
        this._searchTerm = searchTerm;
        this.crunch();
    }
    get searchTerm() : string
    {
        return this._searchTerm;
    }

    async crunch()
    {
        // Filter ratings
        await this.filter();

        // Group ratings by source
        this.groupRatings();

        // Extract unique beers
        this.beers = this.filteredRatings
            .map(rating => rating.beer)
            .filter((b) : b is Beer => Boolean(b))
            .filter((value, index, self) => self.indexOf(value) === index);

        // Extract unique breweries
        this.breweries = this.filteredRatings
            .map(rating => rating.beer?.brewery)
            .filter((b) : b is Brewery => Boolean(b))
            .filter((value, index, self) => self.indexOf(value) === index);

        // Chart ratings per week
        this.buildChart();
        this.buildPie();

        // Initially show all ratings
        this.showAll();
    }

    // Filter ratings
    async filter() : Promise<any>
    {
        const filter = new RatingFilter(this.allRatings);

        if (this.days)
        {
            filter.byDaysOld(this.days);
        }

        if (this.year)
        {
            filter.byRatingYear(this.year);
        }

        if (!this.days && !this.year)
        {
            filter.byDaysOld(7);
        }

        if (this.searchTerm)
        {
            filter.byKeyword(this.searchTerm);
        }

        this.filteredRatings = filter.rows;

        // Trigger calculation of rerate vs new rate in the background and then wait for it to complete
        await Promise.all(this.filteredRatings.map(r => this.ratingDB.isRerate(r)));

        if (!this.showNew)
        {
            this.filteredRatings = this.filteredRatings.filter(r => r.rerate)
        }

        if (!this.showRerate)
        {
            this.filteredRatings = this.filteredRatings.filter(r => !r.rerate)
        }
    }

    // Group ratings by source
    groupRatings()
    {
        const offPremises = new RatingGroup('Off Premises');
        this.ratingGroups = [ ];
        const groupsByTitle = new Map<string, RatingGroup>();

        this.filteredRatings.forEach(rating =>
        {
            if (!rating.isOnPremises)
            {
                offPremises.ratings.push(rating);
            }
            else
            {
                const title = rating.place;

                let group = groupsByTitle.get(title);
                if (!group)
                {
                    group = new RatingGroup(title);
                    this.ratingGroups.push(group);
                    groupsByTitle.set(title, group);
                }

                group.ratings.push(rating);
            }
        });

        this.ratingGroups.sort((a, b) => a.title.localeCompare(b.title));
        this.ratingGroups.unshift(offPremises);
    }

    showGroup(group: RatingGroup)
    {
        this.showDrillDown('Ratings for ' + group.title, group.ratings);
    }

    //-----------------------------------------------------------------------

    async buildChart()
    {
        // Should the chart have month (vs week) bars
        const chartByMonth = (this.year || this.days > 90);

        // Map ratings per week
        this.ratingsPerPeriodMap = new Map<string, RatingsPerPeriod>();
        this.filteredRatings.forEach(rating =>
        {
            const label = chartByMonth ? yyyymm(rating.ratingDate) : yyyyww(rating.ratingDate);
            const mapKey = label;

            let rpp = this.ratingsPerPeriodMap.get(mapKey);
            if (!rpp)
            {
                rpp = new RatingsPerPeriod(label);
                this.ratingsPerPeriodMap.set(mapKey, rpp);
            }

            (rating.rerate ? rpp.rerates : rpp.newRatings).push(rating);
        });

        // Create chart bar of 0 if no chart bar has been created
        const createZeroBar = (mapKey: string) : void =>
        {
            if (!this.ratingsPerPeriodMap.has(mapKey))
            {
                this.ratingsPerPeriodMap.set(mapKey, new RatingsPerPeriod(mapKey));
            }
        };

        // Fill in missing months for yearly charts
        if (this.year)
        {
            for (let month = 1; (month <= 12); ++month)
            {
                createZeroBar(yyyymm(this.year, month));
            }
        }
        // Fill in gaps for recent chart
        else
        {
            const lastDay = new Date();
            const firstDay = new Date();
            firstDay.setDate(lastDay.getDate() - this.days);

            while(firstDay < lastDay)
            {
                createZeroBar(chartByMonth ? yyyymm(firstDay) : yyyyww(firstDay));
                firstDay.setDate(firstDay.getDate() + 1);
            }
        }

        // Create lines for the chart
        const multilines: ChartDataSeries[] = [ ];

        Array.from(this.ratingsPerPeriodMap.values())
        .sort((a, b) => a.label.localeCompare(b.label))
        .forEach(rpp =>
        {
            const line = new ChartDataSeries(rpp.label);
            multilines.push(line);

            line.series.push(
            {
                name: this.newRatingChartLabels.barChartLabel,
                value: rpp.newRatings.length,
                extra: new ChartExtra(rpp.newRatings, this.newRatingChartLabels)
            });

            line.series.push(
            {
                name: this.rerateChartLabels.barChartLabel,
                value: rpp.rerates.length,
                extra: new ChartExtra(rpp.rerates, this.rerateChartLabels)
            });
        });

        this.chart = multilines;
        this.chartTitle = 'Ratings per ' + (this.year || this.days > 90 ? 'Month' : 'Week');
        this.chartHeight = 5 + (this.ratingsPerPeriodMap.size * 2) + 'em';
    }

    async onChartClick(cd: ChartDataSeri)
    {
        // console.log('onChartClick', cd);
        const ce: ChartExtra = cd.extra;
        // const rpp = this.ratingsPerPeriodMap.get(cd.series);

        this.showDrillDown(ce.labels.drillDownTitle + ' for ' + cd.series, ce.ratings);
    }

    //-----------------------------------------------------------------------

    pieNewRatings?: PieExtra;
    pieRerates?: PieExtra;

    buildPie()
    {
        this.pieNewRatings = new PieExtra(this.filteredRatings.filter(r => !r.rerate), this.newRatingChartLabels);
        this.pieRerates = new PieExtra(this.filteredRatings.filter(r => r.rerate), this.rerateChartLabels);

        this.pie =
        [
            {
                name: this.pieNewRatings.labels.pieChartTitle,
                value: this.pieNewRatings.ratings.length,
                extra: this.pieNewRatings
            },
            {
                name: this.pieRerates.labels.pieChartTitle,
                value: this.pieRerates.ratings.length,
                extra: this.pieRerates
            },
        ];
    }

    onPieClick(cd: ChartDataSeri)
    {
        // console.log('onPieClick', cd);
        // const pe: PieExtra = cd.extra;
        // Hack because ngx-charts-advanced-pie-chart flattens cd.extra by converting it to json and back
        const pe: PieExtra | undefined = cd.extra.labels.pieChartTitle === this.rerateChartLabels.pieChartTitle ? this.pieRerates : this.pieNewRatings;
        // console.log('onPieClick extra', pe);

        if (pe)
        {
            this.showDrillDown(pe.labels.drillDownTitle, pe.ratings);
        }
    }

    // totalNR(isRerate: boolean) : number
    // {
    //     return this.filteredRatings.map(r => r.rerate === isRerate ? 1 : 0).reduce((a, cv) => a + cv, 0);
    // }

    //-----------------------------------------------------------------------

    showDrillDown(title: string, ratings: Rating[])
    {
        this.ratingDrillDownTitle = title;
        this.ratings = ratings;
        this.showAllRatingsCard = false;
    }

    showAll()
    {
        this.ratings = [ ];
        this.showAllRatingsCard = true;
    }

    //-----------------------------------------------------------------------

    async allYears()
    {
        return (await this.ratingDB.cache.getList())
        .map(rating => rating.ratingDate.getFullYear())
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => b-a);
    }

}
