import { BonesErrorType } from './bones-error-type';
import { BonesErrorParms } from './bones-error-parms';

/**
 * Application error class
 */
export class BonesError
{
    /**
     * Static ID counter
     */
    static counter = 0;

    /**
     * User or developer friendly error message.
     */
    id: number;

    /**
     * User or developer friendly error message.
     */
    message?: string;

    /**
     * Name of class in which the error occurred.
     */
    className?: string;

    /**
     * Name of class method in which the error occurred.
     */
    methodName?: string;

    /**
     * Nested error further identifying the original cause of the error.
     */
    error?: BonesErrorType;

    /**
     * Any other data relevent to the error.
     */
    otherData?: any;

    /**
     * Error stack trace. This is not really usefull.
     */
    stack?: any;

    /**
     * Timestamp of when the error occurred.
     */
    date: Date;

    /**
     * Construct new error object.
     * 
     * @param p1 Either a simple string for an error message or an object containing detailed info.
     * @param error Original error used for creating a nestable error callback stack.
     */
    constructor(p1: string | BonesErrorParms, error?: BonesErrorType)
    {
        this.id = ++BonesError.counter;
        this.date = new Date();

        // Were we sent parameters (or just a message string)?
        if (typeof p1 === 'string')
        {
            this.message = p1;
        }
        else
        {
            this.message = p1.message;
            this.className = p1.className;
            this.methodName = p1.methodName;
            this.error = p1.error;
        }

        // Populate error from argument unless it has already been populated from the parameter
        if (!this.error)
        {
            this.error = error;
        }

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        // if ('captureStackTrace' in Error)
        // {
        //     Error['captureStackTrace'](this, BonesError);
        // }
        // else
        if (typeof this.error === 'string')
        {
            //
        }
        else if ((typeof this.error === 'object') && ('stack' in this.error))
        {
            this.stack = this.error.stack;
        }
    }

    /**
     * Extract message from error
     * @param error any BonesErrorType
     */
    public static extractMessage(error: BonesErrorType) : string
    {
        if (typeof error === 'string')
        {
            return error;
        }
        // else if (error instanceof DOMError)
        // {
        //     return error.name;
        // }
        else if ((typeof error === 'object') && ('message' in error))
        {
            return error.message;
        }
    }

    /**
     * Add extra data about error, can be single value or object full of values
     */
    add(otherData: any) : BonesError
    {
        this.otherData = otherData;
        return this;
    }
}
