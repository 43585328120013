import { Component, OnInit } from '@angular/core';

import { BonesMenuCardAction } from '@bones/core';

import { BonesTableLocalDataFactory } from '../../service/bones-table-local-data-factory';

import { BonesTableFetchRequest } from '../../class/BonesTableFetchRequest';
import { BonesTableFetchResponse } from '../../class/BonesTableFetchResponse';
// import { BonesTableLocalData } from '../../class/BonesTableLocalData';

export interface SampleData
{
    ID: number;
    CREATED: Date;
    IS_LUCKY: boolean;
    FRUIT: string;
    C5: string;
}

@Component({
  templateUrl: 'test-tools-bones-table-local.html'
})
export class BonesTestToolsTableLocalPage implements OnInit
{
    rows1: SampleData[];
    rows2: SampleData[];
    // localData: BonesTableLocalData<SampleData>;
    display: any;
    cardMenu1a: BonesMenuCardAction[] =
    [
        { title: 'Option one', icon: 'pencil', action: (a) => console.log('Option One', a), args: 1 },
        { title: 'Option two', icon: 'grid', action: () => console.log('Option 2', this) },
    ];
    cardMenu1b: BonesMenuCardAction[] =
    [
        { title: 'Final option', icon: 'beer', action: () => console.log('beer happens') },
    ];

    constructor(
        private ldFactory: BonesTableLocalDataFactory
    )
    {
    }

    async ngOnInit()
    {
        setTimeout(() => this.rows1 = this.getLocalData(), 1000);
        this.rows2 = this.getLocalData();
    }

    //-----------------------------------------------------------------------

    getLocalData() : SampleData[]
    {
        const rows = [ ];

        // Generate local dataset
        const fruits = [ 'Apple', 'Banana', 'Cherry' ];
        for (let rowNumber = 1; (rowNumber <= 32); ++rowNumber)
        {
            const adate = new Date();
            adate.setDate(Math.round(Math.random() * 180) - 90);

            const row: SampleData =
            {
                ID: rowNumber,
                CREATED: adate,
                IS_LUCKY: Math.random() >= .5,
                FRUIT: fruits[Math.floor(Math.random() * fruits.length)],
                C5: 'Row ' + rowNumber + ', column 5'
            };

            // for (let colNumber = 4; (colNumber <= 5); ++colNumber)
            // {
            //     row['C' + colNumber] = 'Row ' + rowNumber + ', column ' + colNumber;
            // }

            rows.push(row);
        }

        // Create local data manager
        // this.localData = this.ldFactory.create<SampleData>(this.rows);

        // console.log('getLocaleData', rows);
        return rows;
    }

    //-----------------------------------------------------------------------

    // /**
    //  * Fetch local data, emulating a web service that does not know how many rows exist until last page is reached
    //  */
    // localFetch(request: BonesTableFetchRequest) : Promise<BonesTableFetchResponse>
    // {
    //     console.log('fetchData request', request);
    //     // console.log('fetchData local data', this.data);

    //     const response =
    //     {
    //         meta:
    //         {
    //         },
    //         // Persist state data
    //         state:
    //         {
    //             totalRowcount: request.state.totalRowcount,
    //             totalPagecount: request.state.totalPagecount
    //         }
    //     } as BonesTableFetchResponse;

    //     // Is client asking for last page without knowing the overall page count?
    //     if (request.pageNumber === 0)
    //     {
    //         // Calculate last page number
    //         request.pageNumber = Math.ceil(this.rows.length / request.pageSize);
    //     }

    //     // Calculate row range based on page number and page size
    //     const setStart = () =>
    //     {
    //         response.meta.pageNumber = request.pageNumber;
    //         response.meta.startingRowNumber = ((request.pageNumber - 1) * request.pageSize) + 1;
    //     };
    //     setStart();

    //     // Went past last page in attempt to go to next page when not realizing it was the last page
    //     if (response.meta.startingRowNumber > this.rows.length)
    //     {
    //         // Back up to previous page which is the last page
    //         request.pageNumber -= 1;
    //         setStart();
    //         response.state.totalPagecount = request.pageNumber;
    //     }

    //     // Make sure last page does not go past available data
    //     response.meta.endingRowNumber = response.meta.startingRowNumber + request.pageSize - 1;
    //     if (response.meta.endingRowNumber >= this.rows.length)
    //     {
    //         response.state.totalPagecount = request.pageNumber;
    //         response.meta.endingRowNumber = this.rows.length;
    //         response.state.totalRowcount = response.meta.endingRowNumber;
    //     }

    //     // // Set ending row number if we are on the last page
    //     // if (response.state.totalPagecount === request.pageNumber)
    //     // {
    //     //     response.state.totalRowcount = response.meta.endingRowNumber;
    //     // }

    //     // Slice rows from local data source
    //     response.rows = this.rows.slice(response.meta.startingRowNumber - 1, response.meta.endingRowNumber);

    //     // Bools
    //     response.meta.isFirstPage = response.meta.pageNumber === 1;
    //     response.meta.isLastPage = response.state.totalPagecount ?
    //         response.meta.pageNumber === response.state.totalPagecount : false;

    //     // Return response
    //     console.log('fetchData response', response);
    //     return response;
    // }

    // fetchData(request: BonesTableFetchRequest) : BonesTableFetchResponse
    // {
    //     return BonesTestToolsTableOnePage.localFetch(request);
    // }

    // getFetchMethod() : BonesTableFetchMethod
    // {
    //     // return (request) => { return this.fetchData(request) };
    //     return (request) => this.localFetch(request);
    // }

    //-----------------------------------------------------------------------

    action1(row: any)
    {
        this.display = row;
    }

    button1()
    {
        this.display = undefined;
    }

    //-----------------------------------------------------------------------

}
