<ion-searchbar placeholder="Search beers" [(ngModel)]="searchTerm" [debounce]="500" *ngIf="showSearchbar"></ion-searchbar>

<ion-card class="ion-padding" *ngIf="show">
  <bones-card-toolbar [title]="title" [note]="showingText()" [actions]="cardMenu" [expandable]="true" [(expanded)]="showBeers">
  </bones-card-toolbar>
  <ion-card-content class="ion-no-padding" *ngIf="showBeers">

    <ion-item-group *ngFor="let group of groups">
      <ion-item-divider>
        <ion-label>{{ group.by }}</ion-label>
      </ion-item-divider>
      <beer-list [beers]="group.items" [showBreweryName]="showBreweryName"></beer-list>
    </ion-item-group>

  </ion-card-content>
</ion-card>

