import { Component } from '@angular/core';

import { BonesErrorService } from '../../service/bones-error.service';
import { BonesError } from '../../model/bones-error';

@Component({
  templateUrl: 'test-tools-error-log.html'
})
export class BonesTestToolsErrorLogPage
{
    history: Array<BonesError>;

    constructor(
        private es: BonesErrorService
    )
    {
        this.history = this.es.getErrorHistory();
    }

}
