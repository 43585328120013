import { Component, OnInit, Input } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';

import { BonesErrorService } from '@bones/core';
import { BonesEditForm } from '@bones/edit';
import { BonesFormItemOptions } from '@bones/form';
import { BonesRestInterface } from '@bones/network';

import { BonesGalleryDocumentInfo } from '../../class/BonesGalleryDocumentInfo';
import { BonesGalleryService } from '../../service/BonesGalleryService';

/**
 * Add a new document or edit an existing document
 */
@Component({
    selector: 'bones-gallery-document-edit',
    templateUrl: 'bones-gallery-document-edit.html'
})
export class BonesGalleryDocumentEditModal extends BonesEditForm<BonesGalleryDocumentInfo> implements OnInit
{
    /**
     * Rest service for calling server services
     */
    protected rest: BonesRestInterface;

    /**
     * @ignore
     */
    constructor(
        protected formBuilder: FormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        private gallery: BonesGalleryService
    )
    {
        super();
        this.rest = this.gallery.settings.rest;
    }

    /**
     * @ignore
     */
    async ngOnInit()
    {
        const columns: BonesFormItemOptions[] = [];

        // Standard columns
        columns.push(
        {
            name: 'document_id',
            hidden: true
        },
        {
            name: 'title',
            title: 'Title',
            validator: Validators.maxLength(128)
        },
        {
            name: 'featured',
            title: 'Featured',
            toggle: { on: 'x', off: '' },
            initialValue: 'x'
        },
        {
            name: 'logo',
            title: 'Logo',
            toggle: { on: 'x', off: '' }
        },
        {
            name: 'notes',
            title: 'Notes',
            textarea: 4,
            validator: Validators.maxLength(65535)
        });

        // Images can only be supplied when adding a new document
        if (!this.launchOptions.pk)
        {
            columns.push(
            {
                name: 'image',
                title: 'Image or Attachment',
                type: 'file',
                validator: Validators.required
            })
        }

        // Add columns for defined foreign keys
        this.gallery.settings.foreignKeyNames.forEach(fk => columns.push({ name: fk, hidden: true }));

        // Different backends for updates vs adds
        const saveUrl = '/ape-gallery/document/update/' + (this.launchOptions.pk ? 'updateDocument' : 'addDocument');
        const uploadFormat = this.launchOptions.pk ? 'json' : 'form';

        super.initialize(
        {
            columns: columns,
            uploadFormat: uploadFormat,
            saveUrl: saveUrl,
            cache: this.gallery.cache
        });

        // Supply defaults
        if (this.isAdd)
        {
            this.bonesForm.setValue(this.launchOptions.moData.foreignKeyName, this.launchOptions.moData.foreignKeyValue);
            this.bonesForm.setValue('title', this.launchOptions.moData.title);
        }
    }

}
