import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';
import { BonesGalleryService } from '@bones/gallery';

import { ApeRest, removePrefix, MonkeyService } from '@BeerMonkey/core';
import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';
import { BeerInfo } from '@BeerMonkey/rate/class/BeerInfo';
import { BeerService } from '@BeerMonkey/rate/service/BeerService';
import { Brewery } from '@BeerMonkey/rate/class/Brewery';
import { RateBeerService } from '@BeerMonkey/rate/service/RateBeerService';

@Component({
    selector: 'beer-edit',
    templateUrl: 'beer-edit.html'
})
export class BeerEditModal extends BonesEditForm<BeerInfo> implements OnInit
{
    brewery?: Brewery;

    constructor(
        protected formBuilder: FormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: ApeRest,
        private gallery: BonesGalleryService,
        public monkey: MonkeyService,
        private breweryDB: BreweryService,
        private beerDB: BeerService,
        private rateBeerDB: RateBeerService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'beer_id',
                    hidden: true
                },
                {
                    name: 'image_id',
                    hidden: true
                },
                {
                    name: 'brewery_id',
                    title: 'Brewery',
                    hideTitle: true,
                    picker: () => this.breweryDB.cache.getPickerMap('name'),
                    validator: Validators.required,
                    onChange: breweryID =>
                    {
                        this.setBrewery(+breweryID);
                    }
                },
                {
                    name: 'image',
                    title: 'Image',
                    type: 'file'
                },
                {
                    name: 'name',
                    title: 'Name',
                    validator: [ Validators.required, Validators.maxLength(128) ]
                },
                {
                    name: 'style',
                    title: 'Style',
                    picker:
                    {
                        populator: () => this.beerDB.getFullStylePicker(),
                        readWrite: true
                    },
                    validator: [ Validators.required, Validators.maxLength(32) ]
                },
                {
                    name: 'abv',
                    title: 'ABV',
                    type: 'number'
                },
                {
                    name: 'ratebeer_score',
                    title: 'RateBeer Score',
                    type: 'number',
                    readonly: true
                },
                {
                    name: 'ratebeer_id',
                    title: 'RateBeer',
                    hideTitle: true,
                    type: 'number',
                    picker:
                    {
                        populator: () => this.rateBeerDB.getBreweryBeerListPicker(this.brewery?.row.ratebeer_id),
                        nocache: true
                    },
                    onChange: ratebeerID =>
                    {
                        // console.log('ratebeer_id.onChange', ratebeerID, this.bonesForm.getValue('name'), this.bonesForm.getValues());
                        // Default name to name used by RateBeer
                        if (ratebeerID && !this.bonesForm.getValue('name'))
                        {
                            this.rateBeerDB.getBeer(+ratebeerID)
                            .then(rbbeer =>
                            {
                                this.bonesForm.setValue('name', removePrefix(rbbeer.BrewerName, rbbeer.BeerName));
                            });
                        }
                    }
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    textarea: 12
                }
            ],
            uploadFormat: 'form',
            saveUrl: '/rate/beer/update/updateBeer',
            cache: this.beerDB.cache,
            postSave: (row, payload) =>
            {
                // If row supplied an image, then update the document cache with the new image
                if (row.image)
                {
                    this.gallery.cache.updated(payload.documentID);
                }

                return Promise.resolve('');
            }
        });

        // Supply defaults
        if (this.isAdd)
        {
            this.bonesForm.setValue('brewery_id', this.launchOptions.moData.breweryID);
            this.setBrewery(this.launchOptions.moData.breweryID);
        }
        else
        {
            this.setBrewery(this.bonesForm.getValue('brewery_id'));
        }
    }

    private setBrewery(breweryID: number)
    {
        this.breweryDB.getBrewery(breweryID)
        .then(brewery => this.brewery = brewery)
        .catch(error => this.bes.errorHandler(error));
    }
}
