<ion-header><modal-header [title]="title"></modal-header></ion-header>

<ion-content [formGroup]="bonesForm.form" *ngIf="bonesForm">

    <!-- beer info -->
    <!-- <beer-info [beer]="beer" [ratebeerID]="bonesForm.getValue('ratebeer_id')"></beer-info> -->

    <ion-card class="ion-padding">
      <!-- <ion-card-title>{{ title }}</ion-card-title> -->
      <bones-form-items [form]="bonesForm.form" [items]="items"></bones-form-items>
    </ion-card>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" (click)="ok()">OK</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
