import { Injectable } from '@angular/core';

/**
 * A collection of miscellaneous and sometimes useful utilities.
 */
@Injectable({
    providedIn: 'root'
})
export class BonesCoreService
{
    /**
     * @ignore
     */
    constructor(
    )
    {
    }

    //-----------------------------------------------------------------------

    /**
     * In-place copy source object into destination object replacing all properties
     *
     * Replacement for angular.copy
     * 
     * @param source Source object.
     * @param destination Destination object that will be emptied and the filled with source properties.
     */
    copyInPlace(source: any, destination: any)
    {
        // Clear destination object; preserving arrays but empting them
        Object.keys(destination).forEach(key =>
        {
            if (Array.isArray(destination[key]) && Array.isArray(source[key]))
            {
                destination[key].splice(0, destination[key].length);
            }
            else
            {
                delete destination[key];
            }
        });

        // Copy populated properties
        Object.keys(source).forEach(key =>
        {
            if (Array.isArray(source[key]) && Array.isArray(destination[key]))
            {
                destination[key].splice(0, destination[key].length, ...source[key]);
            }
            else
            {
                destination[key] = source[key];
            }
        });
    }

    //-----------------------------------------------------------------------

}
