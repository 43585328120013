import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { BonesErrorService, BonesItemGroupFactory } from '@bones/core';
import { BonesForm } from '@bones/form';

import { MonkeyService } from '@BeerMonkey/core';

import { Beer } from '@BeerMonkey/rate/class/Beer';
import { BeerService, BeerFilter, beerRatingGroupFactory } from '@BeerMonkey/rate/service/BeerService';
import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';
import { CannedSearch, CannedSearchPage, enableCannedSearch } from '@BeerMonkey/rate/class/CannedSearch';
import { BeerInfo } from '@BeerMonkey/rate/class/BeerInfo';

@Component({
    selector: 'beer-page',
    templateUrl: 'beer-page.html'
})
export class BeerPage implements OnInit, OnDestroy, CannedSearchPage
{
    filterForm: BonesForm;
    allBeers?: Beer[];
    filteredBeers?: Beer[];
    showFilters = false;
    showFilterCard = true;
    cardTitle = 'Beers';
    group?: BonesItemGroupFactory<Beer, BeerInfo>;
    private nal: (() => void)[] = [ ];

    constructor(
        public route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private es: BonesErrorService,
        public monkey: MonkeyService,
        private breweryDB: BreweryService,
        private beerDB: BeerService
    )
    {
        this.showFilters = this.monkey.isLargeDevice;

        // Create filter form
        this.filterForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'brewery_id',
                    title: 'Brewery',
                    picker: () => this.breweryDB.cache.getPickerMap('name'),
                    onChange: () => this.filter()
                },
                {
                    name: 'style',
                    title: 'Style',
                    picker: () => this.beerDB.getFullStylePicker(),
                    onChange: () => this.filter()
                }
            ]
        });
    }

    async ngOnInit()
    {
        // Load and refresh ratings as needed
        this.nal.push(this.beerDB.cache.nowAndLater(
        rows =>
        {
            this.allBeers = rows;
            this.filter();
        },
        error => this.es.errorHandler(error)));

        // Enable canned searches for this page
        enableCannedSearch(this);
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    filter() : void
    {
        if (this.allBeers && this.filterForm)
        {
            this.filteredBeers = new BeerFilter(this.allBeers)
                .byBrewery(this.filterForm.getValue('brewery_id'))
                .by('style', this.filterForm.getValue('style'))
                .rows;
        }
    }

}

//---------------------------------------------------------------------
// Canned searches
//---------------------------------------------------------------------

export const cannedSearchBeerTopRated = new CannedSearch(
{
    title: 'Beers - Top Rated Beers',
    module: '/rate',
    path: 'beer.top-rated',
    component: BeerPage,
    group: beerRatingGroupFactory('desc')
});

export const cannedSearchBeerTopIPA = new CannedSearch(
{
    title: 'Beers - Top Rated IPAs',
    module: '/rate',
    path: 'beer.top-rated-ipa',
    component: BeerPage,
    group: beerRatingGroupFactory('desc'),
    filters:
    [
        { name: 'style', value: 'IPA' }
    ]
});

