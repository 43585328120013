import { Component, Input } from '@angular/core';

import { TspaceService } from '../../service/tspace';

/**
 * Display tSpace avatar for a given attuid.
 * 
 * @example
 * <bones-tspace-avatar [attuid]="attuid"></bones-tspace-avatar>
 */
@Component({
    selector: 'bones-tspace-avatar',
    templateUrl: 'bones-tspace-avatar.html'
})
export class BonesTspaceAvatarComponent
{
    /**
     * Data URL containing tSpace avatar
     */
    url: string;

    /**
     * Set attuid.
     * 
     * @param attuid Attuid to display.
     */
    @Input() set attuid(attuid: string)
    {
        if (attuid)
        {
            this.tspace.getAvatarDataUrl(attuid)
            .then(url => this.url = url);
        }
    }

    /**
     * @ignore
     */
    constructor(
        public tspace: TspaceService
    )
    {
    }

}
