<ion-header><bones-tt-page-header title="Bones Table - Remote"></bones-tt-page-header></ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="8">

<!--
-->
        <bones-table #bt1 title="Paged Server Data"
          [url]="urlPdr" [exportUrl]="exportUrl"
          [defaultSortOrder]="[{ propertyName: 'FRUIT' }, { propertyName: 'CREATED', sort: 'desc' }]"
          [prependMenuItems]="cardMenu1a"
          [appendMenuItems]="cardMenu1b"
          [pageSize]="5"
          [pageSizes]="[ 5, 8, 10, 100 ]">
          <thead>
            <tr>
              <th>Actions</th>
              <bones-table-th name="ID" title="Row Number">Row<br>Number</bones-table-th>
              <bones-table-th name="CREATED" title="Created"></bones-table-th>
              <bones-table-th name="IS_LUCKY" title="Lucky"></bones-table-th>
              <bones-table-th name="FRUIT" title="Fruit"></bones-table-th>
              <bones-table-th name="C5" title="Column Five" [sortable]="false" [searchable]="false"></bones-table-th>
            </tr>
          </thead>
          <tbody *ngIf="bt1.data">
            <tr *ngFor="let row of bt1.data.rows">
              <td>
                <ion-button fill="solid" size="small" color="primary" (click)="action1(row)">Action 1</ion-button>
              </td>
              <td class="ion-text-right">{{ row.ID }}</td>
              <td>{{ row.CREATED | date:'mediumDate' }}</td>
              <td class="ion-text-center"><ion-checkbox [disabled]="true" [checked]="row.IS_LUCKY"></ion-checkbox></td>
              <td>{{ row.FRUIT }}</td>
              <td>{{ row.C5 }}</td>
            </tr>
          </tbody>
        </bones-table>

        <bones-table #btf1 title="Smart Server Data Cached Locally (server decision)"
          [pageSize]="5"
          [url]="urlFdr" [urlArgs]="{ answer: 42 }"
          [defaultSortOrder]="[{ propertyName: 'FRUIT' }, { propertyName: 'CREATED', sort: 'desc' }]"
          >
          <thead>
            <tr>
              <bones-table-th name="ID">Row Number</bones-table-th>
              <bones-table-th name="CREATED">Created</bones-table-th>
              <bones-table-th name="IS_LUCKY">Lucky</bones-table-th>
              <bones-table-th name="FRUIT">Fruit</bones-table-th>
            </tr>
          </thead>
          <tbody *ngIf="btf1.data">
            <tr *ngFor="let row of btf1.data.rows">
              <td class="ion-text-right">{{ row.ID }}</td>
              <td>{{ row.CREATED | date:'mediumDate' }}</td>
              <td class="ion-text-center"><ion-checkbox [disabled]="true" [checked]="row.IS_LUCKY"></ion-checkbox></td>
              <td>{{ row.FRUIT }}</td>
            </tr>
          </tbody>
        </bones-table>

        <bones-table #btf2 title="Smart Server Data Cached Locally (client decision)"
          [pageSize]="5"
          [url]="urlFdr" [urlArgs]="{ answer: 42 }" [clientCache]="true"
          [defaultSortOrder]="[{ propertyName: 'FRUIT' }, { propertyName: 'CREATED', sort: 'desc' }]"
          >
          <thead>
            <tr>
              <bones-table-th name="ID">Row Number</bones-table-th>
              <bones-table-th name="CREATED">Created</bones-table-th>
              <bones-table-th name="IS_LUCKY">Lucky</bones-table-th>
              <bones-table-th name="FRUIT">Fruit</bones-table-th>
            </tr>
          </thead>
          <tbody *ngIf="btf2.data">
            <tr *ngFor="let row of btf2.data.rows">
              <td class="ion-text-right">{{ row.ID }}</td>
              <td>{{ row.CREATED | date:'mediumDate' }}</td>
              <td class="ion-text-center"><ion-checkbox [disabled]="true" [checked]="row.IS_LUCKY"></ion-checkbox></td>
              <td>{{ row.FRUIT }}</td>
            </tr>
          </tbody>
        </bones-table>

        <bones-table #bt2 title="Legacy Data (no menu)"
          [pageSize]="5"
          [rowUrl]="urlLegacy" [urlArgs]="{ answer: 42 }"
          [defaultSortOrder]="[{ propertyName: 'FRUIT' }, { propertyName: 'CREATED', sort: 'desc' }]"
          >
          <thead>
            <tr>
              <bones-table-th name="ID">Row Number</bones-table-th>
              <bones-table-th name="CREATED">Created</bones-table-th>
              <bones-table-th name="IS_LUCKY">Lucky</bones-table-th>
              <bones-table-th name="FRUIT">Fruit</bones-table-th>
            </tr>
          </thead>
          <tbody *ngIf="bt2.data">
            <tr *ngFor="let row of bt2.data.rows">
              <td class="ion-text-right">{{ row.ID }}</td>
              <td>{{ row.CREATED | date:'mediumDate' }}</td>
              <td class="ion-text-center"><ion-checkbox [disabled]="true" [checked]="row.IS_LUCKY"></ion-checkbox></td>
              <td>{{ row.FRUIT }}</td>
            </tr>
          </tbody>
        </bones-table>

        <bones-table #bt2a title="Legacy Data (with menu)"
          [pageSize]="5"
          [prependMenuItems]="cardMenu1a"
          [rowUrl]="urlLegacy" [urlArgs]="{ answer: 42 }"
          [defaultSortOrder]="[{ propertyName: 'FRUIT' }, { propertyName: 'CREATED', sort: 'desc' }]"
          >
          <thead>
            <tr>
              <bones-table-th name="ID">Row Number</bones-table-th>
              <bones-table-th name="CREATED">Created</bones-table-th>
              <bones-table-th name="IS_LUCKY">Lucky</bones-table-th>
              <bones-table-th name="FRUIT">Fruit</bones-table-th>
            </tr>
          </thead>
          <tbody *ngIf="bt2a.data">
            <tr *ngFor="let row of bt2a.data.rows">
              <td class="ion-text-right">{{ row.ID }}</td>
              <td>{{ row.CREATED | date:'mediumDate' }}</td>
              <td class="ion-text-center"><ion-checkbox [disabled]="true" [checked]="row.IS_LUCKY"></ion-checkbox></td>
              <td>{{ row.FRUIT }}</td>
            </tr>
          </tbody>
        </bones-table>

        <bones-table #btn1 title="Server API Not Found" [url]="urlServer404">
          <thead>
            <tr>
              <bones-table-th name="ID">Row<br>Number</bones-table-th>
              <bones-table-th name="CREATED">Created</bones-table-th>
              <bones-table-th name="IS_LUCKY">Lucky</bones-table-th>
              <bones-table-th name="FRUIT">Fruit</bones-table-th>
            </tr>
          </thead>
          <tbody *ngIf="btn1.data">
            <tr *ngFor="let row of btn1.data.rows">
              <td class="ion-text-right">{{ row.ID }}</td>
              <td>{{ row.CREATED | date:'mediumDate' }}</td>
              <td class="ion-text-center"><ion-checkbox [disabled]="true" [checked]="row.IS_LUCKY"></ion-checkbox></td>
              <td>{{ row.FRUIT }}</td>
            </tr>
          </tbody>
        </bones-table>

        <bones-table #btn2 title="Server Failure Test" [url]="urlServerFailed">
          <thead>
            <tr>
              <bones-table-th name="ID">Row<br>Number</bones-table-th>
              <bones-table-th name="CREATED">Created</bones-table-th>
              <bones-table-th name="IS_LUCKY">Lucky</bones-table-th>
              <bones-table-th name="FRUIT">Fruit</bones-table-th>
            </tr>
          </thead>
          <tbody *ngIf="btn2.data">
            <tr *ngFor="let row of btn2.data.rows">
              <td class="ion-text-right">{{ row.ID }}</td>
              <td>{{ row.CREATED | date:'mediumDate' }}</td>
              <td class="ion-text-center"><ion-checkbox [disabled]="true" [checked]="row.IS_LUCKY"></ion-checkbox></td>
              <td>{{ row.FRUIT }}</td>
            </tr>
          </tbody>
        </bones-table>
<!-- 
-->

      </ion-col>

      <ion-col size="4">
        <ion-card class="ion-padding" *ngIf="display">
          <ion-card-title>Row Contents</ion-card-title>
          <pre>{{ display | json }}</pre>
        </ion-card>
      </ion-col>

    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" (click)="button1()">button</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
