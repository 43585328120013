import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';

import { TspaceService } from '../service/tspace';

/**
 * Display tSpace avatar for a given attuid.
 * 
 * @example
 * <img [tSpaceAvatarAttuid]="attuid"></img>
 */
@Directive({
    selector: 'img[tSpaceAvatarAttuid]'
})
export class BonesTspaceAvatarAttuidDirective
{
    /**
     * Set attuid.
     * 
     * @param attuid Attuid to display.
     */
    @Input() set tSpaceAvatarAttuid(attuid: string)
    {
        if (attuid)
        {
            this.tspace.getAvatarDataUrl(attuid)
            .then(url => this.renderer.setAttribute(this.ellie.nativeElement, 'src', url));
        }
    }

    /**
     * @ignore
     */
    constructor(
        private ellie: ElementRef,
        private renderer: Renderer2,
        private tspace: TspaceService
    )
    {
    }

}
