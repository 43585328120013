<ion-header><bones-tt-page-header title="Location"></bones-tt-page-header></ion-header>

<ion-content>

  <ion-card class="ion-padding">
    <ion-card-title>Location</ion-card-title>
    <ion-list>
      <ion-item *ngFor="let loc of locbits">
        <ion-label>
          <h2>{{ loc.key }}</h2>
          <h3>{{ loc.value }}</h3>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-card>

</ion-content>
