import { Injectable } from '@angular/core';

import { BonesCoreService } from '@bones/core';

import { BonesServiceCache } from '../class/BonesServiceCache';
import { BonesServiceCacheOptions } from '../class/BonesServiceCacheOptions';
import { BonesRestService } from './bones-rest';

/**
 * Create a cache using injected services
 */
@Injectable({
  providedIn: 'root',
})
export class BonesServiceCacheFactory
{
    /**
     * @ignore
     */
    constructor(
        private bones: BonesCoreService,
        private rest: BonesRestService
    )
    {
    }

    /**
     * Create a new cache
     *
     * @param options Cache creation options
     */
    public createCache<K, T>(options: BonesServiceCacheOptions) : BonesServiceCache<K, T>
    {
        return new BonesServiceCache<K, T>(
        {
            bones: this.bones,
            rest: this.rest,
        },
        options);
    }
}
