import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

import { BonesError, BonesSortOption, BonesSearch, dpget, BonesItemGroupFactory, BonesItemGroupFactoryBase } from '@bones/core';
import { BonesCache, BonesCacheFactory } from '@bones/core';
import { BonesGalleryService } from '@bones/gallery';

import { ApeRest } from '@BeerMonkey/core/service/ApeRest';
import { RatingInfo } from '../class/RatingInfo';
import { Rating } from '../class/Rating';
import { BeerService } from './BeerService';
import { RatingSource } from '../class/RatingSource';
import { Beer } from '../class/Beer';

/**
 * Access db information
 */
@Injectable({
  providedIn: 'root',
})
export class RatingService
{
    cache: BonesCache<number, RatingInfo, Rating>;
    sourcePromise?: Promise<RatingSource[]>;

    constructor(
        private bcf: BonesCacheFactory,
        private gallery: BonesGalleryService,
        private rest: ApeRest,
        private beerDB: BeerService
    )
    {
        this.cache = this.bcf.create<number, RatingInfo, Rating>(
        {
            pk: 'rating_id',
            fk: 'beer_id',
            loadCache: () => this.rest.send('rate/rating/getRatings'),
            reloadOne: (id: number) => this.rest.send('rate/rating/getRating', { ratingID: id }),
            converter: async (info: RatingInfo) : Promise<Rating> =>
            {
                const rating = new Rating(info);

                rating.beer = await this.beerDB.getBeer(rating.row.beer_id);

                return rating;
            },
            sorter: (a: Rating, b: Rating) =>
            {
                return b.ratingDate.getTime() - a.ratingDate.getTime() || b.rating_id - a.rating_id;
            }
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Get details
     */
    async getRating(ratingID: number) : Promise<Rating>
    {
        return this.cache.getEntry(ratingID);
    }

    /**
     * Get all ratings for a beer (asynchronous)
     */
    async fetchRatingsForBeer(beer: Beer) : Promise<Rating[] | undefined>
    {
        return this.cache.fetchListByForeignKey('beer_id', beer.beer_id);
    }

    /**
     * Get all ratings for a beer (synchronous)
     */
    getRatingsForBeer(beer: Beer) : Rating[] | undefined
    {
        return this.cache.getListByForeignKey('beer_id', beer.beer_id);
    }

    getThumbnail(rating: Rating) : number | undefined
    {
        return this.gallery.thumbnailPicker(
            { foreignKeyName: 'rating_id', foreignKeyValue: rating.rating_id },
            { foreignKeyName: 'beer_id', foreignKeyValue: rating.beer?.beer_id },
            { foreignKeyName: 'brewery_id', foreignKeyValue: rating.beer?.brewery?.brewery_id }
        );
    }

    /**
     * Get image for a beer, either from the beer itself or one of its ratings if the beer does not have its own image
     */
    getBeerImageID(beer: Beer) : number | undefined
    {
        const ratingsForBeer = this.getRatingsForBeer(beer)
            ?.sort((a, b) => b.ratingDate.getTime() - a.ratingDate.getTime())
            ?.map(r => r.rating_id);

        return this.gallery.thumbnailPicker(
            { foreignKeyName: 'beer_id', foreignKeyValue: beer.beer_id },
            { foreignKeyName: 'rating_id', foreignKeyValue: ratingsForBeer }
        );
    }

    /**
     * Get image for a beer, either from the beer itself or one of its ratings; or fallback to using a brewery image/logo
     */
    getBeerThumbnail(beer: Beer) : number | undefined
    {
        // Get image from beer or rating
        let imageID = this.getBeerImageID(beer);

        // If no image found, check the brewery as a final fallback
        if (!imageID)
        {
            imageID = this.gallery.thumbnailPicker({ foreignKeyName: 'brewery_id', foreignKeyValue: beer.brewery?.brewery_id });
        }

        return imageID;
    }

    //-----------------------------------------------------------------------

    /**
     * Check to see if a given rating is a rerate for a beer (vs the first rating for a beer)
     * 
     * @returns true=rerate, false=new rating
     */
    async isRerate(rating: Rating) : Promise<boolean>
    {
        if (rating.rerate !== undefined)
        {
            return rating.rerate;
        }

        // Search ratings for this rating's beer
        return this.cache.getList()
        .then(ratings =>
        {
            // Get all ratings for the same beer
            const beerRatings = ratings.filter(r => r.beer?.beer_id === rating.beer?.beer_id);

            // This is a rerate unless it is the oldest rating for this beer
            rating.rerate = (rating !== beerRatings[beerRatings.length - 1]);

            // console.log(rating.rerate, rating, beerRatings[beerRatings.length - 1]);

            return rating.rerate;
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Get recent sources
     */
    async getSources() : Promise<RatingSource[]>
    {
        if (!this.sourcePromise)
        {
            this.sourcePromise = this.rest.send('/rate/rating/getSources')
            .catch(error =>
            {
                throw new BonesError(
                {
                    className: 'RatingService',
                    methodName: 'getSources',
                    message: 'Unable to get sources',
                    error: error
                });
            });
        }

        return this.sourcePromise;
    }

    async getSourcePicker() : Promise<string[]>
    {
        return this.getSources()
        .then(sources => sources.map(s => s.source));
    }

    /**
     * Get all unique sources that have been used in the past
     */
    async getAllSources() : Promise<string[]>
    {
        return (await this.cache.getList())
            .map(r => r.row.source)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((a, b) => a.localeCompare(b));
    }

    //-----------------------------------------------------------------------

    /**
     * Get modes
     */
    async getModes() : Promise<string[]>
    {
        return [ 'Bottle', 'Draft', 'Can', 'Firkin', 'Cask', 'Growler', 'Nitro', 'Bottle Conditioned', 'Hand Bottled' ];
    }

    //-----------------------------------------------------------------------

    /**
     * Get years in which a beer was rated
     */
    async getRatingYears() : Promise<number[]>
    {
        return (await this.cache.getList())
            .map(r => r.ratingDate.getFullYear())
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((a, b) => b - a);
    }

    
    //-----------------------------------------------------------------------

    /**
     * Get all unique vintages that have been used in the past
     */
    async getVintages() : Promise<string[]>
    {
        return (await this.cache.getList())
            .map(r => r.row.vintage)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((a, b) => b.localeCompare(a));
    }

    /**
     * Picker to pick a vintage year.
     * Vintages range from 1987 to next year
     */
    getVintagePicker() : string[]
    {
        const vintages: string[] = [ ];
        for (let year = new Date().getFullYear() + 1; (year > 1986); --year)
        {
            vintages.push(year.toString());
        }

        return vintages;
    }

    //-----------------------------------------------------------------------

    // swapBeerPhoto(rating: Rating)
    // {
    //     this.rest.send('/rate/rating/update/swapBeerPhoto',
    //     {
    //         ratingID: rating.rating_id,
    //         ratingImageID: rating.row.image_id,
    //         beerID: rating.beer.beer_id,
    //         beerImageID: rating.beer.row.image_id
    //     })
    //     .then(() =>
    //     {
    //         this.cache.updated(rating.rating_id);
    //         this.beerDB.cache.updated(rating.beer.beer_id);
    //     })
    //     .catch(error =>
    //     {
    //         throw new BonesError(
    //         {
    //             className: 'RatingService',
    //             methodName: 'getSources',
    //             message: 'Unable to get sources',
    //             error: error
    //         });
    //     });
    // }

    /**
     * Move a rating's image to its beer
     */
    async moveImageToBeer(rating: Rating)
    {
        const documents = await this.gallery.cache.fetchListByForeignKey('rating_id', rating.rating_id)
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'RatingService',
                methodName: 'moveImageToBeer',
                message: 'Unable to get documents for rating',
                error: error
            });
        });

        if (!documents)
        {
            throw new BonesError(
            {
                className: 'RatingService',
                methodName: 'moveImageToBeer',
                message: 'This rating has no images',
            });
        }

        if (documents.length > 1)
        {
            throw new BonesError(
            {
                className: 'RatingService',
                methodName: 'moveImageToBeer',
                message: 'This rating has more than one image and I didn\'t feel like writing more code',
            });
        }

        return this.rest.send('/rate/rating/update/moveImageToBeer',
        {
            beerID: rating.beer?.beer_id,
            documentID: documents[0].document_id
        })
        .then(() =>
        {
            this.gallery.cache.updated(documents[0].document_id);
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'RatingService',
                methodName: 'moveImageToBeer',
                message: 'Move failed',
                error: error
            });
        });
    }

    /**
     * Steal the beer's image and attach it to given rating instead
     */
    async moveImageFromBeer(rating: Rating)
    {
        const documents = rating.beer ? await this.gallery.cache.fetchListByForeignKey('beer_id', rating.beer.beer_id)
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'RatingService',
                methodName: 'moveImageFromBeer',
                message: 'Unable to get documents for beer',
                error: error
            });
        }) : undefined;

        if (!documents)
        {
            throw new BonesError(
            {
                className: 'RatingService',
                methodName: 'moveImageFromBeer',
                message: 'This rating\'s beer has no images',
            });
        }

        if (documents.length > 1)
        {
            throw new BonesError(
            {
                className: 'RatingService',
                methodName: 'moveImageFromBeer',
                message: 'This rating\'s beer has more than one image and I didn\'t feel like writing code to pick one',
            });
        }

        return this.rest.send('/rate/rating/update/moveImageFromBeer',
        {
            ratingID: rating.rating_id,
            documentID: documents[0].document_id
        })
        .then(() =>
        {
            this.gallery.cache.updated(documents[0].document_id);
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'RatingService',
                methodName: 'moveImageFromBeer',
                message: 'Move failed',
                error: error
            });
        });
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

class RatingDateGroupFactory extends BonesItemGroupFactoryBase<Rating>
{
    getBy = (item: Rating) : string =>
    {
        return item.ratingDate.toISOString();
    }

    override formatBy(by: string) : string
    {
        return formatDate(by, 'fullDate', 'en');
    }
}

export const ratingDateGroupFactory = (sort?: BonesSortOption) =>
    new RatingDateGroupFactory(sort);

export const ratingYearGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Rating, RatingInfo>((item) => String(item.ratingDate.getFullYear()), { sort: sort });

export const ratingRbScoreGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Rating, RatingInfo>((item) => item.rsc ? String(item.rsc) : '', { sort: sort });

export const ratingBreweryGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Rating, RatingInfo>((item) => item.beer?.brewery?.row.name ?? 'no brewery', { sort: sort });

export const ratingBeerGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Rating, RatingInfo>((item) => item.beer?.row.name ?? 'no beer', { sort: sort });

export const ratingVersionGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Rating, RatingInfo>('version', { sort: sort });

export const ratingVintageGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Rating, RatingInfo>('vintage', { sort: sort });

export const ratingVvGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Rating, RatingInfo>((item) => (item.row.vintage ?? '') + ' ' + (item.row.version ?? ''), { sort: sort });

export const ratingSourceGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Rating, RatingInfo>('source', { sort: sort });

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter
 */
export class RatingFilter
{
    constructor(public rows: Rating[])
    {
    }

    byBeer(beerID: number) : RatingFilter
    {
        if (beerID)
        {
            this.rows = this.rows.filter(r => r.row.beer_id === beerID);
        }
        return this;
    }

    byBrewery(breweryID: number) : RatingFilter
    {
        if (breweryID)
        {
            this.rows = this.rows.filter(r => r.beer?.brewery?.brewery_id === breweryID);
        }
        return this;
    }

    byStyle(style: string) : RatingFilter
    {
        if (style)
        {
            this.rows = this.rows.filter(r => r.beer?.row.style === style);
        }
        return this;
    }

    byRatingYear(year: number) : RatingFilter
    {
        if (year)
        {
            this.rows = this.rows.filter(r => r.ratingDate.getFullYear() === year);
        }
        return this;
    }

    byDaysOld(days: number) : RatingFilter
    {
        if (days)
        {
            this.rows = this.rows.filter(r => (new Date().getTime() - r.ratingDate.getTime()) / (60*60*24*1000) < days);
        }
        return this;
    }

    byMode = (mode: string) : RatingFilter => this.by('mode', mode);
    byVintage = (vintage: string) : RatingFilter => this.by('vintage', vintage);
    bySource = (source: string) : RatingFilter => this.by('source', source);

    by(key: keyof RatingInfo, value: string) : RatingFilter
    {
        if (value)
        {
            this.rows = this.rows.filter(r => r.row[key] === value);
        }

        return this;
    }

    byKeyword(phrase: string) : RatingFilter
    {
        if (phrase)
        {
            this.rows = new BonesSearch<Rating>(this.rows, phrase)
            .execute(row =>
            [
                dpget(row, 'row', 'vintage'),
                dpget(row, 'row', 'version'),
                dpget(row, 'row', 'mode'),
                dpget(row, 'row', 'source'),
                dpget(row, 'row', 'note'),
                
                dpget(row, 'row', 'appearance'),
                dpget(row, 'row', 'aroma'),
                dpget(row, 'row', 'body'),
                dpget(row, 'row', 'drinkability'),
                dpget(row, 'row', 'flavor'),
                dpget(row, 'row', 'overall'),

                dpget(row, 'beer', 'row', 'name'),
                dpget(row, 'beer', 'row', 'style'),

                dpget(row, 'beer', 'brewery', 'row', 'name'),
                dpget(row, 'beer', 'brewery', 'row', 'location')
            ]);
        }

        return this;
    }

}
