<ion-card>
  <ion-list class="ion-no-padding">
    <ion-item *ngFor="let action of actions" (click)="do(action)">
      <ion-icon slot="start" [name]="action.icon" color="secondary"></ion-icon>
      <ion-label>
        <h1>{{ action.title }}</h1>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-card>
