import { Injectable } from '@angular/core';

import { BonesCoreService } from '@bones/core';
import { BonesTableLocalDataOptions } from '../class/BonesTableLocalDataOptions';
import { BonesTableLocalData } from '../class/BonesTableLocalData';

/**
 * Factory to create BonesTableLocalData.
 */
@Injectable({
  providedIn: 'root',
})
export class BonesTableLocalDataFactory
{
    /**
     * @ignore
     */
    constructor(
        private bones: BonesCoreService
    )
    {
    }

    /**
     * Create a new local data manager.
     * 
     * T is the type for the rows.
     * 
     * @param options cache configurations options.
     */
    create<T>(rows: T[], options: BonesTableLocalDataOptions = { }) : BonesTableLocalData<T>
    {
        return new BonesTableLocalData<T>(
        rows,
        {
            bones: this.bones,
            // rest: this.rest
        },
        options);
    }
}

