<ion-header><app-page-header subtitle="Ratings By Year"></app-page-header></ion-header>

<ion-content>

  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="filterTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="gridTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="chartTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="sourceTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">

    <ion-row>
      <ion-col [size]="4">
        <ng-container *ngTemplateOutlet="filterTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="gridTmpl8"></ng-container>
      </ion-col>
      
      <ion-col [size]="8">
        <ng-container *ngTemplateOutlet="vbchartTmpl8"></ng-container>

        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col [size]="6">
              <ng-container *ngTemplateOutlet="pieTmpl8"></ng-container>
              <ng-container *ngTemplateOutlet="sourceTmpl8"></ng-container>
            </ion-col>

            <ion-col [size]="6">
              <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
              <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
              <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<ng-template #filterTmpl8>
  <ion-card class="ion-padding" *ngIf="filterForm">
    <ion-card-title>Settings</ion-card-title>
    <bones-form-items [form]="filterForm.form" [items]="filterForm.items"></bones-form-items>
  </ion-card>
</ng-template>

<!-- Grid -->
<ng-template #gridTmpl8>
  <bones-table #bt [title]="gridTitle" [expandable]="true" *ngIf="gridRows"
    [rows]="gridRows"
    [defaultSortOrder]="[{ propertyName: 'year', sort: 'asc' }]"
    [pageSize]="99" [pageSizes]="undefined" [showFooter]="false" [allowSearch]="false">
    <thead>
      <tr>
        <bones-table-th name="year">Year</bones-table-th>
        <bones-table-th name="sources">Sources</bones-table-th>
        <bones-table-th name="breweries">Breweries</bones-table-th>
        <bones-table-th name="beers">Beers</bones-table-th>
        <bones-table-th name="ratings">Ratings</bones-table-th>
        <bones-table-th name="avgScore">Avg Score</bones-table-th>
      </tr>
    </thead>
    <tbody *ngIf="bt.data">
      <tr *ngFor="let row of bt.data.rows">
        <td [routerLink]="['/rate/yearly-ratings/', row.year]">{{ row.year }}</td>
        <td class="ion-text-right"><a (click)="showSources(row)">{{ row.sources.size | number:'0.0-0' }}</a></td>
        <td class="ion-text-right"><a (click)="showBreweries(row)">{{ row.breweries.size | number:'0.0-0' }}</a></td>
        <td class="ion-text-right"><a (click)="showBeers(row)">{{ row.beers.size | number:'0.0-0' }}</a></td>
        <td class="ion-text-right"><a (click)="showRatings(row)">{{ row.ratings.size | number:'0.0-0' }}</a></td>
        <td class="ion-text-right">{{ row.avgScore | number:'0.1-1' }}</td>
      </tr>
    </tbody>
  </bones-table>
</ng-template>

<!-- Horizontal bar chart -->
<ng-template #chartTmpl8>
  <ion-card id="chartCard" class="ion-padding" *ngIf="chart">
    <bones-card-toolbar #ctb [title]="chartTitle" [actions]="chartCardMenu" [expandable]="true"></bones-card-toolbar>
    <ion-card-content [ngStyle]="{ height: '75em', display: 'flex' }" *ngIf="ctb.expanded">
      <ngx-charts-bar-horizontal
        [scheme]="scheme"
        [results]="chart[0].series"
        [xAxis]="true"
        [yAxis]="true"
        [showDataLabel]="true"
        [tooltipDisabled]="true"
        [legend]="chart.length > 1"
        (select)="onChartClick($event)">
      </ngx-charts-bar-horizontal>
    </ion-card-content>
  </ion-card>
</ng-template>

<!-- Vertical bar chart -->
<ng-template #vbchartTmpl8>
  <ion-card class="ion-padding" *ngIf="vchart">
    <bones-card-toolbar #vctb [title]="chartTitle" [actions]="chartCardMenu" [expandable]="true"></bones-card-toolbar>
    <ion-card-content [ngStyle]="{ height: '25em', display: 'flex' }" *ngIf="vctb.expanded">
      <ngx-charts-bar-vertical-stacked
        [scheme]="scheme"
        [results]="vchart"
        [xAxis]="true"
        [yAxis]="true"
        [showDataLabel]="true"
        [tooltipDisabled]="false"
        [legend]="false"
        (select)="onChartClick($event)">
      </ngx-charts-bar-vertical-stacked>
    </ion-card-content>
  </ion-card>
</ng-template>

<!-- Pie -->
<ng-template #pieTmpl8>
  <ion-card id="pieCard" class="ion-padding" *ngIf="pie && !sfy">
    <bones-card-toolbar #pc [title]="pieTitle" [actions]="chartCardMenu" [expandable]="true"></bones-card-toolbar>
    <ion-card-content class="ion-no-padding" [ngStyle]="{ height: '25em', display: 'flex' }" *ngIf="pc.expanded">
      <ngx-charts-pie-chart
        [scheme]="scheme"
        [results]="pie"
        [labels]="true"
        (select)="onPieClick($event)">
      </ngx-charts-pie-chart>
    </ion-card-content>
  </ion-card>
</ng-template>

<!-- Drill downs -->

<ng-template #breweryTmpl8>
  <brewery-list-card id="breweriesCard" [breweries]="breweries" [title]="cardTitle" [showBreweries]="true" *ngIf="breweries">
  </brewery-list-card>
</ng-template>

<ng-template #beerTmpl8>
  <beer-list-card id="beersCard" [beers]="beers" [title]="cardTitle" [showBreweryName]="true" *ngIf="beers">
  </beer-list-card>
</ng-template>

<ng-template #sourceTmpl8>
  <ion-card id="sourcesCard" class="ion-padding" *ngIf="sfy">
    <bones-card-toolbar #stb [expandable]="true">Sources for {{ sfy.year }}</bones-card-toolbar>
    <ion-card-content class="ion-no-padding" *ngIf="stb.expanded">
      <ion-item [detail]="true" (click)="pickSource(sfy, source)" *ngFor="let source of sfy.sources">
          <ion-label>
            <h2><ion-text color="primary">{{ source }}</ion-text></h2>
            <div>{{ sfy.map.get(source)?.size }} ratings</div>
          </ion-label>
          <!-- <ion-text slot="end">{{ sfy.map.get(source).size }}</ion-text> -->
        </ion-item>
      </ion-card-content>
    </ion-card>
</ng-template>

<ng-template #ratingTmpl8>
  <rating-list-card id="ratingsCard" [ratings]="ratings" [title]="cardTitle" [showSearchbar]="true" *ngIf="ratings"></rating-list-card>
</ng-template>
