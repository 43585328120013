<ion-header><app-page-header subtitle="Rating Detail"></app-page-header></ion-header>

<ion-content *ngIf="rating">

  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="wordsTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="featuredTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="galleryCardTmpl8"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">

    <ion-row>
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="featuredTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="galleryCardTmpl8"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="wordsTmpl8"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<!-- rating info -->
<ng-template #ratingTmpl8>
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <ion-card class="ion-padding" *ngIf="rating">
    <bones-card-toolbar title="Rating Information" [actions]="ratingCardMenu"></bones-card-toolbar>

    <div class="ion-float-right ion-text-right">
      <div *ngIf="rating.rsc > 0">{{ rating.rsc }}</div>
      <div>{{ rating.row.score }}</div>
    </div>

    <!-- deets -->
    <div>{{ rating.row.version }} {{ rating.row.vintage }}</div>
    <div>{{ rating.row.mode }}<span *ngIf="rating.row.mode && rating.row.source"> from </span>{{ rating.row.source }}</div>
    <p class="ion-text-wrap" *ngIf="rating.row.note">{{ rating.row.note }}</p>
    <div class="ion-text-wrap">
      <div *ngIf="rating.ratingDate">Rated: {{ rating.ratingDate | date:'EEE MMM d, y' }}</div>
      <div *ngIf="rating.hasBeenUpdated()"> Updated: {{ rating.lastUpdate | date:'mediumDate' }}</div>
      <div *ngIf="rating.lastUpload"> Uploaded: {{ rating.lastUpload | date:'mediumDate' }}</div>
    </div>
    <p *ngIf="rating.row.rerate">I would like to rerate this beer</p>
    <p *ngIf="rating.row.auto_score">The score was automatically generated based upon the notes</p>
  </ion-card>
</ng-template>

<!-- beer info -->
<ng-template #beerTmpl8>
  <beer-info [beer]="rating.beer" *ngIf="rating"></beer-info>
</ng-template>

<!-- words -->
<ng-template #wordsTmpl8>
  <ion-card class="ion-padding">
    <bones-card-toolbar>Words</bones-card-toolbar>
    <div *ngFor="let section of sections">
      <ion-item *ngIf="section[1] || section[2]">
        <ion-label>
          <ion-text color="primary"><div>{{ section[0] }}</div></ion-text>
          <p class="ion-text-wrap" *ngIf="section[1]">{{ section[1] }}</p>
        </ion-label>
        <span slot="end" *ngIf="section[2]">{{ section[2] }}</span>
      </ion-item>
    </div>
  </ion-card>
</ng-template>

<!-- document templates -->

<ng-template #featuredTmpl8>
  <bones-gallery-document-card [document]="document" [editable]="user.canEdit" *ngFor="let document of featuredDocuments">
  </bones-gallery-document-card>
</ng-template>

<ng-template #galleryCardTmpl8>
  <bones-gallery-card [documents]="documents" [editable]="user.canEdit" [expanded]="false" [add]="[ 'rating_id', ratingID ]"
    *ngIf="(documents | bgdFilterNotFeatured).length > 0">
  </bones-gallery-card>
</ng-template>
