import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Route } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { BonesCoreModule, BonesVersion } from '@bones/core';
import { BonesTestToolsPiglets } from '@bones/core';

import { BonesTableComponent } from './components/bones-table/bones-table';
import { BonesTableThComponent } from './components/bones-table-th/bones-table-th';
import { BonesTestToolsTableLocalPage } from './pages/test-tools-bones-table-local/test-tools-bones-table-local';
import { BonesTestToolsTableRemotePage } from './pages/test-tools-bones-table-remote/test-tools-bones-table-remote';

/**
 * Routes for form test tools.
 */
const routes: Route[] =
[
    { path: 'bones/test-tools/table/local', component: BonesTestToolsTableLocalPage },
    { path: 'bones/test-tools/table/remote', component: BonesTestToolsTableRemotePage },
];

@NgModule({
    declarations: [
        BonesTableComponent,
        BonesTableThComponent,
        BonesTestToolsTableLocalPage,
        BonesTestToolsTableRemotePage
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild(routes),
        FormsModule, ReactiveFormsModule,
        BonesCoreModule,
    ],
    // Use for services that are referenced only within this module
    // Put global provides in forRoot() method below
    providers: [
    // DatePipe
    ],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports: [
        BonesTableComponent,
        BonesTableThComponent
    ]
})
export class BonesTableModule
{
    static initialize()
    {
        BonesVersion.map.set('table', '1.3.2023-12-30');

        BonesTestToolsPiglets.tools.push(
        {
            group: 'Table',
            title: 'Local data provider',
            icon: 'grid',
            path: 'bones/test-tools/table/local'
        },
        {
            group: 'Table',
            title: 'Remote data provider',
            icon: 'cloud',
            path: 'bones/test-tools/table/remote'
        });
    }
}
BonesTableModule.initialize();
