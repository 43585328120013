import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { BonesError } from '@bones/core';
import { BonesRestInterface } from '@bones/network';
import { BonesNetworkService, BnsResponse } from '@bones/network';
import { ApeRestReply } from '../class/ApeRestReply';

/**
 * Access backend rest web services built using ApeWebServlet
 */
@Injectable({
  providedIn: 'root',
})
export class ApeRest implements BonesRestInterface
{
    private config:
    {
        restServiceUrl: string | undefined,
        username: string | undefined,
        password: string | undefined
    }
    =
    {
        restServiceUrl: undefined,
        username: undefined,
        password: undefined
    };

    constructor(
        private loadingCtrl: LoadingController,
        private rest: BonesNetworkService
    )
    {
    }

    /**
     * Set URL for server rest services
     */
    get serverUrl() : string | undefined
    {
        return this.config.restServiceUrl;
    }

    /**
     * Set URL for server rest services
     */
    set serverUrl(url: string | undefined)
    {
        this.config.restServiceUrl = url;
        console.log('set backend rest service: ', url);
    }

    /**
     * Get URL for server rest services
     */
    getServerUrl() : string | undefined
    {
        return this.serverUrl;
    }

    set username(username: string)
    {
        this.config.username = username;
    }

    set password(password: string)
    {
        this.config.password = password;
    }

    /**
     * Send request
     */
    async send(url: string, post: any = { }) : Promise<any>
    {
        // const loading = await this.loadingCtrl.create();
        // loading.present();

        if (!this.config.restServiceUrl)
        {
            throw new BonesError(
            {
                className: 'ApeRest',
                methodName: 'send',
                message: 'ApeRest service has not been configured with restServiceUrl'
            })
        }

        // Call web service
        const reply: ApeRestReply = await this.rest.post(this.config.restServiceUrl, url, post,
        {
            returnValue: 'bns',
            headers: 'x-creds: ' + this.config.username + ';' + this.config.password
        })
        .then((bns: BnsResponse) =>
        {
            // Get the payload from the rest service
            return bns.bnsResponseInfo.payload;
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'ApeRest',
                methodName: 'send',
                message: 'web service failed',
                error: error
            })
            .add({ url, post });
        });
        // .finally(() => loading.dismiss());

        // Did we get a status?
        if (!reply.status)
        {
            throw new BonesError(
            {
                className: 'ApeRest',
                methodName: 'send',
                message: 'no status'
            })
            .add({ url, post });
        }

        // Rest service failed
        if (reply.status.rc !== 1)
        {
            throw new BonesError(
            {
                className: 'ApeRest',
                methodName: 'send',
                message: reply.status.message
            })
            .add({ url, post });
        }

        return reply.payload;
    }

    //-----------------------------------------------------------------------

}
