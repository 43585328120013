import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

/**
 * Routes for module
 */
const routes: Route[] =
[
    {
        path: 'bones/gallery',
        // canActivate: [ AuthGuard ],
        children:
        [
            // { path: 'idea-detail', component: IdeaDetailPage },

            // { path: 'restaurant-detail/:restaurantID', component: RestaurantDetailPage },
        ]
    }
];

@NgModule({
    imports:
    [
        RouterModule.forChild(routes),
    ],
    exports:
    [
        RouterModule
    ]
})
export class BonesGalleryRoutingModule
{
}
