import { Component, Input, OnInit, Optional } from '@angular/core';

import { BonesError, BonesSortOption, BonesSortAs } from '@bones/core';

import { BonesTableComponent } from '../bones-table/bones-table';

/**
 * Turn a regular HTML table into an interactive one with data paging, sorting, and searching.
 * 
 * @example
 * <bones-table-th name="MY_COLUMN">My Column</bones-table-th>
 */
@Component({
    selector: 'bones-table-th',
    templateUrl: 'bones-table-th.html',
    styleUrls: [ 'bones-table-th.scss' ]
})
export class BonesTableThComponent implements OnInit
{
    /**
     * Property name of column in data row object
     */
    @Input() name: string;
    /**
     * Title to display for this column
     */
    @Input() title: string;
    /**
     * Can the table be re-sorted based upon this column? Default true when name is provided.
     */
    @Input() sortable = true;
    /**
     * Alter sort logic.
     */
    @Input() sortAs: BonesSortAs;
    /**
     * Can the table be searched based upon this column? Default true when name is provided.
     */
    @Input() searchable = true;

    /**
     * Current sort setting
     */
    sort: BonesSortOption = 'none';

    /**
     * @ignore
     */
    constructor(
        @Optional() private bt: BonesTableComponent
    )
    {
    }

    /**
     * @ignore
     */
    async ngOnInit()
    {
        // Some options require a data property
        if (!this.name)
        {
            this.sortable = false;
            this.searchable = false;
        }

        // Register this column with the table component
        if (this.bt)
        {
            this.bt.addColumn(this);
        }
        else
        {
            throw new BonesError(
            {
                className: 'BonesTableThComponent',
                methodName: 'ngOnInit',
                message: 'No parent <bones-table> component was found'
            });
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Toggle sort setting between various options
     * @param event dom event
     */
    toggleSort(event: MouseEvent)
    {
        // console.log('event', event);
        if (this.sortable)
        {
            switch (this.sort)
            {
                case 'none':
                    this.sort = 'asc';
                    break;
                case 'asc':
                    this.sort = 'desc';
                    break;
                case 'desc':
                    this.sort = 'asc';
                    break;
            }

            this.bt.setSort(this, event.shiftKey);
        }
    }

    //-----------------------------------------------------------------------
}
