import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { BonesError } from '../model/bones-error';
import { BonesErrorType } from '../model/bones-error-type';

type errorCallback = (error: BonesError) => void;

/**
 * Error handler
 */
@Injectable({
  providedIn: 'root'
})
export class BonesErrorService
{
    private errorHistory = new Array<BonesError>();
    private callback: errorCallback;

    /**
     * @ignore
     */
    constructor(
        private alertCtrl: AlertController,
    )
    {
    }

    /**
     * Subscribe to error notifications.
     * 
     * @param callback Function to be called with BonesError object
     */
    public subscribe(callback: errorCallback) : void
    {
        this.callback = callback;
    }

    /**
     * Get recent errors.
     * 
     * @returns Array of recent BonesError objects.
     */
    public getErrorHistory() : Array<BonesError>
    {
        return this.errorHistory;
    }

    /**
     * Get error by ID.
     * 
     * @param id Error ID
     * 
     * @returns BonesError object.
     */
    public getError(id: number) : BonesError
    {
        // Try and find match in top level error
        let match = this.errorHistory.find(error => error.id === id);

        // Dig into nested errors to see if we have a match
        if (!match)
        {
            this.errorHistory.forEach(error =>
            {
                let nested: BonesErrorType = error;
                let deep = 0;
                while (nested && deep++ < 10)
                {
                    if ((typeof nested === 'object') && ('id' in nested) &&  nested.id === id)
                    {
                        match = nested;
                    }

                    // Get next level if any
                    nested = (nested instanceof BonesError) ? nested.error : undefined;
                }
            });
        }

        return match;
    }

    //-----------------------------------------------------------------------

    /**
     * Handle application errors.
     * 
     * @param error Error to be "handled".
     * @param action Action to take.
     * 
     * The default of "alert" will log the error and alert the user with a dialog box.
     * 
     * The "silent" option will log the error without notifying the user.
     */
    public async errorHandler(error: BonesError, action: 'alert' | 'silent' = 'alert')
    {
        // Log the full error to the console
        console.error('bones error', error);

        // Save the error to the stack of errors
        this.errorHistory.unshift(error);
        if (this.errorHistory.length > 10)
        {
            this.errorHistory.length = 10;
        }

        // Log nested errors to console
        let nested: BonesErrorType = error;
        let deep = 0;
        while (nested && deep++ < 10)
        {
            // Log cause for this level
            console.log('caused by', deep, BonesError.extractMessage(nested));

            // Get next level if any
            nested = (nested instanceof BonesError) ? nested.error : undefined;
        }

        // Display error message
        if (action === 'alert')
        {
            const alert = await this.alertCtrl.create(
            {
                header: 'Application Error',
                message: error.message,
                buttons:
                [
                    {
                        text: 'OK',
                        role: 'cancel',
                        handler: () =>
                        {
                            // if (action === 'back')
                            // {
                            //     this.navCtrl.pop();
                            // }
                            // if (action === 'home')
                            // {
                            //     this.navCtrl.setRoot(HomePage);
                            // }
                        }
                    }
                ]
            });

            alert.present();
        }

        // Notify something of the error, maybe to log the error to the server
        if (this.callback)
        {
            this.callback(error);
        }
    }

    //-----------------------------------------------------------------------


}
