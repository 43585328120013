<ion-header><modal-header title="Edit Glassware" [editForm]="this"></modal-header></ion-header>

<ion-content [formGroup]="form" *ngIf="form">

  <ion-card class="ion-padding">
    <ion-card-title>Brewery</ion-card-title>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('brewery_id')"></bones-form-item>
    <ion-item *ngIf="brewery">
      <ion-label>{{ brewery.row.location }}</ion-label>
    </ion-item>
  </ion-card>

  <ion-card class="ion-padding">
    <ion-card-title>Demographics</ion-card-title>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('glass')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('style')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('quantity')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('notes')"></bones-form-item>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="isAdd">
    <ion-card-title>Images</ion-card-title>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('image_front')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('image_back')"></bones-form-item>
  </ion-card>

</ion-content>

<ion-footer>
  <bones-edit-button-bar [editor]="this"></bones-edit-button-bar>
</ion-footer>
