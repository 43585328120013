<ion-header><app-page-header subtitle="Glassware Detail"></app-page-header></ion-header>

<ion-content *ngIf="glassware">

  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="glasswareTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="featuredTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="galleryCardTmpl8"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">

    <ion-row>
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="glasswareTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="galleryCardTmpl8"></ng-container>
      </ion-col>

      <ion-col size="8" class="two-by">
        <ng-container *ngTemplateOutlet="featuredTmpl8"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<!-- glassware info -->
<ng-template #glasswareTmpl8>
  <ion-card class="ion-padding" *ngIf="glassware">
    <bones-card-toolbar title="Glassware Information" [actions]="cardMenu"></bones-card-toolbar>

    <ion-item [detail]="true" [routerLink]="['/rate/brewery', glassware.brewery.brewery_id ]" *ngIf="glassware.brewery">
      <ion-label>Brewery</ion-label>
      <ion-text slot="end">{{ glassware.brewery.name }}</ion-text>
    </ion-item>
    <ion-item>
      <ion-label>Glass</ion-label>
      <ion-text slot="end">{{ glassware.row.glass }}</ion-text>
    </ion-item>
    <ion-item>
      <ion-label>Style</ion-label>
      <ion-text slot="end">{{ glassware.row.style }}</ion-text>
    </ion-item>
    <ion-item>
      <ion-label>Quantity</ion-label>
      <ion-text slot="end">{{ glassware.row.quantity }}</ion-text>
    </ion-item>

    <p class="kps-pre-line" *ngIf="glassware.row.notes">{{ glassware.row.notes }}</p>
  </ion-card>
</ng-template>

<!-- document templates -->

<ng-template #featuredTmpl8>
  <bones-gallery-document-card [document]="document" [editable]="user.canEdit" *ngFor="let document of featuredDocuments">
  </bones-gallery-document-card>
</ng-template>

<ng-template #galleryCardTmpl8>
  <bones-gallery-card [documents]="documents" [editable]="user.canEdit" [expanded]="false" [add]="[ 'glassware_id', glasswareID ]"
    *ngIf="(documents | bgdFilterNotFeatured).length > 0">
  </bones-gallery-card>
</ng-template>
