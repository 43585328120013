<ion-header><app-page-header subtitle="Inventory Detail"></app-page-header></ion-header>

<ion-content *ngIf="inventory">

  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="inventoryTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="featuredTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="galleryCardTmpl8"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">

    <ion-row>
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="inventoryTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="galleryCardTmpl8"></ng-container>
      </ion-col>

      <ion-col size="8" class="two-by">
        <ng-container *ngTemplateOutlet="featuredTmpl8"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<!-- inventory info -->
<ng-template #inventoryTmpl8>
  <ion-card class="ion-padding" *ngIf="inventory">
    <bones-card-toolbar title="Inventory Information" [actions]="cardMenu"></bones-card-toolbar>

    <ion-item [detail]="true" [routerLink]="['/rate/brewery', inventory.brewery.brewery_id ]" *ngIf="inventory.brewery">
      <ion-label>Brewery</ion-label>
      <ion-text slot="end">{{ inventory.brewery.name }}</ion-text>
    </ion-item>
    <ion-item [detail]="true" [routerLink]="['/rate/beer', inventory.beer.beer_id ]" *ngIf="inventory.beer">
      <ion-label>Beer</ion-label>
      <ion-text slot="end">{{ inventory.beer.name }}</ion-text>
    </ion-item>
    <ion-item *ngIf="inventory.row.beer_name">
      <ion-label>Legacy Name</ion-label>
      <ion-text slot="end">{{ inventory.row.beer_name }}</ion-text>
    </ion-item>
    <ion-item *ngIf="inventory.row.vintage">
      <ion-label>Vintage</ion-label>
      <ion-text slot="end">{{ inventory.row.vintage }}</ion-text>
    </ion-item>
    <ion-item *ngIf="inventory.row.version">
      <ion-label>Version</ion-label>
      <ion-text slot="end">{{ inventory.row.version }}</ion-text>
    </ion-item>
    <ion-item *ngIf="inventory.row.size">
      <ion-label>Size</ion-label>
      <ion-text slot="end">{{ inventory.row.size }}</ion-text>
    </ion-item>
    <ion-item *ngIf="inventory.row.location">
      <ion-label>Location</ion-label>
      <ion-text slot="end">{{ inventory.row.location }}</ion-text>
    </ion-item>
    <ion-item>
      <ion-label>Quantity</ion-label>
      <ion-text slot="end">{{ inventory.row.quantity }}</ion-text>
    </ion-item>

    <p class="kps-pre-line" *ngIf="inventory.row.notes">{{ inventory.row.notes }}</p>
  </ion-card>
</ng-template>

<!-- beer info -->
<ng-template #beerTmpl8>
  <beer-info [beer]="inventory?.beer"></beer-info>
</ng-template>

<!-- document templates -->

<ng-template #featuredTmpl8>
  <bones-gallery-document-card [document]="document" [editable]="user.canEdit" *ngFor="let document of featuredDocuments">
  </bones-gallery-document-card>
</ng-template>

<ng-template #galleryCardTmpl8>
  <bones-gallery-card [documents]="documents" [editable]="user.canEdit" [expanded]="false" [add]="[ 'inventory_id', inventoryID ]"
    *ngIf="(documents | bgdFilterNotFeatured).length > 0">
  </bones-gallery-card>
</ng-template>
