<ion-header><bones-tt-page-header title="Bones Form"></bones-tt-page-header></ion-header>

<ion-content [formGroup]="form">
    
  <ion-card class="ion-padding">
    <ion-card-title>Simple Form</ion-card-title>
    <ion-list>

      <ion-item>
        <ion-label position="stacked">Field One</ion-label>
        <ion-input formControlName="field1"></ion-input>
      </ion-item>
      <bones-form-error [form]="form" control="field1">Required</bones-form-error>

      <ion-item>
        <ion-label position="stacked">Another Field</ion-label>
        <ion-input formControlName="anotherField"></ion-input>
      </ion-item>
      <bones-form-error [form]="form" control="anotherField">Required</bones-form-error>
      <bones-form-error [form]="form" control="anotherField" error="minlength">Must enter at least 12 characters</bones-form-error>

    </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="display">
    <ion-card-title>Form Contents</ion-card-title>
    <pre>{{ display | json }}</pre>
  </ion-card>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" [disabled]="form.invalid" (click)="button1()">Submit</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
