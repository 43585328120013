export type BonesPickerReturnAction = 'cancel' | 'clear' | 'value';
export type BonesPickerReturnValue = (string | number) | (string | number)[];

/**
 * Modal return value for a picker.
 */
export class BonesPickerReturn
{
    /**
     * User action that was taken.
     */
    action: BonesPickerReturnAction;

    /**
     * Picker return value(s).
     */
    value: BonesPickerReturnValue;

    /**
     * Create return object to send back to application.
     * 
     * @param action User action that was taken.
     * @param value Picker return value(s).
     */
    constructor(action: BonesPickerReturnAction, value?: BonesPickerReturnValue)
    {
        this.action = action;
        this.value = value;
    }
}
