<ion-header><app-page-header [subtitle]="mainTitle"></app-page-header></ion-header>

<ion-content>

  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="mainTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="searchTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="chartTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="recentTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">
    <ion-row>
      <ion-col>
        <ng-container *ngTemplateOutlet="searchTmpl8"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="3">
        <ng-container *ngTemplateOutlet="mainTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
      </ion-col>

      <ion-col size="5">
        <ng-container *ngTemplateOutlet="chartTmpl8"></ng-container>

        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col size="7">
              <ng-container *ngTemplateOutlet="pieTmpl8"></ng-container>
            </ion-col>
            <ion-col size="5">
              <ng-container *ngTemplateOutlet="groupSummaryTmpl8"></ng-container>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="recentTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<ng-template #mainTmpl8>
  <ion-card class="ion-padding">
    <bones-card-toolbar #mct [title]="mainTitle" [expandable]="true" [expanded]="!monkey.isSmallDevice">
    </bones-card-toolbar>

    <ion-card-content class="ion-no-padding" *ngIf="mct.expanded">
      <bones-form-items [form]="filterForm.form" [items]="filterForm.items"></bones-form-items>
      <div class="ion-text-right" *ngIf="!fixedYearMode">
        <ion-button (click)="filterForm.resetValues()">Clear</ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ng-template>

<ng-template #recentTmpl8>
  <rating-list-card title="All Ratings" [ratings]="filteredRatings" *ngIf="showAllRatingsCard">
  </rating-list-card>
</ng-template>

<ng-template #ratingTmpl8>
  <rating-list-card [title]="ratingDrillDownTitle ?? 'Ratings'" [ratings]="ratings" *ngIf="ratings">
  </rating-list-card>
</ng-template>

<ng-template #allGroupsTmpl8>
  <rating-list-card [title]="group.title" [ratings]="group.ratings" [showRatings]="false" *ngFor="let group of ratingGroups">
  </rating-list-card>
</ng-template>

<ng-template #breweryTmpl8>
  <brewery-list-card title="Breweries" [breweries]="breweries" [showBreweries]="false"></brewery-list-card>
</ng-template>

<ng-template #beerTmpl8>
  <beer-list-card title="Beers" [beers]="beers" [showBreweryName]="true"></beer-list-card>
</ng-template>

<ng-template #groupSummaryTmpl8>
  <ion-card class="ion-padding" *ngIf="chart">
    <bones-card-toolbar #gs title="Rating Groups" [expandable]="true"></bones-card-toolbar>
    <ion-card-content class="ion-no-padding" *ngIf="gs.expanded">
      <ion-item [detail]="true" (click)="showAll()">
        <ion-label>
          <ion-text color="secondary"><h2>All Ratings</h2></ion-text>
          <p>{{ filteredRatings.length }} ratings</p>
        </ion-label>
      </ion-item>
      <ion-item [detail]="true" (click)="showGroup(group)" *ngFor="let group of ratingGroups">
        <ion-label>
          <ion-text color="secondary"><h2>{{ group.title }}</h2></ion-text>
          <p>{{ group.ratings.length }} ratings</p>
        </ion-label>
      </ion-item>
    </ion-card-content>
  </ion-card>
</ng-template>

<!-- Chart -->
<ng-template #chartTmpl8>
  <ion-card id="chartCard" class="ion-padding" *ngIf="chart">
    <bones-card-toolbar #cc [title]="chartTitle ?? 'Chart'" [expandable]="true" [expanded]="monkey.isLargeDevice">
    </bones-card-toolbar>
    <ion-card-content class="ion-no-padding" [ngStyle]="{ height: chartHeight, display: 'flex' }" *ngIf="cc.expanded">
      <ngx-charts-bar-horizontal-stacked
        [scheme]="scheme"
        [results]="chart"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        legendTitle=""
        [showDataLabel]="true"
        [noBarWhenZero]="false"
        [tooltipDisabled]="false"
        (select)="onChartClick($event)">
      </ngx-charts-bar-horizontal-stacked>
    </ion-card-content>
  </ion-card>
</ng-template>

<!-- Pie -->
<ng-template #pieTmpl8>
  <ion-card id="pieCard" class="ion-padding" *ngIf="pie">
    <bones-card-toolbar #pc [title]="'New Ratings vs Rerates'" [expandable]="true"></bones-card-toolbar>
    <ion-card-content class="ion-no-padding" [ngStyle]="{ height: '15em', display: 'flex' }" *ngIf="pc.expanded">
      <ngx-charts-advanced-pie-chart
        [scheme]="scheme"
        [results]="pie"
        label="Total Ratings"
        (select)="onPieClick($event)">
      </ngx-charts-advanced-pie-chart>
    </ion-card-content>
  </ion-card>
</ng-template>

<ng-template #searchTmpl8>
  <ion-searchbar [(ngModel)]="searchTerm" debounce="500"></ion-searchbar>
</ng-template>
