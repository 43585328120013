<ion-list>
  <ion-item-sliding *ngFor="let rating of ratingList">

    <ion-item lines="full" (click)="showRating(rating)">
      <ion-thumbnail style="height: 5em;" slot="start">
        <img [bonesGalleryDocumentID]="getThumbnail(rating)" [bonesGalleryDocumentSize]="gallery.imageSizeThumbnail" loading="lazy" *ngIf="getThumbnail(rating)">
      </ion-thumbnail>

      <ion-label *ngIf="rating.beer">
        <p>Rated {{ rating.ratingDate | date:'EEE MMM d, y' }}</p>
        <a class="ion-text-wrap" *ngIf="showBeerName" (click)="beerDetail(rating.row.beer_id, $event)">{{ rating.beer.row.name }}</a>
        <p *ngIf="showBreweryName && rating.beer?.brewery">
          <a class="ion-text-wrap" (click)="breweryDetail(rating.beer.brewery.brewery_id, $event)" *ngIf="rating.beer.brewery">{{ rating.beer.brewery.row.name }}</a>
        </p>
        <p>
          <ion-text>{{ rating.beer.row.style }}</ion-text>
          <span *ngIf="rating.row.abv > 0"> @ {{ rating.row.abv }}%</span>
          <span *ngIf="!rating.row.abv && rating.beer.row.abv ?? 0 > 0"> @ {{ rating.beer.row.abv }}%</span>
        </p>
        <p *ngIf="rating.row.version">{{ rating.row.version }}</p>
        <p>{{ rating.row.mode }}<span *ngIf="rating.row.mode && rating.row.source"> from </span>{{ rating.row.source }}</p>
      </ion-label>

      <div class="ion-text-right" slot="end">
        <div *ngIf="rating.rsc > 0">
          <div><rating-stars [score]="rating.rsc" [fractional]="true"></rating-stars></div>
          <div>{{ rating.rsc }}</div>
        </div>
        <div *ngIf="rating.row.score">{{ rating.row.score }}</div>
        <div *ngIf="rating.row.vintage">{{ rating.row.vintage }}</div>
      </div>
    </ion-item>

    <ion-item-options>
      <ion-item-option (click)="showRating(rating)">
        <ion-icon slot="top" size="large" name="eye"></ion-icon>
        Detail
      </ion-item-option>
      <ion-item-option (click)="breweryDetail(rating.beer.brewery.brewery_id, $event)" *ngIf="rating.beer && rating.beer.brewery">
        <ion-icon slot="top" size="large" name="flask"></ion-icon>
        Brewery
      </ion-item-option>
      <ion-item-option (click)="beerDetail(rating.row.beer_id, $event)">
        <ion-icon slot="top" size="large" name="beer"></ion-icon>
        Beer
      </ion-item-option>
      <ion-item-option (click)="launch.editRating(rating)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="pencil"></ion-icon>
        Edit
      </ion-item-option>
      <!-- <ion-item-option (click)="launch.editRating(rating)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="copy"></ion-icon>
        Copy
      </ion-item-option> -->
    </ion-item-options>

  </ion-item-sliding>
</ion-list>