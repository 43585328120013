<ion-header><bones-tt-page-header title="Bones Document"></bones-tt-page-header></ion-header>

<ion-content [formGroup]="form">
  <ion-grid>
    <ion-row>
      <ion-col size="3">
        <bones-tt-edsgw-config-card></bones-tt-edsgw-config-card>
      </ion-col>

      <!-- BonesDocumentService -->
      <ion-col size="3">
        <ion-card class="ion-padding">
          <ion-card-title>Request</ion-card-title>
          <ion-list>
            <ion-item>
              <ion-label position="stacked">documentId</ion-label>
              <ion-input formControlName="documentId"></ion-input>
            </ion-item>
          </ion-list>

          <ion-button fill="solid" color="primary"
            [disabled]="!form.valid || !(brest.directEnabled || edsgw.isAuthenticated)"
            (click)="getInfo()">Get Info</ion-button>
          <ion-button fill="solid" color="primary"
            [disabled]="!form.valid || !(brest.directEnabled || edsgw.isAuthenticated)"
            (click)="getDocument()">Get Document</ion-button>
        </ion-card>

        <ion-card class="ion-padding" *ngIf="docInfo">
          <ion-card-title>Document Information</ion-card-title>
          <pre>{{docInfo | json}}</pre>
        </ion-card>

        <ion-card class="ion-padding" *ngIf="docInfo?.dataUrl && docInfo?.dataUrl.substr(0,10) === 'data:image'">
          <img [src]="docInfo.dataUrl">
        </ion-card>

        <bones-tt-error-card [error]="error"></bones-tt-error-card>
      </ion-col>
      
      <!-- Read-write document -->
      <ion-col size="3">
        <ion-card class="ion-padding">
          <ion-card-title>Document (rw)</ion-card-title>
          <bones-document
                style="width: 8em; height: 10em;"
                [info]="documentInfo1"
                [options]="{ maxSize: 50, maxWidth: 135, maxHeight: 200 }"
                (documentChange)="onDocChange1($event)"
                [rw]="true">
          </bones-document>
        </ion-card>

        <ion-card class="ion-padding" *ngIf="documentInfo1">
          <ion-card-title>Document Information</ion-card-title>
          <pre>{{ documentInfo1 | json }}</pre>
        </ion-card>
      </ion-col>

      <!-- Read-only document -->
      <ion-col size="3">
        <ion-card class="ion-padding">
          <ion-card-title>Document (ro)</ion-card-title>
          <bones-document
                style="width: 8em; height: 10em;"
                [documentID]="documentID2"
                (documentLoaded)="onDocumentLoaded2($event)">
          </bones-document>
          <ion-item>
            <ion-label position="stacked">Document ID</ion-label>
            <ion-input formControlName="documentID2" (ionChange)="onIdChange2($event)"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label>OWP PDF Document</ion-label>
            <div slot="end">1</div>
          </ion-item>
          <ion-item>
            <ion-label>OWP Image Document</ion-label>
            <div slot="end">6324</div>
          </ion-item>
        </ion-card>

        <ion-card class="ion-padding" *ngIf="documentInfo2 || documentID2">
          <ion-card-title>Document Information</ion-card-title>
          <div *ngIf="documentID2">ID: {{ documentID2 }}</div>
          <pre>{{ documentInfo2 | json }}</pre>
        </ion-card>
      </ion-col>

    </ion-row>
  </ion-grid>

</ion-content>
