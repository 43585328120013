import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BonesCache, BonesItemGroupFactory, BonesSortOption, BonesSearch, BonesError, BonesCacheFactory, BonesMenuCardAction } from '@bones/core';
import { BonesEditForm, BonesEditFormLaunchResults } from '@bones/edit';

import { BonesGallerySettings } from '../class/BonesGallerySettings';
import { BonesGalleryDocument } from '../class/BonesGalleryDocument';
import { BonesGalleryDocumentInfo } from '../class/BonesGalleryDocumentInfo';
import { BonesGalleryDocumentEditModal } from '../component/bones-gallery-document-edit/bones-gallery-document-edit';

/**
 * A foreign key name/value pair
 */
interface BonesGalleryKeyPair2
{
    /**
     * The property name (i.e. column name) of the foreign key
     */
    foreignKeyName: string;
    /**
     * The value of a foreign key
     */
    foreignKeyValue: number | string | number[] | undefined;
}

type CacheType = BonesCache<number, BonesGalleryDocumentInfo, BonesGalleryDocument>;

/**
 * Service to access and manage a cache of gallery documents
 */
@Injectable({
  providedIn: 'root',
})
export class BonesGalleryService
{
    /**
     * URL to retrieve all documents from the server
     */
    static urlGetAllDocuments = 'ape-gallery/document/getDocuments';
    /**
     * URL to retrieve a specific document from the server
     */
    static urlGetOneDocument = 'ape-gallery/document/getDocument';
    /**
     * URL to download the image for a specific document
     */
    static urlGetImage = 'ape-gallery/image/get';

    /**
     * Standard download image size for thumbnail images used on lists
     */
    public imageSizeThumbnail = '60x80';
    /**
     * Standard download image size for logos
     */
    public imageSizeLogo = '100x100';
    /**
     * Standard download image size for bones-gallery-document-card
     */
    public imageSizeCard = '350x460';
    /**
     * Standard download image size for bones-gallery-card
     */
    public imageSizeSmallGallery = '100x100';
    /**
     * Standard download image size for bones-gallery-large
     */
    public imageSizeLargeGallery = '250x250';

    private _settings?: BonesGallerySettings;
    private _cache?: CacheType;

    /**
     * @ignore
     */
    constructor(
        private modalCtrl: ModalController,
        private bcf: BonesCacheFactory
    )
    {
    }

    //-----------------------------------------------------------------------

    /**
     * @returns currently defined settings for this service
     * @throws BonesError if the settings have yet to be defined
     */
    get settings() : BonesGallerySettings
    {
        if (!this._settings)
        {
            throw new BonesError(
            {
                className: 'BonesGalleryServiceBase',
                methodName: 'get settings',
                message: 'BonesGalleryService settings have not yet been initialized',

            });
        }
        return this._settings;
    }
    /**
     * Configure this service by defining required settings
     * 
     * @param settings populated settings object
     */
    set settings(settings: BonesGallerySettings)
    {
        this._settings = settings;
    }

    /**
     * @returns The underlying document cache
     */
    get cache() : CacheType
    {
        if (!this._cache)
        {
            throw new BonesError(
            {
                className: 'BonesGalleryServiceBase',
                methodName: 'get cache',
                message: 'BonesGalleryService settings have not yet been initialized',

            });
        }
        return this._cache;
    }
    // set cache(settings: CacheType)
    // {
    //     this._cache = settings;
    // }

    //-----------------------------------------------------------------------

    /**
     * Initialize the service
     * 
     * @param settings Service configuration
     */
    init(settings: BonesGallerySettings)
    {
        this.settings = settings;

        // Create document cache
        this._cache = this.bcf.create<number, BonesGalleryDocumentInfo, BonesGalleryDocument>(
        {
            pk: 'document_id',
            fk: this.settings.foreignKeyNames,
            loadCache: () => this.settings.rest.send(BonesGalleryService.urlGetAllDocuments),
            reloadOne: (id: number) => this.settings.rest.send(BonesGalleryService.urlGetOneDocument, { documentID: id }),
            converter: async row => new BonesGalleryDocument(row),
            sorter: (a, b) => a.row.name.localeCompare(b.row.name)
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Get specific document for a given documentID
     * 
     * @param documentID The primary key of the document
     * @returns a promise containing the document object or undefined if the primary key is invalid
     */
    async getDocument(documentID: number) : Promise<BonesGalleryDocument | undefined>
    {
        return documentID ? this.cache.getEntry(documentID) : undefined;
    }

    /**
     * Open the full size document in a new window/tab.
     * 
     * @param documentID The primary key of the document (can be undefined/null which will cause nothing to silently occur)
     */
    open(documentID?: number | null) : void
    {
        if (documentID)
        {
            window.open(this.settings.rest.getServerUrl() + '/' + BonesGalleryService.urlGetImage + '?documentID=' + documentID);
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Find the best image to use as a thumbnail.
     * 
     * Each foreign key and value(s) are searched in order to find the first featured image.
     * If no featured image is found, the fist non-featured image that was found is retirned.
     * 
     * Note that this is a synchronus operation and does not guarentee that the cache was fully loaded first.
     * 
     * @param keys one or more of key/value pairs
     * @returns best matching image or undefined if no images were found
     */
    thumbnailPicker(...keys: BonesGalleryKeyPair2[]) : number | undefined
    {
        let fallbackID: number | undefined;

        // Search through key/value pairs in order
        for (let i = 0; (i < keys.length); ++i)
        {
            const fk = keys[i];

            if (fk.foreignKeyValue)
            {
                // Value may be either a scalar or an array; coerce into an array for consistancy
                const coerse = Array.isArray(fk.foreignKeyValue) ? fk.foreignKeyValue : [ fk.foreignKeyValue ];

                // Look through each of the values
                for (let j = 0; (j < coerse.length); ++j)
                {
                    const fkv = coerse[j];

                    // Get list of matching documents for this foreign key value
                    let documents = this.cache.getListByForeignKey({ foreignKeyName: fk.foreignKeyName, foreignKeyValue: fkv });

                    // Limit results to only images
                    documents = documents?.filter(d => d.isImage);

                    // Were any images found for this foreign key?
                    if (documents && documents.length > 0)
                    {
                        // Fist check for a logo as that takes top priority
                        const logo = documents.find(d => d.isLogo);
                        if (logo)
                        {
                            return logo.document_id;
                        }

                        // Next check for a fetaured image as that takes second priority
                        const featured = documents.find(d => d.isFeatured);
                        if (featured)
                        {
                            return featured.document_id;
                        }

                        // Save non-featured image as a fallback choice if no featured images are found in the search
                        if (!fallbackID)
                        {
                            fallbackID = documents[0].document_id;
                        }
                    }
                }
            }
        }

        // Return fallback choice as there were no featured images to be found
        return fallbackID;
    }

    //-----------------------------------------------------------------------

    /**
     * Create a menu item for adding a new document
     * 
     * @param foreignKeyName name of foreign key to associate with this document
     * @param foreignKeyValue value of foreign key
     * @param getTitle an optional function to return a default title for this document; function is executed at time of menu invokation
     * 
     * @returns menu item that can be added to a menu
     */
    public getMenuItemAddDocument(foreignKeyName: keyof BonesGalleryDocumentInfo, foreignKeyValue: number,
        getTitle?: () => string | undefined) : BonesMenuCardAction
    {
        return {
            title: 'Add Image or Attachment',
            icon: 'add-circle',
            action: () => this.addDocument(foreignKeyName, foreignKeyValue, getTitle)
        };
    }

    /**
     * Add row
     */
    /**
     * Open a modal to add a new document
     * 
     * @param foreignKeyName name of foreign key to associate with this document
     * @param foreignKeyValue value of foreign key
     * @param getTitle an optional function to return a default title for this document; function is executed at time of menu invokation
     */
    public async addDocument(foreignKeyName: keyof BonesGalleryDocumentInfo, foreignKeyValue: number,
        getTitle?: () => string | undefined) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: BonesGalleryDocumentEditModal,
            moData:
            {
                foreignKeyName,
                foreignKeyValue,
                title: getTitle ? getTitle() : undefined
            }
        });
    }

    /**
     * Open a modal to edit an existing document
     * 
     * @param document The document to edit
     * 
     * @returns Results of the edit modal (canceled, updated, etc)
     */
    public async editDocument(document?: BonesGalleryDocument) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: BonesGalleryDocumentEditModal,
            pk: document ? document.document_id : 0
        });
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * A bones group factory type specific for documents
 */
export type BonesGalleryDocumentGroupFactory = BonesItemGroupFactory<BonesGalleryDocument, BonesGalleryDocumentInfo>;

/**
 * Function to create a groups of documents based upon the first letter of the document name
 * @param sort Sorting options
 */
export const documentNameGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<BonesGalleryDocument, BonesGalleryDocumentInfo>('name', { sort: sort, byFirstLetter: true });

/**
 * Function to create a groups of documents based upon the first letter of the document title
 * @param sort Sorting options
 */
export const documentTitleGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<BonesGalleryDocument, BonesGalleryDocumentInfo>('title', { sort: sort, byFirstLetter: true });

/**
 * Function to create a groups of documents based upon the document content type
 * @param sort Sorting options
 */
export const documentContentTypeGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<BonesGalleryDocument, BonesGalleryDocumentInfo>('content_type', { sort: sort });

/**
 * Function to create a single group of documents (basically ungrouped but following the grouping data model)
 * @param title Group heading for the single group
 */
export const documentNoneGroupFactory = (title = '') =>
    new BonesItemGroupFactory<BonesGalleryDocument, BonesGalleryDocumentInfo>(() => title);

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter
 */
export class BonesGalleryDocumentFilter
{
    /**
     * Filter document rows
     * 
     * @param rows list of rows to filter
     */
    constructor(public rows: BonesGalleryDocument[])
    {
    }

    /**
     * Filter on document object property value
     * 
     * @param key name of one of the document object properties
     * @param value property value
     * @returns same object for chaining
     */
    by(key: keyof BonesGalleryDocumentInfo, value: string | number) : BonesGalleryDocumentFilter
    {
        if (value)
        {
            this.rows = this.rows.filter(r => r.row[key] === value);
        }

        return this;
    }

    /**
     * Filter by a phrase of one or more keywords
     * 
     * @param phrase keyword phrase
     * @returns same object for chaining
     */
    byKeyword(phrase: string) : BonesGalleryDocumentFilter
    {
        if (phrase)
        {
            this.rows = new BonesSearch<BonesGalleryDocument>(this.rows, phrase)
            .execute((r: BonesGalleryDocument) =>
            [
                r.row.name, r.row.title, r.row.content_type, r.row.notes
            ]);
        }

        return this;
    }
}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------
