<ion-list>
  <ion-item-sliding *ngFor="let document of documents">

    <ion-item (click)="documentDetail(document.document_id, $event)">
      <ion-thumbnail style="height: 4em;" slot="start" *ngIf="document.isImage">
        <img [bonesGalleryDocumentID]="document.document_id" [bonesGalleryDocumentSize]="gallery.imageSizeCard">
      </ion-thumbnail>
      <ion-icon style="font-size: 45px;" slot="start" name="document" (click)="documentDetail(document.document_id, $event)" *ngIf="!document.isImage"></ion-icon>

      <ion-label>
        <h2 *ngIf="document.row.title"><ion-text color="secondary">{{ document.row.title }}</ion-text></h2>
        <h2 *ngIf="!document.row.title"><ion-text color="secondary">{{ document.row.name }}</ion-text></h2>
        <div *ngIf="showDeets && document.row.title">{{ document.row.name }}</div>
        <div *ngIf="showDeets">{{ document.row.content_type }}</div>
      </ion-label>

      <ion-text slot="end" *ngIf="document.row.featured"><ion-icon name="heart"></ion-icon></ion-text>
      <ion-text slot="end" *ngIf="document.row.logo"><ion-icon name="at"></ion-icon></ion-text>
      <ion-text slot="end" *ngIf="showDeets">{{document.row.filesize / 1024 | number:'0.0-0'}}k</ion-text>
    </ion-item>

    <ion-item-options>
      <ion-item-option (click)="documentDetail(document.document_id, $event)">
        <ion-icon slot="top" size="large" name="eye"></ion-icon>
        Detail
      </ion-item-option>
      <ion-item-option (click)="gallery.editDocument(document)" *ngIf="editable">
        <ion-icon slot="top" size="large" name="pencil"></ion-icon>
        Edit
      </ion-item-option>
    </ion-item-options>

  </ion-item-sliding>
</ion-list>
