import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory } from '@bones/core';

import { ApeRest } from '@BeerMonkey/core/service/ApeRest';
import { Idea } from '../class/Idea';
import { IdeaInfo } from '../class/IdeaInfo';

/**
 * Access db information
 */
@Injectable({
  providedIn: 'root',
})
export class IdeaService
{
    theKey = 1;
    cache: BonesCache<number, IdeaInfo, Idea>;

    constructor(
        private bcf: BonesCacheFactory,
        private rest: ApeRest
    )
    {
        this.cache = this.bcf.create<number, IdeaInfo, Idea>(
        {
            pk: 'idea_id',
            loadCache: () => this.rest.send('rate/idea/getIdeas'),
            reloadOne: (id: number) => this.rest.send('rate/idea/getIdea', { ideaID: id }),
            converter: async (info: IdeaInfo) : Promise<Idea> =>
            {
                return new Idea(info);
            }
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Get idea details
     */
    async getIdea() : Promise<Idea>
    {
        return this.cache.getEntry(this.theKey);
    }

}

