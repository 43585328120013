import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

import { BonesGalleryService } from '@bones/gallery';

import { UserService, AppUser } from '@BeerMonkey/core';

import { Glassware } from '@BeerMonkey/rate/class/Glassware';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';
import { GlasswareService } from '@BeerMonkey/rate/service/GlasswareService';

@Component({
  selector: 'glassware-list',
  templateUrl: 'glassware-list.html'
})
export class GlasswareListComponent
{
    @Input() showBreweryName = false;
    @Input() editable = false;

    glasswareList: Glassware[] = [ ];
    @Input() set glassware(list: Glassware[])
    {
        this.glasswareList = list;
    }

    user: AppUser;

    constructor(
        private navCtrl: NavController,
        public gallery: BonesGalleryService,
        private mtus: UserService,
        public launch: LaunchEditModalService,
        private glasswareDB: GlasswareService
    )
    {
        this.user = this.mtus.getUser();
    }

    glasswareDetail(glassware_id: number, event: Event)
    {
        this.navCtrl.navigateForward([ '/rate/glassware', glassware_id ]);
        event.stopPropagation();
    }

    breweryDetail(brewery_id: number, event: Event)
    {
        this.navCtrl.navigateForward([ '/rate/brewery', brewery_id ]);
        event.stopPropagation();
    }

    getThumbnail(glassware: Glassware) : number | undefined
    {
        return this.glasswareDB.getThumbnail(glassware);
    }

}
