<ion-searchbar placeholder="Search ratings" [(ngModel)]="searchTerm" [debounce]="500"
  *ngIf="showSearchbar && showRatings && ((groups?.length ?? 0) > 0 || searchTerm)">
</ion-searchbar>

<ion-card class="ion-padding" *ngIf="show">
  <bones-card-toolbar [title]="title" [note]="showingText()" [actions]="ratingCardMenu" [expandable]="true" [(expanded)]="showRatings">
  </bones-card-toolbar>

  <ion-card-content  class="ion-no-padding" *ngIf="showRatings">
    <ion-item-group *ngFor="let group of groups">
      <ion-item-divider>
        <ion-label>{{ group.by }}</ion-label>
      </ion-item-divider>
      <rating-list [ratings]="group.items" [showBreweryName]="showBreweryName" [showBeerName]="showBeerName"></rating-list>
    </ion-item-group>

    <div class="ion-text-right">
      <ion-button (click)="showMore()" *ngIf="truncated">More</ion-button>
    </div>


  </ion-card-content>
</ion-card>

<ion-card class="ion-padding" *ngIf="!show && showSearchbar && searchTerm && groups?.length === 0">
  <p>No ratings found.</p>
</ion-card>
