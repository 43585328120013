import { Component, Input, OnInit } from '@angular/core';

import { httpStatusCodes } from '../../model/httpStatusCodes';

/**
 * Display an http response code. Color will be 'danger' if the code indicates an error.
 * 
 * @example
 * <bones-http-response-code [rc]="404"></bones-http-response-code>
 */
@Component({
  selector: 'bones-http-response-code',
  templateUrl: 'bones-http-response-code.html'
})
export class BonesHttpResponseCodeComponent implements OnInit
{
    /**
     * Either an object or a JSON string containing an object. Required.
     */
    @Input() rc: number;

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

    /**
     * Convert object to list
     * @ignore
     */
    ngOnInit()
    {
    }

    get color() : string
    {
        return this.rc >= 400 ? 'danger' : 'dark';
    }

    get title() : string
    {
        return httpStatusCodes[this.rc] || 'unknown';
    }

}
