<ion-header>
  <ion-toolbar>
    <ion-title>Bones Edit Form</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [formGroup]="form">

  <ion-card class="ion-padding">
    <ion-card-title>Sample Form</ion-card-title>
    <bones-form-items [form]="form" [items]="items"></bones-form-items>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="display">
    <ion-card-title>Form Contents</ion-card-title>
    <pre>{{ display | json }}</pre>
  </ion-card>

</ion-content>

<ion-footer>
  <bones-edit-button-bar [editor]="this"></bones-edit-button-bar>
</ion-footer>
