import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { ApeRest } from '@BeerMonkey/core';
import { BreweryInfo } from '@BeerMonkey/rate/class/BreweryInfo';
import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';

@Component({
    selector: 'brewery-edit',
    templateUrl: 'brewery-edit.html'
})
export class BreweryEditModal extends BonesEditForm<BreweryInfo> implements OnInit
{
    constructor(
        protected formBuilder: FormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: ApeRest,
        private breweryDB: BreweryService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'brewery_id',
                    hidden: true
                },
                {
                    name: 'name',
                    title: 'Name',
                    validator: [ Validators.required, Validators.maxLength(128) ]
                },
                {
                    name: 'location',
                    title: 'Location',
                    validator: Validators.maxLength(128)
                },
                {
                    name: 'rbtype',
                    title: 'Type',
                    picker:
                    {
                        populator: () => this.breweryDB.getTypePicker(),
                        readWrite: true
                    },
                    validator: Validators.maxLength(32)
                },
                {
                    name: 'description',
                    title: 'Description',
                    type: 'textarea'
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    type: 'textarea'
                }
            ],
            saveUrl: '/rate/brewery/update/updateBrewery',
            cache: this.breweryDB.cache
        });
    }
}
