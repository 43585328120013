import { Component, Input } from '@angular/core';

import { BonesErrorService } from '@bones/core';

import { BonesGalleryService } from '@bones/gallery';

import { UserService, AppUser } from '@BeerMonkey/core';

import { Inventory } from '@BeerMonkey/rate/class/Inventory';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';
import { InventoryService } from '@BeerMonkey/rate/service/InventoryService';

@Component({
    selector: 'inventory-list',
    templateUrl: 'inventory-list.html'
})
export class InventoryListComponent
{
    @Input() showBreweryName = false;
    @Input() showBeerName = true;

    inventoryList: Inventory[] = [ ];
    @Input() set inventory(list: Inventory[])
    {
        this.inventoryList = list;
    }

    user: AppUser;

    beerImage = (item: Inventory) : number | undefined => this.inventoryDB.getThumbnail(item);

    constructor(
        private es: BonesErrorService,
        public gallery: BonesGalleryService,
        private mtus: UserService,
        public launch: LaunchEditModalService,
        private inventoryDB: InventoryService
    )
    {
        this.user = this.mtus.getUser();
    }

    hasLegacyName(item: Inventory) : boolean
    {
        return item.beer !== undefined && item.row.beer_name !== undefined && item.row.beer_name !== item.beer.row.name;
    }

    increment(item: Inventory)
    {
        this.inventoryDB.increment(item)
        .catch(error => this.es.errorHandler(error));
    }

    decrement(item: Inventory)
    {
        this.inventoryDB.decrement(item)
        .catch(error => this.es.errorHandler(error));
    }

}
