import { RatingInfo } from './RatingInfo';
import { Beer } from './Beer';
import { dpget } from '@bones/core';

export class Rating
{
    rating_id: number;

    ratingDate: Date;
    lastUpdate?: Date;
    lastUpload?: Date;
    rerate?: boolean;

    beer?: Beer;

    constructor(public row: RatingInfo)
    {
        this.rating_id = row.rating_id;

        this.ratingDate = new Date(this.row.rating_date);
        this.lastUpdate = this.row.last_update ? new Date(this.row.last_update) : undefined;
        this.lastUpload = this.row.last_upload ? new Date(this.row.last_upload) : undefined;
    }

    get rsc() : number
    {
        return this.row.ratebeer_score || this.row.rating;
    }

    /**
     * Try and figure a name for the place where the beer was rated
     */
    get place() : string
    {
        // If it was served as draft (on premises) and the source is a brewery/brewpub, then use the brewery's name
        if (this.isOnPremises && this.atBrewery)
        {
            // return this.beer.brewery.name;
            return dpget(this, 'beer', 'brewery', 'name') ?? 'unknown';
        }
        // Just use the source for any off premises ratings
        else
        {
            return this.row.source ? this.row.source.trim() : 'unknown';
        }
    }

    /**
     * Has this rating been updated?
     */
    hasBeenUpdated() : boolean
    {
        return this.lastUpdate !== undefined && !(
            this.ratingDate.getFullYear() === this.lastUpdate.getFullYear()
            && this.ratingDate.getMonth() === this.lastUpdate.getMonth()
            && this.ratingDate.getDate() === this.lastUpdate.getDate());
    }

    /**
     * Has this rating been uploaded to RateBeer?
     */
    public get hasBeenUploadedToRateBeer() : boolean
    {
        return this.row.last_upload ? true : false;
    }

    /**
     * Is this a new rating possibly eligable to be uploaded to RateBeer?
     */
    public get uploadableRating() : boolean
    {
        return (this.beer !== undefined && (this.beer.linkedToRateBeer || this.beer.linkableToRateBeer) && !this.hasBeenUploadedToRateBeer);
    }

    /**
     * Is this a new rating, for a linked beer, ready to be uploaded to RateBeer?
     */
    public get newUploadableRating() : boolean
    {
        return this.beer !== undefined && this.beer.linkedToRateBeer && !this.hasBeenUploadedToRateBeer;
    }

    /**
     * Could this rating be uploaded to RateBeer?
     */
    public get notUploadableToRateBeer() : boolean
    {
        return this.beer !== undefined && this.beer.notLinkableToRateBeer;
    }

    /**
     * Is this an updated rating needing to be re-uploaded to RateBeer?
     */
    public get updatedUploadedRating() : boolean
    {
        return this.lastUpload !== undefined &&  this.lastUpdate !== undefined && this.lastUpload.getTime() < this.lastUpdate.getTime();
    }

    public get isOnPremises() : boolean
    {
        if (this.row.mode)
        {
            const lc = this.row.mode.toLowerCase();

            return lc.indexOf('draft') >= 0
                || lc.indexOf('firkin') >= 0
                || lc.indexOf('cask') >= 0
                || lc.indexOf('nitro') >= 0;
        }

        return false;
    }

    public get atBrewery() : boolean
    {
        if (this.row.source)
        {
            const lc = this.row.source.toLowerCase();

            return lc.indexOf('brewpub') >= 0
                || lc.indexOf('brewery') >= 0;
        }

        return false;
    }

}
