import { Component, Input } from '@angular/core';

import { BonesGalleryService } from '../../service/BonesGalleryService';
import { BonesGalleryDocument } from '../../class/BonesGalleryDocument';

/**
 * Display a list of docuemnts.
 * 
 * <bones-gallery-document-list [documents]="documents" [showDeets]="true" [editable]="editable">
 */
@Component({
  selector: 'bones-gallery-document-list',
  templateUrl: 'bones-gallery-document-list.html'
})
export class BonesGalleryDocumentListComponent
{
    /**
     * The list of documents to be displayed
     */
    @Input() documents: BonesGalleryDocument[] = [ ];
    /**
     * Should internal file details such as filename, size, and content type be displayed?
     */
    @Input() showDeets = false;
    /**
     * Can the documents in this list be editable?
     * 
     * This enables a sliding item option for "edit"
     */
    @Input() editable = false;

    /**
     * @ignore
     */
    constructor(
        public gallery: BonesGalleryService
    )
    {
    }

    /**
     * Open document in new window/tab
     * 
     * @param documentID primary key of document to view
     * @param event Event that generated this request
     */
    documentDetail(documentID: number, event: Event)
    {
        this.gallery.open(documentID);
        event.stopPropagation();
    }

}
