/*
 * Public API Surface of bones-table
 */

export { BonesTableModule } from './bones-table.module';

export { BonesTableComponent } from './components/bones-table/bones-table';
export { BonesTableThComponent } from './components/bones-table-th/bones-table-th';

// export { BonesTableLocalDataService } from './service/bones-table-local-data';

export { BonesTableFetchRequest } from './class/BonesTableFetchRequest';
export { BonesTableFetchResponse } from './class/BonesTableFetchResponse';
