<ion-header><bones-tt-page-header title="API Log"></bones-tt-page-header></ion-header>

<ion-content>
  <ion-list>
    <ion-item *ngIf="log.length === 0">No activity</ion-item>
    <ion-item *ngFor="let entry of log" [routerLink]="['detail', entry.id]">
      <ion-label>
        <h2>{{ entry.bnsRequestInfo.apiUrl }}</h2>
        <h3>{{ entry.ts | date:'medium' }}</h3>
        <ion-text [color]="entry.status === 'success' ? 'success' : 'danger'">{{ entry.status }}</ion-text>
        <p *ngIf="entry.error">{{ entry.error.message }}</p>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
