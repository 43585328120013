import { Injectable } from '@angular/core';

import { BonesError } from '@bones/core';

import { ApeRest } from '@BeerMonkey/core';
import { RateBeerBrewery } from '../class/RateBeerBrewery';
import { RateBeerBeer } from '../class/RateBeerBeer';
import { RateBeerBreweryBeer } from '../class/RateBeerBreweryBeer';

/**
 * Access rate beer information
 */
@Injectable({
  providedIn: 'root',
})
export class RateBeerService
{
    breweryMap = new Map<number, RateBeerBrewery>();
    beerMap = new Map<number, RateBeerBeer>();
    breweryBeerListMap = new Map<number, RateBeerBreweryBeer[]>();

    constructor(
        private rest: ApeRest
    )
    {
    }

    //-----------------------------------------------------------------------
    //-----------------------------------------------------------------------

    /**
     * getBrewery
     */
    async getBrewery(rbBreweryID: number) : Promise<RateBeerBrewery>
    {
        const map = this.breweryMap.get(rbBreweryID);
        if (map)
        {
            return map;
        }

        return this.rest.send('rb/getBrewery', { rbBreweryID })
        .then(async info =>
        {
            this.breweryMap.set(rbBreweryID, info);
            return info;
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'RateBeerService',
                methodName: 'getBrewery',
                message: 'unable to get rate beer brewery',
                error: error
            })
            .add({ rbBreweryID });
        });
    }

    /**
     * getBreweryBeerList
     */
    async getBreweryBeerList(rbBreweryID: number) : Promise<RateBeerBreweryBeer[]>
    {
        const map = this.breweryBeerListMap.get(rbBreweryID);
        if (map)
        {
            return map;
        }

        return this.rest.send('rb/getBreweryBeerList', { rbBreweryID })
        .then(async info =>
        {
            this.breweryBeerListMap.set(rbBreweryID, info);
            return info;
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'RateBeerService',
                methodName: 'getBreweryBeerList',
                message: 'unable to get rate beer brewery beer list',
                error: error
            })
            .add({ rbBreweryID });
        });
    }

    /**
     * get map of ratebeer beer id to beer name
     */
    async getBreweryBeerListPicker(rbBreweryID?: number) : Promise<Map<number, string>>
    {
        if (rbBreweryID)
        {
            return this.getBreweryBeerList(rbBreweryID)
            .then(bbl => new Map(bbl?.map(bb => [ bb.BeerID, bb.BeerName ])));
        }
        else
        {
            return new Map();
        }
    }

    //-----------------------------------------------------------------------

    /**
     * getBeer
     */
    async getBeer(rbBeerID: number) : Promise<RateBeerBeer>
    {
        const map = this.beerMap.get(rbBeerID);
        if (map)
        {
            return map;
        }

        return this.rest.send('rb/getBeer', { rbBeerID })
        .then(async info =>
        {
            this.beerMap.set(rbBeerID, info);
            return info;
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'RateBeerService',
                methodName: 'getBeer',
                message: 'unable to get rate beer beer',
                error: error
            })
            .add({ rbBeerID });
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Open RateBeer brewery page
     */
    openBrewery(breweryID: number, event?: Event) : void
    {
        window.open('http://ratebeer.com/brewers/x/' + breweryID + '/');
        if (event) event.stopPropagation();
    }

    /**
     * Open RateBeer beer page
     */
    openBeer(beerID: number, event?: Event) : void
    {
        window.open('http://ratebeer.com/beer/x/' + beerID + '/5356/');
        if (event) event.stopPropagation();
    }

    /**
     * Open RateBeer search page
     */
    searchBreweryByName(name: string) : void
    {
        window.open('https://www.ratebeer.com/search?q=' + name);
    }

    //-----------------------------------------------------------------------

}
