import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxChartsModule } from '@swimlane/ngx-charts';

import { BonesCoreModule } from '@bones/core';
import { BonesFormModule } from '@bones/form';
import { BonesEditModule } from '@bones/edit';
import { BonesGalleryModule } from '@bones/gallery';

import { AppCoreModule } from '@BeerMonkey/core';

import { RateRoutingModule } from './routing';

import { SearchPage } from './pages/search/search';
import { IdeaPage } from './pages/idea/idea';
import { IdeaEditModal } from './pages/idea/idea-edit/idea-edit';
import { LoadingModal } from './pages/loading-modal/loading-modal';
import { RbBeerInfoComponent } from './component/rb-beer-info/rb-beer-info';
import { RbBreweryInfoComponent } from './component/rb-brewery-info/rb-brewery-info';
import { RatingStarsComponent } from './component/rating-stars/rating-stars';

import { BreweriesPage } from './clique/brewery/breweries-page/breweries-page';
import { BreweryDetailPage } from './clique/brewery/brewery-detail/brewery-detail';
import { BreweryEditModal } from './clique/brewery/brewery-edit/brewery-edit';
import { BreweryListComponent } from './clique/brewery/brewery-list/brewery-list';
import { BreweryListCardComponent } from './clique/brewery/brewery-list-card/brewery-list-card';

import { BeerPage } from './clique/beer/beer-page/beer-page';
import { BeerExplorerPage } from './clique/beer/beer-explorer/beer-explorer';
import { BeerDetailPage } from './clique/beer/beer-detail/beer-detail';
import { BeerEditModal } from './clique/beer/beer-edit/beer-edit';
import { BeerInfoComponent } from './clique/beer/beer-info/beer-info';
import { BeerListComponent } from './clique/beer/beer-list/beer-list';
import { BeerListCardComponent } from './clique/beer/beer-list-card/beer-list-card';

import { RatingstPage } from './clique/rating/ratings-page/ratings-page';
import { RatingDetailPage } from './clique/rating/rating-detail/rating-detail';
import { RecentRatingsPage } from './clique/rating/recent-ratings/recent-ratings';
import { RatingEditModal } from './clique/rating/rating-edit/rating-edit';
import { RatingEditJrModal } from './clique/rating/rating-edit/rating-edit-jr/rating-edit-jr';
import { RatingGalleryComponent } from './clique/rating/rating-gallery/rating-gallery';
import { RatingListComponent } from './clique/rating/rating-list/rating-list';
import { RatingListCardComponent } from './clique/rating/rating-list-card/rating-list-card';

import { InventoryPage } from './clique/inventory/inventory-page/inventory-page';
import { InventoryDetailPage } from './clique/inventory/inventory-detail/inventory-detail';
import { InventoryEditModal } from './clique/inventory/inventory-edit/inventory-edit';
import { InventoryListComponent } from './clique/inventory/inventory-list/inventory-list';
import { InventoryListCardComponent } from './clique/inventory/inventory-list-card/inventory-list-card';

import { GlasswarePage } from './clique/glassware/glassware-page/glassware-page';
import { GlasswareDetailPage } from './clique/glassware/glassware-detail/glassware-detail';
import { GlasswareEditModal } from './clique/glassware/glassware-edit/glassware-edit';
import { GlasswareListComponent } from './clique/glassware/glassware-list/glassware-list';
import { GlasswareListCardComponent } from './clique/glassware/glassware-list-card/glassware-list-card';

@NgModule({
    declarations:
    [
        LoadingModal,
        RbBreweryInfoComponent,
        RbBeerInfoComponent,
        RatingStarsComponent,
        SearchPage,
        IdeaPage,
        IdeaEditModal,

        BreweriesPage,
        BreweryDetailPage,
        BreweryEditModal,
        BreweryListComponent,
        BreweryListCardComponent,

        BeerPage,
        BeerExplorerPage,
        BeerDetailPage,
        BeerEditModal,
        BeerInfoComponent,
        BeerListComponent,
        BeerListCardComponent,
        
        RatingstPage,
        RatingDetailPage,
        RatingEditModal,
        RatingEditJrModal,
        RecentRatingsPage,
        RatingGalleryComponent,
        RatingListComponent,
        RatingListCardComponent,

        InventoryPage,
        InventoryDetailPage,
        InventoryListComponent,
        InventoryListCardComponent,
        InventoryEditModal,

        GlasswarePage,
        GlasswareDetailPage,
        GlasswareEditModal,
        GlasswareListComponent,
        GlasswareListCardComponent
    ],
    imports:
    [
        CommonModule,
        FormsModule, ReactiveFormsModule,
        IonicModule,
        BrowserAnimationsModule,
        NgxChartsModule,
        BonesCoreModule,
        BonesFormModule,
        BonesEditModule,
        BonesGalleryModule,
        AppCoreModule,
        RateRoutingModule
    ],
    // Use for services that are referenced only within this module
    providers:
    [
    ],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports:
    [
        BreweryListCardComponent,
        BeerListCardComponent,
        RatingGalleryComponent,
        RatingListComponent,
        RatingListCardComponent
    ]
})
export class AppRateModule
{
    static initialize()
    {
    }
}
AppRateModule.initialize();
