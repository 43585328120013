
/**
 * Versions for the bones libarary modules
 */
export class BonesVersion
{
    /**
     * module names and versions.
     */
    public static map = new Map<string, string>();
}
