import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  templateUrl: 'test-tools-bones-form.html'
})
export class BonesTestToolsFormBasicPage
{
    form: UntypedFormGroup;
    display: any;

    constructor(
        private formBuilder: UntypedFormBuilder
    )
    {
        // Define edit form
        this.form = this.formBuilder.group(
        {
            field1: [ '', Validators.required ],
            anotherField: [ '', [ Validators.required, Validators.minLength(12) ] ],
        });
    }

    button1()
    {
        this.display =
        {
            field1: this.form.value.field1,
            anotherField: this.form.value.anotherField
        };
    }

    //-----------------------------------------------------------------------

}
