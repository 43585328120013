<ion-header><modal-header title="Login" [close]="guest"></modal-header></ion-header>

<ion-content>

  <ion-card class="ion-padding">
    <ion-card-title>Login</ion-card-title>
    <ion-list>
      <ion-item>
        <ion-label position="stacked">Username</ion-label>
        <ion-input [(ngModel)]="username"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Password</ion-label>
        <ion-input type="password" [(ngModel)]="password"></ion-input>
      </ion-item>
    </ion-list>

    <div class="ion-text-right">
      <ion-button (click)="guest()">Continue as Guest</ion-button>
      <ion-button (click)="login()">Login</ion-button>
    </div>
  </ion-card>

</ion-content>

<ion-footer>
</ion-footer>
