import { Component, Input, Output, EventEmitter, OnChanges, ContentChild, TemplateRef } from '@angular/core';

import { BonesGalleryService } from '../../service/BonesGalleryService';

/**
 * Gallery group.
 */
export interface BonesGalleryLargeGroup
{
    /**
     * Optional group title. If the title is not provided, then no title card will be rendered.
     */
    title?: string;
    /**
     * Group items
     */
    items: BonesGalleryLargeItem[];
}

/**
 * Gallery group item.
 * This interface can be extended to add additional properties to the item for client use.
 */
export interface BonesGalleryLargeItem
{
    /**
     * Optional title for the item
     */
    title?: string;
    /**
     * ID of image to be shown (documentID)
     */
    imageID?: number;
    /**
     * URL of image to be shown
     */
    imageURL?: string;
}

/**
 * Show one or more galleries of images with templates for header and footer information
 * 
 * Either supply [title] and [items] attrinutes or the [galleries] attribute.
 * 
 * Three templates may be provided to customize the card.
 * A #header and #footer template will be rendered at the top/bottom of each gallery card.
 * A #blank template will be rendered in place of the image when no imageID or imageURL specified.
 * The gallery item is provided to each of the templates.
 * 
 * @example
 * <bones-gallery-large title="Rating Images" [items]="ratingGallery" (select)="ratingDetail($event)">
 *   <ng-template #header let-item>
 *     <ion-card-title>{{ item.mydata.beer.name }}</ion-card-title>
 *     <ion-card-subtitle>{{ item.mydata.brewery.name }}</ion-card-subtitle>
 *   </ng-template>
 *   <ng-template #footer let-item>
 *     Feet don't fail me now
 *   </ng-template>
 * </bones-gallery-large>
 */
@Component({
    selector: 'bones-gallery-large',
    templateUrl: 'bones-gallery-large.html',
    styleUrls: [ 'bones-gallery-large.scss' ]
})
export class BonesGalleryLargeComponent implements OnChanges
{
    /**
     * @ignore
     */
    _galleries: BonesGalleryLargeGroup[] = [ ];

    /**
     * Optional group title to be shown at top of gallery.
     */
    @Input() title?: string;
    /**
     * List of gallery items. Required when there is only one gallery group.
     */
    @Input() items?: BonesGalleryLargeItem[] = [ ];
    /**
     * List of gallery groups. Overrides the [title] and [items] attributes.
     */
    @Input() galleries?: BonesGalleryLargeGroup[] = [ ];
    /**
     * Size of downloaded images.
     */
    @Input() size = this.gallery.imageSizeLargeGallery;
    /**
     * Width of each card in the gallery.
     */
    @Input() cardWidth = '200px';
    /**
     * Event triggerend when the card is clicked.
     * The item is passed as the $event.
     * Note that clicking the image will just show a full size image in a new tab and not trigger this event.
     */
    @Output() select = new EventEmitter<BonesGalleryLargeItem>();

    /**
     * An optional #header template that will be rendered at the top of each gallery card.
     */
    @ContentChild('header', { static: false }) headerTemplate: TemplateRef<any>;
    /**
     * An optional #footer template that will be rendered at the bottom of each gallery card.
     */
    @ContentChild('footer', { static: false }) footerTemplate: TemplateRef<any>;
    /**
     * An optional #blank template that will be rendered in place of the image when no imageID or imageURL specified.
     */
    @ContentChild('blank', { static: false }) blankTemplate: TemplateRef<any>;

    /**
     * @ignore
     */
    constructor(
        private gallery: BonesGalleryService
    )
    {
    }

    /**
     * @ignore
     */
    ngOnChanges() : void
    {
        // If items have been supplied, then use the [title] and [items] attributes to construct galleries
        if (this.items)
        {
            this._galleries = [ { title: this.title, items: this.items } ];
        }
        // The [galleries] attribute was populated
        else if (this.galleries)
        {
            this._galleries = this.galleries;
        }
        else
        {
            this._galleries = [ ];
        }
        // console.log('ThumbnailGalleryComponent', this._galleries);
    }

    /**
     * @ignore
     */
    onClick(item: BonesGalleryLargeItem)
    {
        // console.log('onClick', item);
        this.select.emit(item);
    }

}
