import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { BonesGalleryService, BonesGalleryDocument } from '@bones/gallery';

import { UserService, AppUser, MonkeyService } from '@BeerMonkey/core';
import { RatingService, LaunchEditModalService, Rating, Brewery } from '@BeerMonkey/rate';
import { BreweryService, breweryNoneGroupFactory } from '@BeerMonkey/rate/service/BreweryService';

@Component({
    selector: 'app-home',
    templateUrl: 'home.html',
    styleUrls: [ 'home.scss' ]
})
export class HomePage implements OnInit
{
    ratings: Rating[] = [ ];
    randomRating?: Rating;
    randomImage?: BonesGalleryDocument;
    breweries: Brewery[] = [ ];
    user: AppUser;
    private nal: (() => void)[] = [ ];
    cardMenu: BonesMenuCardAction[];
    breweryGroup = breweryNoneGroupFactory();
    // Always show these breweries: Cherry Street, The Bruery
    alwaysShownBreweries = [ 1639, 1208 ];

    constructor(
        private navCtrl: NavController,
        private es: BonesErrorService,
        public gallery: BonesGalleryService,
        public monkey: MonkeyService,
        private mtus: UserService,
        private launch: LaunchEditModalService,
        private breweryDB: BreweryService,
        private ratingDB: RatingService
    )
    {
        this.user = this.mtus.getUser();

        this.cardMenu =
        [
            { title: 'Recent Ratings', icon: 'list', action: () => this.navCtrl.navigateRoot('/rate/recent-ratings') },
            { title: 'Search', icon: 'search', action: () => this.navCtrl.navigateRoot('/rate/search') },
        ];

        if (this.user.isAdmin)
        {
            this.cardMenu.push({ title: 'Add Brewery', icon: 'add-circle', action: () => this.launch.addBrewery() });
        }
    }

    async ngOnInit()
    {
        // Load and refresh ratings as needed
        this.nal.push(this.ratingDB.cache.nowAndLater(
        async rows =>
        {
            // Only show the most recent ratings
            this.ratings = rows.slice(0, this.monkey.isSmallDevice ? 10 : 8);

            // Find unique breweries from recent ratings
            const breweries = rows.slice(0, 100)
                .map(rating => rating.beer?.brewery)
                .filter((b): b is Brewery => Boolean(b))
                .filter(brewery => brewery !== undefined)
                .filter((value, index, self) => self.indexOf(value) === index)
                .slice(0, this.monkey.isSmallDevice ? 5 : 5);

            // Make sure some breweries are always on the list
            for (let i = 0; (i < this.alwaysShownBreweries.length); ++i)
            {
                const breweryID = this.alwaysShownBreweries[i];
                if (!breweries.find(b => b.brewery_id === breweryID))
                {
                    breweries.push(await this.breweryDB.getBrewery(breweryID));
                }
            }

            // Sort recent brewery list
            this.breweries = breweries.sort((a, b) => a.name.localeCompare(b.name));
        },
        error => this.es.errorHandler(error)));

        // Load and refresh documents as needed
        this.nal.push(this.gallery.cache.nowAndLater(
        async rows =>
        {
            // Pick a random rating
            const ratingImages = rows.filter(r => r.row.rating_id && r.isImage);
            this.randomImage = ratingImages[Math.round(Math.random() * ratingImages.length)];
            this.randomRating = await this.ratingDB.getRating(+this.randomImage.row.rating_id);
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

}
