<ion-list>
  <ion-item-sliding *ngFor="let item of glasswareList">
    <ion-item (click)="glasswareDetail(item.glassware_id, $event)" lines="full">
    <ion-thumbnail style="height: 4em;" slot="start">
      <img [bonesGalleryDocumentID]="getThumbnail(item)" [bonesGalleryDocumentSize]="gallery.imageSizeThumbnail" loading="lazy" *ngIf="getThumbnail(item)">
    </ion-thumbnail>

    <ion-label>
        <h2>
          <ion-text color="secondary">{{ item.row.glass }}</ion-text>
        </h2>

        <h3 *ngIf="showBreweryName && item.brewery">
          <ion-text color="secondary" (click)="breweryDetail(item.brewery.brewery_id, $event)">{{ item.brewery.name }}</ion-text>
        </h3>

        <div>
          <span *ngIf="item.row.quantity > 0">{{ item.row.quantity }}ea </span>
          <span>{{ item.row.style }}</span>
        </div>
        <div *ngIf="!item.row.quantity">No glasses in stock</div>

        <p class="two-line-notes" *ngIf="item.row.notes">{{ item.row.notes }}</p>
      </ion-label>
    </ion-item>

    <ion-item-options>
      <ion-item-option  (click)="launch.editGlassware(item.row)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="pencil"></ion-icon>
        Edit
      </ion-item-option>
    </ion-item-options>

  </ion-item-sliding>
</ion-list>