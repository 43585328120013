import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

import { AuthGuard } from '@BeerMonkey/rate';

import { RatingsByYearPage } from './pages/ratings-by-year/ratings-by-year';
import { RatebeerStatsPage } from './pages/ratebeer-stats/ratebeer-stats';

/**
 * Routes for module
 */
const routes: Route[] =
[
    {
        path: 'stats',
        canActivate: [ AuthGuard ],
        children:
        [
            { path: 'ratings-by-year', component: RatingsByYearPage },
            { path: 'ratebeer', component: RatebeerStatsPage },
        ]
    }
];

@NgModule({
    imports:
    [
        RouterModule.forChild(routes),
    ],
    exports:
    [
        RouterModule
    ]
})
export class StatsRoutingModule
{
}
