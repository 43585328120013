import { Injectable } from '@angular/core';

import { BonesError, BonesSortOption, BonesSearch, BonesItemGroupFactory } from '@bones/core';
import { BonesCache, BonesCacheFactory } from '@bones/core';
import { BonesGalleryService } from '@bones/gallery';

import { ApeRest } from '@BeerMonkey/core/service/ApeRest';
import { BreweryService } from './BreweryService';
import { Inventory } from '../class/Inventory';
import { InventoryInfo } from '../class/InventoryInfo';
import { BeerService } from './BeerService';
import { Brewery } from '../class/Brewery';

/**
 * Access db information
 */
@Injectable({
  providedIn: 'root',
})
export class InventoryService
{
    cache: BonesCache<number, InventoryInfo, Inventory>;

    constructor(
        private bcf: BonesCacheFactory,
        private gallery: BonesGalleryService,
        private rest: ApeRest,
        private breweryDB: BreweryService,
        private beerDB: BeerService
    )
    {
        this.cache = this.bcf.create<number, InventoryInfo, Inventory>(
        {
            pk: 'inventory_id',
            loadCache: () => this.rest.send('rate/inventory/getInventories'),
            reloadOne: (id: number) => this.rest.send('rate/inventory/getInventory', { inventoryID: id }),
            converter: async (info: InventoryInfo) : Promise<Inventory> =>
            {
                const inventory = new Inventory(info);

                inventory.brewery = await this.breweryDB.getBrewery(inventory.row.brewery_id);

                if (inventory.row.beer_id)
                {
                    inventory.beer = await this.beerDB.getBeer(inventory.row.beer_id);
                }

                return inventory;
            },
            sorter: (a: Inventory, b: Inventory) =>
            {
                return a.beerName.localeCompare(b.beerName);
            }
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Get inventory details
     */
    async getInventory(inventoryID: number) : Promise<Inventory | undefined>
    {
        return inventoryID ? this.cache.getEntry(inventoryID) : undefined;
    }

    getThumbnail(inventory: Inventory) : number | undefined
    {
        return this.gallery.thumbnailPicker(
            { foreignKeyName: 'inventory_id', foreignKeyValue: inventory.inventory_id },
            { foreignKeyName: 'beer_id', foreignKeyValue: inventory.row.beer_id },
            { foreignKeyName: 'brewery_id', foreignKeyValue: inventory.brewery?.brewery_id }
        );
    }

    //-----------------------------------------------------------------------

    /**
     * Increment quantity on inventory record
     */
    async increment(row: Inventory) : Promise<Inventory | undefined>
    {
        return this.rest.send('/rate/inventory/update/increment', { inventoryID: row.inventory_id })
        .then(() => this.cache.updated(row.inventory_id))
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'InventoryService',
                methodName: 'increment',
                message: 'Unable to increment inventory',
                error: error
            })
            .add(row);
        });
    }

    /**
     * Decrement quantity on inventory record
     */
    async decrement(row: Inventory) : Promise<Inventory | undefined>
    {
        return this.rest.send('/rate/inventory/update/decrement', { inventoryID: row.inventory_id })
        .then(() => this.cache.updated(row.inventory_id))
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'InventoryService',
                methodName: 'decrement',
                message: 'Unable to decrement inventory',
                error: error
            })
            .add(row);
        });
    }

    //-----------------------------------------------------------------------

    async getBreweryPicker() : Promise<Map<number, string>>
    {
        const pickerMap = new Map<number, string>();

         (await this.cache.getList())
        .map(i => i.brewery)
        .filter((b): b is Brewery => Boolean(b))
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach(b => pickerMap.set(b.brewery_id, b.name));

        return pickerMap;
    }

    async getPicker(key: keyof InventoryInfo) : Promise<string[]>
    {
        return (await this.cache.getList())
        .map(i => '' + i.row[key])
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

export const inventoryBeerNameGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Inventory, InventoryInfo>((item) => item.beerName, { sort: sort });

export const inventoryLocationGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Inventory, InventoryInfo>('location', { sort: sort });

export const inventoryVersionGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Inventory, InventoryInfo>('version', { sort: sort });

export const inventoryVintageGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Inventory, InventoryInfo>('vintage', { sort: sort });

export const inventoryVvGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Inventory, InventoryInfo>((item) => (item.row.vintage ?? '') + ' ' + (item.row.version ?? ''), { sort: sort });

export const inventorySizeGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Inventory, InventoryInfo>('size', { sort: sort });

export const inventoryBreweryNameGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Inventory, InventoryInfo>((item) => item.brewery?.name ?? 'no brewery', { sort: sort });

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter inventoryID
 */
export class InventoryFilter
{
    constructor(public rows: Inventory[])
    {
    }

    byBrewery(breweryID: number) : InventoryFilter
    {
        if (breweryID)
        {
            this.rows = this.rows.filter(r => r.row.brewery_id === breweryID);
        }

        return this;
    }

    byZeros(zeros: boolean) : InventoryFilter
    {
        if (!zeros)
        {
            this.rows = this.rows.filter(r => r.row.quantity > 0);
        }

        return this;
    }

    by(key: keyof InventoryInfo, value: string) : InventoryFilter
    {
        if (value)
        {
            this.rows = this.rows.filter(r => r.row[key] === value);
        }

        return this;
    }

    byKeyword(phrase: string) : InventoryFilter
    {
        if (phrase)
        {
            this.rows = new BonesSearch<Inventory>(this.rows, phrase)
            .execute(r => [ r.beerName, r.row.location, r.row.version, r.row.vintage, r.row.size, r.row.notes, r.brewery?.name ]);
        }

        return this;
    }
}

