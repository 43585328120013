import { Component, Input, OnInit } from '@angular/core';

import { RateBeerService } from '@BeerMonkey/rate/service/RateBeerService';
import { RateBeerBrewery } from '@BeerMonkey/rate/class/RateBeerBrewery';
import { BonesMenuCardAction } from '@bones/core';

@Component({
  selector: 'rb-brewery-info',
  templateUrl: 'rb-brewery-info.html'
})
export class RbBreweryInfoComponent implements OnInit
{
    _rbbrewery?: RateBeerBrewery;
    errorMessage?: string;
    @Input() title?: string;
    @Input() cardMenu?: BonesMenuCardAction[];

    @Input() set rbbrewery(rbbrewery: RateBeerBrewery | undefined)
    {
        this._rbbrewery = rbbrewery;
    }
    get rbbrewery() : RateBeerBrewery | undefined
    {
        return this._rbbrewery;
    }

    @Input() set ratebeerID(ratebeerID: number)
    {
        if (ratebeerID)
        {
            this.rateBeerDB.getBrewery(ratebeerID)
            .then(rbbrewery => this._rbbrewery = rbbrewery)
            .catch(error =>
            {
                this._rbbrewery = undefined;
                // this.es.errorHandler(error);
                this.errorMessage = error.message;
            });
        }
        else
        {
            this._rbbrewery = undefined;
            this.errorMessage = undefined;
        }
    }

    constructor(
        public rateBeerDB: RateBeerService
    )
    {
    }

    ngOnInit()
    {
    }

}
