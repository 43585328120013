import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory, BonesSearch, BonesSortOption, BonesItemGroupFactory, BonesError } from '@bones/core';
import { BonesGalleryService, BonesGalleryDocumentFilter, BonesGalleryDocument } from '@bones/gallery';

import { ApeRest } from '@BeerMonkey/core/service//ApeRest';
import { Brewery } from '../class/Brewery';
import { BreweryInfo } from '../class/BreweryInfo';
import { BreweryType } from '../class/BreweryType';

/**
 * Access db information
 */
@Injectable({
  providedIn: 'root',
})
export class BreweryService
{
    cache: BonesCache<number, BreweryInfo, Brewery>;
    rbtypePromise?: Promise<BreweryType[]>;

    constructor(
        private bcf: BonesCacheFactory,
        public gallery: BonesGalleryService,
        private rest: ApeRest
    )
    {
        this.cache = this.bcf.create<number, BreweryInfo, Brewery>(
        {
            pk: 'brewery_id',
            loadCache: () => this.rest.send('rate/brewery/getBreweries'),
            reloadOne: (id: number) => this.rest.send('rate/brewery/getBrewery', { breweryID: id }),
            converter: async (info: BreweryInfo) : Promise<Brewery> =>
            {
                const brewery = new Brewery(info);

                return brewery;
            },
            sorter: (a: Brewery, b: Brewery) =>
            {
                return a.row.name.localeCompare(b.row.name);
            }
        });
    }

    /**
     * Get brewery details
     */
    async getBrewery(breweryID: number) : Promise<Brewery>
    {
        return this.cache.getEntry(breweryID);
    }

    /**
     * Get details for a list of breweries
     */
    async getBreweries(breweryIDs: number[]) : Promise<Brewery[]>
    {
        const breweries: Brewery[] = [ ];

        for (let i = 0; (i < breweryIDs.length); ++i)
        {
            const id = breweryIDs[i];
            const brewery = await this.getBrewery(id);
            if (brewery)
            {
                breweries.push(brewery);
            }
            else
            {
                console.warn('unable to find brewery for ID', id)
            }
        }

        return breweries;
    }

    //-----------------------------------------------------------------------

    /**
     * Get brewery types
     */
    async getTypes() : Promise<BreweryType[]>
    {
        if (!this.rbtypePromise)
        {
            this.rbtypePromise = this.rest.send('/rate/brewery/getTypes')
            .catch(error =>
            {
                throw new BonesError(
                {
                    className: 'BreweryService',
                    methodName: 'getTypes',
                    message: 'Unable to get types',
                    error: error
                });
            });
        }

        return this.rbtypePromise;
    }

    /**
     * Get a picker of brewery types
     */
    async getTypePicker() : Promise<string[]>
    {
        return this.getTypes()
        .then(rbtype => rbtype.map(s => s.rbtype));
    }

    //-----------------------------------------------------------------------
    
    /**
     * Get document ID for a brewery logo
     */
    getBreweryLogoID(brewery: Brewery) : number | undefined
    {
        if (!brewery)
        {
            console.log('!brewery', brewery);
            return undefined;
        }

        return this.gallery.cache
            .getListByForeignKey('brewery_id', brewery.row.brewery_id)
            ?.find(d => d.isLogo)
            ?.document_id;
    }

    //-----------------------------------------------------------------------
    //-----------------------------------------------------------------------

    // /**
    //  * Get brewery details
    //  */
    // async get(breweryID: number) : Promise<any>
    // {
    //     return this.rest.send('rate/brewery/getBrewery', { breweryID })
    //     .catch(error =>
    //     {
    //         throw new BonesError(
    //         {
    //             className: 'BreweryService',
    //             methodName: 'get',
    //             message: 'unable to get brewery',
    //             error: error
    //         })
    //         .add({ breweryID });
    //     });
    // }

    //-----------------------------------------------------------------------

    // /**
    //  * test
    //  */
    // async getAllBreweries() : Promise<any>
    // {
    //     return this.rest.send('rate/brewery/getBreweries')
    //     .catch(error =>
    //     {
    //         throw new BonesError(
    //         {
    //             className: 'BreweryService',
    //             methodName: 'getAllBreweries',
    //             message: 'unable to get breweries',
    //             error: error
    //         });
    //     });
    // }

    // /**
    //  * test
    //  */
    // async getAllBeers() : Promise<any>
    // {
    //     return this.rest.send('rate/beer/getBeers')
    //     .catch(error =>
    //     {
    //         throw new BonesError(
    //         {
    //             className: 'BreweryService',
    //             methodName: 'getAllBeers',
    //             message: 'unable to get beers',
    //             error: error
    //         });
    //     });
    // }

    // /**
    //  * test
    //  */
    // async getAllRatings() : Promise<any>
    // {
    //     return this.rest.send('rate/rating/getRatings')
    //     .catch(error =>
    //     {
    //         throw new BonesError(
    //         {
    //             className: 'BreweryService',
    //             methodName: 'getAllRatings',
    //             message: 'unable to get ratings',
    //             error: error
    //         });
    //     });
    // }

    //-----------------------------------------------------------------------
}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

export const breweryNameGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Brewery, BreweryInfo>('name', { sort: sort, byFirstLetter: true });

export const breweryLocationGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Brewery, BreweryInfo>((item) => item.row.location, { sort: sort });

export const breweryTypeGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Brewery, BreweryInfo>((item) => item.row.rbtype, { sort: sort });

export const breweryNoneGroupFactory = (title = '') =>
    new BonesItemGroupFactory<Brewery, BreweryInfo>(() => title, { sort: 'none' });

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter
 */
export class BreweryFilter
{
    constructor(public rows: Brewery[])
    {
    }

    byKeyword(phrase: string) : BreweryFilter
    {
        if (phrase)
        {
            this.rows = new BonesSearch<Brewery>(this.rows, phrase)
            .execute(r => [ r.row.name, r.row.location, r.row.rbtype, r.row.description, r.row.notes ]);
        }

        return this;
    }
}
