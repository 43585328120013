<ion-card class="ion-padding" *ngIf="documents.length > 0">
  <bones-card-toolbar [title]="title" [actions]="cardMenu" [expandable]="true" [(expanded)]="expanded"></bones-card-toolbar>
  <ion-card-content class="ion-no-padding" *ngIf="expanded">

    <div *ngIf="!editDocuments">
      <div class="item" [ngClass]="{featured: document.isFeatured}" *ngFor="let document of documents | bgdFilterNotFeatured">

        <div class="title" *ngIf="document.row.title">{{ document.row.title }}</div>
        <div class="title" *ngIf="!document.row.title">{{ document.row.name }}</div>

        <ion-thumbnail class="image" *ngIf="document.isImage">
          <img [bonesGalleryDocumentID]="document.document_id" [bonesGalleryDocumentSize]="gallery.imageSizeSmallGallery">
        </ion-thumbnail>

        <ion-icon class="image" name="document" (click)="documentDetail(document)" *ngIf="document.isAttachment"></ion-icon>
      </div>
    </div>

    <bones-gallery-document-list [documents]="documents" [showDeets]="true" [editable]="editable" *ngIf="editDocuments">
    </bones-gallery-document-list>

  </ion-card-content>
</ion-card>
