import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { Validators, UntypedFormBuilder } from '@angular/forms';

import { BonesErrorService, BonesError } from '@bones/core';
import { BonesForm } from '@bones/form';

import { EDSGatewayService } from '../../service/eds-gateway';

@Component({
  templateUrl: 'test-tools-edsgw-config.html'
})
export class TestToolsEdsgwConfigPage
{
    isAuthorized: boolean = false;
    isConfigured: boolean = false;
    error: BonesError;
    configForm: BonesForm;
    authForm: BonesForm;

    constructor(
        private modalCtrl: ModalController,
        private loadingCtrl: LoadingController,
        private formBuilder: UntypedFormBuilder,
        private es: BonesErrorService,
        private edsgw: EDSGatewayService
    )
    {
        this.configForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'edsGatewayServer',
                    title: 'Gateway Server',
                    picker:
                    {
                        readWrite: false,
                        values: [ 'dev', 'test', 'prod' ]
                    },
                    initialValue: window.localStorage.getItem('bones-tt-gateway-server-env') || 'dev',
                    validator: Validators.required
                },
                {
                    name: 'edsGatewayUrl',
                    title: 'Gateway URL',
                    validator: Validators.required
                },
                {
                    name: 'attEDSApplication',
                    title: 'Application',
                    initialValue: window.localStorage.getItem('bones-tt-gateway-app') || 'OWP',
                    validator: Validators.required
                },
                {
                    name: 'attEDSTarget',
                    title: 'Target',
                    initialValue: window.localStorage.getItem('bones-tt-gateway-target')
                }
            ]
        });

        this.authForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'attEDSUser',
                    title: 'attEDSUser',
                    initialValue: window.localStorage.getItem('attuid') || ''
                },
                {
                    name: 'attEDSUserPassword',
                    title: 'attEDSUserPassword',
                    type: 'password',
                    initialValue: window.localStorage.getItem('password') || ''
                }
            ]
        });

        this.setGatewayServer();
    }

    //-----------------------------------------------------------------------

    /**
     * Set gateway server URL using dev / test / prod select
     */
    setGatewayServer()
    {
        this.edsgw.edsGatewayServer = this.configForm.getValue('edsGatewayServer');
        this.configForm.setValue('edsGatewayUrl', this.edsgw.edsGatewayUrl);
    }

    /**
     * Set gateway configuration
     */
    set()
    {
        // Configure EDS Gateway service
        this.setGatewayServer();
        this.edsgw.edsGatewayUrl = this.configForm.getValue('edsGatewayUrl');
        this.edsgw.attEDSApplication = this.configForm.getValue('attEDSApplication');
        this.edsgw.attEDSTarget = this.configForm.getValue('attEDSTarget');
        this.isConfigured = true;

        // Save latest settings to local storage for next time
        window.localStorage.setItem('bones-tt-gateway-server-env', this.configForm.getValue('edsGatewayServer'));
        window.localStorage.setItem('bones-tt-gateway-app', this.configForm.getValue('attEDSApplication'));
        window.localStorage.setItem('bones-tt-gateway-target', this.configForm.getValue('attEDSTarget'));
    }

    async auth()
    {
        const loading = await this.loadingCtrl.create({});
        loading.present();

        this.edsgw.auth(this.authForm.getValue('attEDSUser'), this.authForm.getValue('attEDSUserPassword'))
        .then(() =>
        {
            loading.dismiss();
            this.isAuthorized = true;
            this.error = undefined;

            // Save latest values to local storage for next time
            window.localStorage.setItem('attuid', this.authForm.getValue('attEDSUser'));
            window.localStorage.setItem('password', this.authForm.getValue('attEDSUserPassword'));

            this.dismiss();
        })
        .catch(error =>
        {
            loading.dismiss();
            this.isAuthorized = false;
            this.error = error;
            this.es.errorHandler(error, 'silent');
        });
    }

    /**
     * Dismiss modal
     */
    dismiss()
    {
        this.modalCtrl.dismiss();
    }

}
