<ion-menu side="start" [contentId]="contentId" [type]="type" #bsm>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button size="small" fill="clear" color="light" (click)="bsm.close()">
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div *ngFor="let group of groups">
          <ion-item color="primary" *ngIf="group.name" (click)="group.open = !group.open">
              <ion-icon name="arrow-forward-circle" *ngIf="!group.open"></ion-icon>
              <ion-icon name="arrow-down-circle" *ngIf="group.open"></ion-icon>
              <ion-label> {{ group.name }}</ion-label>
          </ion-item>

          <div *ngIf="group.open || !group.name">
            <ion-item *ngFor="let action of group.actions" (click)="do(action)">
                <ion-label>{{ action.title }}</ion-label>
            </ion-item>
          </div>
      </div>
    </ion-content>
</ion-menu>
