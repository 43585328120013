<div [formGroup]="form" *ngIf="form">
  <ion-item>
    <bones-form-label [item]="item"></bones-form-label>
    <div class="input">
      <ion-input #hh placeholder="hh" type="number" [readonly]="item.readonly" (ionChange)="onChange()"></ion-input>
      <span>:</span>
      <ion-input #mm placeholder="mm" type="number" [readonly]="item.readonly" (ionChange)="onChange()"></ion-input>
      <span>:</span>
      <ion-input #ss placeholder="ss" type="number" [readonly]="item.readonly" (ionChange)="onChange()"></ion-input>
    </div>
  </ion-item>
</div>
