import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NavController } from '@ionic/angular';
import { formatDate } from '@angular/common';

import { UserService, AppUser } from '@BeerMonkey/core';
import { BonesGalleryService, BonesGalleryLargeItem } from '@bones/gallery';

import { Rating } from '@BeerMonkey/rate/class/Rating';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';
import { RatingFilter } from '@BeerMonkey/rate/service/RatingService';

class Idea
{
    title: string;
    subtitle?: string;
    vv?: string;
    formattedDate: string;
    ratedOn?: string;
    titleLink: (event: Event) => void;
    subtitleLink?: (event: Event) => void;

    constructor(private psc: RatingGalleryComponent, rating: Rating)
    {
        this.formattedDate = formatDate(rating.ratingDate, 'EEE MMM d, y', 'en');
        this.ratedOn = 'Rated on ' + this.formattedDate;

        // Construct vintage and version line if we have either
        if (rating.row.vintage && rating.row.version)
        {
            this.vv = rating.row.vintage + ' ' + rating.row.version;
        }
        else if (rating.row.version)
        {
            this.vv = rating.row.version;
        }
        else if (rating.row.vintage)
        {
            this.vv = rating.row.vintage;
        }

        // Best title is the name of the beer (if desired)
        if (psc.showBeerName && rating.beer)
        {
            this.title = rating.beer.name;
            this.titleLink = (event) => this.psc.beerDetail(rating.beer?.beer_id ?? 0, event);

            // Show brewery name as subtitle (if desired) when the beer is used as the title
            if (psc.showBreweryName && rating.beer.brewery)
            {
                this.subtitle = rating.beer.brewery.name;
                this.subtitleLink = (event) => this.psc.breweryDetail(rating.beer?.brewery?.brewery_id ?? 0, event);
            }
        }
        // Next best title is the vintage/version
        else if (this.vv)
        {
            this.title = this.vv;
            this.vv = undefined;
            this.titleLink = (event) => this.psc.ratingDetail(rating, event);
        }
        // Last best title is the rated date
        else
        {
            this.title = this.formattedDate;
            this.ratedOn = undefined;
            this.titleLink = (event) => this.psc.ratingDetail(rating, event);
        }
    }

}

interface RatingGalleryItem extends BonesGalleryLargeItem
{
    rating: Rating;
    idea: Idea
}

@Component({
  selector: 'rating-gallery',
  templateUrl: 'rating-gallery.html',
    styleUrls: [ 'rating-gallery.scss' ]
})
export class RatingGalleryComponent implements OnChanges
{
    @Input() ratings: Rating[] = [ ];
    @Input() showBeerName = false;
    @Input() showBreweryName = false;
    @Input() cardWidth = 'calc(25% - 1.5em)';
    @Input() searchTerm: string = '';

    user: AppUser;
    galleryItems: RatingGalleryItem[] = [ ];

    constructor(
        private navCtrl: NavController,
        public gallery: BonesGalleryService,
        private mtus: UserService,
        public launch: LaunchEditModalService
    )
    {
        this.user = this.mtus.getUser();
    }

    ngOnChanges() : void
    {
        this.galleryItems = [ ];

        // Filter by keyword
        const filtered = new RatingFilter(this.ratings)
            .byKeyword(this.searchTerm)
            .rows;

        filtered.forEach(rating =>
        {
            const imageID = this.gallery.thumbnailPicker({ foreignKeyName: 'rating_id', foreignKeyValue: rating.rating_id });
            this.galleryItems.push(
            {
                imageID: imageID,
                imageURL: imageID ? undefined : '/assets/icon/thumb-bg.png',
                rating: rating,
                idea: new Idea(this, rating)
            });
        });
    }

    breweryDetail(brewery_id: number, event: Event)
    {
        this.navCtrl.navigateForward([ '/rate/brewery', brewery_id ]);
        event.stopPropagation();
    }

    beerDetail(beer_id: number, event: Event)
    {
        this.navCtrl.navigateForward([ '/rate/beer', beer_id ]);
        event.stopPropagation();
    }

    ratingDetail(rating: Rating, event?: Event)
    {
        this.navCtrl.navigateForward([ '/rate/rating', rating.rating_id ]);
        if (event)
        {
            event.stopPropagation();
        }
    }

    selected(item: BonesGalleryLargeItem)
    {
        this.ratingDetail((item as RatingGalleryItem).rating);
    }

}
