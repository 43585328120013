import { Injectable } from '@angular/core';

import { BonesError } from '@bones/core';
import { BonesRestInterface } from '@bones/network';
import { BonesTestToolsEditMockCacheService } from './test-tools-mock-cache';

/**
 * Mock rest service to similate rest backend server for use with mock cache
 */
@Injectable()
export class BonesTestToolsEditMockRestService implements BonesRestInterface
{
    /**
     * @ignore
     */
    constructor(
        private mcs: BonesTestToolsEditMockCacheService
    )
    {
    }

    /**
     * Process send request
     * @param url url - ignored
     * @param bgeRequest request generated from BonesForm
     */
    send(url: string, bgeRequest: any) : Promise<any>
    {
        console.log('mock send', url, bgeRequest);

        // Extract action and row data from request
        let action: string;
        let row: FormData | any;

        // Request is sent as FormData
        if (bgeRequest instanceof FormData)
        {
            action = bgeRequest.get('bge:action').toString();

            // Convert from FormData to json
            row = { };
            bgeRequest.forEach((v, k) =>
            {
                console.log('mock send, form request: ', k, '=', v);
                row[k] = v;
            });

            // Convert FIELD1 to numeric since FormData does not have the notion of string vs number
            if (row.FIELD1)
            {
                row.FIELD1 = +row.FIELD1;
            }

            console.log('mock send, converted form request: ', row);

            // return Promise.reject(new BonesError(
            // {
            //     className: 'BonesTestToolsEditMockRestService',
            //     methodName: 'send',
            //     message: 'Upload format of FormData not supported by mock backend'
            // }));
        }
        // Request is sent as json object
        else
        {
            // action = bgeRequest['bge:action'];
            action = bgeRequest.action;
            row = bgeRequest.row;
        }

        let response: any;
        if (action === 'delete')
        {
            const pk = row.FIELD1;
            this.mcs.delete(pk);
            response = { };
        }
        else if (row.FIELD1)
        {
            const pk = row.FIELD1;
            this.mcs.update(pk, row);
            response = { id: pk };
        }
        else
        {
            const pk = this.mcs.add(row);
            response = { id: pk };
        }

        console.log('mock send response', response);
        return Promise.resolve(response);
    }

    /**
     * Get URL for server rest services (not implemented)
     */
    getServerUrl() : string | undefined
    {
        return undefined;
    }

    //-----------------------------------------------------------------------

}
