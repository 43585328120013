import { Component } from '@angular/core';

@Component({
  templateUrl: 'test-tools-location.html'
})
export class BonesTestToolsLocationPage
{
    locbits: { key: string, value: string }[] = [ ];
    locationProperties = ['href', 'protocol', 'host', 'hostname', 'port', 'pathname', 'search',
        'hash', 'username', 'password', 'origin'];

    constructor(
    )
    {
        this.locationProperties.forEach(key =>
        {
            if (window.location[key])
            {
                this.locbits.push({ key: key, value: window.location[key] });
            }
        });
    }

}
