import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { BonesErrorService, BonesItemGroupFactory } from '@bones/core';
import { BonesForm } from '@bones/form';

import { MonkeyService } from '@BeerMonkey/core';

import { RatingService, RatingFilter, ratingRbScoreGroupFactory } from '@BeerMonkey/rate/service/RatingService';
import { Rating } from '@BeerMonkey/rate/class/Rating';
import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';
import { BeerService } from '@BeerMonkey/rate/service/BeerService';
import { CannedSearchPage, CannedSearch, enableCannedSearch } from '@BeerMonkey/rate/class/CannedSearch';
import { RatingInfo } from '@BeerMonkey/rate/class/RatingInfo';

@Component({
    selector: 'ratings-page',
    templateUrl: 'ratings-page.html'
})
export class RatingstPage implements OnInit, OnDestroy, CannedSearchPage
{
    filterForm: BonesForm;
    allRatings?: Rating[];
    filteredRatings?: Rating[];
    showFilters = false;
    showFilterCard = true;
    cardTitle = 'Ratings';
    group?: BonesItemGroupFactory<Rating, RatingInfo>;
    private nal: (() => void)[] = [ ];

    constructor(
        public route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private es: BonesErrorService,
        public monkey: MonkeyService,
        private breweryDB: BreweryService,
        private beerDB: BeerService,
        private ratingDB: RatingService
    )
    {
        this.showFilters = this.monkey.isLargeDevice;

        // Create filter form
        this.filterForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'brewery_id',
                    title: 'Brewery',
                    picker: () => this.breweryDB.cache.getPickerMap('name'),
                    onChange: () => this.filter()
                },
                {
                    name: 'beer_id',
                    title: 'Beer',
                    picker:
                    {
                        nocache: true,
                        populator: () =>
                        {
                            const breweryID = this.filterForm.getValue('brewery_id');
                            if (breweryID)
                            {
                                return this.beerDB.cache.getPickerMap('name', (b) => b.row.brewery_id === breweryID);
                            }
                            else
                            {
                                return this.beerDB.cache.getPickerMap('name');
                            }
                        }
                    },
                    onChange: () => this.filter()
                },
                {
                    name: 'style',
                    title: 'Style',
                    picker: () => this.beerDB.getFullStylePicker(),
                    onChange: () => this.filter()
                },
                {
                    name: 'mode',
                    title: 'Mode',
                    picker: () => this.ratingDB.getModes(),
                    onChange: () => this.filter()
                },
                {
                    name: 'vintage',
                    title: 'Vintage',
                    picker: () => this.ratingDB.getVintages(),
                    onChange: () => this.filter()
                },
                {
                    name: 'source',
                    title: 'Source',
                    picker: () => this.ratingDB.getAllSources(),
                    onChange: () => this.filter()
                },
                {
                    name: 'rating_year',
                    title: 'Rating Year',
                    picker: () => this.ratingDB.getRatingYears(),
                    onChange: () => this.filter()
                },
            ]
        });
    }

    async ngOnInit()
    {
        // Load and refresh ratings as needed
        this.nal.push(this.ratingDB.cache.nowAndLater(
        rows =>
        {
            this.allRatings = rows;
            this.filter();
        },
        error => this.es.errorHandler(error)));

        // Enable canned searches for this page
        enableCannedSearch(this);
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    filter()
    {
        if (this.allRatings && this.filterForm)
        {
            this.filteredRatings = new RatingFilter(this.allRatings)
                .byBrewery(this.filterForm.getValue('brewery_id'))
                .byBeer(this.filterForm.getValue('beer_id'))
                .byStyle(this.filterForm.getValue('style'))
                .byMode(this.filterForm.getValue('mode'))
                .byVintage(this.filterForm.getValue('vintage'))
                .bySource(this.filterForm.getValue('source'))
                .byRatingYear(this.filterForm.getValue('rating_year'))
                .rows;
        }
    }

}

//---------------------------------------------------------------------
// Canned searches
//---------------------------------------------------------------------

export const cannedSearchRatingTopAllTime = new CannedSearch(
{
    title: 'Top Ratings - All Time',
    module: '/rate',
    path: 'rating.top-all-time',
    component: RatingstPage,
    group: ratingRbScoreGroupFactory('desc')
});

const thisYear = new Date().getFullYear();

export const cannedSearchRatingTopYTD = new CannedSearch(
{
    title: 'Top Ratings - This Year',
    module: '/rate',
    path: 'rating.top-ytd',
    component: RatingstPage,
    group: ratingRbScoreGroupFactory('desc'),
    filters:
    [
        { name: 'rating_year', value: thisYear }
    ]
});

export const cannedSearchRatingTopLYTD = new CannedSearch(
{
    title: 'Top Ratings - Last Year',
    module: '/rate',
    path: 'rating.top-lytd',
    component: RatingstPage,
    group: ratingRbScoreGroupFactory('desc'),
    filters:
    [
        { name: 'rating_year', value: thisYear - 1 }
    ]
});

class RatingCannedSearch extends CannedSearch
{
    constructor()
    {
        super(
        {
            title: 'Ratings',
            module: '/rate',
            path: 'rating.white-label',
            component: RatingstPage,
            whiteLabel: true,
            filters:
            [
                { name: 'rating_year', value: undefined },
                { name: 'source', value: undefined },
                // { name: 'brewery_id', value: undefined },
                // { name: 'beer_id', value: undefined },
                // { name: 'style', value: undefined },
                // { name: 'mode', value: undefined },
                // { name: 'vintage', value: undefined }
            ]
        });
    }

    clear()
    {
        this.title = 'Ratings';
        this.whiteLabel = true;
        this.filters?.forEach(f => f.value = undefined);
    }

    set year(year: number)
    {
        this.setFilterValue('rating_year', year);
    }

    set source(source: string)
    {
        this.setFilterValue('source', source);
    }
}

export const cannedSearchRating = new RatingCannedSearch();
