import { Component, Input } from '@angular/core';

import { BonesError } from '../../model/bones-error';

@Component({
  selector: 'bones-tt-error-card',
  templateUrl: 'bones-tt-error-card.html'
})
export class BonesTtErrorCardComponent
{
    @Input() error: BonesError;

    constructor(
    )
    {
    }

}
