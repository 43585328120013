import { BnsMethod } from './BnsMethod';
import { BnsOptions } from './BnsOptions';

/**
 * Request object passed to mock web service.
 */
export interface MockBnsRequest
{
    /**
     * The HTTP method (e.g. GET, POST).
     */
    method: BnsMethod;

    /**
     * The URL prefix of the server.
     */
    serverUrl: string;

    /**
     * The web service suffix appended to the serverUrl to get the full URL.
     */
    apiUrl: string;

    /**
     * Request options.
     */
    options: BnsOptions;
}

/**
 * Response object expected back from mock web service.
 */
export class MockBnsResponse
{
    /**
     * Action requested.
     */
    action: 'none' | 'bypass' = 'none';

    /**
     * HTTP status code to emulate.
     */
    status: number = 200;

    /**
     * Mock body to pass back.
     */
    body?: any;
}

/**
 * Mock HTTP response created to emulate real HTTP response with a subset of properties.
 */
export interface MockHttpResponse
{
    /**
     * HTTP status.
     */
    status?: number;

    /**
     * HTTP status text.
     */
    statusText?: string;

    /**
     * HTTP response body.
     */
    body?: any;
}
