import { Component, Input, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { BonesFormItem } from '../../class/BonesFormItem';

/**
 * Form elapsed time.
 * 
 * @example
 * <bones-form-item-elapsed-time [form]="form" [item]="item"></bones-form-item-elapsed-time>
 */
@Component({
    selector: 'bones-form-item-elapsed-time',
    templateUrl: 'form-item-elapsed-time.html',
    styleUrls: [ 'form-item-elapsed-time.scss' ]
})
export class BonesFormItemElapsedTimeComponent implements OnInit, AfterViewInit, OnDestroy
{
    /**
     * Form containing field.
     */
    @Input() form: UntypedFormGroup;

    /**
     * Ionic input element used for date entry
     */
    @ViewChild('hh') hh: IonInput;
    /**
     * Ionic input element used for date entry
     */
    @ViewChild('mm') mm: IonInput;
    /**
     * Ionic input element used for date entry
     */
    @ViewChild('ss') ss: IonInput;

    /**
     * Object with details of form field to create.
     */
    @Input() item: BonesFormItem;

    private changeSubscription: Subscription;
    private formItem: AbstractControl;

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

    ngOnInit()
    {
    }

    ngAfterViewInit()
    {
        // Get Angular form control
        this.formItem = this.form.get(this.item.name);

        // Set initial value
        this.setValue(this.formItem.value);

        // Trigger change event so that return value will be reformatted to consistent format
        this.onChange();

        // Subscribe to value changes that happen outside the form
        this.changeSubscription = this.formItem.valueChanges.subscribe(v => this.setValue(v));
    }

    ngOnDestroy()
    {
        // Unsubscribe from value changes
        this.changeSubscription.unsubscribe();
    }

    /**
     * Set form ion-input value
     * 
     * @param value New elapsed time value to display on input form
     */
    private setValue(value: number)
    {
        const hours: number = Math.floor(value / 3600);
        const minutes: number = Math.floor((value - hours * 3600) / 60);
        const seconds: number = Math.floor(value - hours * 3600 - minutes * 60);
        // console.log('BonesFormItemElapsedTimeComponent.setValue', value, hours, minutes, seconds);
        this.hh.value = hours || '';
        this.mm.value = minutes || '';
        this.ss.value = seconds || '';
    }

    /**
     * Update form value when pseudo ion-input value is changed
     */
    onChange()
    {
        const rv = ((+this.hh.value || 0) * 60 * 60) + ((+this.mm.value || 0) * 60) + (+this.ss.value || 0);
        // console.log('BonesFormItemElapsedTimeComponent.onChange', this.hh.value, this.mm.value, this.ss.value, rv);
        // if (this.item.elapsedTime.returnAs === 'seconds')
        this.formItem.setValue(rv);
    }

}
