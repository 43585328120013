/**
 * Standard text to display number of rows being displayed.
 * 
 * @param showing is the list currently being displayed?
 * @param all total number of rows available
 * @param filtered number of rows after applying filters
 * @param preTruncate number of rows before truncating long lists
 */
export function nowShowing(showing: boolean, all: number, filtered: number, preTruncate: number) : string
{
    const showingText = showing ? 'Showing ' : 'Not showing ';

    if (filtered === all)
    {
        return showingText + 'all ' + all;
    }
    else if (preTruncate > filtered && preTruncate < all)
    {
        return showingText + filtered
            + ' of ' + preTruncate
            + ' filtered from ' + all;
    }
    else if (filtered === 0)
    {
        return 'No matches';
    }
    else
    {
        return showingText + filtered + ' of ' + all;
    }
}
