import { BonesFormItemToggleOptions } from './BonesFormItemToggleOptions';

/**
 * Configuration for item type of 'toggle'.
 */
export class BonesFormItemToggle
{
    /**
     * Value that should be populated in the form when the toggle is in the on position.
     */
    on: string | number | boolean;

    /**
     * Value that should be populated in the form when the toggle is in the off position.
     */
    off: string | number | boolean;

    /**
     * Create a form toggle item.
     */
    constructor(config: BonesFormItemToggleOptions)
    {
        this.on = config.on;
        this.off = config.off;
    }
}
