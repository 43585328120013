import { BonesFormItemElapsedTimeOptions } from './BonesFormItemElapsedTimeOptions';

export type BonesFormItemElapsedTimeReturnAs = 'seconds';

/**
 * Configuration for elapsed time form items.
 */
export class BonesFormItemElapsedTime
{
    /**
     * Format for returned time value.
     * 
     * seconds - return elapsed time as number of seconds.
     * 
     * The only currently implemented value is 'seconds'. Other possible future changes may include 'string'
     * for a formatted string with colons separators and 'object' for an object with properties for 'hh',
     * 'mm', and 'ss'.
     * 
     * Default is seconds.
     */
    returnAs?: BonesFormItemElapsedTimeReturnAs;

    /**
     * Create a elapsed time form item.
     * 
     * @param config either format string or a full blown BonesFormItemElapsedTimeOptions object.
     */
    constructor(config: BonesFormItemElapsedTimeOptions | string)
    {
        if (!config)
        {
            this.returnAs = 'seconds';
        }
        else if (typeof config === 'string')
        {
            this.returnAs = config as BonesFormItemElapsedTimeReturnAs;
        }
        else
        {
            this.returnAs = config.returnAs;
        }
    }
}
