<ion-item *ngFor="let item of propertyList">
  <ion-label>
    {{ item.id }}
    <p class="ion-text-wrap" *ngIf="item.value.length > maxLength">{{ item.value }}</p>
  </ion-label>
  <ion-text slot="end" *ngIf="item.value.length <= maxLength">{{ item.value }}</ion-text>
</ion-item>
<ion-item *ngIf="propertyList?.length === 0 && empty">
  <ion-label>{{ empty }}</ion-label>
</ion-item>
