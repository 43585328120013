<span class="rating-stars" [ngStyle]="{ 'width.em': width }">
  <span *ngIf="!rw">
    <span [ngStyle]="starClass(starcount)"
      [innerHTML]="starGlyph(starcount)"
      *ngFor="let starcount of [1, 2, 3, 4, 5]"></span>
  </span>
  <span *ngIf="rw" class="rating-update">
    <span [ngStyle]="starClass(starcount)"
      (click)="setScore(starcount)"
      [innerHTML]="starGlyph(starcount)"
      *ngFor="let starcount of [5, 4, 3, 2, 1]"></span>
  </span>
</span>
