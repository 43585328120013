import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

import { AuthGuard } from './service/AuthGuard';

import { SearchPage } from './pages/search/search';
import { InventoryPage } from './clique/inventory/inventory-page/inventory-page';
import { InventoryDetailPage } from './clique/inventory/inventory-detail/inventory-detail';
import { BreweriesPage } from './clique/brewery/breweries-page/breweries-page';
import { BreweryDetailPage } from './clique/brewery/brewery-detail/brewery-detail';
import { BeerDetailPage } from './clique/beer/beer-detail/beer-detail';
import { RatingstPage, cannedSearchRatingTopAllTime, cannedSearchRatingTopYTD, cannedSearchRatingTopLYTD, cannedSearchRating } from './clique/rating/ratings-page/ratings-page';
import { RatingDetailPage } from './clique/rating/rating-detail/rating-detail';
import { RecentRatingsPage } from './clique/rating/recent-ratings/recent-ratings';
import { BeerPage, cannedSearchBeerTopRated, cannedSearchBeerTopIPA } from './clique/beer/beer-page/beer-page';
import { GlasswarePage } from './clique/glassware/glassware-page/glassware-page';
import { GlasswareDetailPage } from './clique/glassware/glassware-detail/glassware-detail';
import { IdeaPage } from './pages/idea/idea';

/**
 * Routes for module
 */
const routes: Route[] =
[
    {
        path: 'rate',
        canActivate: [ AuthGuard ],
        children:
        [
            { path: 'breweries', component: BreweriesPage },
            { path: 'brewery/:breweryID', component: BreweryDetailPage },

            { path: 'beers', component: BeerPage },
            { path: 'beer/:beerID', component: BeerDetailPage },
            cannedSearchBeerTopRated.route,
            cannedSearchBeerTopIPA.route,

            
            { path: 'ratings', component: RatingstPage },
            { path: 'rating/:ratingID', component: RatingDetailPage },
            { path: 'recent-ratings', component: RecentRatingsPage },
            { path: 'yearly-ratings/:year', component: RecentRatingsPage },
            cannedSearchRatingTopYTD.route,
            cannedSearchRatingTopLYTD.route,
            cannedSearchRatingTopAllTime.route,
            cannedSearchRating.route,

            { path: 'inventory', component: InventoryPage },
            { path: 'inventory/:inventoryID', component: InventoryDetailPage },

            { path: 'glassware', component: GlasswarePage },
            { path: 'glassware/:glasswareID', component: GlasswareDetailPage },

            { path: 'search', component: SearchPage },

            { path: 'idea', component: IdeaPage },
        ]
    }
];

@NgModule({
    imports:
    [
        RouterModule.forChild(routes),
    ],
    exports:
    [
        RouterModule
    ]
})
export class RateRoutingModule
{
}
