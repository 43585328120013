import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'rating-stars',
    templateUrl: 'rating-stars.html',
    styleUrls: [ 'rating-stars.scss' ]
})
export class RatingStarsComponent implements OnInit
{
    @Input() score?: number;
    @Input() max = 5;
    @Input() rw = false;
    @Input() fractional = false;
    @Input() color = 'primary';
    @Output() change = new EventEmitter<number>();

    width?: number;
    scoreInt?: number;

    constructor(
    )
    {
    }

    async ngOnInit()
    {
        if (this.fractional)
        {
            this.width = this.score;
            this.scoreInt = Math.ceil(this.score ?? 0);
        }
        else
        {
            this.width = this.max;
            this.scoreInt = Math.round(this.score ?? 0);
        }
    }

    starClass(starNumber: number)
    {
        if ((this.scoreInt ?? 0) >= starNumber)
        {
            if (this.color === 'primary' || this.color === 'secondary' || this.color === 'tertiary')
            {
                return { color: 'var(--ion-color-' + this.color + ')' };
            }
            else
            {
                return { color: this.color };
            }
        }
        else
        {
            return { color: '#808080'};
        }
    }

    starGlyph(starNumber: number)
    {
        if ((this.scoreInt ?? 0) >= starNumber)
        {
            return '&#x2605;';
        }
        else
        {
            return '&#x2606;';
        }
    }

    setScore(rating: number)
    {
        this.change.emit(rating);
    }

    //-----------------------------------------------------------------------
}
