import { BonesFormItemPickerOptions } from './BonesFormItemPickerOptions';
import { BonesFormItemPickerChoice } from './BonesFormItemPickerChoice';
import { BonesFormItemPickerPopulator } from './BonesFormItemPickerPopulator';
import { BonesFormItemPickerValues } from './BonesFormItemPickerValues';

/**
 * Configuration for item type of 'picker'.
 */
export class BonesFormItemPicker
{
    /**
     * List of values for picker page.
     */
    private values: BonesFormItemPickerValues;

    /**
     * Can values be typed into the field (true),
     * or should it be left read-only and limited to values selected from the
     * picker page (false, defualt).
     */
    readWrite?: boolean;

    /**
     * Special formatter to display choice.
     * Does not affect the selected value returned.
     */
    formatItem?: (choice: BonesFormItemPickerChoice) => string;

    /**
     * Function to populate picker values.
     */
    populator?: BonesFormItemPickerPopulator;

    /**
     * Should picker values be cached after using a populator to populate the value list? (false, default),
     * Or should the populator be invoked every time the picker is displayed? (true).
     */
    nocache?: boolean;

    /**
     * Can multiple picker values be selected?
     * Default (false) is to allow only one value to be selected.
     */
    multi?: boolean;

    /**
     * Was there an error trying to get values via populator?
     */
    // private defectivePopulator = false;

    /**
     * Maximum values to display at once on the search (to prevent slow list rendering for very long lists).
     * Default 30.
     */
    maxValuesToDisplay?: number = 30;

    private popCount = 0;
    private populatorPromise: Promise<BonesFormItemPickerValues>;
    private unrequitedPopulatorPromise: boolean;

    /**
     * Create a form picker item.
     * 
     * @param config either an array of values for a simple picker or a full blown
     * BonesFormItemPickerOptions object.
     */
    constructor(config: BonesFormItemPickerOptions | BonesFormItemPickerValues | BonesFormItemPickerPopulator)
    {
        if (Array.isArray(config) || config instanceof Map)
        {
            this.values = config;
        }
        else if (typeof config === 'function')
        {
            this.populator = config;
        }
        else
        {
            this.values = config.values;
            this.readWrite = config.readWrite || false;
            this.formatItem = config.formatItem;
            this.populator = config.populator;
            this.nocache = config.nocache;
            this.multi = config.multi;
            this.maxValuesToDisplay = config.maxValuesToDisplay ?? 30;
        }
    }

    /**
     * Get the values used as choices for this picker.
     * @returns picker values.
     */
    async getValues() : Promise<BonesFormItemPickerValues>
    {
        // console.log('picker.getValues', this.values, this.populator, this.nocache);
        if (this.populator && (!this.values || this.nocache))
        {
            await this.populate(true);
        }

        return this.values;
    }

    /**
     * Set the values used as choices for this picker.
     * @param values new values.
     * @returns the values passed in.
     */
    setValues(values: BonesFormItemPickerValues) : BonesFormItemPickerValues
    {
        this.values = values;
        return this.values;
    }

    // getDisplayValue(formValue: string | string[]) : string
    // {
    //     const dv = this.getDisplayValue1(formValue);
    //     console.log('getDisplayValue', dv, this);
    //     return dv;
    // }
    /**
     * Translate form value to display value possibly taking map into account.
     * 
     * @param formValue value populated on form (may be array for multi select).
     * @returns value to display on form.
     */
    getDisplayValue(formValue: string | string[]) : string
    {
        // console.log('picker.getDisplayValue', formValue, this.values, this.populator);
        // The form is populated with a value that /may/ need to be mapped
        // But we don't know for sure because the values have not been loaded
        // It would be nice to know if the populator was going to return a map or an array
        if (formValue && !this.values && this.populator)
        {
            // Start the value population; eventually it will populate and the dom will be updated next refresh
            this.populate(false);

            // Return the internal value for now
            return formValue instanceof Array ? formValue.join(', ') : formValue;
        }

        // Maps need to display the mapped value instead of the internal value
        if (this.values instanceof Map)
        {
            // Multi select will have an array of values
            if (formValue instanceof Array)
            {
                const tv = this.values;
                return formValue.map(v => tv.get(v)).join(', ');
            }
            else
            {
                return this.values.get(formValue) || formValue;
            }
        }
        else
        {
            return formValue instanceof Array ? formValue.join(', ') : formValue;
        }
    }

    /**
     * Populate picker values
     * @param refresh true=trigger new populater, false=use old promise
     * @returns picker values
     */
    private async populate(refresh: boolean) : Promise<BonesFormItemPickerValues>
    {
        // console.log('picker.populator', refresh, this.defectivePopulator, this.populator, this.values);

        // if (++this.popCount > 3)
        // {
        //     console.log('picker.populator popped out', this.popCount);
        //     this.values = [ ];
        //     return Promise.resolve(this.values);
        // }

        // Asking for a refresh, but the old request is still active
        if (refresh && this.unrequitedPopulatorPromise)
        {
            // console.log('picker.populator unrequitedPopulatorPromise', refresh, this.populatorPromise);
            return this.populatorPromise;
        }

        // // App function is defective and returns errors; bypass it to avoid an endless error loop
        // if (this.defectivePopulator)
        // {
        //     console.log('picker.populator defectivePopulator', this.defectivePopulator);
        //     return this.populatorPromise;
        // }

        // Call app function to populate picker
        if (!this.populatorPromise || refresh)
        {
            // console.log('picker.populator trigger');
            this.unrequitedPopulatorPromise = true;

            this.populatorPromise = this.populator()
            .then(v =>
            {
                this.values = v;
                // console.log('picker.populator populated', this.values);
                return this.values;
            })
            .catch(error =>
            {
                // Flag app populator as defective
                // this.defectivePopulator = true;
                console.log('picker.populator failed', error);

                // Resolve as success because there is nobody to catch the error;
                // the app should have handled it but still throw an error so we are in the know and not waiting forever
                this.values = [ ];
                return Promise.resolve(this.values);
            })
            .finally(() =>
            {
                this.unrequitedPopulatorPromise = false;
            });
        }

        // Return new or existing promise
        return this.populatorPromise;
    }
}
