import { BonesSort } from './BonesSort';
import { BonesSortOption } from './BonesSortOption';

/**
 * Generic group of objects
 */
export class BonesItemGroup<ITEM>
{
    _by: string;
    items: ITEM[] = [ ];

    constructor(by: string, private formatter: (by: string) => string)
    {
        this._by = by;
    }

    get by() : string
    {
        return this.formatter(this._by);
    }

    add(item: ITEM) : void
    {
        this.items.push(item);
    }
}

//---------------------------------------------------------------------------

/**
 * An Item is an object with at least a 'row' property
 */
interface Item
{
    row: any;
}

/**
 * Parent class for creating and sorting generic groups
 */
export abstract class BonesItemGroupFactoryBase<ITEM extends Item>
{
    defaultSortOption: BonesSortOption = 'asc';

    constructor(sort?: BonesSortOption)
    {
        if (sort)
        {
            this.defaultSortOption = sort;
        }
    }

    abstract getBy(item: ITEM) : string;

    get sortOption() : BonesSortOption
    {
        return this.defaultSortOption;
    }

    formatBy(by: string) : string
    {
        return by;
    }

    newGroup(by: string) : BonesItemGroup<ITEM>
    {
        return new BonesItemGroup(by, this.formatBy);
    }

    sortItems(items: ITEM[]) : ITEM[]
    {
        BonesSort(items, [ { getValue: (item) => this.getBy(item), sort: this.sortOption } ]);
        return items;
    }

    sortGroups(groups: BonesItemGroup<ITEM>[]) : BonesItemGroup<ITEM>[]
    {
        BonesSort(groups, [ { propertyName: '_by', sort: this.sortOption } ]);
        return groups;
    }

    group(items: ITEM[]) : BonesItemGroup<ITEM>[]
    {
        const groupMap = new Map<string, BonesItemGroup<ITEM>>();

        items.forEach(item =>
        {
            const by = this.getBy(item);

            if (!groupMap.has(by))
            {
                groupMap.set(by, this.newGroup(by));
            }

            groupMap.get(by).add(item);
        });

        // Build sorted list of groups
        return this.sortGroups(Array.from(groupMap.values()));
    }
}

//---------------------------------------------------------------------------

/**
 * Optional options for creating a grouping factory
 */
export interface BonesItemGroupFactoryOptions
{
    sort?: BonesSortOption;
    byFirstLetter?: boolean;
}

/**
 * Parent class for creating and sorting generic groups following the Object.row:ObjectInfo pattern
 */
export class BonesItemGroupFactory<T extends Item, TI> extends BonesItemGroupFactoryBase<T>
{
    // by: keyof TI;

    constructor(arg1: ((item: T) => string) | keyof TI, options?: BonesItemGroupFactoryOptions)
    {
        super(options.sort);

        // First argument is either a function to get the key value from an item or the name of the key's property in the item's row
        if (typeof arg1 === 'function')
        {
            this.getBy = arg1;
        }
        else
        {
            // this.by = arg1;
            this.getBy = (item: T) => String(item.row[arg1] ?? '');
        }

        // Use only the first letter of the key for the grouping
        if (options.byFirstLetter)
        {
            // Save reference to original getBy function
            const innerGetBy = this.getBy;

            this.getBy = (item: T) =>
            {
                // Use original getBy function to get the value and truncate it
                const first = innerGetBy(item).substr(0, 1);

                if (first.match(/[a-zA-Z]/))
                {
                    return first;
                }
                else
                {
                    return '#';
                }
            }
        }
    }

    // Undefined until object creation
    getBy = undefined;
}


