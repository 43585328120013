<ion-header><app-page-header subtitle="RateBeer Statistics"></app-page-header></ion-header>

<ion-content>

  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="gridTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="defTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">

    <ion-row>
      <ion-col size="3">
        <ng-container *ngTemplateOutlet="gridTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="defTmpl8"></ng-container>
      </ion-col>

      <ion-col size="3">
        <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
      </ion-col>

      <ion-col size="3">
        <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
      </ion-col>

      <ion-col size="3">
        <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<!-- Grid -->
<ng-template #gridTmpl8>
  <bones-table #bt title="RateBeer Statistics" *ngIf="showGrid"
    [rows]="rows"
    [pageSize]="99" [pageSizes]="undefined" [showFooter]="false" [allowSearch]="false">
    <thead>
      <tr>
        <bones-table-th name="category"></bones-table-th>
        <bones-table-th name="breweries">Breweries</bones-table-th>
        <bones-table-th name="beers">Beers</bones-table-th>
        <bones-table-th name="ratings">Ratings</bones-table-th>
      </tr>
    </thead>
    <tbody *ngIf="bt.data">
      <tr *ngFor="let row of bt.data.rows">
        <th>
          <a (click)="showAll(row)">{{ row.category }}</a>
        </th>
        <td class="ion-text-right">
          <a (click)="showBreweries(row)" *ngIf="row.breweries">{{ row.breweries | number:'0.0-0' }}</a>
        </td>
        <td class="ion-text-right">
          <a (click)="showBeers(row)" *ngIf="row.beers">{{ row.beers | number:'0.0-0' }}</a>
        </td>
        <td class="ion-text-right">
          <a (click)="showRatings(row)" *ngIf="row.ratings">{{ row.ratings | number:'0.0-0' }}</a>
        </td>
      </tr>
    </tbody>
  </bones-table>
</ng-template>

<!-- Definitions -->
<ng-template #defTmpl8>
  <ion-card class="ion-padding">
    <bones-card-toolbar #bct title="Definitions" [expandable]="true" [expanded]="monkey.isLargeDevice"></bones-card-toolbar>
    <ion-card-content class="ion-no-padding" *ngIf="bct.expanded">
      <ion-item>
        <ion-label>
          <ion-text color="primary">Linked</ion-text>
          <p class="ion-text-wrap">Has been linked to RateBeer</p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <ion-text color="primary">Unlinked</ion-text>
          <p class="ion-text-wrap">Has not been linked to RateBeer, but could possibly be eligable to be linked</p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <ion-text color="primary">Unwanted</ion-text>
          <p class="ion-text-wrap">Is not eligable to linked to RateBeer because it is too old or retired or out of business</p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <ion-text color="primary">New</ion-text>
          <p class="ion-text-wrap">Ratings that have not been uploaded to RateBeer, but the beer has been linked</p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>
          <ion-text color="primary">Updated</ion-text>
          <p class="ion-text-wrap">Ratings that have been uploaded to RateBeer, but have been changed locally and need to be re-uploaded</p>
        </ion-label>
      </ion-item>
      <!-- <ion-item>
        <ion-label>
          <ion-text color="primary">Versions</ion-text>
          <p class="ion-text-wrap"></p>
        </ion-label>
      </ion-item> -->
      <!-- <ion-item>
        <ion-label>
          <ion-text color="primary">Vintages</ion-text>
          <p class="ion-text-wrap"></p>
        </ion-label>
      </ion-item> -->
    </ion-card-content>
  </ion-card>
</ng-template>

<!-- Drill downs -->

<ng-template #breweryTmpl8>
  <brewery-list-card id="breweriesCard" [breweries]="breweries" [title]="breweryCardTitle" [showBreweries]="true" *ngIf="breweries">
  </brewery-list-card>
</ng-template>

<ng-template #beerTmpl8>
  <beer-list-card id="beersCard" [beers]="beers" [title]="beerCardTitle" [showBreweryName]="true" *ngIf="beers">
  </beer-list-card>
</ng-template>

<ng-template #ratingTmpl8>
  <rating-list-card id="ratingsCard" [ratings]="ratings" [title]="ratingCardTitle" *ngIf="ratings">
  </rating-list-card>
</ng-template>
