import { Component, Input, OnInit } from '@angular/core';

import { BonesMenuCardAction } from '@bones/core';

import { BonesGalleryService } from '../../service/BonesGalleryService';
import { BonesGalleryDocumentInfo } from '../../class/BonesGalleryDocumentInfo';
import { BonesGalleryDocument } from '../../class/BonesGalleryDocument';

/**
 * Display documents in a card.
 * 
 * <bones-gallery-card [documents]="documents" [editable]="user.canEdit" [add]="[ 'restaurant_id', restaurantID ]"
 *   *ngIf="(documents | bgdFilterNotFeatured).length > 0">
 * </bones-gallery-card>
 */
@Component({
  selector: 'bones-gallery-card',
  templateUrl: 'bones-gallery-card.html',
  styleUrls: [ 'bones-gallery-card.scss' ]
})
export class BonesGalleryCardComponent implements OnInit
{
    /**
     * Card title - defaults to 'Documents'
     */
    @Input() title = 'Documents';
    /**
     * The list of documents to be displayed
     */
    @Input() documents: BonesGalleryDocument[] = [ ];
    /**
     * Enable documents to be added by specifying a foreign key name and value (requires editable to be true)
     */
    @Input() add?: [ keyof BonesGalleryDocumentInfo, number ];
    /**
     * Can this card be expanded and collapsed?
     */
    @Input() expanded = true;
    /**
     * Can the documents in this card be editable?
     * 
     * This enables card menu options to toggle between "gallery Mode" and "Maintenance Mode"
     */
    @Input() editable = false;

    /**
     * Menu items used to create a menu for the card
     */
    cardMenu: BonesMenuCardAction[] = [ ];
    /**
     * Current state for edit mode vs gallery mode
     */
    editDocuments = false;

    /**
     * @ignore
     */
    constructor(
        public gallery: BonesGalleryService
    )
    {
    }

    /**
     * @ignore
     */
    ngOnInit()
    {
        if (this.editable)
        {
            this.cardMenu.push({ title: 'Gallery Mode', icon: 'pencil', action: () => this.editDocuments = false });
            this.cardMenu.push({ title: 'Maintain Documents', icon: 'pencil', action: () => this.editDocuments = true });

            if (this.add)
            {
                this.cardMenu.push({ title: 'Add', icon: 'add-circle', action: () => this.doadd() });
            }
        }

    }

    /**
     * Open document in new window/tab
     * 
     * @param document document to view
     */
    documentDetail(document: BonesGalleryDocument)
    {
        this.gallery.open(document.document_id);
    }

    private doadd()
    {
        if (this.add)
        {
            this.gallery.addDocument(this.add[0], this.add[1]);
        }
    }

}
