import { Component } from '@angular/core';

import { BonesDeviceService } from '../../service/device.service';
import { DeviceInfo } from '../../model/device-info';

@Component({
  templateUrl: 'test-tools-device.html'
})
export class BonesTestToolsDevicePage
{
    info: DeviceInfo;

    constructor(
        private bds: BonesDeviceService
    )
    {
        this.bds.getInfo().then(info => this.info = info);
    }

}
