import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BonesErrorService } from '@bones/core';
import { BonesEditForm } from '../../class/BonesEditForm';

import { BonesTestToolsEditMockCacheService, CacheRow } from '../../service/test-tools-mock-cache';
import { BonesTestToolsCacheEditFormPage } from '../test-tools-cache-edit-form/test-tools-cache-edit-form';

/**
 * Display mock grid
 */
@Component({
  templateUrl: 'test-tools-cache-edit-grid.html'
})
export class BonesTestToolsCacheEditGridPage
{
    rows: CacheRow[];

    constructor(
        private modalCtrl: ModalController,
        private bes: BonesErrorService,
        private mcs: BonesTestToolsEditMockCacheService
    )
    {
        this.getData();
    }

    /**
     * Populate grid data
     */
    async getData()
    {
        // this.rows = await this.mcs.cache.getList();
        this.mcs.cache.nowAndLater(
        rows =>
        {
            console.log('refresh grid rows', rows);
            this.rows = rows;
        },
        error => this.bes.errorHandler(error));
    }

    /**
     * Launch edit screen
     */
    edit(row?: CacheRow)
    {
        BonesEditForm.open({
            modalCtrl: this.modalCtrl,
            editPage: BonesTestToolsCacheEditFormPage,
            pk: row ? row.info.FIELD1 : undefined
        }).then(results =>
        {
            console.log('BonesEditForm results', results);
        });
    }

}
