import { Component, Input, OnInit } from '@angular/core';

import { BonesMenuCardAction } from '@bones/core';

import { Beer } from '@BeerMonkey/rate/class/Beer';

@Component({
  selector: 'beer-info',
  templateUrl: 'beer-info.html'
})
export class BeerInfoComponent implements OnInit
{
    _beer?: Beer;
    @Input('actions') beerCardMenu?: BonesMenuCardAction[];

    @Input() set beer(beer: Beer | undefined)
    {
        this._beer = beer;
        // this.ratebeerID = this.beer ? this.beer.row.ratebeer_id : undefined;
    }
    get beer() : Beer | undefined
    {
        return this._beer;
    }

    constructor(
    )
    {
    }

    ngOnInit()
    {
    }

}
