<ion-list>
  <ion-item-sliding *ngFor="let brewery of breweryList">

    <ion-item (click)="breweryDetail(brewery.brewery_id, $event)">
      <ion-label>
        <ion-text color="primary">
          <div class="ion-text-wrap" >{{ brewery.row.name }}</div>
        </ion-text>
        <div>{{ brewery.row.location }}</div>
      </ion-label>
      <img style="height: 2em;" slot="end" *ngIf="brewery.row.ratebeer_id"
            src="/assets/icon/rate-beer-logo.png" (click)="rateBeerDB.openBrewery(brewery.row.ratebeer_id, $event)">
    </ion-item>

    <ion-item-options>
      <ion-item-option (click)="breweryDetail(brewery.brewery_id, $event)">
        <ion-icon slot="top" size="large" name="eye"></ion-icon>
        Detail
      </ion-item-option>
      <ion-item-option (click)="rateBeerDB.openBrewery(brewery.row.ratebeer_id, $event)" *ngIf="brewery.row.ratebeer_id">
        <img style="height: 2em;" slot="top" src="/assets/icon/rate-beer-logo.png">
        RateBeer
      </ion-item-option>
      <ion-item-option (click)="launch.editBrewery(brewery)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="pencil"></ion-icon>
        Edit
      </ion-item-option>
      <ion-item-option (click)="launch.addBeer(brewery.brewery_id)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="add-circle"></ion-icon>
        Beer
      </ion-item-option>
    </ion-item-options>

  </ion-item-sliding>
</ion-list>
