import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BonesError } from '@bones/core';
import { BonesNetworkLogService } from './network-log';

import { BnsOptions } from '../class/BnsOptions';
import { BnsResponse } from '../class/BnsResponse';
import { BnsRequestInfo } from '../class/BnsRequestInfo';
import { BnsResponseInfo } from '../class/BnsResponseInfo';
import { MockBnsResponse, MockHttpResponse } from '../class/mock';
import { BonesNetworkMockService } from './mock-service';

/**
 * General web service access
 */
@Injectable({
  providedIn: 'root'
})
export class BonesNetworkService
{
    /**
     * @ignore
     */
    constructor(
        private http: HttpClient,
        private networkLog: BonesNetworkLogService,
        private mock: BonesNetworkMockService
    )
    {
    }

    // // Configuration
    // private config:
    // {
    //     mockService: BonesNetworkMockService;
    // } =
    // {
    //     mockService: undefined
    // };

    // /**
    //  * Set mock service to return responses instead of using actual web service.
    //  *
    //  * @param service Service derived from BonesNetworkMockService to supply mock answers.
    //  */
    // public set mockService(service: BonesNetworkMockService)
    // {
    //     this.config.mockService = service;
    // }

    //-----------------------------------------------------------------------

    /**
     * Get request.
     * 
     * @param serverUrl base url prefix
     * @param apiUrl api url suffix
     * @param options BnsOptions
     */
    public get(serverUrl: string, apiUrl: string, options = new BnsOptions()) : Promise<BnsResponse|any>
    {
        return this.ghost('GET', serverUrl, apiUrl, options);
    }

    /**
     * Post request
     * 
     * @param serverUrl base url prefix
     * @param apiUrl api url suffix
     * @param params post parameters
     * @param options BnsOptions
     */
    public post(serverUrl: string, apiUrl: string, params?: any, options = new BnsOptions()) : Promise<BnsResponse|any>
    {
        if (params)
        {
            options = { ...options, params: params };
        }

        return this.ghost('POST', serverUrl, apiUrl, options);
    }

    /**
     * Web service request
     */
    private async ghost(method: 'GET' | 'POST', serverUrl: string, apiUrl: string, options: BnsOptions) : Promise<BnsResponse|any>
    {
        const startTime = new Date();

        // Option defaults
        options.responseType = options.responseType ? options.responseType : 'json';
        options.returnValue = options.returnValue ? options.returnValue : 'default';

        // Options for http request
        const httpOptions: any =
        {
            withCredentials: true,
            observe: 'response',
            responseType: options.responseType,
        };

        // Track request info
        const bnsRequestInfo = new BnsRequestInfo();
        bnsRequestInfo.method = method;
        bnsRequestInfo.serverUrl = serverUrl;
        bnsRequestInfo.apiUrl = apiUrl;
        bnsRequestInfo.options = options;
        bnsRequestInfo.httpOptions = httpOptions;

        // Track response info
        const bnsResponseInfo = new BnsResponseInfo();

        // Create log entry
        const apiLogEntry = this.networkLog.newEntry();
        apiLogEntry.bnsRequestInfo = bnsRequestInfo;
        apiLogEntry.bnsResponseInfo = bnsResponseInfo;

        // Create http headers
        if (options.headers)
        {
            httpOptions.headers = new HttpHeaders(options.headers);
        }

        // Build url
        let fullUrl = serverUrl;
        if (fullUrl.substr(fullUrl.length - 1, 1) !== '/' && apiUrl.length > 0 && apiUrl.substr(0, 1) !== '/')
        {
            fullUrl += '/';
        }
        fullUrl += apiUrl;

        // Log request
        console.log('web request', bnsRequestInfo);

        try
        {
            // Check for mock service
            let mockAnswer: MockBnsResponse;
            if (this.mock.enabled)
            {
                mockAnswer = await this.mock.onRequest(
                {
                    method: method,
                    serverUrl: serverUrl,
                    apiUrl: apiUrl,
                    options: options
                });
            }

            // Got a good mock answer
            if (mockAnswer && mockAnswer.action !== 'bypass')
            {
                // Fake a simplified http response
                bnsResponseInfo.mockResponse =
                {
                    status: mockAnswer.status,
                    statusText: mockAnswer.status === 200 ? 'ok' : mockAnswer.status,
                    body: mockAnswer.body
                } as MockHttpResponse;

                // Throw error if mock service returns http error status
                if (mockAnswer.status !== 200)
                {
                    throw new BonesError(
                    {
                        className: 'BonesNetworkService',
                        methodName: method,
                        message: 'mock web service returned ' + mockAnswer.status,
                    })
                    .add(
                    {
                        mockAnswer: mockAnswer
                    });
                }

                console.log('mock web response', bnsResponseInfo);
            }
            // Use real web service
            else
            {
                let pp: any;
                if (method === 'GET')
                {
                    bnsRequestInfo.requestBody = undefined;
                    httpOptions.params = options.params;
                    pp = this.http.get(fullUrl, httpOptions);
                }
                else if (options.queryParams)
                {
                    bnsRequestInfo.requestBody = null;
                    httpOptions.params = options.queryParams;
                    pp = this.http.post(fullUrl, options.body || null, httpOptions);
                }
                else
                {
                    bnsRequestInfo.requestBody = options.params;
                    pp = this.http.post(fullUrl, options.params, httpOptions);
                }

                // Get actual HTTP response
                const response = await pp.toPromise();

                // Log response
                bnsResponseInfo.httpResponse = response;
                console.log('web response', bnsResponseInfo);
            }

            // Calculate response time
            const endTime = new Date();
            bnsResponseInfo.stats.totalElapsedTime = endTime.getTime() - startTime.getTime();

            // Mask passwords
            if (options.headers && options.headers.attEDSUserPassword)
            {
                options.headers.attEDSUserPassword = '********';
            }

            // Check response format
            if (bnsResponseInfo.mockResponse)
            {
                bnsResponseInfo.payload = bnsResponseInfo.mockResponse.body;
            }
            else if (options.responseType === 'json')
            {
                if (!bnsResponseInfo.httpResponse || !bnsResponseInfo.httpResponse.body)
                {
                    throw new BonesError(
                    {
                        className: 'BonesNetworkService',
                        methodName: 'ghost.' + method,
                        message: 'Invalid json response'
                    });
                }

                bnsResponseInfo.payload = bnsResponseInfo.httpResponse.body;
            }
            else if (options.responseType === 'text')
            {
                bnsResponseInfo.payload = bnsResponseInfo.httpResponse.body;
            }
            else if (options.responseType === 'blob')
            {
                bnsResponseInfo.payload = bnsResponseInfo.httpResponse.body;
            }
            else
            {
                throw new BonesError(
                {
                    className: 'BonesNetworkService',
                    methodName: 'ghost.' + method,
                    message: 'Unsupported responseType: ' + options.responseType
                });
            }

            // Valid response
            apiLogEntry.status = 'success';

            // Return response object
            const bnsResponse: BnsResponse =
            {
                bnsRequestInfo: bnsRequestInfo,
                bnsResponseInfo: bnsResponseInfo,
                logEntry: apiLogEntry
            };

            // Return either a full response object or just the payload depending on request
            switch (options.returnValue)
            {
                case 'bns':
                    return bnsResponse;
                case 'default':
                default:
                    return bnsResponse.bnsResponseInfo.payload;
            }
        }
        catch (error)
        {
            // Save http error response for upstream analysis
            bnsResponseInfo.httpErrorResponse = error;

            // Mask passwords
            if (options.headers && options.headers.attEDSUserPassword)
            {
                options.headers.attEDSUserPassword = '********';
            }

            // Calculate response time
            const endTime = new Date();
            bnsResponseInfo.stats.totalElapsedTime = endTime.getTime() - startTime.getTime();

            console.log('web response', bnsResponseInfo);

            // if (!BonesError.prototype.isPrototypeOf(error))
            error = new BonesError(
            {
                className: 'BonesNetworkService',
                methodName: method,
                message: 'web service failed',
                error: error
            })
            .add(
            {
                bnsRequestInfo: bnsRequestInfo,
                bnsResponseInfo: bnsResponseInfo
            });

            // Log error
            apiLogEntry.error = error;
            apiLogEntry.status = 'failure';

            throw error;
        }
    }

    //-----------------------------------------------------------------------

}
