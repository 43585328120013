import { IdeaInfo } from './IdeaInfo';

export class Idea
{
    idea_id: number;

    constructor(public row: IdeaInfo)
    {
        this.idea_id = row.idea_id;
    }

}
