<ion-header><bones-tt-page-header title="API Log Detail"></bones-tt-page-header></ion-header>

<ion-content>

  <ion-card class="ion-padding" *ngIf="entry">
    <ion-card-title>Request Summary</ion-card-title>
      <ion-list>
        <ion-item>
          <ion-label>
            <h2>Timestamp</h2>
            <h3>{{ entry.ts | date:'medium' }}</h3>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h2>Method</h2>
            <h3>{{ entry.bnsRequestInfo.method }}</h3>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h2>Server URL</h2>
            <h3>{{ entry.bnsRequestInfo.serverUrl }}</h3>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h2>API URL</h2>
            <h3>{{ entry.bnsRequestInfo.apiUrl }}</h3>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h2>Status</h2>
            <h3>{{ entry.status }}</h3>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="entry.error && entry.error.otherData && entry.error.otherData.status">
          <ion-label>
            <h2>Error Status</h2>
            <h3>{{ entry.error.otherData.status }} {{ entry.error.otherData.statusText }}</h3>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="entry.bnsRequestInfo.requestBody">
          <ion-label>
            <h2>Body</h2>
            <pre>{{ entry.bnsRequestInfo.requestBody | json }}</pre>
          </ion-label>
        </ion-item>
      </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="entry && entry.bnsRequestInfo && entry.bnsRequestInfo.options">
    <ion-card-title>Request Options (Bones)</ion-card-title>
    <pre>{{ entry.bnsRequestInfo.options | json }}</pre>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="entry && entry.bnsRequestInfo && entry.bnsRequestInfo.httpOptions">
    <ion-card-title>Request Options (HTTP)</ion-card-title>
    <pre>{{ entry.bnsRequestInfo.httpOptions | json }}</pre>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="entry && entry.bnsResponseInfo && entry.bnsResponseInfo.httpResponse">
    <ion-card-title>HTTP Response Summary</ion-card-title>
      <ion-list>
        <ion-item>
          <ion-label>
            <h2>Status</h2>
            <h3>{{ entry.bnsResponseInfo.httpResponse.status }}</h3>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h2>Status Text</h2>
            <h3>{{ entry.bnsResponseInfo.httpResponse.statusText }}</h3>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="entry.bnsResponseInfo.httpResponse.ok">
          <ion-label>
            <h2>OK</h2>
            <h3>{{ entry.bnsResponseInfo.httpResponse.ok }}</h3>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="entry.bnsResponseInfo.httpResponse.type">
          <ion-label>
            <h2>Type</h2>
            <h3>{{ entry.bnsResponseInfo.httpResponse.type }}</h3>
          </ion-label>
        </ion-item>
      </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="entry && entry.bnsResponseInfo && entry.bnsResponseInfo.mockResponse">
    <ion-card-title>Mock HTTP Response Summary</ion-card-title>
      <ion-list>
        <ion-item>
          <ion-label>
            <h2>Status</h2>
            <h3>{{ entry.bnsResponseInfo.mockResponse.status }}</h3>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h2>Status Text</h2>
            <h3>{{ entry.bnsResponseInfo.mockResponse.statusText }}</h3>
          </ion-label>
        </ion-item>
      </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="entry && entry.bnsResponseInfo">
    <ion-card-title>Response Statistics</ion-card-title>
      <ion-list>
        <ion-item *ngIf="entry.bnsResponseInfo.stats.totalElapsedTime">
          <ion-label>
            <h2>Total Elapsed Time</h2>
            <h3>{{ entry.bnsResponseInfo.stats.totalElapsedTime }}ms</h3>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="entry.bnsResponseInfo.stats.serverExecutionTime">
          <ion-label>
            <h2>Server Execution Time</h2>
            <h3>{{ entry.bnsResponseInfo.stats.serverExecutionTime }}ms</h3>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="entry.bnsResponseInfo.stats.networkOverheadTime">
          <ion-label>
          <h2>Network Overhead Time</h2>
          <h3>{{ entry.bnsResponseInfo.stats.networkOverheadTime }}ms
            ({{entry.bnsResponseInfo.stats.networkOverheadPercent}}% overhead)</h3>
          </ion-label>
        </ion-item>
      </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="entry && entry.bnsResponseInfo && entry.bnsResponseInfo.httpResponse && entry.bnsResponseInfo.httpResponse.headers">
    <ion-card-title>Response Headers</ion-card-title>
      <ion-list>
        <ion-item *ngFor="let headerName of entry.bnsResponseInfo.httpResponse.headers.keys()">
          <ion-label>
            <h2>{{ headerName }}</h2>
            <h3>{{ entry.bnsResponseInfo.httpResponse.headers.get(headerName) }}</h3>
          </ion-label>
        </ion-item>
      </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="entry && entry.bnsResponseInfo && entry.bnsResponseInfo.payload">
    <ion-card-title>Response Body</ion-card-title>
    <pre>{{ entry.bnsResponseInfo.payload | json }}</pre>
  </ion-card>

  <bones-tt-error-card [error]="entry?.error"></bones-tt-error-card>

</ion-content>
