<ion-header><modal-header title="Edit Brewery" [editForm]="this"></modal-header></ion-header>

<ion-content [formGroup]="form" *ngIf="form">
  <ion-card class="ion-padding">
    <ion-card-title>Brewery</ion-card-title>
    <bones-form-items [form]="form" [items]="items"></bones-form-items>
    <div class="ion-text-right">
      <ion-button (click)="searchRateBeer()">Search RateBeer</ion-button>
      <ion-button (click)="copyRateBeer()">Copy RateBeer</ion-button>
    </div>
  </ion-card>

  <rb-brewery-info title="RateBeer Brewery" [ratebeerID]="bonesForm.getValue('ratebeer_id')"></rb-brewery-info>
</ion-content>

<ion-footer>
  <bones-edit-button-bar [editor]="this"></bones-edit-button-bar>
</ion-footer>
