import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BonesErrorService } from '@bones/core';

import { UserService } from '@BeerMonkey/core/service/UserService';

@Component({
  templateUrl: 'login.html'
})
export class LoginPage implements OnInit
{
    username: string;
    password: string;

    /**
     * Display login screen
     *
     * Login screen handles authentication and returns the user or undefined.
     */
    static async launch(modalCtrl: ModalController)
    {
        // Create a modal for the login screen
        const modal = await modalCtrl.create(
        {
            component: LoginPage
        });

        // Display modal
        modal.present();

        // Get response from modal
        const response = await modal.onDidDismiss();

        // Login modal will return a user or undefined if the login failed
        return (response.data) ? true : false;
    }

    constructor(
        private modalCtrl: ModalController,
        private es: BonesErrorService,
        private mtus: UserService
    )
    {
        // Get saved credentials
        this.username = window.localStorage.getItem('beer.monkey.username') ?? '';
        this.password = window.localStorage.getItem('beer.monkey.password') ?? '';
    }

    async ngOnInit()
    {
    }

    /**
     * Login
     */
    login()
    {
        this.mtus.authenticateUser(this.username, this.password)
        .then(user =>
        {
            console.log('user authenticated', user);

            // Save credentials
            window.localStorage.setItem('beer.monkey.username', this.username);
            window.localStorage.setItem('beer.monkey.password', this.password);

            // Dismiss login modal, passing back user
            console.log('login user', user);
            this.modalCtrl.dismiss(user);
        })
        .catch(error =>
        {
            this.es.errorHandler(error);
        });
    }

    guest()
    {
        // Dismiss login modal, passing back undefined for user to indicate guest login
        console.log('login guest');
        this.modalCtrl.dismiss(undefined);
    }

}
