import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators, ValidationErrors } from '@angular/forms';

import { BonesErrorService, BonesError } from '@bones/core';
import { BonesForm } from '../../class/BonesForm';
// import { BonesFormItemPickerValues } from '@bones/form/class/BonesFormItemPickerValues';
import { BonesFormItemPickerValues } from '../../class/BonesFormItemPickerValues';

/**
 * This test tool uses the various form controls.
 * 
 * It creates its own FormGroup and edit form for more control over the form that what is offered
 * by BonesFormEdit.
 */
@Component({
  templateUrl: 'test-tools-bones-form-controls.html'
})
export class TestToolsBonesFormControlsPage
{
    display: any;
    bf: BonesForm;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private bes: BonesErrorService,
    )
    {
        // Create map with numbers as keys
        const picker3Map = new Map<number, string>();
        picker3Map.set(1, 'One');
        picker3Map.set(2, 'Two');
        picker3Map.set(3, 'Three');
        picker3Map.set(4, 'Four');

        // Create map with strings as keys
        const picker4Map = new Map<string, string>();
        picker4Map.set('A', 'Apple');
        picker4Map.set('B', 'Banana');
        picker4Map.set('C', 'Cherry');

        // Create form and items
        this.bf = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'field1',
                    validator: Validators.required,
                    // hidden on bones form because it is manually placed in html
                    hidden: true,
                    onChange: (v, i) => console.log('field1 change', v, i)
                },
                {
                    name: 'miniTest',
                    title: 'Test minimum and maximum length',
                    validator: [ Validators.required, Validators.minLength(12), Validators.maxLength(15) ],
                    errorMessages:
                    {
                        // commented out in order to use default minlength message
                        // minlength: 'Must enter at least 12 characters.',
                        // override default maxlength message
                        maxlength: 'Enter between 12 to 15 characters.',
                    },
                    help: 'This field requires a minimum of 12 characters and must not have more than 15 characters.'
                        + ' These are silly rules, but, hey, who knows what requirement may come along?'
                },
                {
                    name: 'rofield',
                    title: 'Readonly Field',
                    initialValue: 'You can\'t change me',
                    readonly: true,
                    help: 'Ha! ha!'
                },
                {
                    name: 'dog',
                    title: 'Who\'s a good dog?',
                    validator: TestToolsBonesFormControlsPage.validateGoodDogName,
                    errorMessages:
                    {
                        goodDogName: 'Guess again'
                    },
                    help: `For this field you have to guess the name of a good dog.
                    There are many possible good names.
                    Charlie Brown had a good dog.`
                },
                // ----------------------------------------------------
                // Textarea
                // ----------------------------------------------------
                {
                    name: 'p1',
                    title: 'Paragraph',
                    type: 'textarea',
                    validator: Validators.maxLength(250),
                    help: 'What are your life goals?'
                },
                {
                    name: 'p2',
                    title: 'Taller Paragraph',
                    textarea: 8,
                    validator: Validators.maxLength(250),
                    help: 'What are your death goals?'
                },
                {
                    name: 'p3',
                    title: 'Short Paragraph',
                    textarea:
                    {
                        rows: 2
                    },
                    validator: Validators.maxLength(250),
                    help: 'What are your rebirth goals?'
                },
                // ----------------------------------------------------
                // Numeric
                // ----------------------------------------------------
                {
                    name: 'n1',
                    title: 'Favorite Number',
                    type: 'number',
                    help: 'How about 13?'
                },
                // ----------------------------------------------------
                // Dates
                // ----------------------------------------------------
                {
                    name: 'd1',
                    title: 'd1: Date, defaults',
                    type: 'date',
                    help: 'Dinner and a movie?'
                },
                {
                    name: 'd2',
                    title: 'd2: Date, initial value, format mediumDate',
                    date: 'mediumDate',
                    initialValue: '1981-09-12',
                    help: 'Date with initial value: 1981-09-12.'
                },
                {
                    name: 'd3',
                    title: 'd3: Date, initial value, format MM/dd/yyyy',
                    date:
                    {
                        format: 'MM/dd/yyyy'
                    },
                    initialValue: 'Fri, 08 Nov 2019 00:00:00 -0500',
                    help: 'Date with initial value: Fri, 08 Nov 2019 00:00:00 -0500'
                },
                {
                    name: 'd4',
                    title: 'd4: Date, initial value, format dd-MMM-yyyy',
                    date: 'dd-MMM-yyyy',
                    initialValue: '1991-09-14',
                    help: 'Date with initial value: 1991-09-14.'
                },
                // ----------------------------------------------------
                {
                    name: 'dt1',
                    title: 'Datetime, initial value, format EEE, dd MMM yyyy HH:mm:ss Z',
                    date: 'EEE, dd MMM yyyy HH:mm:ss Z',
                    initialValue: 'Fri, 08 Nov 2019 16:20:00 -0500',
                    validator: Validators.required,
                    help: 'Having a format with time placeholders will trigger time entry.'
                },
                {
                    name: 'dt2',
                    title: 'Datetime, delayed initial value',
                    date: 'MM/dd/yyyy HH:mm:ss Z',
                    validator: Validators.required,
                    help: 'Having a format with time placeholders will trigger time entry.'
                },
                // ----------------------------------------------------
                {
                    name: 'e1',
                    title: 'e1: Elapsed time (default settings)',
                    type: 'elapsedTime'
                },
                {
                    name: 'e2',
                    title: 'e2: Elapsed time, with initial value, readonly',
                    initialValue: 90,
                    readonly: true,
                    elapsedTime:
                    {
                        returnAs: 'seconds'
                    }
                },
                // ----------------------------------------------------
                // File
                // ----------------------------------------------------
                {
                    name: 'f1',
                    hideTitle: true,
                    type: 'file',
                    help: 'I am running out of ideas for testing help text for each field type.'
                },
                // ----------------------------------------------------
                // Pickers
                // ----------------------------------------------------
                {
                    name: 'picker1',
                    title: '1 Read-only',
                    picker: [ 'This', 'That', 'The other' ],
                    help: 'One o\'clock rock',
                    onChange: (v, i) => console.log('picker1 change', v, i)
                },
                {
                    name: 'picker2',
                    title: '2 Read-write',
                    picker:
                    {
                        readWrite: true,
                        values: [ 'More', 'Options', 'Shaking', 'Stick' ]
                    },
                    help: 'Pickers are cool.'
                },
                {
                    name: 'picker3',
                    title: '3 Map of numbers, initialValue',
                    picker: picker3Map,
                    initialValue: 2
                },
                {
                    name: 'picker4',
                    title: '4 Map of strings, initialValue',
                    picker: picker4Map,
                    initialValue: 'B'
                },
                {
                    name: 'picker5',
                    title: '5 Populator, map, cache',
                    picker: () => this.getDeferredPickerValues1()
                },
                {
                    name: 'picker5a',
                    title: '5a Populator, map, nocache',
                    picker:
                    {
                        populator: () => this.getDeferredPickerValues1(),
                        nocache: true
                    }
                },
                {
                    name: 'picker5b',
                    title: '5b Populator, error, nocache',
                    initialValue: 'bob',
                    picker:
                    {
                        populator: () => this.badPopulator(),
                        nocache: true
                    }
                },
                {
                    name: 'picker6',
                    title: '6 Populator, map, initialValue',
                    picker: () => this.getDeferredPickerValues2(),
                    initialValue: 'B'
                },
                {
                    name: 'picker6a',
                    title: '6a Populator, map, bad initialValue',
                    picker: () => this.getDeferredPickerValues2(),
                    initialValue: 'D'
                },
                {
                    name: 'picker7',
                    title: '7 Populator, map, nocache, iv, multi',
                    initialValue: [ 'A', 'C' ],
                    picker:
                    {
                        populator: () => this.getDeferredPickerValues1(),
                        nocache: true,
                        multi: true
                    }
                },
                {
                    name: 'picker7a',
                    title: '7a Array, multi',
                    picker:
                    {
                        values: [ 'yacco', 'wacko', 'dot' ],
                        multi: true
                    }
                },
                {
                    name: 'picker8a',
                    title: '8a Long searchable list with long values',
                    picker:
                    {
                        values: [
'1. Westvleteren 12 (XII) – Westvleteren Abdij St. Sixtus(Belgium)',
'2. Russian River Pliny the Elder – Russian River Brewing(California)',
'3. Russian River Pliny the Younger – Russian River Brewing(California)',
'4. Founders KBS (Kentucky Breakfast Stout) – Founders Brewing Company(Michigan)',
'5. Cigar City Hunahpu’s Imperial Stout – Brandy Barrel Aged – Cigar City Brewing(Florida)',
'6. Bells Hopslam – Bells Brewery(Michigan)',
'7. Rochefort Trappistes 10 – Brasserie Rochefort(Belgium)',
'8. Goose Island Bourbon County Stout – Goose Island Beer Company(Illinois)',
'9. AleSmith Speedway Stout – AleSmith Brewing Company(California)',
'10. Bells Black Note Stout – Bells Brewery(Michigan)',
'11. Alchemist Heady Topper – The Alchemist(Vermont)',
'12. Hill Farmstead Ann – Hill Farmstead Brewery(Vermont)',
'13. Cigar City Pilot Series Miami Madness – Cigar City Brewing(Florida)',
'14. Firestone Walker Parabola – Firestone Walker Brewing Co.(California)',
'15. Deschutes The Abyss – Deschutes Brewery(Oregon)',
'16. Hill Farmstead Abner – Hill Farmstead Brewery(Vermont)',
'17. Westvleteren Extra 8 – Westvleteren Abdij St. Sixtus(Belgium)',
'18. Bells Expedition Stout – Bells Brewery(Michigan)',
'19. Cantillon Fou’ Foune – Cantillon(Belgium)',
'20. Mikkeller Beer Geek Brunch Weasel – Mikkeller(Denmark)',
'21. Three Floyds Dreadnaught Imperial IPA – Three Floyds Brewing Company(Indiana)',
'22. Three Floyds Zombie Dust – Three Floyds Brewing Company(Indiana)',
'23. 3 Fonteinen Framboos (Framboise) – 3 Fonteinen(Belgium)',
'24. Hill Farmstead Society & Solitude #5 – Hill Farmstead Brewery(Vermont)',
'25. Three Floyds Dark Lord Russian Imperial Stout (Bourbon Vanilla Bean) – Three Floyds Brewing(Indiana)',
'26. Hill Farmstead Everett – Hill Farmstead Brewery(Vermont)',
'27. Peg’s G.O.O.D. RareR DOS – Peg’s Cantina(Florida)',
'28. Founders Imperial Stout – Founders Brewing Company(Michigan)',
'29. Dieu du Ciel Péché Mortel – Dieu du Ciel(Quebec)',
'30. St. Bernardus Abt 12 – St. Bernardus Brouwerij(Belgium)',
'31. North Coast Old Rasputin Anniversary Bourbon Barrel Aged Stout – North Coast Brewing(California)',
'32. Oskar Blues Ten FIDY – Oskar Blues Grill & Brew(Colorado)',
'33. Surly Darkness – Surly Brewing Company(Minnesota)',
'34. Cigar City Marshal Zhukov’s Imperial Stout – Cigar City Brewing(Florida)',
'35. Lost Abbey Deliverance – Port Brewing/Lost Abbey(California)',
'36. Struise Pannepot – De Struise Brouwers(Belgium)',
'37. Närke Konjaks! Stormaktsporter – Närke Kulturbryggeri(Sweden)',
'38. Stone Imperial Russian Stout – Stone Brewing Co.(California)',
'39. 3 Fonteinen Oude Geuze (Armand & Tommy) – 3 Fonteinen(Belgium)',
'40. Dark Horse Bourbon Barrel Plead the 5th Imperial Stout – Dark Horse Brewing Company(Michigan)',
'41. Southern Tier Choklat – Southern Tier Brewing Company(New York)',
'42. New Glarus Serendipity – New Glarus Brewing Company(Wisconsin)',
'43. Great Divide Espresso Oak Aged Yeti Imperial Stout – Great Divide Brewing Company(Colorado)',
'44. Struise Black Albert – De Struise Brouwers(Belgium)',
'45. Avery Uncle Jacob’s Stout – Avery Brewing Company(Colorado)',
'46. Lost Abbey Barrel Aged Serpents Stout with Cacao Nibs and Coffee – Port Brewing/Lost Abbey(California)',
'47. Russian River Beatification (Batch 002 +) – Russian River Brewing(California)',
'48. Ballast Point Victory at Sea – Ballast Point Brewing Company(California)',
'49. Cantillon Lou Pepe Pure Kriek – Cantillon(Belgium)',
'50. Lost Abbey The Angels Share (Bourbon Barrel) – Port Brewing/Lost Abbey(California)',
                        ],
                    }
                },
                // ----------------------------------------------------
                // Toggles
                // ----------------------------------------------------
                {
                    name: 'toggle1',
                    title: 'Toggle',
                    toggle: { on: 'YES', off: 'Maybe' },
                    initialValue: 'YES',
                    help: 'It is what it is unless it isn\'t.'
                },
                {
                    name: 'toggle2',
                    title: 'Numeric toggle',
                    toggle: { on: 1, off: 0 },
                    help: 'Roll another one'
                },
                {
                    name: 'toggle3',
                    title: 'Default boolean toggle',
                    type: 'toggle'
                }
            ]
        });

        // Test setting value after form is created
        this.bf.setValue('dt2', 'Fri, 12 Dec 2019 12:12:00 -0500');

        this.display = this.bf.getValues();
    }

    /**
     * Custom form validator to validate good dog names.
     * 
     * @param control Form control
     * @returns null for no errors, or ValidationErrors object populated with failed validator names.
     */
    private static validateGoodDogName(control: UntypedFormControl) : ValidationErrors | null
    {
        switch (control.value)
        {
            case undefined:
            case '':
            case 'Snoopy':
            case 'Woodstock':
                return null;
            default:
                return { goodDogName: true };
        }
    }

    submit()
    {
        this.display = this.bf.getValues();
    }

    debug()
    {
        console.log(this.bf, this.bf.getValues());
        this.display = this.bf.getValues();
    }

    hide()
    {
        const last = this.bf.items.length - 1;
        this.bf.items[last].hidden = !this.bf.items[last].hidden;
    }

    pickerValues()
    {
        this.bf.getItem('picker1').picker.setValues([ 'Apple', 'Banana', 'Commadore' ]);
    }

    async getDeferredPickerValues1() : Promise<BonesFormItemPickerValues>
    {
        const map = new Map<string, string>();

        map.set('A', 'Apple');
        map.set('B', 'Banana');
        map.set('C', 'Cherry');

        console.log('getDeferredPickerValues1', map);
        return map;
    }
    async getDeferredPickerValues2() : Promise<BonesFormItemPickerValues>
    {
        const map = new Map<string, string>();

        map.set('A', 'Apple');
        map.set('B', 'Banana');
        map.set('C', 'Cherry');

        console.log('getDeferredPickerValues2', map);
        return map;
    }

    async badPopulator() : Promise<BonesFormItemPickerValues>
    {
        const error = new BonesError('badPopulator always throws an error');
        this.bes.errorHandler(error);
        throw error;
    }
}
