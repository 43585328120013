/**
 * Remove common words from the biginning of a string.
 * 
 * Used to remove brewery name from the beginning of RateBeer beer names.
 * 
 * @param source string containing matching words
 * @param target string to be modified by removing matching words
 */
export function removePrefix(source: string, target: string) : string
{
    // Split strings into words
    const sourceWords = source.trim().split(' ');
    const targetWords = target.trim().split(' ');

    // Find first non-common word
    let beginIndex = 0;
    for (let i = 0; (i < Math.min(sourceWords.length, targetWords.length)); ++i)
    {
        // Common word found; maybe the next word will not match
        if (sourceWords[i] === targetWords[i])
        {
            beginIndex = i + 1;
        }
        else
        {
            break;
        }
    }

    // Some common words were found
    if (beginIndex > 0)
    {
        // Reassemble to remaining string
        let newTarget = targetWords.slice(beginIndex).join(' ');

        // Some time the prefix will be followed by a '/ ' which needs to be removed
        if (newTarget.startsWith('/ '))
        {
            newTarget = newTarget.substr(2);
        }

        // console.log([source, target, newTarget]);
        return newTarget;
    }
    else
    {
        return target;
    }
}
