/**
 * Results from BonesRestService.configureDirect method call.
 */
export class DirectAccessEnabled
{
    /**
     * Is app is enabled to use direct access to backend web services
     */
    public isEnabled: boolean;

    /**
     * Does user have valid CSP (global login) credentials?
     */
    public hasValidCspCredentials: boolean;

    /**
     * Does user have valid application credentials?
     */
    public hasValidAppCredentials: boolean;

    /**
     * Create smart object from status.
     * 
     * @param status
     * 'y' = app is enabled to use direct access to backend web services,
     * 'n' = app cannot use direct access,
     * 'InvalidCSP' = invalid global login,
     * 'InvalidATTUID' = valid global login, but does not have app access
     */
    public constructor(status: 'InvalidCSP' | 'InvalidATTUID' | 'y' | 'n')
    {
        switch (status)
        {
            case 'y':
                this.isEnabled = true;
                this.hasValidCspCredentials = true;
                this.hasValidAppCredentials = true;
                break;

            case 'n':
                this.isEnabled = false;
                break;

            case 'InvalidCSP':
                this.isEnabled = false;
                this.hasValidCspCredentials = false;
                break;

            case 'InvalidATTUID':
                this.isEnabled = false;
                this.hasValidCspCredentials = true;
                this.hasValidAppCredentials = false;
                break;

        }
    }

}
