import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';

import { BonesTestToolsPiglet } from '../../model/bones-test-tools-piglet';
import { BonesTestToolsPiglets } from '../../model/bones-test-tools-piglets';
import { BonesTestToolsTestCase } from '../../model/bones-test-tools-test-case';

/**
 * Display a hierachal menu of testing tools.
 */
@Component({
  templateUrl: 'test-tools.html'
})
export class BonesTestToolsPage
{
    /**
     * Tool group name
     */
    group: string;
    /**
     * List of tools to display for the current group
     */
    tools: BonesTestToolsPiglet[] = [ ];
    /**
     * List of test cases defined for this group
     */
    testCases: BonesTestToolsTestCase[] = [ ];

    /**
     * @ignore
     */
    constructor(
        private aroute: ActivatedRoute,
        private navCtrl: NavController
    )
    {
        // Get group, if any, passed from ourselves to drill down into a group
        this.group = this.aroute.snapshot.params.groupName || 'Main';

        // Add groups to main screen
        if (this.group === 'Main')
        {
            const seen = { };
            BonesTestToolsPiglets.tools.forEach(piggy =>
            {
                // New group found
                if (piggy.group && !seen[piggy.group])
                {
                    // Create menu entry to drill down into group
                    this.tools.push(
                    {
                        group: piggy.group,
                        title: piggy.group,
                        icon: 'arrow-down',
                        path: 'bones/test-tools/group/' + piggy.group
                    });

                    // Flag menu item created
                    seen[piggy.group] = true;
                }
            });
        }

        // Add items for this group
        BonesTestToolsPiglets.tools.forEach(piggy =>
        {
            if (piggy.group === this.group || (!piggy.group && this.group === 'Main'))
            {
                this.tools.push(piggy);
            }
        });

        // Add items for this group
        BonesTestToolsPiglets.testCases.forEach(testCase =>
        {
            if (testCase.group === this.group || (!testCase.group && this.group === 'Main'))
            {
                this.testCases.push(testCase);
            }
        });

        // Add link to test tools test cases page if there are any test cases for this group
        if (this.testCases.length > 0)
        {
            this.tools.push(
            {
                group: this.group,
                title: 'Test Cases',
                icon: 'briefcase',
                path: 'bones/test-tools-cases/' + this.group
            });
        }

    }

    /**
     * Route to a testing tool page or sub menu
     * 
     * @param tool Tool definition object
     */
    route(tool: BonesTestToolsPiglet)
    {
        this.navCtrl.navigateForward(tool.path);
    }
}
