/**
 * Default colors for ngx-charts
 */

import { Color, ScaleType } from '@swimlane/ngx-charts';

export const chartColorScheme: Color =
{
    name: 'Beer Charts',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#532415', '#DE6D25', '#881C00', '#492012', '#c36021', '#781900']
};
