<ion-header><bones-tt-page-header title="Test Grid"></bones-tt-page-header></ion-header>

<ion-content class="ion-padding">

  <table class="bones-table" *ngIf="rows && rows.length > 0">
    <caption>Test Grid</caption>
    <thead>
      <tr>
        <th>Actions</th>
        <th>Field 1</th>
        <th>Field 2</th>
        <th>Field 3</th>
        <th>Field 4</th>
        <th>Field 5</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of rows">
        <td>
          <ion-button small (click)="edit(row)">Edit</ion-button>
        </td>
        <td>{{row.FIELD1}}</td>
        <td>{{row.FIELD2}}</td>
        <td>{{row.FIELD3}}</td>
        <td>{{row.FIELD4}}</td>
        <td>{{row.FIELD5}}</td>
      </tr>
    </tbody>
  </table>

  <ion-card class="ion-padding" *ngIf="rows && rows.length === 0">
    <p>No items available.</p>
  </ion-card>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" (click)="edit()">Add</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
