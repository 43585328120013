import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { BonesGalleryService, BonesGalleryDocument, BonesGalleryDocumentFilter } from '@bones/gallery';

import { MonkeyService, AppUser, UserService } from '@BeerMonkey/core';

import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';
import { BeerService } from '@BeerMonkey/rate/service/BeerService';
import { Brewery } from '@BeerMonkey/rate/class/Brewery';
import { Beer } from '@BeerMonkey/rate/class/Beer';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';
import { RatingService } from '@BeerMonkey/rate/service/RatingService';
import { Rating } from '@BeerMonkey/rate/class/Rating';
import { InventoryService } from '@BeerMonkey/rate/service/InventoryService';
import { Inventory } from '@BeerMonkey/rate/class/Inventory';
import { GlasswareService } from '@BeerMonkey/rate/service/GlasswareService';
import { Glassware } from '@BeerMonkey/rate/class/Glassware';
import { RecentSearchService } from '@BeerMonkey/rate/service/RecentSearchService';

@Component({
    selector: 'brewery-detail',
    templateUrl: 'brewery-detail.html',
    styleUrls: [ 'brewery-detail.scss' ]
})
export class BreweryDetailPage implements OnInit, OnDestroy
{
    user: AppUser;
    breweryID: number;
    brewery?: Brewery;
    beers?: Beer[];
    ratings?: Rating[];
    inventory?: Inventory[];
    glassware?: Glassware[];
    documents: BonesGalleryDocument[] = [ ];
    logo?: BonesGalleryDocument;
    searchTerm: string = '';
    private nal: (() => void)[] = [ ];
    breweryCardMenu: BonesMenuCardAction[] = [ ];

    constructor(
        private route: ActivatedRoute,
        private navCtrl: NavController,
        public gallery: BonesGalleryService,
        private es: BonesErrorService,
        public monkey: MonkeyService,
        private mtus: UserService,
        private launch: LaunchEditModalService,
        private searches: RecentSearchService,
        private breweryDB: BreweryService,
        private beerDB: BeerService,
        private ratingDB: RatingService,
        private inventoryDB: InventoryService,
        private glasswareDB: GlasswareService
    )
    {
        this.breweryID = +(this.route.snapshot.paramMap.get('breweryID') ?? 0);

        this.user = this.mtus.getUser();

        if (this.user.isAdmin)
        {
            this.breweryCardMenu.push(this.launch.getMenuItemEdit(() => this.launch.editBrewery(this.brewery)));
            this.breweryCardMenu.push({ title: 'Add Beer', icon: 'add-circle', action: () => this.launch.addBeer(this.breweryID) });
            this.breweryCardMenu.push({ title: 'Add Inventory', icon: 'add-circle', action: () => this.launch.addInventory(this.breweryID) });
            this.breweryCardMenu.push({ title: 'Add Glassware', icon: 'add-circle', action: () => this.launch.addGlassware(this.breweryID) });
            this.breweryCardMenu.push(this.gallery.getMenuItemAddDocument('brewery_id', this.breweryID, () => this.brewery?.name));
        }

        // Add beer to recently viewed breweries
        this.searches.addRecentBrewery(this.breweryID);
    }

    async ngOnInit()
    {
        // Load brewery info
        this.breweryDB.getBrewery(this.breweryID)
        .then(brewery => this.brewery = brewery)
        .catch(error => this.es.errorHandler(error));

        // Load and refresh beers as needed
        this.nal.push(this.beerDB.cache.nowAndLater(
        rows =>
        {
            this.beers = rows.filter(p => p.row.brewery_id === this.breweryID);
        },
        error => this.es.errorHandler(error)));

        // Load and refresh ratings as needed
        this.nal.push(this.ratingDB.cache.nowAndLater(
        rows =>
        {
            this.ratings = rows.filter(p => p.beer?.row.brewery_id === this.breweryID);
        },
        error => this.es.errorHandler(error)));

        // Load and refresh inventory as needed
        this.nal.push(this.inventoryDB.cache.nowAndLater(
        rows =>
        {
            this.inventory = rows.filter(p => p.brewery?.brewery_id === this.breweryID) ?? [ ];
        },
        error => this.es.errorHandler(error)));

        // Load and refresh glassware as needed
        this.nal.push(this.glasswareDB.cache.nowAndLater(
        rows => this.glassware = rows.filter(p => p.brewery?.brewery_id === this.breweryID) ?? [ ],
        error => this.es.errorHandler(error)));

        // Load and refresh documents as needed
        this.nal.push(this.gallery.cache.nowAndLater(
        rows =>
        {
            this.documents = new BonesGalleryDocumentFilter(rows).by('brewery_id', this.breweryID).rows;
            this.logo = this.documents.find(d => d.isLogo);
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

}
