import { UntypedFormGroup } from '@angular/forms';

import { BonesFormItem } from './BonesFormItem';
import { BonesFormOptions } from './BonesFormOptions';

/**
 * Definition for a form used with <bones-form-item> components.
 */
export class BonesForm
{
    /**
     * Options used to create BonesForm object.
     */
    options: BonesFormOptions;

    /**
     * The underlying Angular form group.
     */
    form: UntypedFormGroup;

    /**
     * The items on the form.
     */
    items: BonesFormItem[] = [ ];

    /**
     * Create a form.
     * 
     * @param options BonesFormOptions.
     */
    constructor(options: BonesFormOptions)
    {
        this.options = options;

        // Define empty edit form
        this.form = this.options.formBuilder.group({});

        // Convert column options to full blown item object array
        this.options.columns.forEach(option =>
        {
            // Create item object
            const item = new BonesFormItem(this, option);

            // Add form control to form
            this.form.addControl(item.name, item.control);

            // Add item to item array
            this.items.push(item);
        });
    }

    /**
     * Get form item.
     * 
     * @param itemName Name of form item.
     * @returns BonesFormItem object or undefined if not item exists by the given name.
     */
    public getItem(itemName: string) : BonesFormItem
    {
        return this.items.find(i => i.name === itemName);
    }

    /**
     * Get value of form item.
     * 
     * @param itemName Name of form item
     */
    public getValue(itemName: string) : any
    {
        return this.getItem(itemName).getValue();
    }

    /**
     * Set value of form item.
     * 
     * @param itemName Name of form item
     * @param value New value.
     */
    public setValue(itemName: string, value: any) : void
    {
        this.getItem(itemName).setValue(value);
    }

    /**
     * Get multi-part FormData object from form items.
     */
    public getFormData() : FormData
    {
        const formData = new FormData();

        this.items.forEach(item =>
        {
            formData.append(item.name, item.getValue() || '');
        });

        return formData;
    }

    /**
     * Get values of all form items.
     * 
     * @returns Simple object with form item names as property name and form item value as property value.
     */
    public getValues() : any
    {
        const values: any = { };

        this.items.forEach(item =>
        {
            values[item.name] = item.getValue();
        });

        return values;
    }

    /**
     * Reset values of all form items to their initial value.
     */
    public resetValues() : void
    {
        this.items.forEach(item =>
        {
            this.setValue(item.name, item.initialValue);
        });
    }

    /**
     * Mark all form fields as dirty to trigger error styling and messages.
     */
    public markDirty() : void
    {
        this.items.forEach(item =>
        {
            const control = this.form.controls[item.name];
            control.markAsDirty();
            control.markAsTouched();
            control.updateValueAndValidity();
            // control.updateValueAndValidity({ emitEvent: false });
        });
    }

}
