<ion-header><bones-tt-page-header title="Bones Table - Local"></bones-tt-page-header></ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="8">

        <bones-table #bt title="One Test Table"
          [rows]="rows1"
          [defaultSortOrder]="[{ propertyName: 'FRUIT' }, { propertyName: 'CREATED', sort: 'desc' }]"
          [prependMenuItems]="cardMenu1a"
          [appendMenuItems]="cardMenu1b"
          [pageSize]="5"
          [pageSizes]="[ 5, 8, 10, 100 ]">
          <!--
            [search]="'che'"
            [localData]="localData"
            [localDataMethod]="localFetch.bind(this)"
          -->
          <thead>
            <tr>
              <th>Actions</th>
              <bones-table-th name="ID">Row<br>Number</bones-table-th>
              <bones-table-th name="CREATED">Created</bones-table-th>
              <bones-table-th name="IS_LUCKY">Lucky</bones-table-th>
              <bones-table-th name="FRUIT">Fruit</bones-table-th>
              <bones-table-th name="C5" [sortable]="false" [searchable]="false">Column Five</bones-table-th>
            </tr>
          </thead>
          <tbody *ngIf="bt.data">
            <tr *ngFor="let row of bt.data.rows">
              <td>
                <ion-button fill="solid" size="small" color="primary" (click)="action1(row)">Action 1</ion-button>
              </td>
              <td class="ion-text-right">{{ row.ID }}</td>
              <td>{{ row.CREATED | date:'mediumDate' }}</td>
              <td class="ion-text-center"><ion-checkbox [disabled]="true" [checked]="row.IS_LUCKY"></ion-checkbox></td>
              <td>{{ row.FRUIT }}</td>
              <td>{{ row.C5 }}</td>
            </tr>
          </tbody>
        </bones-table>

        <bones-table #bt2 title="Another Test Table" [rows]="rows2">
          <thead>
            <tr>
              <bones-table-th name="ID">Row<br>Number</bones-table-th>
              <bones-table-th name="CREATED">Created</bones-table-th>
              <bones-table-th name="IS_LUCKY">Lucky</bones-table-th>
              <bones-table-th name="FRUIT">Fruit</bones-table-th>
            </tr>
          </thead>
          <tbody *ngIf="bt2.data">
            <tr *ngFor="let row of bt2.data.rows">
              <td class="ion-text-right">{{ row.ID }}</td>
              <td>{{ row.CREATED | date:'mediumDate' }}</td>
              <td class="ion-text-center"><ion-checkbox [disabled]="true" [checked]="row.IS_LUCKY"></ion-checkbox></td>
              <td>{{ row.FRUIT }}</td>
            </tr>
          </tbody>
        </bones-table>

      </ion-col>

      <ion-col>
        <ion-card class="ion-padding" *ngIf="display">
          <ion-card-title>Row Contents</ion-card-title>
          <pre>{{ display | json }}</pre>
        </ion-card>
      </ion-col>

    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" (click)="button1()">button</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
