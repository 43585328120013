import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router, UrlTree } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { BonesGalleryService } from '@bones/gallery';

import { LoginPage } from '@BeerMonkey/core';
import { RatingService } from './RatingService';
import { LoadingModal } from '../pages/loading-modal/loading-modal';
import { InventoryService } from './InventoryService';
import { GlasswareService } from './GlasswareService';

/**
 * Make sure user is authenticated before any routes can be loaded
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad
{
    needFirstTimeLoginModal = true;

    constructor(
        private modalCtrl: ModalController,
        private router: Router,
        private gallery: BonesGalleryService,
        private ratingDB: RatingService,
        private inventoryDB: InventoryService,
        private glasswareDB: GlasswareService
    )
    {
    }

    /**
     * Can user activate a given route?
     */
    async canActivate() : Promise<boolean | UrlTree>
    {
        if (this.needFirstTimeLoginModal)
        {
            this.needFirstTimeLoginModal = false;

            // Start loading cache
            this.ratingDB.cache.load();
            this.inventoryDB.cache.load();
            this.glasswareDB.cache.load();
            this.gallery.cache.load();

            // Launch login modal and wait for it to be dismissed
            // const guest = !(await LoginPage.launch(this.modalCtrl));
            await LoginPage.launch(this.modalCtrl);

            // Show loading message overlay if the cache has not completed loading
            if (!this.ratingDB.cache.isLoaded || !this.inventoryDB.cache.isLoaded || !this.glasswareDB.cache.isLoaded
                || !this.gallery.cache.isLoaded)
            {
                const modal = await this.modalCtrl.create({ component: LoadingModal });
                await modal.present();

                // Wait for cache to finish loading
                await this.ratingDB.cache.load();
                await this.gallery.cache.load();

                // Hide loading message overlay
                modal.dismiss();
            }

            // Prevent deep linking
            // return this.router.parseUrl('/');
        }

        return true;
    }

    async canActivateChild() : Promise<boolean | UrlTree>
    {
        return this.canActivate();
    }

    async canLoad() : Promise<boolean>
    {
        return !!this.canActivate();
    }

    //-----------------------------------------------------------------------



}
