import { Component, Input } from '@angular/core';

import { BonesFormItem } from '../../class/BonesFormItem';

/**
 * Display label for form item.
 * 
 * @example
 * <bones-form-label [item]="item"></bones-form-label>
 */
@Component({
    selector: 'bones-form-label',
    templateUrl: 'form-label.html',
    styleUrls: [ 'form-label.scss' ]
})
export class BonesFormLabelComponent
{
    /**
     * Help text.
     */
    @Input() item: BonesFormItem;

    /**
     * Click to display text.
     */
    showViaClick = false;

    /**
     * Mouse over to display text.
     */
    showViaMouse = false;

    /**
     * @ignore
     */
    constructor()
    {
    }

}
