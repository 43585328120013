import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { ApeRest, MonkeyService } from '@BeerMonkey/core';
import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';
import { Brewery } from '@BeerMonkey/rate/class/Brewery';
import { InventoryInfo } from '@BeerMonkey/rate/class/InventoryInfo';
import { InventoryService } from '@BeerMonkey/rate/service/InventoryService';
import { BeerService } from '@BeerMonkey/rate/service/BeerService';
import { RatingService } from '@BeerMonkey/rate/service/RatingService';
import { Beer } from '@BeerMonkey/rate/class/Beer';

@Component({
    selector: 'inventory-edit',
    templateUrl: 'inventory-edit.html'
})
export class InventoryEditModal extends BonesEditForm<InventoryInfo> implements OnInit
{
    brewery?: Brewery;
    // beer: Beer;
    initialQuantity?: number;

    constructor(
        protected formBuilder: FormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: ApeRest,
        public monkey: MonkeyService,
        private breweryDB: BreweryService,
        private beerDB: BeerService,
        private ratingDB: RatingService,
        private inventoryDB: InventoryService
    )
    {
        super();
    }

    async ngOnInit()
    {
        await super.initialize(
        {
            columns:
            [
                {
                    name: 'inventory_id',
                    hidden: true
                },
                {
                    name: 'brewery_name',
                    hidden: true
                },
                {
                    name: 'brewery_id',
                    title: 'Brewery',
                    picker: () => this.breweryDB.cache.getPickerMap('name'),
                    validator: Validators.required,
                    onChange: breweryID => this.setBrewery(+breweryID)
                },
                {
                    name: 'beer_id',
                    title: 'Beer',
                    picker:
                    {
                        populator: () => this.beerDB.cache.getPickerMap('name', (b) => b.row.brewery_id === this.brewery?.brewery_id),
                        nocache: true
                    },
                    validator: Validators.required,
                    // onChange: beerID => this.setBeer(+beerID)
                },
                {
                    name: 'beer_name',
                    title: 'Legacy Beer Name',
                    validator: Validators.maxLength(128)
                },
                {
                    name: 'location',
                    title: 'Location',
                    picker:
                    {
                        populator: () => this.inventoryDB.getPicker('location'),
                        readWrite: true
                    },
                    validator: [ Validators.required, Validators.maxLength(128) ]
                },
                {
                    name: 'version',
                    title: 'Version',
                    validator: Validators.maxLength(32)
                },
                {
                    name: 'vintage',
                    title: 'Vintage',
                    picker:
                    {
                        values: this.ratingDB.getVintagePicker(),
                        readWrite: true
                    },
                    validator: Validators.maxLength(32)
                },
                {
                    name: 'size',
                    title: 'Size',
                    picker:
                    {
                        populator: () => this.inventoryDB.getPicker('size'),
                        readWrite: true
                    },
                    validator: Validators.maxLength(16)
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    hideTitle: true,
                    type: 'textarea'
                },
                {
                    name: 'quantity',
                    title: 'Quantity',
                    hideTitle: true,
                    type: 'number'
                }
            ],
            saveUrl: '/rate/inventory/update/updateInventory',
            cache: this.inventoryDB.cache
        });

        // Copy row
        if (this.launchOptions.moData && this.launchOptions.moData.row)
        {
            const row: InventoryInfo = this.launchOptions.moData.row;
            this.bonesForm.setValue('brewery_id', row.brewery_id);
            this.bonesForm.setValue('beer_name', row.beer_name);
            this.bonesForm.setValue('location', row.location);
            this.bonesForm.setValue('size', row.size);
        }
        // Defaults for new row
        else if (this.isAdd)
        {
            this.bonesForm.setValue('brewery_id', this.launchOptions.moData.breweryID);
            this.bonesForm.setValue('beer_id', this.launchOptions.moData.beerID);
        }
        else
        {
            this.initialQuantity = this.launchOptions.moData.initialQuantity;
        }
    }

    //-------------------------------------------------------------------------------

    private setBrewery(breweryID: number)
    {
        if (breweryID)
        {
            this.breweryDB.getBrewery(breweryID)
            .then(brewery =>
            {
                // console.log('setBrewery', breweryID, brewery);
                this.brewery = brewery;
                // brewery_name is required in the db although it is not used anywhere in this client app
                this.bonesForm.setValue('brewery_name', this.brewery.name);
            })
            .catch(error => this.bes.errorHandler(error));
        }
    }

    // private setBeer(beerID: number)
    // {
    //     if (beerID)
    //     {
    //         this.beerDB.getBeer(beerID)
    //         .then(beer =>
    //         {
    //             // console.log('setBeer', beerID, beer);
    //             this.beer = beer;
    //             // beer_name is required for rows without the beer_id (yet)
    //             // this.bonesForm.setValue('beer_name', this.beer.name);
    //         })
    //         .catch(error => this.bes.errorHandler(error));
    //     }
    // }

    //-------------------------------------------------------------------------------

    increment()
    {
        this.bonesForm.setValue('quantity', (this.bonesForm.getValue('quantity') ?? 0) + 1);
    }

    decrement()
    {
        this.bonesForm.setValue('quantity', this.bonesForm.getValue('quantity') - 1);
    }
}
