import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MockHttpResponse } from './mock';
import { BnsResponseStats } from './BnsResponseStats';

/**
 * Response info object.
 */
export class BnsResponseInfo
{
    /**
     * Low level HTTP response object.
     */
    httpResponse?: HttpResponse<object>;

    /**
     * Low level HTTP error response object.
     */
    httpErrorResponse?: HttpErrorResponse;

    /**
     * Mock version of HttpResponse.
     */
    mockResponse?: MockHttpResponse;

    /**
     * Response body.
     */
    payload?: any;

    /**
     * Statistics on time required to execute web service.
     */
    stats: BnsResponseStats = { };
}
