import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { BonesErrorService, BonesError, BonesSortOrder, BonesMenuCardAction } from '@bones/core';
import { BonesRestService } from '@bones/network';

import { BonesTableLocalDataFactory } from '../../service/bones-table-local-data-factory';

import { BonesTableFetchRequest, BonesTablePagedDataRequest } from '../../class/BonesTableFetchRequest';
import { BtKeywordSearch } from '../../class/BonesTableFetchRequest';
import { BonesTableFetchResponse, BonesTablePagedDataResponse } from '../../class/BonesTableFetchResponse';
import { BonesTableFetchMethod } from '../../class/BonesTableFetchMethod';
import { BonesTableLocalData } from '../../class/BonesTableLocalData';
import { BonesTableThComponent } from '../bones-table-th/bones-table-th';

/**
 * Turn a regular HTML table into an interactive one with data paging, sorting, and searching.
 * 
 * @example
 *  <bones-table #bt1 [title]="My Table" [url]="/rest/module/getData">
 *               <thead>
 *                 <tr>
 *                   <bones-table-th name="SOME_COLUMN">My Very Best<br>Column of Data</bones-table-th>
 *                 </tr>
 *               </thead>
 *               <tbody *ngIf="bt1.data">
 *                 <tr *ngFor="let row of bt1.data.rows">
 *                   <td>{{ row.SOME_COLUMN }}</td>
 *                 </tr>
 *               </tbody>
 * </bones-table>
 */
@Component({
    selector: 'bones-table',
    templateUrl: 'bones-table.html',
    styleUrls: [ 'bones-table.scss' ]
})
export class BonesTableComponent implements OnInit, AfterViewInit
{
    // /**
    //  * Should table be displayed?
    //  */
    // @Input() show: boolean = true;
    /**
     * Table title
     */
    @Input() title?: string;
    /**
     * Sould table contents be expandable
     */
    @Input() expandable?: boolean;
    /**
     * Initial page size (number of rows per page)
     */
    @Input() pageSize: number = 10;
    /**
     * Method to return rowset
     */
    @Input() localDataMethod?: BonesTableFetchMethod;
    /**
     * Rows to use to populate the table
     */
    @Input() set rows(rows: any[] | undefined) { this.setRows(rows); }
    /**
     * Use local data object for the table data
     */
    @Input() localData?: BonesTableLocalData<any>;

    /**
     * URL of backend web service using BonesDBTable to provide table rows plus metadata
     */
    @Input() url?: string;
    /**
     * URL of backend web service using BonesDBTable to provide Excel exports
     */
    @Input() exportUrl?: string;
    /**
     * URL of backend web service that just provides an array of rows
     */
    @Input() rowUrl?: string;

    /**
     * Arguments to pass to backend web service
     */
    @Input() set urlArgs(args: any | undefined) { this.setUrlArgs(args); }
    /**
     * Arguments to pass to backend web service
     */
    get urlArgs() : any | undefined { return this._urlArgs; }
    private _urlArgs?: any;

    /**
     * Should all rows be retrieved from the server and cached on the client?
     */
    @Input() clientCache = false;
    /**
     * What options should be used to display available page sizes?
     */
    @Input() pageSizes? = [ 10, 25, 100, 500 ];
    /**
     * Default sort order to use if no sort order is specified
     */
    @Input() defaultSortOrder?: BonesSortOrder[];
    /**
     * Should the table footer (row count and page controls) be shown?
     */
    @Input() showFooter = true;

    /**
     * Keyword search value (set by app or UI)
     */
    @Input() set search(keyword: string | undefined) { this.setKeyword(keyword); }
    /**
     * Keyword search value (internal value)
     */
    keyword?: string;
    /**
     * Should the user be presented with a search box to do keyword searches?
     */
    @Input() allowSearch = true;


    /**
     * Response containing a page of data along with metadata
     */
    data?: BonesTablePagedDataResponse;
    /**
     * Columns contained in this table
     */
    private columns: BonesTableThComponent[] = [ ];
    /**
     * Table columns mapped by name
     */
    private columnMap = new Map<string, BonesTableThComponent>();
    /**
     * Column titles mapped by column name
     */
    private columnTitleMap = new Map<string, string>();
    /**
     * Array of columns used for sorting in the order in which the sort is to be preformed
     */
    private sortedColumns: BonesTableThComponent[] = [ ];
    /**
     * Textual description of the sorting used for the table
     */
    sortOrderDescription?: string;
    /**
     * Using local vs remote data
     */
    usingLocalData = false;
    /**
     * Waiting on data to load
     */
    loading = false;
    /**
     * Error messages
     */
    errors?: string[];
    /**
     * Has the ngView been initialized?
     */
    private viewInited = false;

    /**
     * Menu options
     */
    cardMenu: BonesMenuCardAction[] = [ ];

    /**
     * Items to be prepended to the card menu before default options like excel downloads
     */
    @Input() set prependMenuItems(items: BonesMenuCardAction[] | undefined)
    {
        this._prependMenuItems = items;
        this.buildCardMenu();
    }
    get prependMenuItems() : BonesMenuCardAction[] | undefined
    {
        return this._prependMenuItems;
    }
    private _prependMenuItems?: BonesMenuCardAction[];

    /**
     * Items to be appended to the card menu after default options like excel downloads
     */
    @Input() set appendMenuItems(items: BonesMenuCardAction[] | undefined)
    {
        this._appendMenuItems = items;
        this.buildCardMenu();
    }
    get appendMenuItems() : BonesMenuCardAction[] | undefined
    {
        return this._appendMenuItems;
    }
    private _appendMenuItems?: BonesMenuCardAction[];

    /**
     * Excel download options
     */
    private excelActions: BonesMenuCardAction[] =
    [
        { title: 'Export page', icon: 'cloud-download', action: () => this.exportPage() },
        { title: 'Export all', icon: 'cloud-download', action: () => this.exportAll() },
    ];

    //-----------------------------------------------------------------------

    /**
     * @ignore
     */
    constructor(
        private alertCtrl: AlertController,
        private rest: BonesRestService,
        private bes: BonesErrorService,
        private ldFactory: BonesTableLocalDataFactory
    )
    {
    }

    /**
     * @ignore
     */
    async ngOnInit()
    {
        // console.log('BonesTableComponent.ngOnInit', this.title, this.columns, this.rows, this.url, this.rowUrl, this.localData);
        this.buildCardMenu();
    }

    /**
     * @ignore
     */
    ngAfterViewInit()
    {
        // console.log('BonesTableComponent.ngAfterViewInit', this.title, this.columns, this.rows, this.url, this.rowUrl, this.localData);
        this.viewInited = true;

        if (this.url || this.rowUrl || this.localData)
        {
            // Display first page on next pass of ng change detection cycle
            // because it's too late to change the loading indicator on this pass
            // console.log('BonesTableComponent.ngAfterViewInit', this.title, 'delay 1st page load until next cycle');
            setTimeout(() => this.firstPage());
        }
    }

    private buildCardMenu()
    {
        this.cardMenu = [ ];

        if (this.prependMenuItems)
        {
            this.cardMenu.push(...this.prependMenuItems);
        }

        // Add excel options to menu when using an export url is provided
        if (this.exportUrl)
        {
            this.cardMenu.push(...this.excelActions);
        }

        if (this.appendMenuItems)
        {
            this.cardMenu.push(...this.appendMenuItems);
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Provide array or rows to be used as data for table
     * @param rows rows to use for table
     */
    private setRows(rows?: any[])
    {
        // console.log('BonesTableComponent.setRows', this.title, rows);

        // Flag that this table is configured to use local data (even if it is not yet available)
        this.usingLocalData = true;

        // Check to see if rows are populated yet
        if (rows)
        {
            this.localData = this.ldFactory.create<any>(rows);
            this.firstPage();
        }
        else
        {
            // this.loading = true;
            this.localData = undefined;
        }
    }

    /**
     * Set new arguments to be sent to backend server when fetching data.
     * A change in arguments will reset table state and reload page one of data.
     * @param args args from client app
     */
    private setUrlArgs(args?: any)
    {
        this._urlArgs = args;
        this.clearState();
        this.firstPage();
    }

    /**
     * Clear the current row and page counts (presumably upon external changes to the data or filtering)
     */
    clearState()
    {
        if (this.data)
        {
            this.data.state = { };
        }
    }

    /**
     * Clear the current page counts (presumably upon external changes to the data, filtering, or page size)
     */
    clearPageState()
    {
        if (this.data && this.data.state)
        {
            this.data.state.totalPagecount = undefined;
            this.data.state.filteredPagecount = undefined;
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Set the number of rows to be displayed on each "page" of the table
     * @param pageSize number of rows to be displayed
     */
    setPageSize(pageSize?: number)
    {
        this.pageSize = pageSize;
        this.clearPageState();
        this.firstPage();
    }

    //-----------------------------------------------------------------------

    /**
     * Search rows for keyword
     * @param value keyword
     */
    setKeyword(value?: string)
    {
        this.keyword = value;

        // If we have data already loaded, it needs to be refiltered
        if (this.data)
        {
            // Clear cached filtered counts when the keyword changes
            if (this.data.state)
            {
                this.data.state.filteredRowcount = 0;
                this.data.state.filteredPagecount = 0;
            }

            // Display first page of new search
            this.firstPage();
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Refresh page
     * @param page page number to display (defaults to refreshing current page)
     */
    refresh(page?: number)
    {
        this.loadPage(page ? page : this.data && this.data.meta ? this.data.meta.pageNumber : 1);
    }

    /**
     * Display first page of data
     */
    firstPage()
    {
        this.loadPage(1);
    }

    /**
     * Display previous page of data
     */
    prevPage()
    {
        this.loadPage(this.data.meta.pageNumber - 1);
    }

    /**
     * Display next page of data
     */
    nextPage()
    {
        this.loadPage(this.data.meta.pageNumber + 1);
    }

    /**
     * Display last page of data
     */
    lastPage()
    {
        if (this.data.state.totalPagecount)
        {
            this.loadPage(this.data.state.totalPagecount);
        }
        else
        {
            this.loadPage(0);
        }
    }

    /**
     * Load data for the desired page
     * @param pageNumber page number to be displayed
     */
    private async loadPage(pageNumber: number)
    {
        if (!this.viewInited)
        {
            // console.error('BonesTableComponent.loadPage', this.title, 'ignoring premature load request');
            return;
        }

        // Create page request
        const pdr: BonesTablePagedDataRequest =
        {
            pageSize: this.pageSize,
            pageNumber: pageNumber,
            wantsLastPage: pageNumber === 0,
            // Send back state metadata
            state: this.data ? this.data.state : undefined,
            // Add sorting options
            sortOrder: this.getSortOrder(),
            // Add keyword and searchable column list to request when a search keyword has been defined
            search: this.getSearchArgs()
        };

        const request = { pagedDataRequest: pdr, urlArgs: this.urlArgs } as BonesTableFetchRequest;
        // console.error('BonesTableComponent.loadPage request', this.title, request);

        // Fetch data from data provider
        let p1: Promise<BonesTableFetchResponse>;
        if (this.localData)
        {
            p1 = this.localData.fetch(request);
        }
        else if (this.localDataMethod)
        {
            p1 = this.localDataMethod(request);
        }
        else if (this.rowUrl)
        {
            p1 = this.getServerDataRows(request);
        }
        else if (this.url)
        {
            p1 = this.getServerDataResponse(request);
        }
        else
        {
            this.bes.errorHandler(new BonesError(
            {
                className: 'BonesTableComponent',
                methodName: 'loadPage',
                message: 'No data provider for table'
            }));

            p1 = new Promise(resolve => resolve({ errors: [ 'No data provider for table' ]}));
        }

        // Clear prior data while loading new data
        if (this.data)
        {
            this.data.rows = undefined;
        }

        // Await for data to arrive
        this.loading = true;
        const r1 = await p1;
        this.loading = false;

        // Process response
        if (!r1)
        {
            this.data = undefined;
            this.errors = [ 'No payload from provider' ];
        }
        else if (r1.pagedDataResponse)
        {
            this.data = r1.pagedDataResponse;
            this.errors = r1.errors;
        }
        else if (r1.errors)
        {
            this.data = undefined;
            this.errors = r1.errors;
        }
        else
        {
            this.data = undefined;
            this.errors = [ 'Unable to interpret payload from provider' ];
        }

        // console.log('BonesTableComponent.loadPage response', this.title, r1, this.data, this.errors);
    }

    //-----------------------------------------------------------------------

    /**
     * Get response from server api using BonesDBTable
     */
    private async getServerDataResponse(pdr: BonesTableFetchRequest) : Promise<BonesTableFetchResponse>
    {
        let request = pdr;

        // Client is asking to get all data at once; change to a full data request
        if (this.clientCache && !this.localData)
        {
            request = { fullDataRequest: { }, urlArgs: this.urlArgs } as BonesTableFetchRequest;
            // console.log('BonesTableComponent.loadPage fdr', request);
        }

        // Send request to server
        return this.rest.send(this.url, request)
        .then(payload =>
        {
            // console.log('BonesTableComponent.getServerData got payload', payload);

            // Server implements BonesDBTable as backend, and sends back a page at a time
            if ('pagedDataResponse' in payload)
            {
                return payload;
            }

            // Server implements BonesDBTable as backend, but sends back all data for local caching
            if ('fullDataResponse' in payload)
            {
                this.localData = this.ldFactory.create<any>(payload.fullDataResponse.rows);
                return this.localData.fetch(pdr);
            }

            // Errors returned from server
            if ('errors' in payload)
            {
                return payload;
            }

            return { errors: [ 'Unable to interpret server response' ] } as BonesTableFetchResponse;
        })
        .catch(error =>
        {
            this.bes.errorHandler(new BonesError(
            {
                className: 'BonesTableComponent',
                methodName: 'getServerData',
                message: 'Unable to get table data',
                error: error
            }));

            return { errors: [ error.message || 'Server request failed' ] } as BonesTableFetchResponse;
        });
    }

    /**
     * Get rows of data from server api using legacy BonesDBIterator
     */
    private async getServerDataRows(request: BonesTableFetchRequest) : Promise<BonesTableFetchResponse>
    {
        return this.rest.send(this.rowUrl, this.urlArgs)
        .then(payload =>
        {
            // console.log('BonesTableComponent.getServerDataRows got payload', payload);

            // Create local cache for data
            this.localData = this.ldFactory.create<any>(payload);

            // Use local cache to get page of data
            return this.localData.fetch(request);
        })
        .catch(error =>
        {
            this.bes.errorHandler(new BonesError(
            {
                className: 'BonesTableComponent',
                methodName: 'getServerDataRows',
                message: 'Unable to get table data',
                error: error
            }));

            return { errors: [ error.message || 'Server request failed' ]};
        });
    }

    /**
     * Create BtKeywordSearch from column settings
     */
    private getSearchArgs() : BtKeywordSearch
    {
        // Add keyword and searchable column list to request when a search keyword has been defined
        if (this.keyword)
        {
            return {
                keyword: this.keyword,
                columnNames: this.columns.filter(c => c.searchable).map(c => c.name)
            };
        }
        else
        {
            return undefined;
        }
    }

    private getColumnNameTitleMap()
    {
        const ctm = { };
        this.columnTitleMap.forEach((v, k) => ctm[k] = v);
        return ctm;
    }

    //-----------------------------------------------------------------------

    /**
     * Export single page to Excel
     */
    exportPage()
    {
        const request: BonesTableFetchRequest =
        {
            exportRequest:
            {
                pageNumber: this.data.meta.pageNumber,
                pageSize: this.pageSize,
                sortOrder: this.getSortOrder(),
                search: this.getSearchArgs(),
                columnNameTitleMap: this.getColumnNameTitleMap()
            },
            urlArgs: this.urlArgs
        };

        this.exporter(request);
    }

    /**
     * Export all pages to Excel
     */
    async exportAll()
    {
        const request: BonesTableFetchRequest =
        {
            exportRequest:
            {
                sortOrder: this.getSortOrder(),
                search: this.getSearchArgs(),
                columnNameTitleMap: this.getColumnNameTitleMap()
            },
            urlArgs: this.urlArgs
        };

        this.exporter(request);
    }

    /**
     * Export to Excel
     */
    private async exporter(request: BonesTableFetchRequest)
    {
        // Open download request in a new tab that will automatically close when the download is complete
        // const arg = encodeURIComponent(JSON.stringify(request));
        // const url = this.rest.directServerUrl + this.exportUrl + '?fetchRequest=' + arg;
        // console.log('export', request, url);
        // window.open(url);

        // Create hidden form and submit it for the download
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', this.rest.directServerUrl + this.exportUrl);
        form.style.display = 'none';
        const input = document.createElement('input');
        input.setAttribute('name', 'fetchRequest');
        input.setAttribute('value', JSON.stringify(request));
        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        form.remove();

        // Show alert that download is in progress
        (await this.alertCtrl.create(
        {
            header: 'Download Started',
            message: 'Download should start shortly.<br><br>Export will be in your browser\'s downloads folder.',
            buttons:
            [
                {
                    text: 'OK'
                }
            ]
        })).present();
    }

    //-----------------------------------------------------------------------

    /**
     * Add a column to the list of configured table columns
     * @param column column component
     */
    addColumn(column: BonesTableThComponent)
    {
        // console.log('BonesTableComponent.addColumn', column);

        // Add column to list
        this.columns.push(column);

        // Add column to maps
        if (column.name)
        {
            // Lookup columns by name
            this.columnMap.set(column.name, column);

            // Lookup column titles by name; default to column name if no title is provided
            this.columnTitleMap.set(column.name, column.title || column.name);
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Set column sort order
     * @param column column to use for sorting
     * @param shiftKey true=column should be added to a compound sort, false=column should replace current sort
     */
    setSort(column: BonesTableThComponent, shiftKey: boolean)
    {
        // console.log('BonesTableComponent.setSort', column.sort, shiftKey);

        // Clear sort of other columns unless the shift key was down when the sort was selected
        if (!shiftKey)
        {
            this.sortedColumns = [ ];

            this.columns.forEach(c =>
            {
                if (c.name !== column.name)
                {
                    c.sort = 'none';
                }
            });
        }

        // Add column to sort order unless it is already there
        const inSort = this.sortedColumns.find(c => c.name === column.name);
        if (!inSort)
        {
            this.sortedColumns.push(column);
        }

        // Textual description of the sort order
        this.sortOrderDescription = this.getSortOrderDescription();

        // console.log('BonesTableComponent.setSort', this.sortOrderDescription, this.getSortOrder());

        // Reload table data based upon new sort
        this.firstPage();
    }

    /**
     * Set column sort options based upon a sort order
     */
    private setColumnSortOrder(sortOrder: BonesSortOrder[]) : void
    {
        // Map returned sort order by column name
        // const used = new Map(sortOrder.map(so => [ so.propertyName, so.sort ]));

        // Clear sort from all columns
        this.columns.forEach(column => column.sort = 'none');

        // Update each column sort option to returned value
        this.sortedColumns = [ ];
        sortOrder.forEach(so =>
        {
            const column = this.columnMap.get(so.propertyName);
            column.sort = so.sort || 'asc';
            this.sortedColumns.push(column);
        });

        // Textual description of the sort order
        this.sortOrderDescription = this.getSortOrderDescription();
    }

    /**
     * Create BonesSortOrder[] from column settings
     */
    private getSortOrder() : BonesSortOrder[]
    {
        // Build user requested sort order from column settings
        let sortOrder: BonesSortOrder[] = this.sortedColumns
        .filter(c => c.sort !== 'none')
        .map(c => ({ propertyName: c.name, sort: c.sort, sortAs: c.sortAs }));

        // There is no user defined sort order, but there is a default one
        if ((!sortOrder || sortOrder.length === 0) && (this.defaultSortOrder && this.defaultSortOrder.length > 0))
        {
            sortOrder = this.defaultSortOrder;
            this.setColumnSortOrder(sortOrder);
        }

        return sortOrder;
    }

    /**
     * Get textual description of the sort order
     * @returns sort order description
     */
    getSortOrderDescription() : string
    {
        if (this.sortedColumns.length < 2)
        {
            return undefined;
        }

        let sortOrderText: string;

        this.sortedColumns.forEach(c =>
        {
            if (c.sort !== 'none')
            {
                const title = this.columnTitleMap.get(c.name);
                if (sortOrderText)
                {
                    sortOrderText += ', ' + title;
                }
                else
                {
                    sortOrderText = 'Sorted by ' + title;
                }
                if (c.sort === 'desc')
                {
                    sortOrderText += ' (descending)';
                }
            }
        });

        return sortOrderText;
    }
}
