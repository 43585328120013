import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxChartsModule } from '@swimlane/ngx-charts';

import { BonesCoreModule } from '@bones/core';
import { BonesFormModule } from '@bones/form';
import { BonesTableModule } from '@bones/table';

import { AppCoreModule } from '@BeerMonkey/core';
import { AppRateModule } from '@BeerMonkey/rate';

import { StatsRoutingModule } from './routing';

import { RatingsByYearPage } from './pages/ratings-by-year/ratings-by-year';
import { RatebeerStatsPage } from './pages/ratebeer-stats/ratebeer-stats';

@NgModule({
    declarations:
    [
        RatingsByYearPage,
        RatebeerStatsPage
    ],
    imports:
    [
        CommonModule,
        FormsModule, ReactiveFormsModule,
        IonicModule,
        BrowserAnimationsModule,
        NgxChartsModule,
        BonesCoreModule,
        BonesFormModule,
        BonesTableModule,
        AppCoreModule,
        AppRateModule,
        StatsRoutingModule
    ],
    // Use for services that are referenced only within this module
    providers:
    [
    ],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports:
    [
    ]
})
export class AppStatsModule
{
    static initialize()
    {
    }
}
AppStatsModule.initialize();
