import { Directive, ElementRef, Renderer2, Input, OnDestroy, OnChanges } from '@angular/core';

import { BonesGalleryService } from '../service/BonesGalleryService';

/**
 * Load scaled img via id
 *
 * <img [bonesGalleryDocumentID]="row.image_id" bonesGalleryDocumentSize="64x64" />
 */
@Directive({
    selector: 'img[bonesGalleryDocumentID],img[bonesGalleryDocumentSize]',
})
export class BonesGalleryDocumentIdDirective implements OnDestroy, OnChanges
{
    private _id?: number | null;
    private _size?: string;
    private unlisten?: () => void;

    /**
     * Define primary key of document to be displayed
     * 
     * @param id Document primary key
     */
    @Input() set bonesGalleryDocumentID(id: number | undefined | null)
    {
        this._id = id;
        // this.do();
    }

    /**
     * Define size of document to be displayed
     * 
     * @param size Maximum size of downloaded document in the format of: 'width'x'height'. Can be resized within browser.
     */
    @Input() set bonesGalleryDocumentSize(size: string)
    {
        this._size = size;
        // this.do();
    }

    /**
     * @ignore
     */
    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
        private gallery: BonesGalleryService
    )
    {
    }

    /**
     * @ignore
     */
    ngOnChanges()
    {
        this.do();
    }

    /**
     * @ignore
     */
    ngOnDestroy()
    {
        if (this.unlisten)
        {
            this.unlisten();
        }
    }

    private do()
    {
        let url = this.gallery.settings.rest.getServerUrl() + '/' + BonesGalleryService.urlGetImage;
        const args: string[] = [];

        if (this._id)
        {
            args.push('documentID=' + this._id);
        }
        if (this._size)
        {
            args.push('size=' + this._size);
        }

        if (args.length > 0)
        {
            url += '?' + args.join('&');
        }

        // Set img element src url to trigger browser to load image
        this.renderer.setAttribute(this.element.nativeElement, 'src',  url);

        // Cancel any old image listeners
        if (this.unlisten)
        {
            this.unlisten();
        }

        // Setup listener to open full sized image in new window
        if (this._id)
        {
            this.unlisten = this.renderer.listen(this.element.nativeElement, 'click',
            (event: PointerEvent) =>
            {
                // console.log('clicked image', this._id, event);
                event.stopPropagation();
                // window.open(this.rest.serverUrl + '/bones-gallery/image/get?documentID=' + this._id);
                this.gallery.open(this._id);
                return false;
            });
        }
    }

    //-----------------------------------------------------------------------

    // private setDefaultImage()
    // {
    //     // const url = URL.createObjectURL(this.b64toBlob(entry.contents, entry.contentType));
    //     this.renderer.setAttribute(this.element.nativeElement, 'src', './assets/icon-male.png');
    // }
}
