import { Injectable } from '@angular/core';

import { BonesError } from '@bones/core';
import { BnsOptions } from '../class/BnsOptions';
import { BnsResponse } from '../class/BnsResponse';
import { BonesNetworkService } from './ws';

/**
 * Identify gateway server environment
 */
export type GatewayServerType = 'dev' | 'test' | 'prod';

//-----------------------------------------------------------------------

/**
 * Access EDS Gateway.
 * 
 * To use the gateway methods, this service first needs to be configured using set methods.
 * This only needs to be done once upon application startup, like in the app.component.ts.
 * Alternately, this can be does elsewhere in the app if it has the ability to switch between
 * different environments.
 * 
 * @example
 * edsgw.edsGatewayServer = 'prod';
 * edsgw.attEDSApplication = 'MyApp';
 * edsgw.attEDSTarget = 'PROD';
 * 
 */
@Injectable({
  providedIn: 'root'
})
export class EDSGatewayService
{
    /**
     * URL for EDS Gateway running in the production environment.
     */
    public static edsGatewayUrlProduction = 'https://attdashboard.wireless.att.com/EDSGateway';

    /**
     * URL for EDS Gateway running in the test environment.
     */
    public static edsGatewayUrlTest = 'https://attdashboard-test.wireless.att.com/EDSGateway';

    /**
     * URL for EDS Gateway running in the development environment.
     */
    public static edsGatewayUrlDevelopment = 'https://attdashboard-dev.wireless.att.com/EDSGateway';

    /**
     * URL for EDS Gateway via Mobile Key in the production environment.
     */
    public static mobileKeyUrlProduction = 'https://oa-app.e-access.att.com/dashboard/EDSGateway';

    /**
     * URL for EDS Gateway via Mobile Key in the production environment.
     */
    public static mobileKeyUrlTest = 'https://oa-app.e-access.att.com/dashboardqa/EDSGateway';

    // /**
    //  * URL for Mobile Key oAuth.
    //  */
    // public static mobileKeyOauthUrl = 'https://oa-app.e-access.att.com/mga/sps/oauth';

    /**
     * Has the user been authenticated?
     */
    public isAuthenticated: boolean = false;

    private attuid: string = '';
    private password: string = '';

    //-----------------------------------------------------------------------

    // Gateway configuration - control via get/set methods
    private config =
    {
        edsGatewayServer: 'prod' as GatewayServerType,
        edsGatewayUrl: EDSGatewayService.edsGatewayUrlProduction,
        edsApplication: '',
        edsTarget: '',
        usingMobileKey: false,
        mobileKeyToken: undefined
    };

    /**
     * Get gateway url
     */
    public get edsGatewayServer() : GatewayServerType
    {
        return this.config.edsGatewayServer;
    }

    /**
     * Set gateway url
     * 
     * @param env Name of server environment.
     */
    public set edsGatewayServer(env: GatewayServerType)
    {
        this.config.edsGatewayServer = env;

        if (this.usingMobileKey)
        {
            switch (env)
            {
                case 'prod':
                    this.config.edsGatewayUrl = EDSGatewayService.mobileKeyUrlProduction;
                    break;
                case 'test':
                    this.config.edsGatewayUrl = EDSGatewayService.mobileKeyUrlTest;
                    break;
                case 'dev':
                    throw new BonesError(
                    {
                        className: 'EDSGatewayService',
                        methodName: 'edsGatewayServer',
                        message: 'Mobile key is not available with EDS dev server'
                    });
            }
        }
        else
        {
            switch (env)
            {
                case 'prod':
                    this.config.edsGatewayUrl = EDSGatewayService.edsGatewayUrlProduction;
                    break;
                case 'test':
                    this.config.edsGatewayUrl = EDSGatewayService.edsGatewayUrlTest;
                    break;
                case 'dev':
                    this.config.edsGatewayUrl = EDSGatewayService.edsGatewayUrlDevelopment;
                    break;
            }
        }
    }

    /**
     * Get gateway url
     */
    public get edsGatewayUrl() : string
    {
        return this.config.edsGatewayUrl;
    }

    /**
     * Set gateway url
     */
    public set edsGatewayUrl(url: string)
    {
        this.config.edsGatewayUrl = url;
    }

    /**
     * Get backend application
     */
    public get attEDSApplication() : string
    {
        return this.config.edsApplication;
    }

    /**
     * Set backend application
     */
    public set attEDSApplication(attEDSApplication: string)
    {
        if (attEDSApplication !== this.config.edsApplication)
        {
            this.config.edsApplication = attEDSApplication;
            this.isAuthenticated = false;
        }
    }

    /**
     * Get backend target
     */
    public get attEDSTarget() : string
    {
        return this.config.edsTarget;
    }

    /**
     * Set backend target
     */
    public set attEDSTarget(attEDSTarget: string)
    {
        this.config.edsTarget = attEDSTarget;
    }

    /**
     * Is this app using mobile key?
     */
    public get usingMobileKey() : boolean
    {
        return this.config.usingMobileKey;
    }

    /**
     * Turn on/off mobile key usage
     */
    public set usingMobileKey(usingMobileKey: boolean)
    {
        this.config.usingMobileKey = usingMobileKey;
    }

    /**
     * Get token used by mobile key
     */
    public get mobileKeyToken() : string
    {
        return this.config.mobileKeyToken;
    }

    /**
     * Set token used by mobile key
     */
    public set mobileKeyToken(mobileKeyToken: string)
    {
        // Save token for later use
        this.config.mobileKeyToken = mobileKeyToken;

        // Turn on mobile key usage
        this.usingMobileKey = true;

        // User has been authenticated (by mobile key)
        this.isAuthenticated = true;

        // Reset gateway URL to point to mobile key
        this.edsGatewayServer = this.config.edsGatewayServer;
    }

    // /**
    //  * Set token used by mobile key
    //  */
    // public async authenticateMobileKeyToken(mobileKeyToken: string) : Promise<any>
    // {
    //     // Save token for later use
    //     this.config.mobileKeyToken = mobileKeyToken;

    //     // Turn on mobile key usage
    //     this.usingMobileKey = true;

    //     // User has been authenticated (by mobile key)
    //     // this.isAuthenticated = true;

    //     // Reset gateway URL to point to mobile key
    //     this.edsGatewayServer = this.config.edsGatewayServer;

    //     // Post request to mobile key oauth to establish session
    //     const payload = 'access_token=' + this.config.mobileKeyToken;
    //     return this.bns.post(EDSGatewayService.mobileKeyOauthUrl, 'oauth20/session', payload,
    //     {
    //         responseType: 'text'
    //     })
    //     .then(() =>
    //     {
    //         // User has been authenticated (by mobile key)
    //         this.isAuthenticated = true;
    //     })
    //     .catch(error =>
    //     {
    //         // Flesh out error details
    //         error = new BonesError(
    //         {
    //             className: 'EDSGatewayService',
    //             methodName: 'mobileKeyToken',
    //             message: 'Unable to establish MobileKey oAuth session',
    //             error: error
    //         })
    //         .add({ payload });

    //         throw error;
    //     });
    // }

    //-----------------------------------------------------------------------

    /**
     * @ignore
     */
    constructor(
        private bns: BonesNetworkService
    ) { }

    //-----------------------------------------------------------------------

    /**
     * Validate user against EDS gateway
     * 
     * @param attuid User ID.
     * @param password User password.
     */
    async auth(attuid: string, password: string) : Promise<boolean|any>
    {
        return this.bns.get(this.config.edsGatewayUrl, '/auth',
        {
            responseType: 'text',
            headers:
            {
                attEDSApplication: this.config.edsApplication,
                attEDSUser: attuid,
                attEDSUserPassword: password
            }
        })
        .then(() =>
        {
//            console.log('authenticated with EDS gateway', response);
            this.attuid = attuid;
            this.password = password;
            this.isAuthenticated = true;
            return true;
        })
        .catch(error =>
        {
            this.isAuthenticated = false;
            throw new BonesError(
            {
                className: 'EDSGatewayService',
                methodName: 'auth',
                message: 'EDS Gateway auth failed',
                error: error
            })
            .add(
            {
                config: this.config
            });
        });
    }

    /**
     * Defer calling /auth as a seperate web service, but rather save the id and password to send
     * with the first /forward call to save one extra network request.
     * 
     * @param attuid User ID.
     * @param password User password.
     */
    authDeferred(attuid: string, password: string) : void
    {
        this.attuid = attuid;
        this.password = password;
        this.isAuthenticated = false;
    }

    //-----------------------------------------------------------------------

    /**
     * Forward a request through the EDS Gateway.
     * 
     * @param url web service url
     * @param payload data to post to server
     * @param options low level networking options
     */
    async forward(url: string, payload?: any, options = new BnsOptions()) : Promise<any>
    {
        // Default headers
        const headers: any = options.headers || { };

        // Append EDS Gateway headers
        headers.attEDSApplication = this.config.edsApplication;
        if (this.config.edsTarget)
        {
            headers.attEDSTarget = this.config.edsTarget;
        }

        // Append user ID and password to headers
        // These are not normally required since the /auth call setups a session on the gateway.
        // Sometimes things get mis-routed and the request ends up on a gateway without a session.
        // Always sending these will allow the gateway to establish a new session.
        // This also allows the gateway to replace an expired session.
        // This is also necessary for deferred authentication
        if (this.attuid)
        {
            headers.attEDSUser = this.attuid;
            headers.attEDSUserPassword = this.password;
        }

        // When routing through mobile key, the token needs to be sent via authorization header
        if (this.usingMobileKey)
        {
            headers.Authorization = 'Bearer ' + this.mobileKeyToken;
        }

//         // No need to supply basic auth header as the gateway can be configured to do that for you
//         // headers.append('Authorization', 'Basic ' + btoa(this.cma.cbusBasicAuthUserID + ':' + this.cma.cbusBasicAuthPassword);

        // Post request to gateway
        return this.bns.post(this.config.edsGatewayUrl + '/forward', url, payload,
        {
            ...options,
            headers: headers,
            returnValue: 'bns'
        })
        .then((bnsResponse: BnsResponse) =>
        {
            // Mark as authenticated in the case of deferred authentication
            this.isAuthenticated = true;

            // Return requestsed response
            return (options.returnValue === 'bns') ? bnsResponse : bnsResponse.bnsResponseInfo.payload;
        })
        .catch(error =>
        {
            // Flesh out error details
            error = new BonesError(
            {
                className: 'EDSGatewayService',
                methodName: 'forward',
                message: 'EDS Gateway forward failed',
                error: error
            })
            .add(
            {
                config: this.config
            });

            throw error;
        });
    }

    //-----------------------------------------------------------------------

}
