import { UntypedFormControl, ValidatorFn, AsyncValidatorFn, FormControl } from '@angular/forms';

import { BonesFormItemDate } from './BonesFormItemDate';
import { formatDate } from '@angular/common';

/**
 * BonesFormControl wraps the Angular form control with additional functionality
 */
export class BonesFormControl
{
    /**
     * Internal Angular form control
     */
    protected control: UntypedFormControl;

    /**
     * Construct a new form control
     */
    public constructor()
    {
        this.control = new UntypedFormControl();
    }

    /**
     * Set one or more Angular form validators.
     * @param validator Single Angular ValidatorFn or an array of ValidatorFn
     * @returns self for method chaining.
     */
    public setValidators(validator: ValidatorFn | ValidatorFn[]) : BonesFormControl
    {
        this.control.setValidators(validator);
        return this;
    }

    /**
     * Set one or more asynchronous Angular form validators.
     * @param validator Single Angular AsyncValidatorFn or an array of AsyncValidatorFn
     * @returns self for method chaining.
     */
    public setAsyncValidators(validator: AsyncValidatorFn | AsyncValidatorFn[]) : BonesFormControl
    {
        this.control.setAsyncValidators(validator);
        return this;
    }

    /**
     * Set the value of the Angular form control
     * @param newValue New value for the control
     * @returns void
     */
    public setValue(newValue: any) : void
    {
        this.control.setValue(newValue);
    }

    /**
     * @returns value from Angular form control
     */
    public getValue() : any
    {
        return this.control.value;
    }

    /**
     * @returns internal Angular form control
     */
    public getControl() : FormControl
    {
        return this.control;
    }
}

/**
 * BonesFormControlDate adds date functionality to the basic form control
 */
export class BonesFormControlDate extends BonesFormControl
{
    /**
     * Construct a new form control for date entry
     * @param formItem The BonesFormItemDate form item associated with this control.
     */
    public constructor(private formItem: BonesFormItemDate)
    {
        super();
    }

    /**
     * Set the value of the Angular form control
     * @param newValue New value for the control
     * @returns void
     */
    public setValue(newValue: any) : void
    {
        if (newValue)
        {
            this.control.setValue(formatDate(newValue, this.formItem.format, 'en'));
        }
        else
        {
            this.control.setValue(newValue);
        }
    }

    /**
     * @returns value from Angular form control formatted as per the BonesFormItemDate defined format.
     */
    public getValue() : string
    {
        const currentValue = this.control.value;

        if (currentValue)
        {
            return formatDate(currentValue, this.formItem.format, 'en');
        }
        else
        {
            return currentValue;
        }
    }

}