import { BonesTestToolsPiglet } from './bones-test-tools-piglet';
import { BonesTestToolsTestCase } from './bones-test-tools-test-case';

/**
 * Track list of test tool pages and test cases available based upon included modules.
 * This class is normally referenced in modules that define test tools or test cases in order to
 * add the module specific test tools and cases.
 */
export class BonesTestToolsPiglets
{
    /**
     * Array of test tool pages.
     */
    public static tools: BonesTestToolsPiglet[] = [ ];

    /**
     * Array of test cases.
     */
    public static testCases: BonesTestToolsTestCase[] = [ ];
}
