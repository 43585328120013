<!-- beer information card -->
<ion-card class="ion-padding" *ngIf="beer">
  <bones-card-toolbar title="Beer Information" [actions]="beerCardMenu"></bones-card-toolbar>
  <ion-item>
    <ion-label>
      <div *ngIf="beer.brewery">
        <ion-text color="primary" [routerLink]="['/rate/brewery', beer.brewery.brewery_id ]">{{ beer.brewery.row.name }}</ion-text>
        <ion-text> ({{ beer.brewery.row.location }})</ion-text>
      </div>
      <div>
        <ion-text color="primary" [routerLink]="['/rate/beer', beer.beer_id ]">{{ beer.row.name }}</ion-text>
      </div>
      <div>
        <ion-text>{{ beer.row.style }}</ion-text>
        <ion-text *ngIf="(beer.row.abv ?? 0) > 0"> @ {{ beer.row.abv }}%</ion-text>
      </div>
    </ion-label>
    <div slot="end">{{ beer.row.ratebeer_score }}</div>
  </ion-item>
  <p class="kps-pre-line" *ngIf="beer.row.notes">{{ beer.row.notes }}</p>
</ion-card>

<!-- rate beer information card -->
<rb-beer-info [ratebeerID]="ratebeerID"></rb-beer-info>
