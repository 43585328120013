import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { BonesGalleryService, BonesGalleryDocument, BonesGalleryDocumentFilter } from '@bones/gallery';

import { AppUser, UserService, MonkeyService } from '@BeerMonkey/core';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';
import { Inventory } from '@BeerMonkey/rate/class/Inventory';
import { InventoryService } from '@BeerMonkey/rate/service/InventoryService';

@Component({
    selector: 'inventory-detail',
    templateUrl: 'inventory-detail.html',
    styleUrls: [ 'inventory-detail.scss' ]
})
export class InventoryDetailPage implements OnInit, OnDestroy
{
    user: AppUser;
    inventoryID: number;
    inventory?: Inventory;
    documents: BonesGalleryDocument[] = [ ];
    featuredDocuments: BonesGalleryDocument[] = [ ];
    private nal: (() => void)[] = [ ];
    cardMenu: BonesMenuCardAction[] = [ ];

    constructor(
        private route: ActivatedRoute,
        private es: BonesErrorService,
        private gallery: BonesGalleryService,
        public monkey: MonkeyService,
        private mtus: UserService,
        private launch: LaunchEditModalService,
        private inventoryDB: InventoryService
    )
    {
        this.inventoryID = +(this.route.snapshot.paramMap.get('inventoryID') ?? 0);

        this.user = this.mtus.getUser();

        if (this.user.isAdmin)
        {
            this.cardMenu.push(this.launch.getMenuItemEdit(() => this.launch.editInventory(this.inventory?.row)))
            this.cardMenu.push(this.gallery.getMenuItemAddDocument('inventory_id', this.inventoryID, () => this.inventory?.beer?.name));
        }
    }

    async ngOnInit()
    {
        this.inventoryDB.getInventory(this.inventoryID)
        .then(async inventory => this.inventory = inventory)
        .catch(error => this.es.errorHandler(error));

        // Load and refresh documents as needed
        this.nal.push(this.gallery.cache.nowAndLater(
        rows =>
        {
            this.documents = new BonesGalleryDocumentFilter(rows).by('inventory_id', this.inventoryID).rows;
            this.featuredDocuments = this.documents.filter(d => d.isFeatured);
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

}
