<ion-header><bones-tt-page-header title="Application Info"></bones-tt-page-header></ion-header>

<ion-content>

  <ion-card class="ion-padding" *ngIf="info">
    <ion-card-title>App Info</ion-card-title>
    <ion-list>
      <ion-item>
        <ion-label>isDevMode</ion-label>
        <span slot="end">{{ info.isDevMode }}</span>
      </ion-item>
      <ion-item>
        <ion-label>isCordova</ion-label>
        <span slot="end">{{ info.isCordova }}</span>
      </ion-item>
      <ion-item>
        <ion-label>isAndroidPlatform</ion-label>
        <span slot="end">{{ info.isAndroidPlatform }}</span>
      </ion-item>
      <ion-item>
        <ion-label>isIosPlatform</ion-label>
        <span slot="end">{{ info.isIosPlatform }}</span>
      </ion-item>
      <ion-item>
        <ion-label>
          <h2>Platforms</h2>
          <div>{{ info.platforms }}</div>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>isBrowserDevice</ion-label>
        <span slot="end">{{ info.isBrowserDevice }}</span>
      </ion-item>
      <ion-item>
        <ion-label>isAndroidDevice</ion-label>
        <span slot="end">{{ info.isAndroidDevice }}</span>
      </ion-item>
      <ion-item>
        <ion-label>isIosDevice</ion-label>
        <span slot="end">{{ info.isIosDevice }}</span>
      </ion-item>
      <ion-item>
        <ion-label>
          <h2>uuid</h2>
          <div>{{ info.uuid }}</div>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-card>

</ion-content>
