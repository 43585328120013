<ion-header><bones-tt-page-header title="Pickers"></bones-tt-page-header></ion-header>

<ion-content>
  <ion-grid class="ion-no-padding">
    <ion-row>

      <ion-col>
        <ion-card class="ion-padding">
          <bones-card-toolbar title="Tests" [actions]="actions"></bones-card-toolbar>
          <ion-item [detail]="true" (click)="doPick(picker)" *ngFor="let picker of pickers">
            <ion-label>
              <p>{{ picker.title }}</p>
            </ion-label>
          </ion-item>
        </ion-card>
      </ion-col>

      <ion-col>
        <ion-card class="ion-padding" *ngIf="options">
          <bones-card-toolbar title="Options"></bones-card-toolbar>
          <pre>{{ options | json }}</pre>
        </ion-card>

        <ion-card class="ion-padding" *ngIf="returnValue">
          <bones-card-toolbar title="Return Value" [actions]="rvActions"></bones-card-toolbar>
          <pre>{{ returnValue | json }}</pre>
        </ion-card>
      </ion-col>

    </ion-row>
  </ion-grid>

</ion-content>
<!-- 
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" (click)="picker1()">Picker1</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer> -->
