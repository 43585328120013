import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { BonesGalleryService, BonesGalleryDocument, BonesGalleryDocumentFilter } from '@bones/gallery';

import { AppUser, UserService, MonkeyService } from '@BeerMonkey/core';
import { RatingService } from '@BeerMonkey/rate/service/RatingService';
import { Rating } from '@BeerMonkey/rate/class/Rating';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';

@Component({
    selector: 'rating-detail',
    templateUrl: 'rating-detail.html'
})
export class RatingDetailPage implements OnInit, OnDestroy
{
    user: AppUser;
    ratingID: number;
    rating?: Rating;
    documents: BonesGalleryDocument[] = [ ];
    featuredDocuments: BonesGalleryDocument[] = [ ];
    private nal: (() => void)[] = [ ];
    ratingCardMenu: BonesMenuCardAction[];

    constructor(
        private route: ActivatedRoute,
        private es: BonesErrorService,
        private gallery: BonesGalleryService,
        public monkey: MonkeyService,
        private mtus: UserService,
        private launch: LaunchEditModalService,
        private ratingDB: RatingService
    )
    {
        this.ratingID = +(this.route.snapshot.paramMap.get('ratingID') ?? 0);

        this.user = this.mtus.getUser();

        this.ratingCardMenu =
        [
        ];

        if (this.user.isAdmin)
        {
            this.ratingCardMenu.push(this.launch.getMenuItemEdit(() => this.launch.editRating(this.rating)))
        //     this.ratingCardMenu.push({ title: 'Copy', icon: 'copy', action: () => undefined });
            this.ratingCardMenu.push(this.gallery.getMenuItemAddDocument('rating_id', this.ratingID, () => this.rating?.beer?.name));
            // this.ratingCardMenu.push(
            // {
            //     title: 'Swap Beer Photo',
            //     icon: 'swap-vertical',
            //     action: () => this.ratingDB.swapBeerPhoto(this.rating)
            // });
            this.ratingCardMenu.push(
            {
                title: 'Steal Beer\'s Image',
                icon: 'arrow-down',
                action: () => this.moveImageFromBeer()
            });
            this.ratingCardMenu.push(
            {
                title: 'Move Image to Beer',
                icon: 'arrow-up',
                action: () => this.moveImageToBeer()
            });
        }
    }

    async ngOnInit()
    {
        this.ratingDB.getRating(this.ratingID)
        .then(async rating => this.rating = rating)
        .catch(error => this.es.errorHandler(error));

        // Load and refresh documents as needed
        this.nal.push(this.gallery.cache.nowAndLater(
        rows =>
        {
            this.documents = new BonesGalleryDocumentFilter(rows).by('rating_id', this.ratingID).rows;
            this.featuredDocuments = this.documents.filter(d => d.isFeatured);
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    get sections() : [ string, string, number ][]
    {
        if (this.rating)
        {
            return [
                [ 'Appearance', this.rating.row.appearance, this.rating.row.appearance_score ],
                [ 'Aroma', this.rating.row.aroma, this.rating.row.aroma_score ],
                [ 'Body', this.rating.row.body, this.rating.row.body_score ],
                [ 'Flavor', this.rating.row.flavor, this.rating.row.flavor_score ],
                [ 'Drinkability', this.rating.row.drinkability, this.rating.row.drinkability_score ],
                [ 'Overall', this.rating.row.overall, this.rating.row.overall_score ]
            ];
        }
        else
        {
            return [ ];
        }
    }

    handleRefresh(e: Event)
    {
        const event = e as CustomEvent;

        this.ratingDB.cache.updated(this.ratingID)
        .then(rating =>
        {
            this.rating = rating;
            event.detail.complete();
        })
        .catch(error => this.es.errorHandler(error));
    }

    moveImageToBeer()
    {
        if (this.rating)
        {
            this.ratingDB.moveImageToBeer(this.rating)
            .catch(error => this.es.errorHandler(error));
        }
    }

    moveImageFromBeer()
    {
        if (this.rating)
        {
            this.ratingDB.moveImageFromBeer(this.rating)
            .catch(error => this.es.errorHandler(error));
        }
    }

}
