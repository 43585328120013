import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

import { BonesForm, BonesFormItem } from '@bones/form';

import { Beer } from '@BeerMonkey/rate/class/Beer';

@Component({
    templateUrl: 'rating-edit-jr.html'
})
export class RatingEditJrModal
{
    beer: Beer;
    bonesForm: BonesForm;
    title: string;
    items: BonesFormItem[];

    constructor(
        private modalCtrl: ModalController,
        private navParams: NavParams
    )
    {
        // Get existing objects for edit
        this.beer = this.navParams.get('beer');
        this.bonesForm = this.navParams.get('bonesForm');
        this.title = this.navParams.get('title');
        this.items = this.navParams.get('items');
    }

    ok()
    {
        this.modalCtrl.dismiss();
    }

    //-------------------------------------------------------------------------------


}
