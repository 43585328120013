import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { BonesGalleryService, BonesGalleryDocument, BonesGalleryDocumentFilter } from '@bones/gallery';

import { AppUser, UserService, MonkeyService } from '@BeerMonkey/core';
import { BeerService } from '@BeerMonkey/rate/service/BeerService';
import { Beer } from '@BeerMonkey/rate/class/Beer';
import { RatingService } from '@BeerMonkey/rate/service/RatingService';
import { Rating } from '@BeerMonkey/rate/class/Rating';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';
import { InventoryService, inventoryVintageGroupFactory } from '@BeerMonkey/rate/service/InventoryService';
import { Inventory } from '@BeerMonkey/rate/class/Inventory';
import { RecentSearchService } from '@BeerMonkey/rate/service/RecentSearchService';

@Component({
    selector: 'beer-detail',
    templateUrl: 'beer-detail.html'
})
export class BeerDetailPage implements OnInit, OnDestroy
{
    user: AppUser;
    beerID: number;
    beer?: Beer;
    ratings: Rating[] = [ ];
    inventory: Inventory[] = [ ];
    documents: BonesGalleryDocument[] = [ ];
    featuredDocuments: BonesGalleryDocument[] = [ ];
    private nal: (() => void)[] = [ ];
    beerCardMenu: BonesMenuCardAction[] = [ ];
    defaultInventoryGroup = inventoryVintageGroupFactory('asc');
    layout: 'list' | 'gallery' = 'list';
    searchTerm: string = '';

    constructor(
        private route: ActivatedRoute,
        private es: BonesErrorService,
        public gallery: BonesGalleryService,
        public monkey: MonkeyService,
        private mtus: UserService,
        private launch: LaunchEditModalService,
        private searches: RecentSearchService,
        private beerDB: BeerService,
        private inventoryDB: InventoryService,
        private ratingDB: RatingService
    )
    {
        this.beerID = +(this.route.snapshot.paramMap.get('beerID') ?? 0);

        this.user = this.mtus.getUser();

        if (this.user.isAdmin)
        {
            this.beerCardMenu.push(this.launch.getMenuItemEdit(() => this.launch.editBeer(this.beer)));
            this.beerCardMenu.push({ title: 'Add Rating', icon: 'add-circle', action: () => this.launch.addRating(this.beerID) });
            this.beerCardMenu.push({ title: 'Add Inventory', icon: 'add-circle', action: () => this.launch.addInventory(this.beer) });
            this.beerCardMenu.push(this.gallery.getMenuItemAddDocument('beer_id', this.beerID, () => this.beer?.name));
        }

        this.beerCardMenu.push({ title: 'Show Ratings as List', icon: 'list', action: () => this.layout = 'list' });
        this.beerCardMenu.push({ title: 'Show Ratings as Gallery', icon: 'grid', action: () => this.layout = 'gallery' });

        // Add beer to recently viewed beers
        this.searches.addRecentBeer(this.beerID);
    }

    async ngOnInit()
    {
        this.beerDB.getBeer(this.beerID)
        .then(async beer => this.beer = beer)
        .catch(error => this.es.errorHandler(error));

        // Load and refresh inventory as needed
        this.nal.push(this.inventoryDB.cache.nowAndLater(
        rows => this.inventory = rows.filter(p => p.row.beer_id === this.beerID) ?? [ ],
        error => this.es.errorHandler(error)));

        // Load and refresh ratings as needed
        this.nal.push(this.ratingDB.cache.nowAndLater(
        rows =>
        {
            this.ratings = rows.filter(p => p.row.beer_id === this.beerID);
            this.setLayout();

        },
        error => this.es.errorHandler(error)));

        // Load and refresh documents as needed
        this.nal.push(this.gallery.cache.nowAndLater(
        rows =>
        {
            this.documents = new BonesGalleryDocumentFilter(rows).by('beer_id', this.beerID).rows;
            this.featuredDocuments = this.documents.filter(d => d.isFeatured);
            this.setLayout();
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    beerImageID() : number | undefined
    {
        return this.beer ? this.ratingDB.getBeerImageID(this.beer) : undefined;
    }

    setLayout()
    {
        if (this.ratings && this.documents)
        {
            // If there are featured images for this beer, then show the ratings as a list to make room for the featured images
            if ((this.featuredDocuments?.length ?? 0) > 0)
            {
                this.layout = 'list';
                return;
            }

            // How many ratings have images?
            let imageCount = 0;
            this.ratings.forEach(r =>
            {
                const images = this.gallery.cache.getListByForeignKey('rating_id', r.rating_id);
                if (images && images.map(d => d.isImage).length > 0)
                {
                    ++imageCount;
                }
            });

            // If there are more than one rating images, then show a gallery; a single image will display in the featured slot
            if (imageCount > 1)
            {
                this.layout = 'gallery';
                return;
            }

            // Default to list layout
            this.layout = 'list';
        }
    }

}
