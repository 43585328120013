import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

import { BonesErrorService } from '@bones/core';
import { BonesFormItemOptions } from '@bones/form';
import { BonesEditForm } from '@bones/edit';
import { BonesGalleryService } from '@bones/gallery';

import { ApeRest, MonkeyService } from '@BeerMonkey/core';

import { Beer } from '@BeerMonkey/rate/class/Beer';
import { RatingInfo } from '@BeerMonkey/rate/class/RatingInfo';
import { BeerService } from '@BeerMonkey/rate/service/BeerService';
import { RatingService } from '@BeerMonkey/rate/service/RatingService';
import { RatingEditJrModal } from './rating-edit-jr/rating-edit-jr';

interface RatingEditWordConfig
{
    title: string;
    words: string;
    numbers: string;
    top: number;
}

const thefaults = { mode: undefined, source: undefined };

@Component({
    templateUrl: 'rating-edit.html'
})
export class RatingEditModal extends BonesEditForm<RatingInfo> implements OnInit
{
    beer?: Beer;

    // Ratable sections
    sections: RatingEditWordConfig[] =
    [
        { title: 'Appearance', words: 'appearance', numbers: 'appearance_score', top: 10 },
        { title: 'Aroma', words: 'aroma', numbers: 'aroma_score', top: 10 },
        { title: 'Body', words: 'body', numbers: 'body_score', top: 10 },
        { title: 'Flavor', words: 'flavor', numbers: 'flavor_score', top: 10 },
        { title: 'Drinkability', words: 'drinkability', numbers: 'drinkability_score', top: 10 },
        { title: 'Overall', words: 'overall', numbers: 'overall_score', top: 20 },
    ];

    constructor(
        protected formBuilder: FormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: ApeRest,
        private gallery: BonesGalleryService,
        public monkey: MonkeyService,
        private beerDB: BeerService,
        private ratingDB: RatingService
    )
    {
        super();
    }

    async ngOnInit()
    {
        // Build form columns for sections
        const wordColumns: BonesFormItemOptions[] = [ ];
        this.sections.forEach(section =>
        {
            const range: number[] = [ ];
            for (let i = section.top; (i > 0); --i)
            {
                range.push(i);
            }
            wordColumns.push(
            {
                name: section.numbers,
                title: section.title,
                type: 'number',
                picker: range,
                validator: Validators.maxLength(65535),
                // Recalculate overall ratebber score as each individual score changes
                onChange: () =>
                {
                    const nn = (columnName: string) : number => this.bonesForm.getValue(columnName) ?? 0;

                    const score = (0
                        + Math.round(nn('appearance_score') / 2)
                        + nn('aroma_score')
                        + Math.round(nn('body_score') / 2)
                        + nn('flavor_score')
                        + nn('overall_score')) / 10;

                    // console.log('recalc',
                    //     ['appearance_score', 'aroma_score', 'body_score', 'flavor_score', 'overall_score']
                    //         .map(c => [ c, this.bonesForm.getValue(c), typeof this.bonesForm.getValue(c), nn(c) ]),
                    //     '=', score);

                    this.bonesForm.setValue('ratebeer_score', score);
                }
            });
            wordColumns.push(
            {
                name: section.words,
                title: section.title,
                textarea: 4
            });
        });

        await super.initialize(
        {
            columns:
            [
                {
                    name: 'rating_id',
                    hidden: true
                },
                // Beer
                {
                    name: 'beer_id',
                    title: 'Beer',
                    hideTitle: true,
                    picker: () => this.beerDB.cache.getPickerMap('name', (b) => b.row.brewery_id === this.beer?.row.brewery_id),
                    onChange: beerID => this.setBeer(+beerID),
                    validator: Validators.required
                },
                // Deets
                {
                    name: 'rating_date',
                    title: 'Rating Date',
                    type: 'date',
                    // initialValue: new Date(),
                    validator: Validators.required
                },
                {
                    name: 'source',
                    title: 'Source',
                    picker:
                    {
                        readWrite: true,
                        populator: () => this.ratingDB.getSourcePicker()
                    },
                    validator: Validators.maxLength(48)
                },
                {
                    name: 'mode',
                    title: 'Mode',
                    picker:
                    {
                        readWrite: true,
                        populator: () => this.ratingDB.getModes()
                    },
                    validator: Validators.maxLength(32)
                },
                {
                    name: 'vintage',
                    title: 'Vintage',
                    picker: this.ratingDB.getVintagePicker(),
                    validator: Validators.maxLength(16)
                },
                {
                    name: 'version',
                    title: 'Version',
                    picker:
                    {
                        readWrite: true,
                        values: [ 'Barrel Aged', 'Bourbon', 'Brandy', 'Oak', 'French Oak', 'Vanilla', 'Chocolate',
                                  'Brett', 'Coffee', 'Cherry', 'Cedar', 'Cognac' ]
                    },
                    validator: Validators.maxLength(48)
                },
                {
                    name: 'abv',
                    title: 'ABV',
                    type: 'number'
                },
                // Words
                ...wordColumns,
                // Scores
                {
                    name: 'score',
                    title: 'Star Score',
                    picker: [ '-', '*', '*+', '**', '**+', '***', '***+', '****' ],
                    validator: Validators.maxLength(4)
                },
                {
                    name: 'ratebeer_score',
                    title: 'Numeric Score (calculated)',
                    type: 'number',
                    readonly: true
                },
                {
                    name: 'rating',
                    title: 'Numeric Score (1-5) (In lue of individual scores)',
                    type: 'number'
                },
                // Notes
                {
                    name: 'note',
                    title: 'Note',
                    type: 'textarea',
                    validator: Validators.maxLength(65535)
                },
                {
                    name: 'rerate',
                    title: 'I would like to rerate this beer',
                    toggle: { on: '1', off: '' }
                },
                {
                    name: 'auto_score',
                    title: 'Attribute scores generated from notes',
                    toggle: { on: '1', off: '' }
                },
                // Image
                {
                    name: 'image',
                    title: 'Image',
                    type: 'file'
                },
                {
                    name: 'image_id',
                    hidden: true
                },
                {
                    name: 'image_title',
                    hidden: true
                }
            ],
            uploadFormat: 'form',
            saveUrl: '/rate/rating/update/updateRating',
            cache: this.ratingDB.cache,
            postSave: (row, payload) =>
            {
                // Updating the rating recalculates the beer's average rating; beer cache needs to be updated
                this.updateBeerCache();

                // If row supplied an image, then update the document cache with the new image
                if (row.image)
                {
                    this.gallery.cache.updated(payload.documentID);
                }

                // Save source and mode as defaults for next rating
                thefaults.source = this.bonesForm.getValue('source');
                thefaults.mode = this.bonesForm.getValue('mode');

                return Promise.resolve('');
            },
            // postDelete: () =>
            // {
            //     this.updateBeerCache();
            //     return undefined;
            // }
        });

        // Supply defaults
        if (this.isAdd)
        {
            this.bonesForm.setValue('rating_date', formatDate(new Date(), 'yyyy-MM-dd', 'en'));
            this.bonesForm.setValue('beer_id', this.launchOptions.moData.beerID);
            this.bonesForm.setValue('source', thefaults.source);
            this.bonesForm.setValue('mode', thefaults.mode);

            await this.setBeer(this.launchOptions.moData.beerID);
            this.bonesForm.setValue('image_title', this.beer?.name);
        }
    }

    //-------------------------------------------------------------------------------

    private setBeer(beerID: number)
    {
        return this.beerDB.getBeer(beerID)
        .then(beer => this.beer = beer)
        .catch(error => this.bes.errorHandler(error));
    }

    // Updating the rating recalculates the beer's average rating; beer cache needs to be updated
    private updateBeerCache()
    {
        this.beerDB.cache.updated(this.bonesForm.getValue('beer_id'));
    }

    //-------------------------------------------------------------------------------

    deets()
    {
        this.editJr('Deets', [ 'rating_date', 'source', 'mode', 'vintage', 'version', 'abv' ]);
    }

    aaf()
    {
        this.editJr('AAF', [ 'appearance', 'aroma', 'flavor' ]);
    }

    editSection(section: RatingEditWordConfig)
    {
        this.editJr(section.title, [ section.numbers, section.words ]);
    }

    score()
    {
        this.editJr('Score', [ ...this.sections.map(s => s.numbers), 'ratebeer_score', 'score', 'rating' ]);
    }

    notes()
    {
        this.editJr('Notes', [ 'note', 'rerate', 'auto_score' ]);
    }

    async editJr(title: string, columnNames: string[])
    {
        (await this.modalCtrl.create(
        {
            component: RatingEditJrModal,
            componentProps:
            {
                beer: this.beer,
                bonesForm: this.bonesForm,
                title: title,
                items: columnNames.map(c => this.bonesForm.getItem(c))
            }
        }))
        .present();
    }

    //-------------------------------------------------------------------------------

}
