import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BonesError } from '@bones/core';

import { MockBnsRequest, MockBnsResponse } from '../class/mock';

/**
 * Base class to define mock web services
 */
@Injectable({
  providedIn: 'root'
})
export class BonesNetworkMockService
{
    private jsonFilesMap = new Map<string, string>();
    private _enabled: boolean = false;

    /**
     * Callback function to process a mock request that does not have a mock file mapped to it.
     */
    public planB: (request: MockBnsRequest) => Promise<MockBnsResponse>;

    /**
     * @ignore
     */
    constructor(
        private http: HttpClient
    )
    {
    }

    /**
     * Check to see if mock services have been enabled.
     * 
     * @returns true=enabled
     */
    public get enabled()
    {
        return this._enabled;
    }

    /**
     * Enable or disable using mock services.
     * 
     * @param enabled true=turn on, false=turn off
     */
    public set enabled(enabled: boolean)
    {
        this._enabled = enabled;
    }

    //-----------------------------------------------------------------------

    /**
     * Intercept a web service request and substitute data from a file built into the client app.
     * 
     * To implement wildcards, map the entry of '*' to '/assets/data/mock' (or the location of your mock files).
     * 
     * @param url The web service url to be intercepted.
     * @param filename The filename of the json data file under /assets/data/mock.
     */
    public addMockFile(url: string, filename: string)
    {
        this.jsonFilesMap.set(url, filename);
        this._enabled = true;
    }

    /**
     * Lookup entry in mock file map including wildcard lookups.
     * 
     * @param apiUrl api url to lookup.
     * @returns filename url or undefined if the api url is not mapped.
     */
    public getMockFile(apiUrl: string) : string
    {
        if (this.jsonFilesMap.has(apiUrl))
        {
            return '/assets/data/mock/' + this.jsonFilesMap.get(apiUrl) + '.json' ;
        }
        else if (this.jsonFilesMap.has('*'))
        {
            return this.jsonFilesMap.get('*') + apiUrl + '.json' ;
        }
        else
        {
            return undefined;
        }
    }

    /**
     * Get contents from mock file.
     * 
     * @param filename path of mock file.
     * @returns mock file contents.
     */
    public async getMockFileContents(filename: string) : Promise<any>
    {
        try
        {
            return this.http.get(filename,
            {
                responseType: 'json'
            })
            .toPromise();
        }
        catch (error)
        {
            throw new BonesError(
            {
                className: 'BonesNetworkMockService',
                methodName: 'getMockFileData',
                message: 'unable to read mock data file',
                error: error
            })
            .add({ filename });
        }
    }

    /**
     * Callback function to process a mock request.
     * 
     * @param request MockBnsRequest object with request details including apiUrl.
     * @returns MockBnsResponse object with mock body or action of 'bypass' to call real web service.
     */
    async onRequest(request: MockBnsRequest) : Promise<MockBnsResponse>
    {
        let reply = new MockBnsResponse();

        // Check to see if this api url has an entry in the mock map
        const mockfile = this.getMockFile(request.apiUrl);

        if (mockfile)
        {
            try
            {
                await this.getMockFileContents(mockfile)
                .then(data =>
                {
                    reply.body = {
                        status: 'ok',
                        payload: data
                    };
                });
            }
            catch (error)
            {
                throw new BonesError(
                {
                    className: 'BonesNetworkMockService',
                    methodName: 'onRequest',
                    message: 'mock web service failed',
                    error: error
                });
            }
        }
        else if (this.planB)
        {
            reply = await this.planB(request);
        }
        else
        {
            console.log('bypassing mock for', request.apiUrl, request);
            reply.action = 'bypass';
        }

        return reply;
    }

    // /**
    //  * Callback function to process a mock request that does not have a mock file mapped to it.
    //  * 
    //  * @param request MockBnsRequest object with request details including apiUrl.
    //  * @returns MockBnsResponse object with mock body or action of 'bypass' to call real web service.
    //  */
    // async planB(request: MockBnsRequest) : Promise<MockBnsResponse>
    // {
    // }

    //-----------------------------------------------------------------------

}
