import { Component, Input, OnInit } from '@angular/core';

/**
 * Setup a desktop style UI with a grid to show app icons on the left and app content on the right.
 * 
 * @example
 * <bones-app-launcher-pane>
 *   <div class="app-launcher-icons">
 *     <bones-app-launcher-icon icon="beer" title="Breweries Around Here" #beer></bones-app-launcher-icon>
 *     <bones-app-launcher-icon icon="wine" title="Wineries Around Here" #wine></bones-app-launcher-icon>
 *   </div>
 *   <div class="app-launcher-body">
 *     <div *ngIf="beer.active">Here is beer!</div>
 *     <div *ngIf="wine.active">Here is wine!</div>
 *   </div>
 * </bones-app-launcher-pane>
 */
@Component({
  selector: 'bones-app-launcher-pane',
  templateUrl: 'bones-app-launcher-pane.html'
})
export class BonesAppLauncherPaneComponent implements OnInit
{
    /**
     * Number of columns to reserve for the icon side panel. Default 1 column.
     */
    @Input() iconColumns = 1;

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

    /**
     * @ignore
     */
    ngOnInit()
    {
    }

}
