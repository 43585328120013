import { BonesGalleryDocumentInfo } from './BonesGalleryDocumentInfo';

/**
 * Document smart object
 */
export class BonesGalleryDocument
{
    /**
     * Document primary key
     */
    document_id: number;

    /**
     * Construct a smart object from server data
     * 
     * @param row dumb object received from server
     */
    constructor(public row: BonesGalleryDocumentInfo)
    {
        this.document_id = row.document_id;
    }

    /**
     * @returns is this document an image?
     */
    get isImage() : boolean
    {
        return this.row.content_type.indexOf('image') >= 0;
    }

    /**
     * @returns is this document an attachment (i.e. not an image)?
     */
    get isAttachment() : boolean
    {
        return this.row.content_type.indexOf('image') < 0;
    }

    /**
     * @returns does this document have the 'featured' attribute?
     */
    get isFeatured() : boolean
    {
        return !!this.row.featured;
    }

    /**
     * @returns does this document have the 'logo' attribute?
     */
    get isLogo() : boolean
    {
        return !!this.row.logo;
    }
}
