import { Injectable } from '@angular/core';

import { BonesCache, BonesCacheFactory, BonesSearch, BonesSortOption, BonesItemGroupFactory } from '@bones/core';

import { ApeRest } from '@BeerMonkey/core/service//ApeRest';
import { Brewery } from '../class/Brewery';
import { BreweryInfo } from '../class/BreweryInfo';

/**
 * Access db information
 */
@Injectable({
  providedIn: 'root',
})
export class BreweryService
{
    cache: BonesCache<number, BreweryInfo, Brewery>;

    constructor(
        private bcf: BonesCacheFactory,
        private rest: ApeRest
    )
    {
        this.cache = this.bcf.create<number, BreweryInfo, Brewery>(
        {
            pk: 'brewery_id',
            loadCache: () => this.rest.send('rate/brewery/getBreweries'),
            reloadOne: (id: number) => this.rest.send('rate/brewery/getBrewery', { breweryID: id }),
            converter: async (info: BreweryInfo) : Promise<Brewery> =>
            {
                const brewery = new Brewery(info);

                return brewery;
            },
            sorter: (a: Brewery, b: Brewery) =>
            {
                return a.row.name.localeCompare(b.row.name);
            }
        });
    }

    /**
     * Get brewery details
     */
    async getBrewery(breweryID: number) : Promise<Brewery>
    {
        return this.cache.getEntry(breweryID);
    }

    /**
     * Get details for a list of breweries
     */
    async getBreweries(breweryIDs: number[]) : Promise<Brewery[]>
    {
        const breweries: Brewery[] = [ ];

        for (let i = 0; (i < breweryIDs.length); ++i)
        {
            breweries.push(await this.getBrewery(breweryIDs[i]))
        }

        return breweries;
    }

    //-----------------------------------------------------------------------
    //-----------------------------------------------------------------------

    // /**
    //  * Get brewery details
    //  */
    // async get(breweryID: number) : Promise<any>
    // {
    //     return this.rest.send('rate/brewery/getBrewery', { breweryID })
    //     .catch(error =>
    //     {
    //         throw new BonesError(
    //         {
    //             className: 'BreweryService',
    //             methodName: 'get',
    //             message: 'unable to get brewery',
    //             error: error
    //         })
    //         .add({ breweryID });
    //     });
    // }

    //-----------------------------------------------------------------------

    // /**
    //  * test
    //  */
    // async getAllBreweries() : Promise<any>
    // {
    //     return this.rest.send('rate/brewery/getBreweries')
    //     .catch(error =>
    //     {
    //         throw new BonesError(
    //         {
    //             className: 'BreweryService',
    //             methodName: 'getAllBreweries',
    //             message: 'unable to get breweries',
    //             error: error
    //         });
    //     });
    // }

    // /**
    //  * test
    //  */
    // async getAllBeers() : Promise<any>
    // {
    //     return this.rest.send('rate/beer/getBeers')
    //     .catch(error =>
    //     {
    //         throw new BonesError(
    //         {
    //             className: 'BreweryService',
    //             methodName: 'getAllBeers',
    //             message: 'unable to get beers',
    //             error: error
    //         });
    //     });
    // }

    // /**
    //  * test
    //  */
    // async getAllRatings() : Promise<any>
    // {
    //     return this.rest.send('rate/rating/getRatings')
    //     .catch(error =>
    //     {
    //         throw new BonesError(
    //         {
    //             className: 'BreweryService',
    //             methodName: 'getAllRatings',
    //             message: 'unable to get ratings',
    //             error: error
    //         });
    //     });
    // }

    //-----------------------------------------------------------------------
}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

export const breweryNameGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Brewery, BreweryInfo>('name', { sort: sort, byFirstLetter: true });

export const breweryLocationGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Brewery, BreweryInfo>((item) => item.row.location, { sort: sort });

export const breweryNoneGroupFactory = (title = '') =>
    new BonesItemGroupFactory<Brewery, BreweryInfo>(() => title, { sort: 'none' });

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter
 */
export class BreweryFilter
{
    constructor(public rows: Brewery[])
    {
    }

    byKeyword(phrase: string) : BreweryFilter
    {
        if (phrase)
        {
            this.rows = new BonesSearch<Brewery>(this.rows, phrase)
            .execute(r => [ r.row.name, r.row.location, r.row.notes ]);
        }

        return this;
    }
}
