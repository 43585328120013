import { Component, OnInit, OnDestroy } from '@angular/core';

import { BonesErrorService } from '@bones/core';

import { MonkeyService } from '@BeerMonkey/core';

import { RatingService } from '@BeerMonkey/rate/service/RatingService';
import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';
import { BeerService } from '@BeerMonkey/rate/service/BeerService';
import { Brewery, Beer, Rating } from '@BeerMonkey/rate';

//-----------------------------------------------------------------------

class Moe
{
    ratings: number;
    breweries?: number;
    beers?: number;

    constructor(
        page: RatebeerStatsPage,
        public category: string,
        public ratingFilter: (row: Rating) => boolean,
        public breweryFilter?: (row: Brewery) => boolean,
        public beerFilter?: (row: Beer) => boolean)
        {
            this.ratings = page.allRatings.filter(this.ratingFilter).length;
            if (this.breweryFilter) this.breweries = page.allBreweries.filter(this.breweryFilter).length;
            if (this.beerFilter) this.beers = page.allBeers.filter(this.beerFilter).length;
        }
}

//-----------------------------------------------------------------------

@Component({
    selector: 'ratebeer-stats',
    templateUrl: 'ratebeer-stats.html'
})
export class RatebeerStatsPage implements OnInit, OnDestroy
{
    allBreweries: Brewery[] = [ ];
    allBeers: Beer[] = [ ];
    allRatings: Rating[] = [ ];
    private nal: (() => void)[] = [ ];
    showGrid = true;
    rows?: Moe[];
    breweryCardTitle?: string;
    beerCardTitle?: string;
    ratingCardTitle?: string;
    breweries?: Brewery[];
    beers?: Beer[];
    ratings?: Rating[];

    constructor(
        private es: BonesErrorService,
        public monkey: MonkeyService,
        private breweryDB: BreweryService,
        private beerDB: BeerService,
        private ratingDB: RatingService
    )
    {
    }

    async ngOnInit()
    {
        // Load and refresh breweries as needed
        this.nal.push(this.breweryDB.cache.nowAndLater(
        rows =>
        {
            this.allBreweries = rows;
            this.calculate();
        },
        error => this.es.errorHandler(error)));

        // Load and refresh beers as needed
        this.nal.push(this.beerDB.cache.nowAndLater(
        rows =>
        {
            this.allBeers = rows;
            this.calculate();
        },
        error => this.es.errorHandler(error)));

        // Load and refresh ratings as needed
        this.nal.push(this.ratingDB.cache.nowAndLater(
        rows =>
        {
            this.allRatings = rows;
            this.calculate();
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    //-----------------------------------------------------------------------

    calculate()
    {
        if (!this.allBreweries || !this.allBeers || !this.allRatings)
        {
            return;
        }

        this.clearDrillDown();

        this.rows = [ ];

        // Total
        const moe = new Moe(this, 'Total', () => true, () => true, () => true);
        this.rows.push(moe);
        this.showAll(moe);

        // Linked
        this.rows.push(new Moe(this, 'Linked',
            r => r.hasBeenUploadedToRateBeer,
            r => r.linkedToRateBeer,
            r => r.linkedToRateBeer));

        // Unlinked
        this.rows.push(new Moe(this, 'Unlinked',
            r => r.uploadableRating,
            r => r.linkableToRateBeer,
            r => r.linkableToRateBeer));

        // Unwanted
        this.rows.push(new Moe(this, 'Unwanted',
            r => r.notUploadableToRateBeer,
            r => r.notLinkableToRateBeer,
            r => r.notLinkableToRateBeer));

        // New
        this.rows.push(new Moe(this, 'New', r => r.newUploadableRating));

        // Updated
        this.rows.push(new Moe(this, 'Updated', r => r.updatedUploadedRating));

        // Versions
        // moe = new Moe();
        // moe.category = 'Versions';
        // this.rows.push(moe);

        // Vintages
        // moe = new Moe();
        // moe.category = 'Vintages';
        // this.rows.push(moe);
    }

    clearDrillDown()
    {
        this.breweries = undefined;
        this.beers = undefined;
        this.ratings = undefined;
    }

    showAll(moe: Moe)
    {
        if (!this.monkey.isSmallDevice)
        {
            this.clearDrillDown();

            this.showBreweries(moe, false);
            this.showBeers(moe, false);
            this.showRatings(moe, false);
        }
    }

    showBreweries(moe: Moe, clear = true)
    {
        if (clear)
        {
            this.clearDrillDown();
        }

        if (moe.breweryFilter)
        {
            this.breweryCardTitle = moe.category + ' Breweries';
            this.breweries = this.allBreweries.filter(moe.breweryFilter);
        }
    }

    showBeers(moe: Moe, clear = true)
    {
        if (clear)
        {
            this.clearDrillDown();
        }

        if (moe.beerFilter)
        {
            this.beerCardTitle = moe.category + ' Beers';
            this.beers = this.allBeers.filter(moe.beerFilter);
        }
    }

    showRatings(moe: Moe, clear = true)
    {
        if (clear)
        {
            this.clearDrillDown();
        }

        if (moe.ratingFilter)
        {
            this.ratingCardTitle = moe.category + ' Ratings';
            this.ratings = this.allRatings.filter(moe.ratingFilter);
        }
    }

    //-----------------------------------------------------------------------

}
