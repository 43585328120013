<ion-list>
  <ion-item-sliding *ngFor="let beer of beerList">

  <ion-item [ngStyle]="{'--ion-background-color': beer.rateBeerOnly ? 'var(--ion-color-light-shade)' : 'inherit' }"
    (click)="beerDetail(beer.beer_id, $event)">
    <ion-thumbnail style="height: 4em;" slot="start">
      <img [bonesGalleryDocumentID]="beerImage(beer)" [bonesGalleryDocumentSize]="gallery.imageSizeThumbnail" loading="lazy" *ngIf="beerImage(beer)">
    </ion-thumbnail>

    <ion-label>
      <h2 class="ion-text-wrap" *ngIf="showBeerName">
        <ion-text color="secondary">{{ beer.name }}</ion-text>
      </h2>

      <h3 *ngIf="showBreweryName && beer.brewery">
        <ion-text color="secondary" (click)="breweryDetail(beer.brewery.brewery_id, $event)">{{ beer.brewery.name }}</ion-text>
      </h3>

      <div>{{ beer.row.style }} <span *ngIf="beer.row.abv"> @ {{ beer.row.abv }}%</span></div>

      <p class="two-line-notes" *ngIf="beer.row.notes">{{ beer.row.notes }}</p>

      <!-- <p class="ion-text-wrap" *ngIf="beer.rateBeerOnly">Unrated beer from RateBeer</p> -->
    </ion-label>

    <ion-text class="ion-text-right" slot="end">
      <div *ngIf="beer.row.ratebeer_score">
        <rating-stars [score]="beer.row.ratebeer_score" [fractional]="true"></rating-stars>
        <div>{{ beer.row.ratebeer_score | number:'0.1-1' }}</div>
      </div>
      <div *ngIf="beer.row.ratebeer_id">
        <img style="height: 1.25em;" src="/assets/icon/rate-beer-logo.png"
             (click)="rateBeerDB.openBeer(beer.row.ratebeer_id, $event)">
      </div>
      <!-- <div *ngIf="beer.row.abv > 0">{{ beer.row.abv }}%</div> -->
    </ion-text>
  </ion-item>

    <ion-item-options>
      <ion-item-option (click)="breweryDetail(beer.brewery.brewery_id, $event)" *ngIf="showBreweryName && beer.brewery">
        <ion-icon slot="top" size="large" name="flask"></ion-icon>
        Brewery
      </ion-item-option>
      <ion-item-option (click)="beerDetail(beer.beer_id, $event)" *ngIf="showBreweryName">
        <ion-icon slot="top" size="large" name="beer"></ion-icon>
        Beer
      </ion-item-option>
      <ion-item-option (click)="beerDetail(beer.beer_id, $event)" *ngIf="!showBreweryName">
        <ion-icon slot="top" size="large" name="eye"></ion-icon>
        Detail
      </ion-item-option>
      <ion-item-option (click)="rateBeerDB.openBeer(beer.row.ratebeer_id, $event)" *ngIf="beer.row.ratebeer_id">
        <img style="height: 2em;" slot="top" src="/assets/icon/rate-beer-logo.png">
        RB
      </ion-item-option>
      <ion-item-option (click)="launch.editBeer(beer)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="pencil"></ion-icon>
        Edit
      </ion-item-option>
      <ion-item-option (click)="launch.addRating(beer.beer_id)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="add-circle"></ion-icon>
        Rating
      </ion-item-option>
      <ion-item-option (click)="launch.addInventory(beer)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="add-circle"></ion-icon>
        Inv
      </ion-item-option>
    </ion-item-options>

  </ion-item-sliding>
</ion-list>
