import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { BonesGalleryService, BonesGalleryDocument, BonesGalleryDocumentFilter } from '@bones/gallery';

import { AppUser, UserService, MonkeyService } from '@BeerMonkey/core';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';
import { Glassware } from '@BeerMonkey/rate/class/Glassware';
import { GlasswareService } from '@BeerMonkey/rate/service/GlasswareService';

@Component({
    selector: 'glassware-detail',
    templateUrl: 'glassware-detail.html',
    styleUrls: [ 'glassware-detail.scss' ]
})
export class GlasswareDetailPage implements OnInit, OnDestroy
{
    user: AppUser;
    glasswareID: number;
    glassware?: Glassware;
    documents: BonesGalleryDocument[] = [ ];
    featuredDocuments: BonesGalleryDocument[] = [ ];
    private nal: (() => void)[] = [ ];
    cardMenu: BonesMenuCardAction[] = [ ];

    constructor(
        private route: ActivatedRoute,
        private es: BonesErrorService,
        private gallery: BonesGalleryService,
        public monkey: MonkeyService,
        private mtus: UserService,
        private launch: LaunchEditModalService,
        private glasswareDB: GlasswareService
    )
    {
        this.glasswareID = +(this.route.snapshot.paramMap.get('glasswareID') ?? 0);

        this.user = this.mtus.getUser();

        if (this.user.isAdmin)
        {
            this.cardMenu.push(this.launch.getMenuItemEdit(() => this.launch.editGlassware(this.glassware?.row)))
            this.cardMenu.push(this.gallery.getMenuItemAddDocument('glassware_id', this.glasswareID, () => this.glassware?.row.glass));
        }
    }

    async ngOnInit()
    {
        this.glasswareDB.getGlassware(this.glasswareID)
        .then(async glassware => this.glassware = glassware)
        .catch(error => this.es.errorHandler(error));

        // Load and refresh documents as needed
        this.nal.push(this.gallery.cache.nowAndLater(
        rows =>
        {
            this.documents = new BonesGalleryDocumentFilter(rows).by('glassware_id', this.glasswareID).rows;
            this.featuredDocuments = this.documents.filter(d => d.isFeatured);
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

}
