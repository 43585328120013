import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'bones-tt-modal-header',
    templateUrl: 'bones-tt-modal-header.html'
})
export class BonesTtModalHeaderComponent implements OnInit
{
    @Input() title: string;

    constructor(
        private modal: ModalController
    )
    {
    }

    async ngOnInit()
    {
    }

    /**
     * close modal
     */
    async close()
    {
        this.modal.dismiss();
    }

}
