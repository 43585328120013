import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IonInput } from '@ionic/angular';

import { BonesFormItem } from '../../class/BonesFormItem';

/**
 * Form textarea.
 * 
 * @example
 * <bones-form-item-file [form]="form" [item]="item"></bones-form-item-file>
 */
@Component({
    selector: 'bones-form-item-file',
    templateUrl: 'form-item-file.html'
})
export class BonesFormItemFileComponent
{
    /**
     * Form containing field.
     */
    @Input() form: UntypedFormGroup;

    /**
     * Object with details of form field to create.
     */
    @Input() item: BonesFormItem;

    /**
     * Real file input element that is hidden from view
     */
    @ViewChild('fileInput') fileInput: ElementRef;

    /**
     * Ionic input element that displays the name of the selected file
     */
    @ViewChild('textInput') textInput: IonInput;

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

    /**
     * Simulate that the <input> element was clicked to trigger file open dialog
     */
    trigger()
    {
        console.log('trigger', this.fileInput);
        const event = new MouseEvent('click', {bubbles: false});
        this.fileInput.nativeElement.dispatchEvent(event);
    }

    /**
     * File was selected via browser dialog
     */
    onFileSelect(event)
    {
        if (event.target.files.length > 0)
        {
            const file = event.target.files[0];
            console.log('onFileSelect', file);
            console.log('onFileSelect', this.textInput);
            this.textInput.value = file.name;
            this.form.get(this.item.name).setValue(file);
        }
    }

}
