import { Component } from '@angular/core';

import { BonesNetworkLogEntry } from '../../class/BonesNetworkLogEntry';
import { BonesNetworkLogService } from '../../service/network-log';

@Component({
  templateUrl: 'test-tools-network-log.html'
})
export class BonesTestToolsNetworkLogPage
{
    log: BonesNetworkLogEntry[];

    constructor(
        private apiLog: BonesNetworkLogService,
    )
    {
        this.log = this.apiLog.getLog();
    }

}
