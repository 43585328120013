import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BonesNetworkLogEntry } from '../../class/BonesNetworkLogEntry';
import { BonesNetworkLogService } from '../../service/network-log';

@Component({
  templateUrl: 'test-tools-network-log-detail.html'
})
export class BonesTestToolsNetworkLogDetailPage
{
    entry: BonesNetworkLogEntry;

    constructor(
        private route: ActivatedRoute,
        private apiLog: BonesNetworkLogService
    )
    {
        // Get entry passed from log page
        this.entry = this.apiLog.getLogEntry(+this.route.snapshot.params.id);
    }

}
