import { UntypedFormBuilder, UntypedFormGroup, FormControl } from '@angular/forms';
import { LoadingController, AlertController, ModalController, NavParams } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';

import { BonesErrorService, BonesError } from '@bones/core';
import { BonesRestInterface } from '@bones/network';
import { BonesForm, BonesFormItem } from '@bones/form';

import { BonesEditFormOptions } from './BonesEditFormOptions';
import { BonesEditFormLaunchOptions } from './BonesEditFormLaunchOptions';
import { BonesEditFormLaunchResults } from './BonesEditFormLaunchResults';

/**
 * Base class for creating an edit form.
 * 
 * The form integrates with the server-side StdWebGridEditor class to perform database updates.
 * 
 * RowClass is a class/interface that describes the DB schema row and matches the items
 * defined in the BonesEditFormOptions passed to the initialize() method.
 * 
 * See the sample-parent/sample-client app or test-tools-bones-edit-form for examples
 * of creating edit forms.
 */
export abstract class BonesEditForm<RowClass>
{
    /**
     * Service provider defined in derived class constructor.
     */
    protected abstract formBuilder: UntypedFormBuilder;
    /**
     * Service provider defined in derived class constructor.
     */
    protected abstract loadingCtrl: LoadingController;
    /**
     * Service provider defined in derived class constructor.
     */
    protected abstract alertCtrl: AlertController;
    /**
     * Service provider defined in derived class constructor.
     */
    protected abstract modalCtrl: ModalController;
    /**
     * Service provider defined in derived class constructor.
     */
    protected abstract navParams: NavParams;
    /**
     * Service provider defined in derived class constructor.
     */
    protected abstract bes: BonesErrorService;
    /**
     * Service provider defined in derived class constructor.
     */
    protected abstract rest: BonesRestInterface;

    /**
     * Options used to launch edit page.
     */
    protected launchOptions: BonesEditFormLaunchOptions;

    /**
     * Options passed to initialize() method.
     */
    public options: BonesEditFormOptions;

    /**
     * BonesForm object.
     */
    public bonesForm: BonesForm;

    /**
     * Angular FormGroup created for edit page.
     */
    public form: UntypedFormGroup;

    /**
     * Array of items created from BonesFormItem options.
     */
    public items: BonesFormItem[] = [ ];

    /**
     * Primary key of row being edited. Will be 0 when adding a new row.
     */
    protected pk: number;

    /**
     * Is a new row being added (as opposed to editing an existing)
     */
    public isAdd: boolean;

    /**
     * Results passed back to function calling open() method.
     */
    public results: BonesEditFormLaunchResults;

    /**
     * @ignore
     */
    constructor()
    {

    }

    //-----------------------------------------------------------------------

    /**
     * Launch edit screen.
     * 
     * @param options Launch options.
     * 
     * @returns Promise that resolves when the screen is dismissed.
     * @deprecated Use open() method instead.
     */
    static launch(launchOptions: BonesEditFormLaunchOptions) : Promise<OverlayEventDetail>;

    /**
     * Launch edit screen.
     * 
     * @param modalCtrl Ionic ModalController (sorry).
     * @param editPage The Ionic page containing the edit component derived from BonesFormEdit.
     * @param pk primary key of row to edit or undefined to add new row.
     * 
     * @returns Promise that resolves when the screen is dismissed.
     * @deprecated Use open() method instead.
     */
    static launch(modalCtrl: ModalController, editPage: any, pk?: number) : Promise<OverlayEventDetail>;

    /**
     * Launch edit screen.
     * 
     * @param modalCtrl Ionic ModalController (sorry).
     * @param editPage The Ionic page containing the edit component derived from BonesFormEdit.
     * @param pk primary key of row to edit or undefined to add new row.
     * 
     * @returns Promise that resolves when the screen is dismissed.
     * @deprecated Use open() method instead.
     */
    static launch(arg1: ModalController | BonesEditFormLaunchOptions, editPage?: any, pk?: number) : Promise<OverlayEventDetail>
    {
        // Resolve overloaded method into options
        let launchOptions: BonesEditFormLaunchOptions;
        if ('modalCtrl' in arg1)
        {
            // Options was passed
            launchOptions = arg1;
        }
        else
        {
            // Build options based upon full arguments
            launchOptions =
            {
                modalCtrl: arg1 as ModalController,
                editPage: editPage,
                pk: pk
            };
        }

        // This is very ugly code written in bad style all because you can't use a lambda in
        // a static function. You used to be able to. What happened?
        let resolve = null;

        // This function called after modal is created
        const f2 = function(modal: HTMLIonModalElement)
        {
            // Action when edit screen exits
            // modal.onDidDismiss().then(resolve);
            modal.onDidDismiss().then((oed) =>
            {
                const results: BonesEditFormLaunchResults = oed.data;
                oed.data = results.row;
                return oed;
            });

            // Show edit screen
            modal.present();
        };

        // This function called when promise is first created
        const f1 = function(r)
        {
            resolve = r;

            // Create edit screen as a modal
            // Arguments for edit screen based on having a row (edit) or not (add)
            launchOptions.modalCtrl.create(
            {
                component: launchOptions.editPage,
                componentProps: { launchOptions }
            })
            .then(f2);
        };

        return new Promise(f1);
    }

    /**
     * Open edit screen.
     * 
     * @param options Launch options.
     * 
     * @returns Launch results object. Promise resolves when the screen is dismissed.
     */
    static async open(launchOptions: BonesEditFormLaunchOptions) : Promise<BonesEditFormLaunchResults>
    {
        const modal = await launchOptions.modalCtrl.create(
        {
            component: launchOptions.editPage,
            componentProps: { launchOptions },
            cssClass: launchOptions.cssClass
        });

        // Show edit screen
        modal.present();

        return modal.onDidDismiss().then((oed) =>
        {
            // Get results object and blank it out of the oed object to avoid object loops
            const results: BonesEditFormLaunchResults = oed.data;
            results.overlayEventDetail = oed;
            oed.data = undefined;

            return results;
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Initialize form editor.
     * 
     * @param options Options to describe table and form.
     */
    protected async initialize(options: BonesEditFormOptions)
    {
        this.options = options;
        this.launchOptions = this.navParams.get('launchOptions');
        this.pk = this.launchOptions.pk || 0;
        this.isAdd = (this.pk === 0);

        // // Default to allow delete button
        // if (!('allowDelete' in options))
        // {
        //     this.options.allowDelete = true;
        // }

        // Populate results object
        this.results = new BonesEditFormLaunchResults();
        this.results.launchOptions = this.launchOptions;

        // Create form from column options
        this.bonesForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns: this.options.columns
        });
        this.form = this.bonesForm.form;
        this.items = this.bonesForm.items;

        // Edit an existing row
        if (!this.isAdd)
        {
            // Display loading message
            const loading = await this.loadingCtrl.create({});
            loading.present();

            // Get values for row to be edited
            let p1: Promise<RowClass>;
            if (this.options.cache && !this.options.getEditRow)
            {
                // Default to getting the row to be edited from the cache
                p1 = this.options.cache.getRestEntry(this.pk);
            }
            else
            {
                // Call application function to get row to be edited
                p1 = this.options.getEditRow();
            }

            // Get row and populate form
            p1.then((row: RowClass) =>
            {
                loading.dismiss();
                this.setFormValues(row);
            })
            .catch(error =>
            {
                loading.dismiss();
                this.bes.errorHandler(error);
            });
        }
        // Pre add hook
        else if (this.options.preAdd)
        {
            const row = { } as RowClass;
            this.options.preAdd(row);
            this.setFormValues(row);
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Populate edit form with database row.
     * 
     * @param row db row
     */
    private setFormValues(row: RowClass)
    {
        this.items.forEach(item =>
        {
            this.bonesForm.setValue(item.name, row[item.name]);
        });
    }

    /**
     * Cancel edit screen (called from HTML page).
     */
    cancel()
    {
        // Return results back to open() method
        this.results.action = 'cancel';
        this.modalCtrl.dismiss(this.results);
    }

    /**
     * Save changes (called from HTML page).
     */
    async save()
    {
        // Ignore save if the form is not valid.
        if (this.form.invalid)
        {
            // Mark all form fields as dirty to trigger error styling and messages
            this.bonesForm.markDirty();
            return;
        }

        // Get updated row
        const updatedRow = this.createRowFromForm();

        // Pre save hook
        if (this.options.preSave)
        {
            const error = this.options.preSave(updatedRow);
            if (error)
            {
                this.bes.errorHandler(new BonesError(
                {
                    className: 'BonesFormEdit',
                    methodName: 'save.preSave',
                    message: error
                }));

                return;
            }
        }

        // Display loading message
        const loading = await this.loadingCtrl.create({});
        loading.present();

        // Save changes to server
        this.rest.send(this.options.saveUrl, this.generateUpload('update', updatedRow))
        .then(async (payload) =>
        {
            // Post save hook
            if (this.options.postSave)
            {
                const error = await this.options.postSave(updatedRow, payload);
                if (error)
                {
                    throw new BonesError(
                    {
                        className: 'BonesFormEdit',
                        methodName: 'save.postSave',
                        message: error
                    });
                }
            }

            // Update cache
            if (this.options.cache)
            {
                let newID: number;
                if (this.isAdd)
                {
                    if (this.options.cache.options.getPkFromBgePayload)
                    {
                        // Get pk of new row from app supplied function
                        newID = this.options.cache.options.getPkFromBgePayload(payload);
                    }
                    else
                    {
                        // Default to using 'id' property for pk of new row
                        newID = payload.id;
                    }
                }
                else
                {
                    // Updated rows keep the same pk
                    newID = this.pk;
                }

                // Update cache
                // console.log('BonesEditForm: cache.updated', newID, payload);
                await this.options.cache.updated(newID);
            }

            // Return results back to open() method
            this.results.row = updatedRow;
            this.results.payload = payload;
            this.results.action = this.isAdd ? 'add' : 'update';
            loading.dismiss();
            this.modalCtrl.dismiss(this.results);
        })
        .catch(error =>
        {
            loading.dismiss();
            this.bes.errorHandler(error);
        });
    }

    /**
     * Delete row (called from HTML page).
     */
    async delete()
    {
        const alert = await this.alertCtrl.create(
        {
            header: 'Confirmation',
            message: 'Do you really want to delete this row?',
            buttons:
            [
                {
                    text: 'Cancel',
                    // role: 'cancel'
                },
                {
                    text: 'Delete',
                    handler: async () =>
                    {
                        // Display loading message
                        const loading = await this.loadingCtrl.create({});
                        loading.present();

                        // Save changes to server
                        this.rest.send(this.options.saveUrl, this.generateUpload('delete'))
                        .then((payload) =>
                        {
                            // Update cache
                            if (this.options.cache)
                            {
                                this.options.cache.deleted(this.pk);
                            }

                            // Return results back to open() method
                            this.results.row = null;
                            this.results.payload = payload;
                            this.results.action = 'delete';
                            loading.dismiss();
                            this.modalCtrl.dismiss(this.results);
                        })
                        .catch(error =>
                        {
                            loading.dismiss();
                            this.bes.errorHandler(error);
                        });
                    }
                }
            ]
        });
        alert.present();
    }

    /**
     * Generate payload to send to server to perform update
     */
    private generateUpload(action: 'update' | 'delete', updatedRow = this.createRowFromForm()) : FormData | any
    {
        if (this.options.uploadFormat === 'form')
        {
            // Save payload as form encoded
            const savePayload = this.bonesForm.getFormData();
            savePayload.append('bge:action', action);
            return savePayload;
        }
        else
        {
            // Save payload as json
            const savePayload =
            {
                'bge:action': action,
                action: action,
                row: updatedRow
            };

            return savePayload;
        }
    }

    /**
     * Build new row from form values
     */
    private createRowFromForm() : RowClass
    {
        const row = { } as RowClass;

        // Populate row properties from form fields
        this.items.forEach(item =>
        {
            const value = this.bonesForm.getValue(item.name);
            if (value)
            {
                row[item.name] = value;
            }
        });

        return row;
    }
}
