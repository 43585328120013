import { BeerInfo } from './BeerInfo';
import { Brewery } from './Brewery';

export class Beer
{
    beer_id: number;
    brewery?: Brewery;

    constructor(public row: BeerInfo)
    {
        this.beer_id = row.beer_id;
    }

    // Needed to make edit modal picker happy
    public get name() : string
    {
        return this.row.name;
    }

    /**
     * Is this a beer from the local DB?
     */
    public get inDB() : boolean
    {
        return !!this.beer_id;
    }

    /**
     * Has this beer been linked to RateBeer?
     */
    public get linkedToRateBeer() : boolean
    {
        return (this.row.ratebeer_id ?? 0) > 0;
    }

    /**
     * RateBeer-only entry?
     */
    public get rateBeerOnly() : boolean
    {
        return !this.beer_id && this.linkedToRateBeer;
    }

}
