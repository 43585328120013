<ion-card class="ion-padding">
  <bones-card-toolbar #bttb [title]="title" [actions]="cardMenu" [expandable]="expandable"></bones-card-toolbar>
  <ion-card-content class="ion-no-padding" *ngIf="bttb.expanded">

    <p *ngIf="!data?.rows && !loading">No data available</p>
    <ion-spinner *ngIf="loading"></ion-spinner>

    <!-- Header -->
    <div class="header" *ngIf="data">

      <div>
        <div class="pagesize" *ngIf="pageSizes">
          <ion-label>Page Size:</ion-label>
          <select (change)="setPageSize(+$event.target.value)">
            <option [selected]="value === pageSize" *ngFor="let value of pageSizes">{{ value }}</option>
          </select>
        </div>
        <div class="sod" *ngIf="sortOrderDescription">{{ sortOrderDescription }}</div>
      </div>

      <div class="search" *ngIf="allowSearch">
        <ion-searchbar [(ngModel)]="keyword" (ionChange)="setKeyword($event.target.value)" [debounce]="750"></ion-searchbar>
      </div>

    </div>

    <!-- Table -->
    <table class="table" *ngIf="data?.rows?.length > 0">
      <ng-content></ng-content>
    </table>

    <ion-text color="danger" *ngIf="data?.rows?.length === 0">No matches</ion-text>

    <!-- Footer -->
    <div class="ion-padding-top footer" *ngIf="showFooter && data?.rows?.length > 0">

      <div>
        <div class="rowcount">
          <span>Rows {{ data.meta.startingRowNumber }} - {{ data.meta.endingRowNumber }}</span>
          <span *ngIf="data.state.totalRowcount && !data.state.filteredRowcount"> of {{ data.state.totalRowcount }}</span>
          <span *ngIf="data.state.filteredRowcount"> of {{ data.state.filteredRowcount }}</span>
          <span *ngIf="data.state.totalRowcount && data.meta.isFiltered">
            (filtered from {{ data.state.totalRowcount }})
          </span>
        </div>
      </div>

      <div class="pager">
        <ion-button size="small" fill="clear" slot="icon-only" [disabled]="data.meta.isFirstPage" (click)="firstPage()">
          <ion-icon name="play-skip-back"></ion-icon>
        </ion-button>
        <ion-button size="small" fill="clear" slot="icon-only" [disabled]="data.meta.isFirstPage" (click)="prevPage()">
          <ion-icon name="play-back"></ion-icon>
        </ion-button>
        <span class="label">
            <span>Page {{ data.meta.pageNumber }}</span>
            <span *ngIf="data.state.totalPagecount && !data.state.filteredPagecount"> of {{ data.state.totalPagecount }}</span>
            <span *ngIf="data.state.filteredPagecount"> of {{ data.state.filteredPagecount }}</span>
            <span *ngIf="data.state.totalPagecount && data.meta.isFiltered">
              (of {{ data.state.totalPagecount }})
            </span>
        </span>
        <ion-button size="small" fill="clear" slot="icon-only" [disabled]="data.meta.isLastPage" (click)="nextPage()">
          <ion-icon name="play-forward"></ion-icon>
        </ion-button>
        <ion-button size="small" fill="clear" slot="icon-only" [disabled]="data.meta.isLastPage" (click)="lastPage()">
          <ion-icon name="play-skip-forward"></ion-icon>
        </ion-button>
      </div>

    </div>

    <div *ngIf="errors?.length > 0">
      <div *ngFor="let error of errors"><ion-text color="danger">{{ error }}</ion-text></div>
    </div>
  </ion-card-content>
</ion-card>
