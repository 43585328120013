import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { ApeRest } from '@BeerMonkey/core';
import { IdeaService } from '@BeerMonkey/rate/service/IdeaService';
import { IdeaInfo } from '@BeerMonkey/rate/class/IdeaInfo';

@Component({
    selector: 'idea-edit',
    templateUrl: 'idea-edit.html'
})
export class IdeaEditModal extends BonesEditForm<IdeaInfo> implements OnInit
{
    constructor(
        protected formBuilder: FormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: ApeRest,
        private ideaDB: IdeaService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'idea_id',
                    hidden: true
                },
                {
                    name: 'idea',
                    hideTitle: true,
                    textarea: 36
                }
            ],
            disableDelete: true,
            saveUrl: '/rate/idea/update/updateIdea',
            cache: this.ideaDB.cache
        });
    }
}
