<ion-header><bones-tt-page-header title="Test Cases"></bones-tt-page-header></ion-header>

<ion-content>

  <ion-list>
    <ion-item (click)="set(testCase)" *ngFor="let testCase of testCases">
      <ion-label>
        <h2>{{ testCase.title }}</h2>
        <p class="ion-text-wrap">{{ testCase.description }}</p>
      </ion-label>
    </ion-item>
  </ion-list>

</ion-content>
