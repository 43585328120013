import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BonesErrorService } from '@bones/core';
import { BonesForm } from '@bones/form';

import { InventoryService, InventoryFilter } from '@BeerMonkey/rate/service/InventoryService';
import { Inventory } from '@BeerMonkey/rate/class/Inventory';
import { MonkeyService } from '@BeerMonkey/core';
import { RatingService } from '@BeerMonkey/rate/service/RatingService';

@Component({
    selector: 'inventory-page',
    templateUrl: 'inventory-page.html'
})
export class InventoryPage implements OnInit, OnDestroy
{
    bonesForm: BonesForm;
    allInventory?: Inventory[];
    filteredInventory?: Inventory[];
    showFilters: boolean;
    private nal: (() => void)[] = [ ];

    constructor(
        private formBuilder: FormBuilder,
        private es: BonesErrorService,
        public monkey: MonkeyService,
        private ratingDB: RatingService,
        private inventoryDB: InventoryService
    )
    {
        this.showFilters = this.monkey.isLargeDevice;

        // Create filter form
        this.bonesForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'brewery_id',
                    title: 'Brewery',
                    picker: () => this.inventoryDB.getBreweryPicker(),
                    onChange: () => this.filter()
                },
                {
                    name: 'location',
                    title: 'Location',
                    picker: () => this.inventoryDB.getPicker('location'),
                    onChange: () => this.filter()
                },
                {
                    name: 'vintage',
                    title: 'Vintage',
                    picker: () => this.inventoryDB.getPicker('vintage'),
                    onChange: () => this.filter()
                },
                {
                    name: 'version',
                    title: 'Version',
                    picker: () => this.inventoryDB.getPicker('version'),
                    onChange: () => this.filter()
                },
                {
                    name: 'size',
                    title: 'Size',
                    picker: () => this.inventoryDB.getPicker('size'),
                    onChange: () => this.filter()
                },
                {
                    name: 'zeros',
                    title: 'Show Zeros',
                    type: 'toggle',
                    onChange: () => this.filter()
                },
                {
                    name: 'nobeer',
                    title: 'Omit Linked to a Beer',
                    type: 'toggle',
                    onChange: () => this.filter()
                },
                {
                    name: 'unrated',
                    title: 'Omit rated beers',
                    type: 'toggle',
                    onChange: () => this.filter()
                }
            ]
        });
    }

    async ngOnInit()
    {
        // Load and refresh ratings as needed
        this.nal.push(this.inventoryDB.cache.nowAndLater(
        rows =>
        {
            this.allInventory = rows;
            this.filter();
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    async filter()
    {
        if (this.allInventory && this.bonesForm)
        {
            let firstPass = new InventoryFilter(this.allInventory)
                .byBrewery(this.bonesForm.getValue('brewery_id'))
                .by('location', this.bonesForm.getValue('location'))
                .by('vintage', this.bonesForm.getValue('vintage'))
                .by('version', this.bonesForm.getValue('version'))
                .by('size', this.bonesForm.getValue('size'))
                // .byZeros(this.bonesForm.getValue('zeros'))
                .rows;

            if (this.bonesForm.getValue('nobeer'))
            {
                firstPass = firstPass.filter(i => !i.beer);
            }

            if (this.bonesForm.getValue('unrated'))
            {
                firstPass = await this.unrated(firstPass);
            }

            this.filteredInventory = firstPass;
        }
    }

    set showZeros(value: boolean)
    {
        this.bonesForm.setValue('zeros', value);
    }

    get showZeros() : boolean
    {
        return this.bonesForm.getValue('zeros');
    }

    /**
     * Filter out inventory items that have already been rated
     */
    async unrated(filtered: Inventory[]) : Promise<Inventory[]>
    {
        const results: Inventory[] = [ ];

        for (let i = 0; (i < filtered.length); ++i)
        {
            const beer = filtered[i].beer;
            if (beer)
            {
                if (!(await this.ratingDB.fetchRatingsForBeer(beer)))
                {
                    results.push(filtered[i]);
                }
            }
        }

        return results;
    }

}
