<ion-header><app-page-header subtitle="Inventory"></app-page-header></ion-header>

<ion-content>
  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="filters"></ng-container>
    <ng-container *ngTemplateOutlet="inventory"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">

    <ion-row>
      <ion-col size="5">
        <ng-container *ngTemplateOutlet="filters"></ng-container>
      </ion-col>

      <ion-col size="7">
        <ng-container *ngTemplateOutlet="inventory"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<ng-template #filters>
  <ion-card class="ion-padding" *ngIf="bonesForm">
    <ion-card-title>
      <ion-icon name="caret-forward" color="primary" (click)="showFilters = true" *ngIf="!showFilters"></ion-icon>
      <ion-icon name="caret-down" color="primary" (click)="showFilters = false" *ngIf="showFilters"></ion-icon>
      <ion-text> Filters</ion-text>
    </ion-card-title>

    <ion-card-content class="ion-no-padding" *ngIf="showFilters">
      <bones-form-items [form]="bonesForm.form" [items]="bonesForm.items"></bones-form-items>
      <div class="ion-text-right">
        <ion-button (click)="bonesForm.resetValues()">Clear</ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ng-template>

<ng-template #inventory>
  <inventory-list-card title="Inventory" [inventory]="filteredInventory"
                       [showSearchbar]="true" [(showZeros)]="showZeros" *ngIf="filteredInventory">
  </inventory-list-card>
</ng-template>
