import { Component, Input, ElementRef, Renderer2, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';

import { BonesMenuCardAction } from '../../model/bones-menu-card-action';

/**
 * Display toolbar with menu at the top of an ion-card.
 * 
 * Actions is a list of BonesMenuCardAction objects.
 * 
 * @example
 * <bones-card-toolbar #bct title="Card Title" [actions]="actions" [expandable]="true"></bones-card-toolbar>
 * <ion-card-content class="ion-no-padding" *ngIf="bct.expanded">
 * 
 * @example
 * <!-- Give client component local control and visibility over expanded state -->
 * <bones-card-toolbar title="Card Title" [actions]="actions" [expandable]="true" [(expanded)]="myFlag"></bones-card-toolbar>
 * <ion-card-content class="ion-no-padding" *ngIf="myFlag">
 */
@Component({
  selector: 'bones-card-toolbar',
  templateUrl: 'bones-card-toolbar.html',
  styleUrls: [ 'bones-card-toolbar.scss' ]
})
export class BonesCardToolbarComponent implements OnInit
{
    /**
     * Card title to display.
     */
    @Input() title?: string;

    /**
     * Card subtitle to display.
     */
    @Input() subtitle?: string;

    /**
     * Card note to display.
     */
    @Input() note?: string;

    /**
     * Should this card be expandable to show/hide the card contents?
     */
    @Input() expandable = false;

    /**
     * Has this card been expanded?
     * It is up to the user of this component to actually show/hide the card contents using this flag.
     */
    @Input() set expanded(expanded: boolean)
    {
        this._expanded = expanded;
        this.expandedChange.emit(this._expanded);
    }
    /**
     * Get current state of expanded
     */
    get expanded() : boolean
    {
        return this._expanded;
    }
    /**
     * Get notification when expanded has been changed - to use/update client component flag with [(expanded)]="myFlag"
     */
    @Output() expandedChange = new EventEmitter<boolean>();
    private _expanded = true;

    /**
     * Array of BonesMenuCardAction objects.
     */
    @Input() actions?: BonesMenuCardAction[];

    /**
     * Menu element
     */
    @ViewChild('mm') menuChild: ElementRef;

    /**
     * Parent card element
     */
    cardElementRef?: any;

    /**
     * Is the menu currently visible?
     */
    menuVisible = false;

    /**
     * @ignore
     */
    constructor(
        private ellie: ElementRef,
        private renderer: Renderer2,
        private navCtrl: NavController
    )
    {
    }
    // this.renderer.setAttribute(this.ellie.nativeElement, 'src', url)

    /**
     * @ignore
     */
    async ngOnInit()
    {
        this.cardElementRef = this.ellie.nativeElement.parentElement;
    }

    /**
     * Execute menu item.
     * 
     * @param action Action to execute.
     */
    do(action: BonesMenuCardAction)
    {
        if (typeof action.action === 'string')
        {
            this.navCtrl.navigateForward(action.action);
        }
        else if (action.action)
        {
            action.action(action.args);
        }
    }

    /**
     * Toggle menu between hidden and visible
     */
    toggleMenu()
    {
        if (this.menuVisible)
        {
            this.hideMenu();
        }
        else
        {
            this.showMenu();
        }
    }

    // Function to hide menu preserving object context
    private callHideMenu = () => this.hideMenu();

    /**
     * Display menu
     */
    showMenu()
    {
        this.menuVisible = true;

        // Defer until menu is visible
        setTimeout(() =>
        {
            const cardHeight: number = this.cardElementRef.clientHeight;
            const menuHeight: number = this.menuChild.nativeElement.clientHeight;

            // Card is too short to show entire menu
            if (menuHeight > cardHeight - 100)
            {
                this.renderer.setStyle(this.cardElementRef, 'height', (menuHeight + 100) + 'px');
            }

            // Create a listener to hide the menu whenever the user clicks anywhere on the screen
            document.addEventListener('click', this.callHideMenu);
        });
    }

    /**
     * Hide menu
     */
    hideMenu()
    {
        this.menuVisible = false;
        this.renderer.removeStyle(this.cardElementRef, 'height');
        document.removeEventListener('click', this.callHideMenu);
    }

}
