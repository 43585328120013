<ion-searchbar placeholder="Search glassware" [(ngModel)]="searchTerm" [debounce]="500" *ngIf="showSearchbar"></ion-searchbar>

<ion-card class="ion-padding" *ngIf="show">
  <bones-card-toolbar [title]="title" [note]="showingText()" [actions]="cardMenu" [expandable]="true" [(expanded)]="showGlassware">
  </bones-card-toolbar>

  <ion-card-content class="ion-no-padding" *ngIf="showGlassware">
    <ion-item-group *ngFor="let group of groups">
      <ion-item-divider>
        <ion-label>{{ group.by }}</ion-label>
      </ion-item-divider>
      <glassware-list [glassware]="group.items" [showBreweryName]="showBreweryName"></glassware-list>
    </ion-item-group>

    <div class="ion-text-right">
      <ion-button (click)="showMore()" *ngIf="truncated">More</ion-button>
    </div>

  </ion-card-content>
</ion-card>
