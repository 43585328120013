<ion-toolbar>
  <ion-buttons slot="start">
    <ion-back-button></ion-back-button>
    <!-- <ion-menu-button></ion-menu-button> -->
  </ion-buttons>
  <ion-title *ngIf="title">
    <div>{{ title }}</div>
    <div class="subtitle" *ngIf="subtitle">{{ subtitle }}</div>
    <div class="subtitle" *ngIf="!subtitle">Bones Test Tools</div>
  </ion-title>
  <ion-title *ngIf="!title">Bones Test Tools<span class="subtitle" *ngIf="subtitle"> - {{ subtitle }}</span></ion-title>
  <div slot="end">
    <ion-button size="small" fill="clear" color="light" (click)="gohome()">
      <ion-icon name="home"></ion-icon>
    </ion-button>
  </div>
</ion-toolbar>
