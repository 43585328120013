import { Injectable, EventEmitter } from '@angular/core';

import { BonesError } from '@bones/core';

import { AppUser } from '../class/AppUser';
import { ApeRest } from './ApeRest';

/**
 * Access eco user information
 */
@Injectable({
  providedIn: 'root',
})
export class UserService
{
    private user: AppUser = new AppUser();
    public userChange = new EventEmitter<AppUser>();

    constructor(
        private rest: ApeRest
    )
    {
        this.userChange.emit(this.user);
    }

    //-----------------------------------------------------------------------

    /**
     * Get user object
     */
    getUser() : AppUser
    {
        return this.user;
    }

    //-----------------------------------------------------------------------

    /**
     * Authenticate user
     */
    async authenticateUser(username: string, password: string) : Promise<AppUser>
    {
        // Set user credentials
        this.rest.username = username;
        this.rest.password = password;

        return this.rest.send('core/user/getUser')
        .then(async info =>
        {
            // Update user
            this.user.authenticated = true;
            this.user.info = info;
            this.userChange.emit(this.user);
            return this.user;
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'BreweryService',
                methodName: 'get',
                message: 'login failed',
                error: error
            });
        });
    }

    //-----------------------------------------------------------------------

}
