import { Component, Input } from '@angular/core';

import { BonesEditForm } from '../../class/BonesEditForm';

/**
 * Display button bar with edit controls.
 * 
 * @example
 * <bones-edit-button-bar [editor]="this"></bones-edit-button-bar>
 */
@Component({
  selector: 'bones-edit-button-bar',
  templateUrl: 'bones-edit-button-bar.html'
})
export class BonesEditButtonBarComponent
{
    /**
     * Edit form page component object
     */
    @Input() editor: BonesEditForm<any>;

    /**
     * @ignore
     */
    constructor()
    {
    }

}
