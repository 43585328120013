<ion-header><modal-header title="Edit Beer" [editForm]="this"></modal-header></ion-header>

<ion-content [formGroup]="form" *ngIf="form">

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="bru"></ng-container>
    <ng-container *ngTemplateOutlet="demog"></ng-container>
    <ng-container *ngTemplateOutlet="image"></ng-container>
    <ng-container *ngTemplateOutlet="notes"></ng-container>
  </div>

  <ion-grid *ngIf="monkey.isLargeDevice">
    <ion-row>
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="bru"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="demog"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="image"></ng-container>
        <ng-container *ngTemplateOutlet="notes"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<ion-footer>
  <bones-edit-button-bar [editor]="this"></bones-edit-button-bar>
</ion-footer>

<!-- brewery -->
<ng-template #bru>
  <ion-card class="ion-padding">
    <ion-card-title>Brewery</ion-card-title>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('brewery_id')"></bones-form-item>
    <ion-item *ngIf="brewery">
      <ion-label>{{ brewery.row.location }}</ion-label>
    </ion-item>
  </ion-card>
</ng-template>

<!-- basic columns -->
<ng-template #demog>
  <ion-card class="ion-padding">
    <ion-card-title>Beer</ion-card-title>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('name')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('style')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('abv')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('ratebeer_score')"></bones-form-item>
  </ion-card>
</ng-template>

<!-- image -->
<ng-template #image>
  <ion-card class="ion-padding" *ngIf="isAdd">
    <bones-form-item [form]="form" [item]="bonesForm.getItem('image')"></bones-form-item>
  </ion-card>
</ng-template>

<!-- notes -->
<ng-template #notes>
  <ion-card class="ion-padding">
    <bones-form-item [form]="form" [item]="bonesForm.getItem('description')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('notes')"></bones-form-item>
  </ion-card>
</ng-template>
