import { Injectable, EventEmitter } from '@angular/core';

import { BonesCoreService } from './bones-core.service';
import { BonesError } from '../model/bones-error';

/**
 * Options for creating cache
 */
export interface BonesCacheOptions<K, RestType, CacheType>
{
    /**
     * Name of primary key property in CacheType objects.
     */
    pk: string;

    /**
     * Name of one or more tracked foreign key properties in RestType objects.
     */
    fk?: keyof RestType | (keyof RestType)[];

    /**
     * Function to initially load the cache.
     */
    loadCache: () => Promise<RestType[]>;

    /**
     * Function to reload one cache entry.
     */
    reloadOne: (pk: K) => Promise<RestType>;

    /**
     * Function to convert a RestType object into a CacheType object.
     */
    converter: (row: RestType) => Promise<CacheType>;

    /**
     * Function to sort array as passed to array sort() method.
     */
    sorter?: (a: CacheType, b: CacheType) => number;

    /**
     * Get the primary key from the backend web service payload after
     * adding a new row via an BonesEditForm that list linked to the cache.
     * Defaults to using payload.id.
     */
    getPkFromBgePayload?: (payload: any) => K;

    /**
     * Callback function triggered after the cache has been loaded.
     */
    onLoad?: (list: CacheType[]) => void;

    /**
     * Callback function triggered after an object has been added/updated in the cache.
     */
    onUpdate?: (item: CacheType) => void;

    /**
     * Callback function triggered after an object has been removed from the cache.
     */
    onDelete?: (item: CacheType) => void;
}

/**
 * Services required when creating a cache
 */
export interface BonesCacheServices
{
    /**
     * BonesCoreService
     */
    bones: BonesCoreService;
}

/**
 * Foreign key name/value pair
 */
interface ForeignKeyValuePair<RestType>
{
    /**
     * Name of foreign key
     */
    foreignKeyName: keyof RestType;

    /**
     * Value of foreign key
     */
    foreignKeyValue: any;
}

type ForeignKeyMap<CacheType> = Map<any, CacheType[]>;

/**
 * Saved state for state-based cache loader
 */
class LoaderState<RestType>
{
    /**
     * Current loading state
     */
    state: 'fetch' | 'convert' | 'sort' | 'resolve';
    /**
     * Rows fetched from the server
     */
    rows?: RestType[];
    /**
     * Number of rows to convert to smart objects at a time
     */
    converterBatchSize = 250;
    /**
     * Current position for row conversion
     */
    converterPosition = 0;

    /**
     * Create new state machine
     * @param resolve function to resolve promise
     * @param reject  function to reject promise
     */
    constructor(public resolve: () => void, public reject: (error: BonesError) => void)
    {
        this.state = 'fetch';
    }
}

/**
 * Cache smart objects created from combining cached information from other services.
 */
export class BonesCache<K, RestType, CacheType>
{
    private promise: Promise<void>;
    private list: CacheType[] = [ ];
    private map = new Map<K, CacheType>();
    private restMap = new Map<K, RestType>();
    private fkmaps = new Map<keyof RestType, ForeignKeyMap<CacheType>>();
    private reverseFkMap = new Map<number, ForeignKeyValuePair<RestType>[]>();
    private foreignKeyNames = (Array.isArray(this.options.fk) ? this.options.fk : [ this.options.fk ] );
    private cacheChange = new EventEmitter<CacheType[]>();

    /**
     * Trigger event when an object has been added/updated in the cache.
     */
    public onUpdate = new EventEmitter<CacheType>();
    /**
     * Trigger event when an object has been removed from the cache.
     */
    public onDelete = new EventEmitter<CacheType>();
    /**
     * Has the cache been loaded?
     */
    public isLoaded = false;

    /**
     * @ignore
     */
    constructor(private services: BonesCacheServices, public options: BonesCacheOptions<K, RestType, CacheType>)
    {
    }

    //-----------------------------------------------------------------------

    /**
     * Get single entry
     * @param pk primary key of entry to return
     * @returns cache entry matching primary key or undefined if the primary key does not exist in the cache.
     */
    public async getEntry(pk: K) : Promise<CacheType>
    {
        await this.load();
        return this.map.get(pk);
    }

    /**
     * Get original pre-conversion rest entry
     * @param pk primary key of entry to return
     * @returns original pre-conversion rest entry matching primary key or undefined if the primary key does not exist in the cache.
     */
    public async getRestEntry(pk: K) : Promise<RestType>
    {
        await this.load();
        return this.restMap.get(pk);
    }

    /**
     * Get all cache entries.
     * @returns cache entries
     */
    public async getList() : Promise<CacheType[]>
    {
        await this.load();
        return this.list;
    }

    /**
     * Get Map of cache entries.
     * @returns Map of cache entries
     */
    public async getMap() : Promise<Map<K, CacheType>>
    {
        await this.load();
        return this.map;
    }

    /**
     * Get map of entry keys to entry property as used by a form picker.
     * 
     * @param propertyName name of property to use to build map.
     * @param filter optional filter to apply to overall cache
     * @returns Entry map
     */
    public async getPickerMap(propertyName: string, filter?: (entry: CacheType) => boolean) : Promise<Map<K, string>>
    {
        // Make sure cache is loaded
        await this.load();

        // Filter the list if required
        const filtered = filter ? this.list.filter(filter) : this.list;

        // Build picker
        const pickerMap = new Map<K, string>();
        filtered.forEach(row => pickerMap.set(row[this.options.pk], row[propertyName]));

        return pickerMap;
    }

    //-----------------------------------------------------------------------

    // /**
    //  * Get event emitter used to subscribe to cache entries
    //  */
    // getEE() : EventEmitter<CacheType[]>
    // {
    //     // if (this.promise)
    //     // Trigger a load if the cache has not already been loaded
    //     this.load();

    //     // Return the emitter so that the caller can subscribe and unsubscribe
    //     return this.cacheChange;
    // }

    /**
     * Get cache entries now and then reinvoke callback whenever cache changes.
     *
     * @param onSuccess function to call when cache data is ready or updated.
     * @param onError function to call when error occurrs loading or updating cache.
     *
     * @returns cleanup function that must be called by calling method's ngOnDestroy or equivalent.
     */
    nowAndLater(onSuccess: (rows: CacheType[]) => void, onError?: (error: BonesError) => void) : () => void
    {
        // Subscribe to cache changes
        const subscription = this.cacheChange.subscribe(
        (rows: CacheType[]) =>
        {
            // Send a copy of the cache so ng16 will trigger change detection
            onSuccess([ ...rows]);
        },
        (error: BonesError) =>
        {
            if (onError)
            {
                onError(error);
            }
        });

        if (this.promise)
        {
            // Go ahead and reinvoke callback since the cache was already populated
            onSuccess(this.list);
        }
        else
        {
            // Trigger a load since the cache has not already been loaded
            this.load();
        }

        // Return cleanup function
        return () => subscription.unsubscribe();
    }

    //-----------------------------------------------------------------------

    /**
     * Load cache by fetching dumb objects to convert to smart objects
     */
    public async load() : Promise<void>
    {
        if (!this.promise)
        {
            this.promise = new Promise((resolve, reject) =>
            {
                this.loadNext(new LoaderState<RestType>(resolve, reject));
            });
        }

        return this.promise;
    }

    private loadNext(loader: LoaderState<RestType>)
    {
        switch (loader.state)
        {
            case 'fetch':
                setTimeout(async () =>
                {
                    this.options.loadCache()
                    .then(rows =>
                    {
                        loader.rows = rows;
                        loader.state = 'convert';
                        this.loadNext(loader);
                    })
                    .catch(error =>
                    {
                        error = new BonesError(
                        {
                            className: 'BonesCache',
                            methodName: 'load',
                            message: 'cache creation failed',
                            error: error
                        })
                        .add(this.options);

                        loader.reject(error);
                        this.cacheChange.error(error);
                    });
                });
                break;

            case 'convert':
                setTimeout(async () =>
                {
                    if (loader.converterPosition < loader.rows.length)
                    {
                        const endPosition = Math.min(loader.rows.length, loader.converterPosition + loader.converterBatchSize);
                        await this.convertSome(loader.rows.slice(loader.converterPosition, endPosition));
                        loader.converterPosition = endPosition;

                        this.loadNext(loader);
                    }
                    else
                    {
                        loader.state = 'sort';
                        this.loadNext(loader);
                    }
                });
                break;

            case 'sort':
                setTimeout(async () =>
                {
                    if (this.options.sorter)
                    {
                        this.list.sort(this.options.sorter);
                    }

                    loader.state = 'resolve';
                    this.loadNext(loader);
                });
                break;

            case 'resolve':
                setTimeout(async () =>
                {
                    // Broadcast event that the cache has changed
                    this.cacheChange.emit(this.list);

                    this.isLoaded = true;

                    if (this.options.onLoad)
                    {
                        this.options.onLoad(this.list);
                    }

                    // console.log('bc.load', this.list, this.map, this.restMap, this.fkmaps, loader);

                    loader.resolve();
                });
                break;
        }
    }

    private async convertSome(rows: RestType[]) : Promise<void>
    {
        for (let i = 0; (i < rows.length); ++i)
        {
            const dumb = rows[i];

            // Convert dumb json object into smart ts object
            const smart = await this.options.converter(dumb);

            // Add smart object to cache list
            this.list.push(smart);

            // Map dumb/smart objects for fast retrieval
            this.restMap.set(dumb[this.options.pk], dumb);
            this.map.set(smart[this.options.pk], smart);

            // Map any defined foreign keys
            if (this.options.fk)
            {
                this.addToFkMaps(dumb, smart);
            }
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Add cache object to relevent foreign key map(s)
     */
    private addToFkMaps(dumb: RestType, smart: CacheType)
    {
        const inmaps: ForeignKeyValuePair<RestType>[] = [ ];

        this.foreignKeyNames.forEach(foreignKeyName =>
        {
            // Is the foreign key populated for this cache object?
            if (dumb[foreignKeyName])
            {
                // Get the value of the foreign key from the cache object
                const foreignKeyValue = dumb[foreignKeyName];

                // Get the cache object maps for this foreign key
                let fkmap = this.fkmaps.get(foreignKeyName);

                // Create the initial map for this foreign key
                if (!fkmap)
                {
                    fkmap = new Map<any, CacheType[]>();
                    this.fkmaps.set(foreignKeyName, fkmap);
                }

                // Get cache object list for this specific foreign key value
                const mapByFk = fkmap.get(foreignKeyValue);

                // Is there already a cache object list for this specific foreign key value?
                if (mapByFk)
                {
                    // Add another cache object for this foreign key value unless it already exists
                    if (mapByFk.indexOf(smart) < 0)
                    {
                        mapByFk.push(smart);
                    }
                }
                else
                {
                    // Create new cache object list for this foreign key value
                    fkmap.set(foreignKeyValue, [ smart ]);
                }

                // Track which maps contain this cache object
                inmaps.push({ foreignKeyName: foreignKeyName, foreignKeyValue: foreignKeyValue });

                // console.log('map cache object', smart[this.options.pk], smart, 'to', foreignKeyName, 'list for', foreignKeyValue,
                //     fkmap.get(foreignKeyValue)?.map(d => d[this.options.pk]));
            }
        });

        // Track which maps contain this cache object for later backout
        if (inmaps.length > 0)
        {
            this.reverseFkMap.set(smart[this.options.pk], inmaps);
        }
    }

    /**
     * Remove cache object from foreign key map(s)
     */
    private removeFromFkMaps(smart: CacheType)
    {
        // Find which foreign keys had this cache object mapped
        const inmaps = this.reverseFkMap.get(smart[this.options.pk]);
        if (inmaps)
        {
            // Document may exist in more than one fk map (it shouldn't by design, but is not disallowed by code)
            inmaps.forEach(inmap =>
            {
                // Get the cache object maps for this foreign key
                const fkmap = this.fkmaps.get(inmap.foreignKeyName);
                if (fkmap && inmap.foreignKeyValue)
                {
                    // Get cache object list for this specific foreign key value
                    const mapByFk = fkmap.get(inmap.foreignKeyValue);

                    // Is there a cache object list for this specific foreign key value?
                    if (mapByFk)
                    {
                        // Find desired cache object in list
                        const idx = mapByFk.findIndex(d => d[this.options.pk] === smart[this.options.pk]);
                        if (idx >= 0)
                        {
                            // Remove cache object from list
                            mapByFk.splice(idx, 1);
                            // console.log('deleting cache object', smart[this.options.pk], smart, 'from', inmap.foreignKeyName,
                            //     'list for', inmap.foreignKeyValue);
                        }
                        // else
                        // {
                        //     console.log('cache object', smart[this.options.pk], 'was not in', inmap.foreignKeyName,
                        //         'map for', inmap.foreignKeyValue, mapByFk);
                        // }

                        // console.log('resulting', inmap.foreignKeyName, 'map for', inmap.foreignKeyValue,
                        //     mapByFk.map(d => d[this.options.pk]));

                        // Delete empty cache object list from map
                        if (mapByFk.length === 0)
                        {
                            fkmap.delete(inmap.foreignKeyValue);
                            // console.log('deleting empty cache object list from', inmap.foreignKeyName, 'list for', inmap.foreignKeyValue);
                        }
                    }
                    // else
                    // {
                    //     console.log('did not find', inmap.foreignKeyName, 'map for', inmap.foreignKeyValue);
                    // }
                }
                // else
                // {
                //     console.log('did not find fk map for', inmap.foreignKeyName);
                // }
            });

            // Document is no longer mapped to any foreign keys
            this.reverseFkMap.delete(smart[this.options.pk]);
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Get a list of cache objects for a specific foreign key value (asynchronous operation)
     * 
     * @param foreignKeyName Name of indexed foreign key
     * @param foreignKeyValue Value of indexed foreign key
     * @returns List of cached entries
     */
    async fetchListByForeignKey(foreignKeyName: keyof RestType, foreignKeyValue: number) : Promise<CacheType[] | undefined>;

    /**
     * Get a list of cache objects for a specific foreign key value (asynchronous operation)
     * 
     * @param pair Object containing foreignKeyName and foreignKeyValue properties
     * @returns List of cached entries
     */
    async fetchListByForeignKey(pair: ForeignKeyValuePair<RestType>) : Promise<CacheType[] | undefined>;

    /**
     * Internal overload implementation method
     * @ignore
     */
    async fetchListByForeignKey(arg1: keyof RestType | ForeignKeyValuePair<RestType>, arg2?: number) : Promise<CacheType[] | undefined>
    {
        const pair = typeof arg1 === 'object' ? arg1 : { foreignKeyName: arg1, foreignKeyValue: arg2 };
        return (await this.fetchForeignKeyMap(pair.foreignKeyName)).get(pair.foreignKeyValue || 0);
    }

    //-------------------------

    /**
     * Get a list of cache objects for a specific foreign key value (synchronous operation)
     * 
     * @param foreignKeyName Name of indexed foreign key
     * @param foreignKeyValue Value of indexed foreign key
     * @returns List of cached entries
     */
    getListByForeignKey(foreignKeyName: keyof RestType, foreignKeyValue: number) : CacheType[] | undefined

    /**
     * Get a list of cache objects for a specific foreign key value (synchronous operation)
     * 
     * @param pair Object containing foreignKeyName and foreignKeyValue properties
     * @returns List of cached entries
     */
    getListByForeignKey(pair: ForeignKeyValuePair<RestType>) : CacheType[] | undefined

    /**
     * Internal overload implementation method
     * @ignore
     */
    getListByForeignKey(arg1: keyof RestType | ForeignKeyValuePair<RestType>, arg2?: number) : CacheType[] | undefined
    {
        const pair = typeof arg1 === 'object' ? arg1 : { foreignKeyName: arg1, foreignKeyValue: arg2 };
        return this.getForeignKeyMap(pair.foreignKeyName).get(pair.foreignKeyValue || 0);
    }

    //-------------------------

    /**
     * Get a map of cache objects keyed on one of the defined foreign keys (asynchronous operation)
     * 
     * @param foreignKey Name of indexed foreign key
     * @returns Promise of the map
     */
    async fetchForeignKeyMap(foreignKey: keyof RestType) : Promise<ForeignKeyMap<CacheType>>
    {
        // Make sure cache is loaded
        await this.load();

        // Return populated map
        return this.getForeignKeyMap(foreignKey);
    }

    /**
     * Get a map of cache objects keyed on one of the defined foreign keys (synchronous operation)
     * 
     * @param foreignKey Name of indexed foreign key
     * @returns desired map, which may or may not be fully loaded
     */
    getForeignKeyMap(foreignKey: keyof RestType) : ForeignKeyMap<CacheType>
    {
        // Validate requested foreign key
        if (this.foreignKeyNames.indexOf(foreignKey) < 0)
        {
            const error = new BonesError(
            {
                className: 'BonesCache',
                methodName: 'getForeignKeyMap',
                message: 'foreign key ' + foreignKey + ' not in foreign key list: ' + this.foreignKeyNames,
            });

            throw error;
        }

        // Make sure map was created
        const map = this.fkmaps.get(foreignKey);
        if (map)
        {
            return map;
        }
        else
        {
            const error = new BonesError(
            {
                className: 'BonesCache',
                methodName: 'getForeignKeyMap',
                message: 'no map for ' + foreignKey
                    + '; foreign keys: ' + this.foreignKeyNames
                    + '; maps: ' + this.fkmaps.keys,
            });

            throw error;
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Notify the cache when an entry has been updated in the db and needs to be refreshed in the cache.
     * @param pk primary key of entry to reload.
     * @returns updated cache entry.
     */
    async updated(pk?: K) : Promise<CacheType | undefined>
    {
        if (!pk) return;

        // Fetch new row
        return this.options.reloadOne(pk)
        .then(async row =>
        {
            const newEntry = await this.options.converter(row);
            let returnValue: CacheType;

            if (this.map.has(pk))
            {
                // Find original object
                const ori = this.map.get(pk);

                // Remove original from fk maps
                this.removeFromFkMaps(ori);

                // Update original object with new contents
                this.services.bones.copyInPlace(newEntry, ori);
                this.services.bones.copyInPlace(row, this.restMap.get(pk));

                // New updated row to fk maps
                this.addToFkMaps(row, ori);

                returnValue = ori;
                // console.log('bc.updated: copyInPlace', pk, newEntry, ori, this.list, this.map, this.restMap);
            }
            else
            {
                // Add new row to list and map
                this.list.push(newEntry);
                this.map.set(pk, newEntry);
                this.restMap.set(pk, row);
                this.addToFkMaps(row, newEntry);

                returnValue = newEntry;
                // console.log('bc.updated: new row', pk, newEntry, this.list, this.map, this.restMap);
            }

            // Update foreign key maps
            // this.removeFromFkMaps(deletedEntry);

            // Resort list
            if (this.options.sorter)
            {
                this.list.sort(this.options.sorter);
            }

            // Broadcast event that the cache has changed
            this.cacheChange.emit(this.list);
            this.onUpdate.emit(returnValue);

            if (this.options.onUpdate)
            {
                this.options.onUpdate(returnValue);
            }

            return returnValue;
        })
        .catch(error =>
        {
            error = new BonesError(
            {
                className: 'BonesCache',
                methodName: 'updated',
                message: 'Unable to refresh cache entry',
                error: error
            })
            .add(this.options);

            this.cacheChange.error(error);
            throw error;
        });
    }

    /**
     * Notify the cache when an entry has been deleted.
     * @param pk primary key of entry to remove.
     */
    deleted(pk?: K) : void
    {
        if (!pk) return;

        const idx = this.list.findIndex(a => a[this.options.pk] === pk);
        if (idx !== -1)
        {
            const deletedEntry = this.list[idx];

            // Remove from cache
            this.list.splice(idx, 1);
            this.map.delete(pk);

            // Remove from foreign key maps
            this.removeFromFkMaps(deletedEntry);

            // console.log('bc.deleted', pk, this.map, this.restMap);

            // Broadcast event that the cache has changed
            this.cacheChange.emit(this.list);
            this.onDelete.emit(deletedEntry);

            if (this.options.onDelete)
            {
                this.options.onDelete(deletedEntry);
            }
        }
    }

    //-----------------------------------------------------------------------

}

/**
 * Factory to create BonesCache.
 */
@Injectable({
  providedIn: 'root',
})
export class BonesCacheFactory
{
    /**
     * @ignore
     */
    constructor(
        private bones: BonesCoreService,
        // private rest: BonesRestInterface
    )
    {
    }

    /**
     * Create a new cache for a given primary key type, rest type, and cache type.
     * 
     * K is the type for the primary key. Normally number but sometimes string.
     * 
     * The RestType describes the layout of a row retrieved from a rest service.
     * This is usually an interface since these objects are received and not constructed.
     * 
     * The CacheType is the type of object stored in the cache.
     * This can be the same type as the RestType for simple caches, or it can be a full blown class based object
     * that is constructed by the converter configuration option.
     * 
     * @param options cache configurations options.
     */
    create<K, RestType, CacheType>(options: BonesCacheOptions<K, RestType, CacheType>) : BonesCache<K, RestType, CacheType>
    {
        return new BonesCache<K, RestType, CacheType>(
        {
            bones: this.bones,
            // rest: this.rest
        },
        options);
    }
}
