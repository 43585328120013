import { AppUserInfo } from './AppUserInfo';

export class AppUser
{
    /**
     * Has user been authenticated
     */
    authenticated = false;

    /**
     * User details
     */
    info?: AppUserInfo;

    constructor()
    {
    }

    /**
     * Is user an admin?
     */
    get isAdmin() : boolean
    {
        return (this.info !== undefined && !!this.info.admin);
    }

    /**
     * Can user edit information?
     */
    get canEdit() : boolean
    {
        return this.isAdmin;
    }

    // /**
    //  * Does user have access to a task?
    //  */
    // hasAccess(task: string) : boolean
    // {
    //     return (this.info && this.info.tasks && this.info.tasks.indexOf(task) >= 0);
    // }
}
