import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { EDSGatewayService } from '../../service/eds-gateway';

import { TestToolsEdsgwConfigPage } from '../../pages/test-tools-edsgw-config/test-tools-edsgw-config';

@Component({
  selector: 'bones-tt-edsgw-config-card',
  templateUrl: 'bones-tt-edsgw-config-card.html'
})
export class BonesTtEdsGatewayConfigCardComponent
{
    constructor(
        private modalCtrl: ModalController,
        public edsgw: EDSGatewayService
    )
    {
    }

    /**
     * Route to gateway configuration
     */
    async configureEdsgw()
    {
         (await this.modalCtrl.create({ component: TestToolsEdsgwConfigPage })).present();
    }

}
