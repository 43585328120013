<ion-header><bones-tt-page-header title="Error Log"></bones-tt-page-header></ion-header>

<ion-content>
  <ion-card *ngIf="history">
    <ion-list>
      <ion-item *ngIf="history.length === 0">No errors</ion-item>
      <ion-item *ngFor="let error of history"
        [routerLink]="['/bones/test-tools/core/error-detail/', error.id]">
        <ion-label>
            <h2>{{ error.message }}</h2>
            <p>{{ error.date | date:'medium' }}</p>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-card>

</ion-content>
