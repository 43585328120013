<ion-header>
  <ion-toolbar>
    <ion-title>{{ options.title }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-searchbar class="ion-padding" (ionChange)="search($event.detail.value)" debounce="250"></ion-searchbar>

  <ion-card *ngIf="filteredValues && filteredValues.length > 0">
    <ion-item *ngFor="let value of filteredValues" (click)="pick(value)">
      <ion-icon slot="start" name="" *ngIf="options.multi && !chosen(value)"></ion-icon>
      <ion-icon slot="start" name="checkmark" *ngIf="options.multi && chosen(value)"></ion-icon>
      <ion-label class="ion-text-wrap" [ngClass]="{current: chosen(value)}">{{ formatItem(value) }}</ion-label>
    </ion-item>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="filteredValues && filteredValues.length === 0">
    No matches.
  </ion-card>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" (click)="done()" *ngIf="options.multi">Done</ion-button>
      <ion-button fill="solid" color="primary" (click)="clear()">Clear</ion-button>
      <ion-button fill="solid" color="primary" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
