import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesErrorService, BonesError } from '@bones/core';
import { BonesForm } from '@bones/form';

import { EDSGatewayService } from '../../service/eds-gateway';
import { BonesRestService } from '../../service/bones-rest';

@Component({
  templateUrl: 'test-tools-bones-rest.html'
})
export class BonesTestToolsBonesRestPage
{
    forwardResults: any;
    error: BonesError;
    directServerForm: BonesForm;
    reqForm: BonesForm;

    constructor(
        private loadingCtrl: LoadingController,
        private formBuilder: UntypedFormBuilder,
        private es: BonesErrorService,
        public edsgw: EDSGatewayService,
        private brest: BonesRestService
    )
    {
        this.directServerForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'route',
                    hidden: true,
                    initialValue: 'gw'
                },
                {
                    name: 'directServerUrl',
                    title: 'directServerUrl',
                    initialValue: window.localStorage.getItem('bones-tt-brest-server') || '/appContentRoot/rest'
                }
            ]
        });

        this.reqForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'forwardUrl',
                    title: 'Forward URL',
                    initialValue: window.localStorage.getItem('bones-tt-brest-forward-url') || '/client/ws/ping',
                    validator: Validators.required
                },
                {
                    name: 'forwardBody',
                    title: 'Body',
                    type: 'textarea',
                    initialValue: window.localStorage.getItem('bones-tt-brest-forward-body') || ''
                }
            ]
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Send forward request
     */
    async forward()
    {
        this.forwardResults = undefined;
        this.error = undefined;

        try
        {
            const body = this.reqForm.getValue('forwardBody') ? JSON.parse(this.reqForm.getValue('forwardBody')) : undefined;

            const loading = await this.loadingCtrl.create({});
            loading.present();

            // Save latest values to local storage for next time
            window.localStorage.setItem('bones-tt-brest-forward-url', this.reqForm.getValue('forwardUrl'));
            window.localStorage.setItem('bones-tt-brest-forward-body', this.reqForm.getValue('forwardBody'));

            // Request routing
            let pp: Promise<any>;
            switch (this.directServerForm.getValue('route'))
            {
                case 'd':
                    this.brest.directServerUrl = this.directServerForm.getValue('directServerUrl');
                    window.localStorage.setItem('bones-tt-brest-server', this.directServerForm.getValue('directServerUrl'));
                    pp = this.brest.direct(this.reqForm.getValue('forwardUrl'), body);
                    break;

                case 'gw':
                    pp = this.brest.forward(this.reqForm.getValue('forwardUrl'), body);
                    break;
            }

            // Send request
            pp.then(payload =>
            {
                loading.dismiss();
                this.forwardResults = payload;
            })
            .catch(error =>
            {
                loading.dismiss();
                this.error = error;
                this.es.errorHandler(error);
            });
        }
        catch (error)
        {
            this.error = error;
            this.es.errorHandler(error);
        }
    }

    //-----------------------------------------------------------------------

}
