import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';

/**
 * Routes for module
 */
const routes: Route[] =
[
    {
        path: '',
        children:
        [
        ]
    }
];

@NgModule({
    imports:
    [
        RouterModule.forChild(routes),
    ],
    exports:
    [
        RouterModule
    ]
})
export class AppCoreRoutingModule
{
}
