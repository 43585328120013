import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AppUser, UserService } from '@BeerMonkey/core';

import { RateBeerService } from '@BeerMonkey/rate/service/RateBeerService';
import { Brewery } from '@BeerMonkey/rate/class/Brewery';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';

@Component({
  selector: 'brewery-list',
  templateUrl: 'brewery-list.html'
})
export class BreweryListComponent
{
    breweryList: Brewery[] = [ ];
    @Input() set breweries(list: Brewery[])
    {
        this.breweryList = list;
    }

    user: AppUser;

    constructor(
        private navCtrl: NavController,
        private mtus: UserService,
        public launch: LaunchEditModalService,
        public rateBeerDB: RateBeerService,
    )
    {
        this.user = this.mtus.getUser();
    }

    breweryDetail(brewery_id: number, event: Event)
    {
        this.navCtrl.navigateForward([ '/rate/brewery', brewery_id ]);
        event.stopPropagation();
    }

}
