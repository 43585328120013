<ion-header><app-page-header subtitle="Home"></app-page-header></ion-header>

<ion-content>

  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="homeTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">

    <ion-row>
      <ion-col size="3">
        <!-- <ng-container *ngTemplateOutlet="homeTmpl8"></ng-container> -->
        <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="randomRatingTmpl8"></ng-container>
      </ion-col>
      
      <ion-col size="9">
          <ng-container *ngTemplateOutlet="ratingGalleryTmpl8"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<ng-template #homeTmpl8>
  <ion-card class="ion-padding">
    <bones-card-toolbar title="Beer Monkey" [actions]="cardMenu"></bones-card-toolbar>
  </ion-card>
</ng-template>

<ng-template #breweryTmpl8>
  <brewery-list-card title="Recent Breweries" [breweries]="breweries" [showBreweries]="true" [group]="breweryGroup"></brewery-list-card>
</ng-template>

<ng-template #ratingTmpl8>
  <rating-list-card title="Recent Ratings" [ratings]="ratings" [showBeerName]="true" [showBreweryName]="true">
  </rating-list-card>
</ng-template>

<ng-template #ratingGalleryTmpl8>
  <rating-gallery [ratings]="ratings" [showBreweryName]="true" [showBeerName]="true"></rating-gallery>
</ng-template>

<ng-template #randomRatingTmpl8>
  <ion-card class="ion-padding" *ngIf="randomRating">
    <ion-card-title>Remembering {{ randomRating.ratingDate | date:'MMMM y' }}</ion-card-title>
    <ion-item [detail]="true" [routerLink]="['/rate/rating', randomRating.rating_id]">
      <ion-label>
        <ion-text color="primary"><h2>{{ randomRating.beer?.name }}</h2></ion-text>
        <p>{{ randomRating.beer?.brewery?.name }}</p>
      </ion-label>
    </ion-item>
    <img style="width: 100%"
      [bonesGalleryDocumentID]="randomImage.document_id"
      [bonesGalleryDocumentSize]="gallery.imageSizeCard" *ngIf="randomImage">
  </ion-card>
</ng-template>
