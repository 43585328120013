import { BnsMethod } from './BnsMethod';
import { BnsOptions } from './BnsOptions';

/**
 * Request info object.
 */
export class BnsRequestInfo
{
    /**
     * Options used to generate the request.
     */
    options: BnsOptions;

    /**
     * URL prefix of all server rest services.
     */
    serverUrl: string;

    /**
     * URL suffix for this specific server rest service.
     */
    apiUrl: string;

    /**
     * Body posted to server.
     */
    requestBody?: any;

    /**
     * HTTP options used.
     */
    httpOptions: any;

    /**
     * Server HTTP method (e.g. GET, POST).
     */
    method: BnsMethod;
}
