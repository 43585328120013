import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { BonesFormItem } from '../../class/BonesFormItem';

/**
 * Display input item within a form.
 * 
 * @example
 * <bones-form-item [form]="form" [item]="formItemObject"></bones-form-item>
 */
@Component({
  selector: 'bones-form-item',
  templateUrl: 'form-item.html'
})
export class BonesFormItemComponent implements OnInit, OnDestroy
{
    /**
     * FormGroup containing form controls
     */
    @Input() form: UntypedFormGroup;

    /**
     * Item to display on form
     */
    @Input() item: BonesFormItem;

    /**
     * Underlying Angular AbstractControl for the form item.
     */
    control: AbstractControl;

    /**
     * Array of possible validator names used by this form item.
     */
    validatorNames: string[];

    /**
     * Name of general error not covered by specific validators (e.g. required, maxlength).
     */
    otherValidatorError: string;

    /**
     * Error message for general error not covered by specific validators.
     */
    otherValidatorErrorMessage: string;

    /**
     * Change subscription
     */
    private changeSubscription: Subscription;

    /**
     * @ignore
     */
    constructor()
    {
    }

    /**
     * Initial component once all attributes are populated.
     */
    ngOnInit() : void
    {
        // Get Angular form control
        this.control = this.form.controls[this.item.name];

        // Get list of validators based upon defined error messages
        this.validatorNames = this.item.errorMessages ? Object.keys(this.item.errorMessages) : [ ];

        // Track status changes
        this.control.statusChanges.subscribe(() =>
        {
            // console.log('form-item: statusChanges', this.item.name, this.otherValidatorError, this.otherValidatorErrorMessage);
            this.otherValidatorError = undefined;
            this.otherValidatorErrorMessage = undefined;

            if (!this.control.valid && this.control.errors)
            {
                // console.log(this.item.name, this.control, this.control.errors);
                const firstError = Object.keys(this.control.errors)[0];
                if (!(firstError in this.item.errorMessages))
                {
                    this.otherValidatorError = firstError;
                    // console.log('form-item: statusChanges: set otherValidatorError', this.item.name, this.otherValidatorError);

                    switch (this.otherValidatorError)
                    {
                    case 'required':
                        this.otherValidatorErrorMessage = 'Required';
                        break;

                    case 'minlength':
                        this.otherValidatorErrorMessage =
                            'Must enter at least ' + this.control.errors.minlength.requiredLength + ' characters.';
                        break;

                    case 'maxlength':
                        this.otherValidatorErrorMessage =
                            'Don\'t enter more than ' + this.control.errors.maxlength.requiredLength + ' characters.';
                        break;

                    default:
                        this.otherValidatorErrorMessage = 'Invalid';
                        break;
                    }
                }
            }
        });

        // Setup onChange callback
        if (this.item.onChange)
        {
            this.changeSubscription = this.control.valueChanges.subscribe(value =>
            {
                // console.log('BonesFormItemComponent: calling item.onChange', this.item.name, this.item.control.value, value, this);
                this.item.onChange(value, this.item);
            });
        }
    }

    /**
     * Component destroyed
     */
    ngOnDestroy()
    {
        // Unsubscribe from value changes
        if (this.changeSubscription)
        {
            this.changeSubscription.unsubscribe();
        }
    }

}
