import { Component, Input } from '@angular/core';

import { BonesMenuCardAction, BonesItemGroupFactory, BonesItemGroup } from '@bones/core';

import { nowShowing } from '@BeerMonkey/rate/class/nowShowing';
import { showCard } from '@BeerMonkey/rate/class/showCard';
import { Brewery } from '@BeerMonkey/rate/class/Brewery';
import { BreweryFilter, breweryNameGroupFactory, breweryLocationGroupFactory, breweryNoneGroupFactory, breweryTypeGroupFactory } from '@BeerMonkey/rate/service/BreweryService';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';
import { BreweryInfo } from '@BeerMonkey/rate/class/BreweryInfo';

@Component({
    selector: 'brewery-list-card',
    templateUrl: 'brewery-list-card.html'
})
export class BreweryListCardComponent
{
    @Input() showSearchbar = false;
    @Input() showBreweries = false;
    @Input() resort = true;
    @Input() add = false;
    @Input() search = false;

    _title?: string = 'Breweries';
    @Input() set title(title: string | undefined)
    {
        this._title = title ?? 'Breweries';
    }
    get title() : string | undefined
    {
        return this._title;
    }

    _breweries?: Brewery[];
    @Input() set breweries(breweries: Brewery[] | undefined)
    {
        if (breweries)
        {
            this._breweries = breweries;
            this.filterContent();
        }
        else
        {
            this._breweries = undefined;
            this.groups = undefined;
        }
    }
    get breweries() : Brewery[] | undefined
    {
        return this._breweries;
    }

    _searchTerm: string = '';
    @Input() set searchTerm(searchTerm: string)
    {
        this._searchTerm = searchTerm;
        this.filterContent();
    }
    get searchTerm() : string
    {
        return this._searchTerm;
    }

    groupFactory: BonesItemGroupFactory<Brewery, BreweryInfo> = breweryNameGroupFactory();
    @Input() set group(groupFactory: BonesItemGroupFactory<Brewery, BreweryInfo>)
    {
        if (groupFactory)
        {
            this.groupFactory = groupFactory;
            this.filterContent();
        }
    }

    filtered: Brewery[] = [ ];
    groups?: BonesItemGroup<Brewery>[];
    numberToDisplay = 30;
    preTruncateLength?: number;
    truncated?: boolean;
    cardMenu?: BonesMenuCardAction[];

    constructor(
        private launch: LaunchEditModalService
    )
    {
    }

    async ngOnInit()
    {
        this.cardMenu =
        [
            { title: 'Group by Name', icon: 'funnel', action: () => this.doSort(breweryNameGroupFactory()) },
            { title: 'Group by Location', icon: 'funnel', action: () => this.doSort(breweryLocationGroupFactory()) },
            { title: 'Group by Type', icon: 'funnel', action: () => this.doSort(breweryTypeGroupFactory()) },
            { title: 'Ungroup', icon: 'funnel', action: () => this.doSort(breweryNoneGroupFactory()) },
            { title: 'Show more', icon: 'add', action: () => this.showMore() },
        ];

        if (this.add)
        {
            this.cardMenu.push(this.launch.getMenuItemAdd(() => this.launch.addBrewery()))
        }
    }

    ngOnDestroy()
    {
    }

    doSort(group: BonesItemGroupFactory<Brewery, BreweryInfo>)
    {
        this.groupFactory = group;
        this.filterContent();
    }

    /**
     * Filter, sort, and group ratings
     */
    filterContent()
    {
        if (this.breweries)
        {
            // Filter by keyword
            this.filtered = new BreweryFilter(this.breweries).byKeyword(this.searchTerm).rows;

            // Resort
            if (this.resort)
            {
                this.groupFactory.sortItems(this.filtered);
            }

            // Limit display size
            this.preTruncateLength = this.filtered.length;
            this.truncated = this.filtered.length > this.numberToDisplay;
            if (this.truncated)
            {
                this.filtered = this.filtered.slice(0, this.numberToDisplay);
            }

            // Group
            this.groups = this.groupFactory.group(this.filtered);
        }
    }

    async showMore()
    {
        this.numberToDisplay += 30;
        this.filterContent();
    }

    showingText() : string
    {
        return nowShowing(this.showBreweries, this.breweries?.length ?? 0, this.filtered.length, this.preTruncateLength ?? 0);
    }

    get show() : boolean
    {
        return showCard(this.search, this.searchTerm, this.filtered);
    }

}
