import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@BeerMonkey/rate';

import { HomePage } from './home/home';

const routes: Routes = [
    {
        path: '',
        canActivate: [ AuthGuard ],
        children:
        [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: HomePage },
        ]
    }
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
    // , relativeLinkResolution: 'legacy'
    // RouterModule.forRoot(routes, { useHash: true, enableTracing: true })
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
