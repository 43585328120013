import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { BonesErrorService, BonesError } from '@bones/core';
import { EDSGatewayService } from '../../service/eds-gateway';

@Component({
  templateUrl: 'test-tools-eds-gateway.html'
})
export class BonesTestToolsEdsGatewayPage
{
    form: UntypedFormGroup;
    forwardResults: any;
    error: BonesError;

    constructor(
        private loadingCtrl: LoadingController,
        private formBuilder: UntypedFormBuilder,
        private es: BonesErrorService,
        public edsgw: EDSGatewayService
    )
    {
        // Define edit form
        this.form = this.formBuilder.group(
        {
            forwardUrl: ['', Validators.required],
            forwardBody: [''],
        });

        // Populate form using locally saved values
        this.form.patchValue({ forwardUrl: window.localStorage.getItem('bones-tt-gateway-forward-url') || '/client/ws/ping' });
        this.form.patchValue({ forwardBody: window.localStorage.getItem('bones-tt-gateway-forward-body') || '' });
    }

    /**
     * Send forward request to eds gateway
     */
    async forward()
    {
        this.forwardResults = undefined;
        this.error = undefined;

        try
        {
            const body = JSON.parse(this.form.value.forwardBody);

            const loading = await this.loadingCtrl.create({});
            loading.present();

            // Save latest values to local storage for next time
            window.localStorage.setItem('bones-tt-gateway-forward-url', this.form.value.forwardUrl);
            window.localStorage.setItem('bones-tt-gateway-forward-body', this.form.value.forwardBody);

            this.edsgw.forward(this.form.value.forwardUrl, body)
            .then(payload =>
            {
                loading.dismiss();
                this.forwardResults = payload;
            })
            .catch(error =>
            {
                loading.dismiss();
                this.error = error;
                this.es.errorHandler(error, 'silent');
            });
        }
        catch (error)
        {
            this.error = error;
            this.es.errorHandler(error, 'silent');
        }
    }

    //-----------------------------------------------------------------------

}
