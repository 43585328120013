<!-- rate beer information card -->
<ion-card class="ion-padding" *ngIf="rbbrewery">
  <bones-card-toolbar [title]="title" [actions]="cardMenu" *ngIf="title || cardMenu"></bones-card-toolbar>

  <ion-item lines="none">
    <ion-label>
      <ion-text color="primary" (click)="rateBeerDB.openBrewery(rbbrewery.BrewerID)">
        <img style="height: 1.25em;" src="/assets/icon/rate-beer-logo.png">
        <ion-text> {{ rbbrewery.BrewerName }}</ion-text>
      </ion-text>
      <div>{{ rbbrewery.BrewerType }}</div>
      <div>{{ rbbrewery.BrewerAddress }}</div>
      <div>{{ rbbrewery.BrewerCity }}, {{ rbbrewery.BrewerStateID }} {{ rbbrewery.BrewerZipCode }}</div>
      <div>{{ rbbrewery.AreaCode }} {{ rbbrewery.BrewerPhone }}</div>
    </ion-label>
  </ion-item>
  <p *ngIf="rbbrewery?.BrewerDescription">{{ rbbrewery.BrewerDescription }}</p>
</ion-card>

<ion-card class="ion-padding" *ngIf="errorMessage">
  <ion-text color="danger" *ngIf="errorMessage">{{ errorMessage }}</ion-text>
</ion-card>
