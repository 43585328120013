import { Injectable } from '@angular/core';

import { BonesCacheFactory, BonesCache } from '@bones/core';

export interface RestRow
{
    FIELD1?: number;
    FIELD2: string;
    FIELD3?: string;
    FIELD4?: string;
    FIELD5?: string;
}

export class CacheRow
{
    FIELD1: number;
    converted = new Date();

    constructor(public info: RestRow)
    {
        this.FIELD1 = info.FIELD1;
    }
}

/**
 * Create a BonesCache with mock data, updated by mock rest service
 */
@Injectable()
export class BonesTestToolsEditMockCacheService
{
    /**
     * Cache.
     */
    cache: BonesCache<number, RestRow, CacheRow>;

    /**
     * Primary key sequence counter.
     */
    pk = 0;

    /**
     * Mock data.
     */
    private data: RestRow[] = [ ];

    /**
     * @ignore
     */
    constructor(
        private bcf: BonesCacheFactory
    )
    {
        this.add(
        {
            FIELD2: 'Data for field two',
            FIELD3: 'This',
            FIELD4: 'Shaking',
            FIELD5: 'Y'
        });

        this.add(
        {
            FIELD2: 'Field two, row two',
        });

        this.cache = this.bcf.create<number, RestRow, CacheRow>(
        {
            pk: 'FIELD1',
            converter: (row) => Promise.resolve(new CacheRow(row)),
            loadCache: () =>
            {
                const cacheCopy: RestRow[] = [ ];
                this.data.forEach(d => cacheCopy.push({ ...d }));
                return Promise.resolve(cacheCopy);
            },
            reloadOne: (id: number) => Promise.resolve(this.data.find(entry => entry.FIELD1 === id))
        });
    }

    /**
     * Add row to private data store
     * @param row new data row
     * @returns new primary key
     */
    add(row: RestRow) : number
    {
        const pk = ++this.pk;
        const newRow = { ...row, FIELD1: pk };

        this.data.push(newRow);

        console.log('add new mock row', pk, newRow, this.data);

        return pk;
    }

    /**
     * Update row in private data store
     * @param pk primary key of row to update
     * @param row new data row
     */
    update(pk: number, row: RestRow) : void
    {
        const idx = this.data.findIndex(r => r.FIELD1 === pk);
        if (idx >= 0)
        {
            this.data.splice(idx, 1, row);
            console.log('update mock row', pk, row, this.data);
        }
        else
        {
            console.log('update mock row- not found', pk, row, this.data);
        }
    }

    /**
     * Delete row from private data store
     * @param pk primary key of row to delete
     */
    delete(pk: number) : void
    {
        const idx = this.data.findIndex(r => r.FIELD1 === pk);
        if (idx >= 0)
        {
            this.data.splice(idx, 1);
            console.log('delete mock row', pk, this.data);
        }
        else
        {
            console.log('delete mock row- not found', pk, this.data);
        }
    }

    //-----------------------------------------------------------------------

}
