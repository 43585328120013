import { Brewery } from './Brewery';
import { GlasswareInfo } from './GlasswareInfo';

export class Glassware
{
    glassware_id: number;
    brewery?: Brewery;

    constructor(public row: GlasswareInfo)
    {
        this.glassware_id = row.glassware_id;
    }

}
