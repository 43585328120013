import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BonesErrorService } from '@bones/core';
import { BonesForm } from '@bones/form';

import { MonkeyService } from '@BeerMonkey/core';

import { GlasswareService, GlasswareFilter } from '@BeerMonkey/rate/service/GlasswareService';
import { Glassware } from '@BeerMonkey/rate/class/Glassware';

@Component({
    selector: 'glassware-page',
    templateUrl: 'glassware-page.html'
})
export class GlasswarePage implements OnInit, OnDestroy
{
    bonesForm: BonesForm;
    allGlassware?: Glassware[];
    filteredGlassware?: Glassware[];
    showFilters = false;
    private nal: (() => void)[] = [ ];

    constructor(
        private formBuilder: FormBuilder,
        private es: BonesErrorService,
        public monkey: MonkeyService,
        private glasswareDB: GlasswareService
    )
    {
        this.showFilters = this.monkey.isLargeDevice;

        // Create filter form
        this.bonesForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'brewery_id',
                    title: 'Brewery',
                    picker: () => this.glasswareDB.getBreweryPicker(),
                    onChange: () => this.filter()
                },
                {
                    name: 'style',
                    title: 'Style',
                    picker: () => this.glasswareDB.getPicker('style'),
                    onChange: () => this.filter()
                },
            ]
        });
    }

    async ngOnInit()
    {
        // Load and refresh ratings as needed
        this.nal.push(this.glasswareDB.cache.nowAndLater(
        rows =>
        {
            this.allGlassware = rows;
            this.filter();
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    filter()
    {
        if (this.allGlassware && this.bonesForm)
        {
            this.filteredGlassware = new GlasswareFilter(this.allGlassware)
                .byBrewery(this.bonesForm.getValue('brewery_id'))
                .by('style', this.bonesForm.getValue('style'))
                .rows;
        }
    }

}
