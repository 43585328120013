import { Injectable } from '@angular/core';

import { BonesError, BonesErrorType } from '@bones/core';
import { BonesRestService } from './bones-rest';

/**
 * Log client error to the server
 */
@Injectable({
    providedIn: 'root'
})
export class PostErrorService
{

    /**
     * @ignore
     */
    constructor(
        private brest: BonesRestService
    )
    {
    }

    //-----------------------------------------------------------------------

    // Settings
    private config =
    {
        url: '/user/logClientError'
    };

    /**
     * Get url.
     */
    public get url() : string
    {
        return this.config.url;
    }

    /**
     * Set url.
     */
    public set url(value: string)
    {
        this.config.url = value;
    }

    //-----------------------------------------------------------------------

    /**
     * Log error to the server.
     */
    log2db(error: BonesError)
    {
        // Build stack body that contains the bulk of the error information
        let nested: BonesErrorType = error;
        let deep = 0;
        let stack = '';
        let className = '';
        let methodName = '';
        while (nested && deep++ < 10)
        {
            // Log cause for this level
            stack = stack + deep + ': ' + BonesError.extractMessage(nested) + '\n';

            if (nested instanceof BonesError)
            {
                if (nested.className)
                {
                    stack = stack + 'in ' + nested.className + '.' + nested.methodName + '\n';

                    // Keep the highest level class and method
                    className = className || nested.className;
                    methodName = methodName || nested.methodName;
                }
                if (nested.otherData)
                {
                    stack = stack + JSON.stringify(nested.otherData, null, 4) + '\n';
                }
                // if (nested.stack)
                // {
                //     stack = stack + nested.stack + '\n';
                // }
            }

            stack = stack + '\n';

            // Get next level if any
            nested = (nested instanceof BonesError) ? nested.error : undefined;
        }

        // Send error to server
        this.brest.send(this.config.url,
        {
            message: error.message,
            stack: stack,
            ua: window.navigator.userAgent,
            className: className,
            methodName: methodName
        })
        .catch(e =>
        {
            console.log('error logging error', e);
        });
    }

}
