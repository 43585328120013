import { Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

import { BonesSideMenuAction } from '../model/bones-side-menu-action';

/**
 * Process BonesSideMenuAction actions.
 */
@Injectable({
  providedIn: 'root'
})
export class BonesMenuActionService
{

    /**
     * @ignore
     */
    constructor(
        private navCtrl: NavController,
        private modalCtrl: ModalController
    )
    {
    }

    //-----------------------------------------------------------------------

    /**
     * Execute menu item.
     * 
     * @param action Action to execute.
     */
    async execute(action: BonesSideMenuAction)
    {
        if (typeof action.action === 'function')
        {
            // Call function
            action.action.call(undefined, action);
        }
        else
        {
            switch (action.action)
            {
            case 'push':
                // Route to this page with a forward animation.
                this.navCtrl.navigateForward(action.path);
                break;

            case 'root':
                // Reset the content nav to have just this page
                // this.router.navigate([ action.path ], { replaceUrl: true });
                this.navCtrl.navigateRoot(action.path);
                break;

            case 'modal':
                // Display as a modal
                const modal = await this.modalCtrl.create(action.component);
                modal.onDidDismiss().then(action.onDidDismiss);
                modal.present();
                break;
            }
        }
    }

}
