<ion-header><bones-tt-page-header title="Device"></bones-tt-page-header></ion-header>

<ion-content>
<!-- 
  <ion-card class="ion-padding" *ngIf="info?.nativeDevice">
    <ion-card-title>Device Info</ion-card-title>
    <ion-list>
      <ion-item>
        <ion-label>cordova</ion-label>
        <span slot="end">{{ info.nativeDevice.cordova }}</span>
      </ion-item>
      <ion-item>
        <ion-label>model</ion-label>
        <span slot="end">{{ info.nativeDevice.model }}</span>
      </ion-item>
      <ion-item>
        <ion-label>platform</ion-label>
        <span slot="end">{{ info.nativeDevice.platform }}</span>
      </ion-item>
      <ion-item>
        <ion-label>
          uuid
          <div>{{ info.nativeDevice.uuid }}</div>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>version</ion-label>
        <span slot="end">{{ info.nativeDevice.version }}</span>
      </ion-item>
      <ion-item>
        <ion-label>manufacturer</ion-label>
        <span slot="end">{{ info.nativeDevice.manufacturer }}</span>
      </ion-item>
      <ion-item>
        <ion-label>isVirtual</ion-label>
        <span slot="end">{{ info.nativeDevice.isVirtual }}</span>
      </ion-item>
      <ion-item>
        <ion-label>serial</ion-label>
        <span slot="end">{{ info.nativeDevice.serial }}</span>
      </ion-item>
    </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="!info?.nativeDevice">
    <p>Not a native device.</p>
  </ion-card>
-->
  <ion-card class="ion-padding">
    <p>Cordova native device is no longer supported</p>
  </ion-card>

</ion-content>
