import { OverlayEventDetail } from '@ionic/core';
import { BonesEditFormLaunchOptions } from './BonesEditFormLaunchOptions';

/**
 * Results returned from BonesEditForm.open().
 */
export class BonesEditFormLaunchResults
{
    /**
     * Results returned from Ionic modal.
     * overlayEventDetail.data will always be undefined.
     */
    overlayEventDetail: OverlayEventDetail;

    /**
     * Options originally passed to open() method.
     */
    launchOptions: BonesEditFormLaunchOptions;

    /**
     * The action taken by the user.
     */
    action: 'add' | 'update' | 'delete' | 'cancel';

    /**
     * Row populated by the form.
     * In the event the edit form was canceled, the row will be undefined.
     * If the row was deleted, the row will be null.
     */
    row: any;

    /**
     * Payload (if any) reveived from backend server after processing form data.
     */
    payload: any;
}
