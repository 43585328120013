import { Injectable } from '@angular/core';

import { BonesError } from '@bones/core';
import { BonesRestService } from './bones-rest';
import { WebPhoneUser } from '../class/WebPhoneUser';

/**
 * Access webphone to validate and retrieve employee info
 */
@Injectable({
    providedIn: 'root'
})
export class WebphoneService
{
    /**
     * @ignore
     */
    constructor(
        private rest: BonesRestService
    )
    {
    }

    //-----------------------------------------------------------------------

    /**
     * Get info.
     */
    public async getForAttuid(attuid: string) : Promise<WebPhoneUser>
    {
        return this.rest.send('/bones-webphone/getForAttuid', { attuid })
        .then((users: WebPhoneUser[]) =>
        {
            // console.log('WebphoneService.getUserForAttuid', users);
            return users.length === 0 ? undefined : users[0];
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'WebphoneService',
                methodName: 'getForAttuid',
                message: 'Unable to lookup webphone for attuid',
                error: error
            })
            .add({ attuid });
        });
    }

    //-----------------------------------------------------------------------

}
