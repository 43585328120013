// import { Device } from '@ionic-native/device/ngx';

/**
 * Information about running the application
 */
export class DeviceInfo
{
    /**
     * Is this application running in development mode? The actual meaning of development mode is
     * up to the application to decide. By default, development builds start in development mode,
     * and production builds do not. The application can change this value to suite its needs.
     */
    public isDevMode: boolean;

    /**
     * Does this application contain Cordova plugins? Is it the result of a Cordova build vs a
     * simple web implemention via 'ionic serve'? A Cordova build for the browser platform is
     * considered a Cordova build as it will contain Cordova compoents and browser-based plugins.
     */
    public isCordova: boolean;

    /**
     * Is application running under the ionic serve command
     * (or other plain browser non-build experience)?
     */
    public isIonicServe: boolean;

    /**
     * Unique device plus application identifier. The UUID is used for push notifications. It can
     * also be used for any other use requiring a unique ID. This ID is null for browser builds.
     */
    public uuid: string;

    // /**
    //  * Information object from Cordova Device plugin.
    //  */
    // public nativeDevice: Device;

    // Platform settings
    /**
     * Is this application running on an Android platform? This could be an actual Android device
     * or a browser emulating an Android device.
     */
    public isAndroidPlatform: boolean;

    /**
     * Is this application running on an iOS platform? This could be an actual iOS device
     * or a browser emulating an iOS device.
     */
    public isIosPlatform: boolean;

    /**
     * What is an English name for this platform?
     */
    public platformName: 'Android' | 'iOS' | 'browser' | 'unknown';

    // Device settings
    /**
     * Is this application running within a web browser? A native web view component is not
     * considered a browser device when setting this flag.
     */
    public isBrowserDevice: boolean = true;

    /**
     * Is this application running on an actual Android device or simulator?
     */
    public isAndroidDevice: boolean = false;

    /**
     * Is this application running on an actual iOS device or simulator?
     */
     public isIosDevice: boolean = false;

     /**
      * Platform names as assigned by ionic/angular Platform service.
      */
     public platforms: string[] = [ ];

     /**
      * The name of the application.
      */
     public appName: string;

     /**
      * The package name of the application.
      */
     public packageName: string;

     /**
      * The build identifier of the application.
      */
     public versionCode: string | number;

     /**
      * The version number of the app.
      */
     public versionNumber: string;
}
