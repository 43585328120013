import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

import { BonesMenuCardAction } from '../../model/bones-menu-card-action';

/**
 * Display a card with a array of menu items.
 * 
 * Actions is a list of BonesMenuCardAction objects.
 * 
 * @example
 * <bones-menu-card [actions]="actions"></bones-menu-card>
 */
@Component({
  selector: 'bones-menu-card',
  templateUrl: 'bones-menu-card.html'
})
export class BonesMenuCardComponent
{
    /**
     * Array of BonesMenuCardAction objects.
     */
    @Input() actions: BonesMenuCardAction[];

    /**
     * @ignore
     */
    constructor(
        private navCtrl: NavController
    )
    {
    }

    /**
     * Execute menu item.
     * 
     * @param action Action to execute.
     */
    do(action: BonesMenuCardAction)
    {
        if (typeof action.action === 'string')
        {
            this.navCtrl.navigateForward(action.action);
        }
        else
        {
            action.action(action.args);
        }
    }

}
