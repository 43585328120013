import { BreweryInfo } from './BreweryInfo';

export class Brewery
{
    brewery_id: number;

    constructor(public row: BreweryInfo)
    {
        this.brewery_id = row.brewery_id;
    }

    // Needed to make edit modal picker happy
    public get name() : string
    {
        return this.row.name;
    }

}
