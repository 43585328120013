import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { BonesMenuCardAction, BonesItemGroupFactory, BonesItemGroup } from '@bones/core';

import { nowShowing } from '@BeerMonkey/rate/class/nowShowing';
import { Beer } from '@BeerMonkey/rate/class/Beer';
import { BeerFilter, beerNoneGroupFactory } from '@BeerMonkey/rate/service/BeerService';
import { beerNameGroupFactory, beerBreweryGroupFactory, beerStyleGroupFactory, beerRatingGroupFactory, beerAbvGroupFactory } from '@BeerMonkey/rate/service/BeerService';
import { showCard } from '@BeerMonkey/rate/class/showCard';
import { MonkeyService } from '@BeerMonkey/core';
import { BeerInfo } from '@BeerMonkey/rate/class/BeerInfo';

@Component({
    selector: 'beer-list-card',
    templateUrl: 'beer-list-card.html'
})
export class BeerListCardComponent implements OnChanges
{
    @Input() showSearchbar = false;
    @Input() resort = true;
    @Input() showBreweryName = false;
    @Input() search = false;
    @Input() smallDeviceCollapse?: number;

    _title?: string = 'Beers';
    @Input() set title(title: string | undefined)
    {
        this._title = title ?? 'Beers';
    }
    get title() : string | undefined
    {
        return this._title;
    }

    _beers?: Beer[];
    @Input() set beers(beers: Beer[] | undefined)
    {
        if (beers)
        {
            this._beers = beers;
        }
        else
        {
            this._beers = undefined;
            this.groups = undefined;
        }
    }
    get beers() : Beer[] | undefined
    {
        return this._beers;
    }

    _searchTerm: string = '';
    @Input() set searchTerm(searchTerm: string)
    {
        this._searchTerm = searchTerm;
        this.filterContent();
    }
    get searchTerm() : string
    {
        return this._searchTerm;
    }

    // @Input() groupFactory = beerNameGroupFactory();
    groupFactory = beerNameGroupFactory();
    @Input() set group(groupFactory: BonesItemGroupFactory<Beer, BeerInfo> | undefined)
    {
        if (groupFactory)
        {
            this.groupFactory = groupFactory;
        }
    }

    filtered: Beer[] = [ ];
    groups?: BonesItemGroup<Beer>[];
    numberToDisplay = 30;
    preTruncateLength?: number;
    truncated?: boolean;
    showBeers = true;
    cardMenu: BonesMenuCardAction[];

    constructor(
        private monkey: MonkeyService
    )
    {
        this.cardMenu =
        [
            { title: 'Group by Brewery', icon: 'funnel', action: () => this.doSort(beerBreweryGroupFactory()) },
            { title: 'Group by Beer', icon: 'funnel', action: () => this.doSort(beerNameGroupFactory()) },
            { title: 'Group by Style', icon: 'funnel', action: () => this.doSort(beerStyleGroupFactory()) },
            { title: 'Group by Rating (a)', icon: 'funnel', action: () => this.doSort(beerRatingGroupFactory('asc')) },
            { title: 'Group by Rating (d)', icon: 'funnel', action: () => this.doSort(beerRatingGroupFactory('desc')) },
            { title: 'Group by ABV (a)', icon: 'funnel', action: () => this.doSort(beerAbvGroupFactory('asc')) },
            { title: 'Group by ABV (d)', icon: 'funnel', action: () => this.doSort(beerAbvGroupFactory('desc')) },
            { title: 'Ungroup', icon: 'funnel', action: () => this.doSort(beerNoneGroupFactory()) },
            { title: 'Show more', icon: 'add', action: () => this.showMore() },
        ];
    }

    ngOnChanges(changes: SimpleChanges)
    {
        // console.log('BeerListCardComponent.ngOnChanges', changes, this);

        // Content needs to be refiltered when specific attributes are changes
        if (changes.beers || changes.group || changes.resort || changes.searchTerm)
        {
            // console.log('BeerListCardComponent.ngOnChanges: refilter required');
            if (this.beers)
            {
                this.filterContent();
            }
        }
    }

    doSort(group: BonesItemGroupFactory<Beer, BeerInfo>)
    {
        this.groupFactory = group;
        this.filterContent();
    }

    /**
     * Filter, sort, and group ratings
     */
    filterContent()
    {
        if (this.beers)
        {
            // Filter by keyword
            this.filtered = new BeerFilter(this.beers).byKeyword(this.searchTerm).rows;
            if (!this.filtered)
            {
                return;
            }

            // Resort
            if (this.resort)
            {
                // console.log('filterContent: resort', this.groupFactory, this.filtered);
                this.groupFactory.sortItems(this.filtered);
            }

            // Limit display size
            this.preTruncateLength = this.filtered.length;
            this.truncated = this.filtered.length > this.numberToDisplay;
            if (this.truncated)
            {
                this.filtered = this.filtered.slice(0, this.numberToDisplay);
            }

            // Collapse the card if the list is too long for small devices
            if (this.smallDeviceCollapse && this.monkey.isSmallDevice)
            {
                this.showBeers = (this.filtered.length < this.smallDeviceCollapse);
            }

            // Group
            this.groups = this.groupFactory.group(this.filtered);
        }
    }

    async showMore()
    {
        this.numberToDisplay += 30;
        this.filterContent();
    }

    showingText() : string
    {
        return nowShowing(this.showBeers, this.beers?.length ?? 0, this.filtered.length, this.preTruncateLength ?? 0);
    }

    get show() : boolean
    {
        return showCard(this.search, this.searchTerm, this.filtered);
    }

}
