import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { BonesErrorService, BonesError } from '@bones/core';
import { BonesForm } from '@bones/form';

import { TspaceService } from '../../service/tspace';

@Component({
  templateUrl: 'test-tools-tspace.html'
})
export class BonesTestToolsTspacePage
{
    results: any;
    error: BonesError;
    reqForm: BonesForm;

    constructor(
        private loadingCtrl: LoadingController,
        private formBuilder: UntypedFormBuilder,
        private es: BonesErrorService,
        private tspace: TspaceService
    )
    {
        this.reqForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'attuid',
                    title: 'ATTUID',
                    initialValue: window.localStorage.getItem('bones-tt-tspace-attuid'),
                    validator: Validators.required
                }
            ]
        });
    }

    /**
     * Send request
     */
    async send(cmd: string)
    {
        this.results = undefined;
        this.error = undefined;

        try
        {
            const loading = await this.loadingCtrl.create({});
            loading.present();

            // Save latest values to local storage for next time
            const attuid = this.reqForm.getValue('attuid');
            window.localStorage.setItem('bones-tt-tspace-attuid', attuid);

            let pp: Promise<any>;
            switch (cmd)
            {
                case 'p':
                    pp = this.tspace.getProfile(attuid);
                    break;

                case 'a':
                    pp = this.tspace.getAvatar(attuid);
                    break;
            }

            // Send request
            pp.then(payload =>
            {
                loading.dismiss();
                this.results = payload;
            })
            .catch(error =>
            {
                loading.dismiss();
                this.error = error;
                this.es.errorHandler(error);
            });
        }
        catch (error)
        {
            this.error = error;
            this.es.errorHandler(error);
        }
    }

    //-----------------------------------------------------------------------

}
