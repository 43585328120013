import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { BonesCoreModule, BonesTestToolsPiglets } from '@bones/core';
import { BonesFormModule } from '@bones/form';

import { AppCoreRoutingModule } from './routing';
import { AppPageHeaderComponent } from './component/page-header/page-header';
import { AppModalHeaderComponent } from './component/modal-header/modal-header';

import { LoginPage } from './pages/login/login';
// import { TestToolsUserPage } from './pages/test-tools-user/test-tools-user';

@NgModule({
    declarations:
    [
        AppPageHeaderComponent,
        AppModalHeaderComponent,
        LoginPage
        // TestToolsUserPage
    ],
    imports:
    [
        CommonModule,
        FormsModule, ReactiveFormsModule,
        IonicModule,
        BonesCoreModule,
        BonesFormModule,
        AppCoreRoutingModule
    ],
    // Use for services that are referenced only within this module
    providers:
    [
    ],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports:
    [
        AppPageHeaderComponent,
        AppModalHeaderComponent
    ]
})
export class AppCoreModule
{
    static initialize()
    {
        // BonesTestToolsPiglets.tools.push(
        // {
        //     title: 'User',
        //     icon: 'person',
        //     path: 'test-tools/app/user'
        // });
    }
}
AppCoreModule.initialize();
