import { Component, Input, Output, EventEmitter } from '@angular/core';

import { BonesMenuCardAction, BonesItemGroupFactory, BonesItemGroup } from '@bones/core';

import { nowShowing } from '@BeerMonkey/rate/class/nowShowing';
import { Inventory } from '@BeerMonkey/rate/class/Inventory';
import { InventoryFilter } from '@BeerMonkey/rate/service/InventoryService';
import { inventoryBreweryNameGroupFactory } from '@BeerMonkey/rate/service/InventoryService';
import { inventoryBeerNameGroupFactory } from '@BeerMonkey/rate/service/InventoryService';
import { inventoryLocationGroupFactory } from '@BeerMonkey/rate/service/InventoryService';
import { inventoryVersionGroupFactory } from '@BeerMonkey/rate/service/InventoryService';
import { inventoryVintageGroupFactory } from '@BeerMonkey/rate/service/InventoryService';
import { inventoryVvGroupFactory } from '@BeerMonkey/rate/service/InventoryService';
import { inventorySizeGroupFactory } from '@BeerMonkey/rate/service/InventoryService';
import { showCard } from '@BeerMonkey/rate/class/showCard';
import { InventoryInfo } from '@BeerMonkey/rate/class/InventoryInfo';

@Component({
    selector: 'inventory-list-card',
    templateUrl: 'inventory-list-card.html'
})
export class InventoryListCardComponent
{
    @Input() title: string = 'Inventory';
    @Input() showSearchbar = false;
    @Input() resort = true;
    @Input() showInventory = true;
    @Input() search = false;
    @Input() showBeerName = true;

    _showBreweryName = true;
    @Input() set showBreweryName(value: boolean)
    {
        this._showBreweryName = value;
        this.defaultGroup = this.showBreweryName ? inventoryBreweryNameGroupFactory() : inventoryBeerNameGroupFactory();
    }
    get showBreweryName() : boolean
    {
        return this._showBreweryName;
    }

    _showZeros = false;
    @Input() set showZeros(value: boolean)
    {
        this._showZeros = value;
        this.filterContent();
    }
    get showZeros() : boolean
    {
        return this._showZeros;
    }
    @Output() showZerosChange = new EventEmitter<boolean>();

    _inventories?: Inventory[];
    @Input() set inventory(inventory: Inventory[] | undefined)
    {
        this._inventories = inventory;
        this.filterContent();
    }
    get inventory() : Inventory[] | undefined
    {
        return this._inventories;
    }

    _searchTerm: string = '';
    @Input() set searchTerm(searchTerm: string)
    {
        this._searchTerm = searchTerm;
        this.filterContent();
    }
    get searchTerm() : string
    {
        return this._searchTerm;
    }

    groupFactory: BonesItemGroupFactory<Inventory, InventoryInfo> = inventoryBreweryNameGroupFactory();
    @Input() set defaultGroup(groupFactory: BonesItemGroupFactory<Inventory, InventoryInfo>)
    {
        this.groupFactory = groupFactory;
        this.filterContent();
    }

    maybeZero: Inventory[] = [ ];
    filtered: Inventory[] = [ ];
    groups?: BonesItemGroup<Inventory>[];
    numberToDisplay = 30;
    preTruncateLength?: number;
    truncated?: boolean;
    cardMenu: BonesMenuCardAction[];
    bottles?: number;

    constructor(
    )
    {
        this.cardMenu =
        [
            { title: 'Group by Brewery', icon: 'funnel', action: () => this.doSort(inventoryBreweryNameGroupFactory()) },
            { title: 'Group by Beer', icon: 'funnel', action: () => this.doSort(inventoryBeerNameGroupFactory()) },
            { title: 'Group by Location', icon: 'funnel', action: () => this.doSort(inventoryLocationGroupFactory()) },
            { title: 'Group by Version', icon: 'funnel', action: () => this.doSort(inventoryVersionGroupFactory()) },
            { title: 'Group by Vintage (a)', icon: 'funnel', action: () => this.doSort(inventoryVintageGroupFactory('asc')) },
            { title: 'Group by Vintage (d)', icon: 'funnel', action: () => this.doSort(inventoryVintageGroupFactory('desc')) },
            { title: 'Group by Vintage + Version (a)', icon: 'funnel', action: () => this.doSort(inventoryVvGroupFactory()) },
            { title: 'Group by Vintage + Version (d)', icon: 'funnel', action: () => this.doSort(inventoryVvGroupFactory('desc')) },
            { title: 'Group by Size', icon: 'funnel', action: () => this.doSort(inventorySizeGroupFactory()) },
            { title: 'Toggle Zeros', icon: 'funnel', action: () => this.toggleShowZeros() },
        ];
    }

    async ngOnInit()
    {
    }

    ngOnDestroy()
    {
    }

    doSort(group: BonesItemGroupFactory<Inventory, InventoryInfo>)
    {
        this.groupFactory = group;
        this.filterContent();
    }

    /**
     * Filter, sort, and group ratings
     */
    filterContent()
    {
        // Filter zeros in or out as a first step to have it not reflected in the filtered counts
        this.maybeZero = new InventoryFilter(this.inventory ?? [ ]).byZeros(this.showZeros).rows;

        // Filter by keyword
        this.filtered = new InventoryFilter(this.maybeZero).byKeyword(this.searchTerm).rows;

        // Bottle counts
        this.bottles = this.filtered.map(i => i.row.quantity ?? 0).reduce((a, cv) => a + cv, 0);

        // Resort
        if (this.resort)
        {
            this.groupFactory.sortItems(this.filtered);
        }

        // Limit display size
        this.preTruncateLength = this.filtered.length;
        this.truncated = this.filtered.length > this.numberToDisplay;
        if (this.truncated)
        {
            this.filtered = this.filtered.slice(0, this.numberToDisplay);
        }

        // Group
        this.groups = this.groupFactory.group(this.filtered);
    }

    async showMore()
    {
        this.numberToDisplay += 30;
        this.filterContent();
    }

    showingText() : string
    {
        let text = nowShowing(this.showInventory, this.maybeZero.length, this.filtered.length, this.preTruncateLength ?? 0);

        text += ' (beers)';

        if ((this.bottles ?? 0) > 0)
        {
            text += '\nTotal ' + this.bottles + ' (bottles)';
        }

        return text;
    }

    get show() : boolean
    {
        return showCard(this.search, this.searchTerm, this.filtered);
    }

    toggleShowZeros()
    {
        this.showZeros = !this.showZeros;
        this.filterContent();
        this.showZerosChange.emit(this.showZeros);
    }

}
