<div [formGroup]="form" *ngIf="form">
  <ion-item>
    <ion-button fill="clear" slot="end" (click)="showPicker('button', $event)" *ngIf="!item.readonly">
        <ion-icon name="search"></ion-icon>
    </ion-button>
    <div *ngIf="item.picker.readWrite">
      <bones-form-label [item]="item"></bones-form-label>
      <ion-input [formControlName]="item.name" [readonly]="item.readonly"></ion-input>
    </div>
    <div *ngIf="!item.picker.readWrite">
      <bones-form-label [item]="item"></bones-form-label>
      <ion-input [value]="item.picker.getDisplayValue(item.getValue())"
        [title]="item.getValue() ? item.picker.getDisplayValue(item.getValue()) : ''"
        readonly="true"
        (click)="showPicker('input', $event)"></ion-input>
    </div>
  </ion-item>
</div>
