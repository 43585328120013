<ion-header><bones-tt-page-header title="tSpace"></bones-tt-page-header></ion-header>

<ion-content>

  <bones-tt-edsgw-config-card></bones-tt-edsgw-config-card>

  <ion-card class="ion-padding">
    <ion-card-title>Request</ion-card-title>
    <ion-list>
      <bones-form-items [form]="reqForm.form" [items]="reqForm.items"></bones-form-items>
    </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="results">
    <ion-card-title>Results</ion-card-title>
    <pre>{{results | json}}</pre>
  </ion-card>

  <bones-tt-error-card [error]="error"></bones-tt-error-card>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" [disabled]="!reqForm.form.valid" (click)="send('p')">Get Profile</ion-button>
      <ion-button fill="solid" color="primary" [disabled]="!reqForm.form.valid" (click)="send('a')">Get Avatar</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
