import { PipeTransform, Pipe } from '@angular/core';

/**
 * Pipe to format elapsed time since a timestamp as Xd Xh Xm Xs
 *
 * @example
 * {{ row.timestamp | bonesTimeSince }}
 */
@Pipe({
  name: 'bonesTimeSince'
})
export class BonesTimeSincePipe implements PipeTransform
{
    /**
     * Format elapsed time since a timestamp as Xd Xh Xm Xs
     * @param value Date object or string parsable into Date object
     */
    transform(value: string | Date) : string
    {
        if (value === undefined || value === null)
        {
            return '';
        }

        const epoch = (value instanceof Date) ? value : new Date(value);
        const since = Math.round((new Date().getTime() - epoch.getTime()) / 1000);

        let dd = 0;
        let hh = 0;
        let mm = Math.floor(since / 60);
        const ss = (since - mm * 60);

        if (mm > 59)
        {
            hh = Math.floor(mm / 60);
            mm -= hh * 60;

            if (hh > 24)
            {
                dd = Math.floor(hh / 24);
                hh -= dd * 24;
            }
        }

        let formatted = '';
        if (dd > 0)
        {
            formatted = dd + 'd ' + hh + 'h ';
        }
        else if (hh > 0)
        {
            formatted = hh + 'h ' + mm + 'm ';
        }
        else if (mm > 0)
        {
            formatted = mm + 'm ' + ss + 's ';
        }
        else
        {
            formatted = ss + 's ';
        }

        // console.log('BonesTimeSincePipe', value, epoch, new Date(), since, dd, hh, mm, ss, formatted);

        return formatted;
    }

}
