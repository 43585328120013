import { ActivatedRoute, Route, Router, Data } from '@angular/router';

import { BonesSideMenuAction, BonesItemGroupFactory } from '@bones/core';
import { BonesForm } from '@bones/form';

export interface CannedSearchPage
{
    cardTitle: string;
    group?: BonesItemGroupFactory<any, any>;
    route: ActivatedRoute;
    filterForm: BonesForm;
    showFilters: boolean;
    showFilterCard: boolean;
    filter: () => void;
}

interface CannedSearchFilter
{
    name: string;
    value?: string | number;
}

type Implementation<I> = new(...args: any) => I;
type CannedSearchImplementation = Implementation<CannedSearchPage>;

interface CannedSearchOptions
{
    title: string;
    module: string;
    path: string;
    component: CannedSearchImplementation;
    description?: string;
    group?: BonesItemGroupFactory<any, any>;
    filters?: CannedSearchFilter[];
    whiteLabel?: boolean;
}

export class CannedSearch implements CannedSearchOptions
{
    title: string;
    module: string;
    path: string;
    component: CannedSearchImplementation;
    description?: string;
    group?: BonesItemGroupFactory<any, any>;
    filters?: CannedSearchFilter[];
    whiteLabel?: boolean;

    constructor(options: CannedSearchOptions)
    {
        // Object.assign(this, options);
        this.title = options.title;
        this.module = options.module;
        this.path = options.path;
        this.component = options.component;
        this.description = options.description;
        this.group = options.group;
        this.filters = options.filters;
        this.whiteLabel = options.whiteLabel;
    }

    get fullPath() : string
    {
        return this.module + '/' + this.path;
    }

    get route() : Route
    {
        return { path: this.path, component: this.component, data: this };
    }

    get menu() : BonesSideMenuAction
    {
        return { group: 'Canned Searches', title: this.title, path: this.fullPath, action: 'root' };
    }

    navigate(router: Router)
    {
        router.navigate([ this.fullPath ]);
    }

    findFilter(filterName: string) : CannedSearchFilter | undefined
    {
        if (this.filters)
        {
            return this.filters.find(f => f.name === filterName);
        }
        else
        {
            return undefined;
        }
    }

    setFilterValue(filterName: string, value?: string | number) : void
    {
        const filter = this.findFilter(filterName);
        if (filter)
        {
            filter.value = value;
        }
    }
}

export function enableCannedSearch(page: CannedSearchPage) : void
{
    if (page.route.data)
    {
        page.route.data.subscribe((data: Data) =>
        {
            const cs = data as CannedSearch;
            // console.log('CannedSearch', data, cs);

            // Set grouping option
            if (cs.group)
            {
                page.group = cs.group;
            }

            // Populate filters
            if (cs.filters)
            {
                cs.filters.forEach(f => page.filterForm.setValue(f.name, f.value));
                page.showFilters = true;
                page.filter();
            }

            // White label search has external branding
            if (cs.whiteLabel)
            {
                page.showFilterCard = false;
                if (cs.title)
                {
                    page.cardTitle = cs.title;
                }
            }
        });
    }
}
