<ion-header><app-page-header subtitle="Ratings"></app-page-header></ion-header>

<ion-content>
  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="filterTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">

    <ion-row>
      <ion-col size="5">
        <ng-container *ngTemplateOutlet="filterTmpl8"></ng-container>
      </ion-col>

      <ion-col size="7">
        <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<ng-template #filterTmpl8>
  <ion-card class="ion-padding" *ngIf="filterForm && showFilterCard">
    <ion-card-title>
      <ion-icon name="caret-forward" color="primary" (click)="showFilters = true" *ngIf="!showFilters"></ion-icon>
      <ion-icon name="caret-down" color="primary" (click)="showFilters = false" *ngIf="showFilters"></ion-icon>
      <ion-text> Filters</ion-text>
    </ion-card-title>

    <ion-card-content class="ion-no-padding" *ngIf="showFilters">
      <bones-form-items [form]="filterForm.form" [items]="filterForm.items"></bones-form-items>
      <div class="ion-text-right">
        <ion-button (click)="filterForm.resetValues()">Clear</ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ng-template>

<ng-template #ratingTmpl8>
  <rating-list-card [title]="cardTitle" [ratings]="filteredRatings" [showSearchbar]="true" [group]="group" *ngIf="filteredRatings">
  </rating-list-card>
</ng-template>
