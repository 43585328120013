import { BonesSortOrder } from './BonesSortOrder';

/**
 * Sort data rows per configured sort order
 * @param rows array of objects to be sorted
 * @param sortOrder array of properties used for the sorting
 */
export function BonesSort(rows: any[], sortOrder: BonesSortOrder[]) : void
{
    rows.sort((a, b) =>
    {
        let result = 0;

        sortOrder.forEach(so =>
        {
            if (!result && so.sort !== 'none')
            {
                const v1 = so.propertyName ? a[so.propertyName] : so.getValue(a);
                const v2 = so.propertyName ? b[so.propertyName] : so.getValue(b);
                const v1set = (v1 !== null && v1 !== undefined && v1 !== '');
                const v2set = (v2 !== null && v2 !== undefined && v2 !== '');
                // console.log('sort', so, v1, v1set, typeof v1, v2, v2set, typeof v2);

                if (!v1set)
                {
                    result = 1;
                }
                else if (!v2set)
                {
                    result = -1;
                }
                else if (typeof v1 === 'string' && so.sortAs === 'date')
                {
                    result = new Date(v1).getTime() - new Date(v2).getTime();
                }
                else if (typeof v1 === 'string')
                {
                    result = v1.localeCompare(v2);
                }
                else if (typeof v1 === 'number')
                {
                    result = v1 - v2;
                }
                else if (typeof v1 === 'boolean')
                {
                    if (v1 && !v2)
                    {
                        result = 1;
                    }
                    else if (v2 && !v1)
                    {
                        result = -1;
                    }
                }
                else if (typeof v1 === 'object' && 'getTime' in v1)
                {
                    result = v1.getTime() - v2.getTime();
                }
                else
                {
                    console.log('can\'t sort', so, v1, v1set, typeof v1, v2, v2set, typeof v2);
                }

                // Reverse for descending sort unless a value is missing and blanks sort tot the bottom
                if (so.sort === 'desc' && v1set && v2set)
                {
                    result = result > 0 ? -1 : result < 0 ? 1 : 0;
                }
            }
        });

        return result;
    });
}
