/**
 * Search a list of objects for keyword values
 */
export class BonesSearch<T>
{
    private words: string[];

    /**
     * Construct searcher
     * 
     * @param rows rows to search
     * @param phrase one or more space separated words; row must match all individual words
     */
    public constructor(private rows: T[], phrase: string)
    {
        // Split phrase into words
        this.words = phrase.trim().split(/\s+/);
        // console.log('words', this.words);
    }

    /**
     * Execute search
     * 
     * @param getValues function to return a list of string values to be searched from the given object
     */
    public execute(getValues: (row: T) => (string|undefined)[])
    {
        // Filter rows to find matches
        return this.rows.filter(row =>
        {
            // Check each word
            for (let i = 0; (i < this.words.length); ++ i)
            {
                const word = this.words[i];

                // If any words is not found then the whole row fails
                if (!this.matchAny(word, getValues(row)))
                {
                    // console.log('did not find', word, 'in', row);
                    return false;
                }
            }

            // All words were found within the row
            // console.log('All words found', row);
            return true;
        });
    }

    /**
     * Match a word within a list of string values
     */
    private matchAny(word: string, values: (string|undefined)[]) : boolean
    {
        const lcword = word.toLowerCase();

        // Prefix a word with ! to find entries that do not contain the word
        if (word.startsWith('!') && word.length > 1)
        {
            for (let i = 0; (i < values.length); ++ i)
            {
                const lcvalue = values[i] ? values[i].toLowerCase() : undefined;

                if (lcvalue && lcvalue.indexOf(lcword.substr(1)) >= 0)
                {
                    // console.log('negate', word, 'in', value);
                    return false;
                }
            }

            return true;
        }

        // Use "/expr/flags" to search via regular expression
        const isrexexp = word.match(new RegExp('^/(.*?)/([gimy]*)$'));
        if (isrexexp)
        {
            const regexp = new RegExp(isrexexp[1], isrexexp[2]);

            for (let i = 0; (i < values.length); ++ i)
            {
                const matches = values[i] ? values[i].match(regexp) : undefined;
                // console.log('regex', regexp, 'in', value.toLowerCase(), 'matches', matches);
                if (matches)
                {
                    return true;
                }
            }
        }

        // Prefix a word with ^ to find entries that start with the word
        const startswith = (word.startsWith('^') && word.length > 1);

        // Search through all values looking for a match
        for (let i = 0; (i < values.length); ++ i)
        {
            const value = values[i];
            const lcvalue = value ? value.toLowerCase() : undefined;

            if (startswith)
            {
                if (lcvalue && lcvalue.startsWith(lcword.substr(1)))
                {
                    // console.log('startsWith', word, 'in', value);
                    return true;
                }
            }
            else
            {
                if (lcvalue && lcvalue.indexOf(lcword) >= 0)
                {
                    // console.log('found', word, 'in', value);
                    return true;
                }
            }
        }

        return false;
    }

}
