import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BonesErrorService, BonesMenuCardAction } from '@bones/core';
import { BonesForm } from '@bones/form';

import { ChartDataSeries, ChartDataSeri, chartColorScheme, MonkeyService, ChartData } from '@BeerMonkey/core';
import { Brewery, Beer, Rating } from '@BeerMonkey/rate';

import { RatingService } from '@BeerMonkey/rate/service/RatingService';
import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';
import { BeerService } from '@BeerMonkey/rate/service/BeerService';

//-----------------------------------------------------------------------

class RatingsByYear
{
    sources = new Map<string, Set<number>>();
    breweries = new Set<number>();
    beers = new Set<number>();
    ratings = new Set<number>();
    ratingsWithScore: number = 0;
    totalScore: number = 0;

    constructor(public year: number)
    {
    }

    get avgScore() : number
    {
        return this.totalScore / this.ratingsWithScore;
    }
}

class SourcesForYear
{
    constructor(public year: number, public sources: string[], public map: Map<string, Set<number>>)
    {}
}

class DataPoint
{
    constructor(public year: number, public value: number)
    {
    }
}

class ChartLine
{
    constructor(public variable: 'sources' | 'breweries' | 'beers' | 'ratings',
        public title: string,
        public show: (row: RatingsByYear) => void)
    {
    }
}

//-----------------------------------------------------------------------

@Component({
    selector: 'ratings-by-year',
    templateUrl: 'ratings-by-year.html'
})
export class RatingsByYearPage implements OnInit, OnDestroy
{
    filterForm?: BonesForm;
    allRatings: Rating[] = [ ];
    gridRows: RatingsByYear[] = [ ];
    byYear: Map<number, RatingsByYear> = new Map<number, RatingsByYear>();
    breweries?: Brewery[];
    beers?: Beer[];
    ratings?: Rating[];
    sfy?: SourcesForYear;
    points: DataPoint[] = [ ];
    chart?: ChartDataSeries[];
    vchart?: ChartDataSeries[];
    gridTitle?: string;
    chartTitle?: string;
    scheme = chartColorScheme;
    chartLines: ChartLine[];
    pie?: ChartData[];
    pieTitle?: string;
    cardTitle?: string;
    private nal: (() => void)[] = [ ];
    chartCardMenu: BonesMenuCardAction[] = [ ];
    activeChartLine: ChartLine;

    constructor(
        private formBuilder: FormBuilder,
        private es: BonesErrorService,
        public monkey: MonkeyService,
        private breweryDB: BreweryService,
        private beerDB: BeerService,
        private ratingDB: RatingService
    )
    {
        // Create options for lines to chart
        this.chartLines =
        [
            new ChartLine('sources', 'Sources', row => this.showSources(row)),
            new ChartLine('breweries', 'Breweries', row => this.showBreweries(row)),
            new ChartLine('beers', 'Beers', row => this.showBeers(row)),
            new ChartLine('ratings', 'Ratings', row => this.showRatings(row))
        ];

        // Default chart
        this.activeChartLine = this.chartLines[this.chartLines.length - 1];

        // Build card menu for chart to select different chart lines
        this.chartLines.forEach(cl =>
        {
            this.chartCardMenu.push({ title:  cl.title, icon: 'stats-chart', action: () => 
            {
                this.activeChartLine = cl;
                this.rebuild();
            }});
        });
    }

    async ngOnInit()
    {
        // Create filter form
        this.createForm();

        // Load and refresh ratings as needed
        this.nal.push(this.ratingDB.cache.nowAndLater(
        ratings =>
        {
            this.allRatings = ratings;
            this.rebuild();
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    //-----------------------------------------------------------------------

    createForm()
    {
        this.filterForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'onlyNewRatings',
                    title: 'Show only new breweries or beers',
                    type: 'toggle',
                    onChange: () => this.rebuild(),
                }
            ]
        });
    }

    get onlyNewRatings() : boolean
    {
        return this.filterForm && this.filterForm.getValue('onlyNewRatings');
    }

    get onlyNewRatingsText() : string
    {
        return this.onlyNewRatings ? 'New' : 'All';
    }

    //-----------------------------------------------------------------------

    rebuild()
    {
        this.clearDrillDownCards();
        this.buildGrid();

        // Extract data points
        this.points = this.gridRows
            .map(row => new DataPoint(row.year, row[this.activeChartLine.variable].size))
            .sort((a, b) => a.year - b.year);

        if (this.monkey.isLargeDevice)
        {
            this.buildVertChart();
            this.buildPie();
        }
        else
        {
            this.buildChart();
        }
    }

    //-----------------------------------------------------------------------

    buildGrid()
    {
        // Find the first year in which a brewery or beer was rated
        const firstBreweryYear = new Map<number, number>();
        const firstBeerYear = new Map<number, number>();
        const firstSourceYear = new Map<string, number>();

        // This depends on ratings sorted in desc rating date, so last update to map will be the oldest year
        if (this.onlyNewRatings)
        {
            this.allRatings.forEach(rating =>
            {
                // Skip ratings without a valid beer or brewery
                if (!rating.beer || !rating.beer.brewery)
                {
                    return;
                }

                const year = rating.ratingDate.getFullYear();
                firstBreweryYear.set(rating.beer.brewery.brewery_id, year);
                firstBeerYear.set(rating.beer.beer_id, year);
                if (rating.row.source)
                {
                    firstSourceYear.set(rating.row.source.trim(), year);
                }
            });
        }

        this.byYear = new Map<number, RatingsByYear>();
        this.allRatings.forEach(rating =>
        {
            // Skip ratings without a valid beer or brewery
            if (!rating.beer || !rating.beer.brewery)
            {
                return;
            }

            // Group ratings by year
            const year = rating.ratingDate.getFullYear();

            let rby = this.byYear.get(year);
            if (!rby)
            {
                rby = new RatingsByYear(year);
                this.byYear.set(year, rby);
            }

            // Group ratings by breweries
            if (!this.onlyNewRatings || firstBreweryYear.get(rating.beer?.brewery?.brewery_id) === year)
            {
                rby.breweries.add(rating.beer.brewery.brewery_id);
            }

            // Group ratings by beers
            if (!this.onlyNewRatings || firstBeerYear.get(rating.beer.beer_id) === year)
            {
                rby.beers.add(rating.beer.beer_id);
                rby.ratings.add(rating.rating_id);

                if (rating.rsc > 0)
                {
                    ++rby.ratingsWithScore;
                    rby.totalScore += rating.rsc;
                }
            }

            // Group ratings by place
            const place = rating.place;
            if (!this.onlyNewRatings || firstSourceYear.get(place) === year)
            {
                let source = rby.sources.get(place);
                if (source)
                {
                    source.add(rating.rating_id);
                }
                else
                {
                    rby.sources.set(place, new Set<number>().add(rating.rating_id));
                }
            }
        });

        this.gridRows = Array.from(this.byYear.values());
        this.gridTitle = this.onlyNewRatingsText + ' Ratings by Year';
        // console.log('this.rows', this.rows);
    }

    //-----------------------------------------------------------------------

    async buildChart()
    {
        // Create lines for the chart
        const charts: ChartDataSeries[] = [ ];

        // Build chart value line
        const valueLine = new ChartDataSeries(this.activeChartLine.title);
        charts.push(valueLine);
        this.points.forEach(dataPoint => valueLine.series.push(
        {
            name: dataPoint.year.toString(),
            value: dataPoint.value,
            extra: dataPoint
        }));

        // Assign the variable used by ng-charts last to trigger redraw only when all data is ready
        this.chartTitle = this.onlyNewRatingsText + ' ' + this.activeChartLine.title + ' by Year';
        // console.log('charts', charts);
        this.chart = [ ...charts ];
    }

    async buildVertChart()
    {
        // Create lines for the chart
        const charts: ChartDataSeries[] = [ ];

        // Build chart value line
        this.points.forEach(dataPoint =>
        {
            const valueLine = new ChartDataSeries(dataPoint.year.toString());
            charts.push(valueLine);

            valueLine.series.push(
            {
                name: this.onlyNewRatingsText,
                value: dataPoint.value,
                extra: dataPoint
            });
        });

        // Assign the variable used by ng-charts last to trigger redraw only when all data is ready
        this.chartTitle = this.onlyNewRatingsText + ' ' + this.activeChartLine.title + ' by Year';
        // console.log('hcharts', charts);
        this.vchart = [ ...charts ];
    }

    async onChartClick(cd: ChartDataSeri)
    {
        // console.log('clicked', cd);
        const dataPoint: DataPoint = cd.extra;
        const rby = this.byYear.get(dataPoint.year);
        if (rby)
        {
            this.activeChartLine.show(rby);
        }
    }

    //-----------------------------------------------------------------------

    buildPie()
    {
        // Create lines for the chart
        const slices: ChartData[] = [ ];

        // Build chart value line
        this.points.forEach(dataPoint =>
        {
            slices.push(
            {
                name: dataPoint.year.toString(),
                value: dataPoint.value,
                extra: dataPoint
            });
        });

        // Assign the variable used by ng-charts last to trigger redraw only when all data is ready
        this.pieTitle = this.onlyNewRatingsText + ' ' + this.activeChartLine.title + ' by Year';
        // console.log('pie', slices);
        this.pie = [ ...slices ];
    }

    onPieClick(cd: ChartDataSeri)
    {
        // console.log('onPieClick', cd);
        this.onChartClick(cd);
    }

    //-----------------------------------------------------------------------

    clearDrillDownCards()
    {
        this.breweries = undefined;
        this.beers = undefined;
        this.ratings = undefined;
        this.sfy = undefined;
    }

    async showBreweries(row: RatingsByYear)
    {
        this.clearDrillDownCards();
        this.breweries = (await this.breweryDB.cache.getList()).filter(b => row.breweries.has(b.brewery_id));
        this.cardTitle = this.onlyNewRatingsText + ' Breweries for ' + row.year;

        this.scrollToCard('#breweriesCard');
    }

    async showBeers(row: RatingsByYear)
    {
        this.clearDrillDownCards();
        this.beers = (await this.beerDB.cache.getList()).filter(b => row.beers.has(b.beer_id));
        this.cardTitle = this.onlyNewRatingsText + ' Beers for ' + row.year;

        this.scrollToCard('#beersCard');
    }

    async showRatings(row: RatingsByYear)
    {
        this.clearDrillDownCards();
        this.ratings = (await this.ratingDB.cache.getList()).filter(r => row.ratings.has(r.rating_id));
        this.cardTitle = this.onlyNewRatingsText + ' Ratings for ' + row.year;

        this.scrollToCard('#ratingsCard');
    }

    async showSources(row: RatingsByYear)
    {
        this.clearDrillDownCards();
        this.sfy = new SourcesForYear(row.year, Array.from(row.sources.keys()).sort((a, b) => a.localeCompare(b)), row.sources);

        this.scrollToCard('#sourcesCard');
    }

    async pickSource(sfy: SourcesForYear, source: string)
    {
        const ids = sfy.map.get(source) || new Set();

        // Populate ratings card with ratings from the source for the given year
        this.ratings = (await this.ratingDB.cache.getList()).filter(r => ids.has(r.rating_id));
        this.cardTitle = this.onlyNewRatingsText + ' Ratings for ' + sfy.year + ' from ' + source;

        this.scrollToCard('#ratingsCard');
    }

    get showingDrilldown() : boolean
    {
        return this.ratings !== undefined || this.beers !== undefined || this.breweries !== undefined;
    }
    
    private scrollToCard(id: string)
    {
        if (!this.monkey.isLargeDevice)
        {
            setTimeout(() =>
            {
                const el = document.querySelector(id);
                if (el)
                {
                    el.scrollIntoView({ behavior: 'smooth' });
                }
            });
        }
    }

    //-----------------------------------------------------------------------

}
