import { Component } from '@angular/core';
import { VERSION } from '@angular/core';

import { BonesCoreService } from '../../service/bones-core.service';
import { BonesDeviceService } from '../../service/device.service';
import { BonesVersion } from '../../model/bones-version';
import { DeviceInfo } from '../../model/device-info';

@Component({
  templateUrl: 'test-tools-versions.html'
})
export class BonesTestToolsVersionsPage
{
    pluglist: Array<any>;
    info: DeviceInfo;
    bonesVersionMap = BonesVersion.map;
    bonesModuleNames = Array.from(BonesVersion.map.keys());
    ionicVersion = '';
    angularVersion = VERSION;

    constructor(
        public bore: BonesCoreService,
        public bds: BonesDeviceService
    )
    {
        this.init();
    }

    async init()
    {
        try
        {
            this.info = await this.bds.getInfo();

            // Load cordova info
            if (this.info.isCordova)
            {
                // Get list of plugins
                const pluginList = (window as any).cordova.require('cordova/plugin_list');

                // Convert metadata map into list for display
                this.pluglist = new Array<any>();
                for (const name in pluginList.metadata)
                {
                    this.pluglist.push({ name: name, version: pluginList.metadata[name] });
                }

                // Sort by name
                this.pluglist.sort((a, b) => a.name.localeCompare(b.name));
            }
        }
        catch (error)
        {
            console.log('error getting info', error);
        }
    }

}
