import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';
import { BonesGalleryService } from '@bones/gallery';

import { ApeRest } from '@BeerMonkey/core';
import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';
import { Brewery } from '@BeerMonkey/rate/class/Brewery';
import { GlasswareInfo } from '@BeerMonkey/rate/class/GlasswareInfo';
import { GlasswareService } from '@BeerMonkey/rate/service/GlasswareService';

@Component({
    selector: 'glassware-edit',
    templateUrl: 'glassware-edit.html'
})
export class GlasswareEditModal extends BonesEditForm<GlasswareInfo> implements OnInit
{
    brewery?: Brewery;
    initialQuantity?: number;

    constructor(
        protected formBuilder: FormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: ApeRest,
        private gallery: BonesGalleryService,
        private breweryDB: BreweryService,
        private glasswareDB: GlasswareService
    )
    {
        super();
    }

    async ngOnInit()
    {
        await super.initialize(
        {
            columns:
            [
                {
                    name: 'glassware_id',
                    hidden: true
                },
                {
                    name: 'image_front_id',
                    hidden: true
                },
                {
                    name: 'image_back_id',
                    hidden: true
                },
                {
                    name: 'brewery_id',
                    title: 'Brewery',
                    picker: () => this.breweryDB.cache.getPickerMap('name'),
                    validator: Validators.required,
                    onChange: breweryID => this.setBrewery(+breweryID)
                },
                {
                    name: 'glass',
                    title: 'Glass',
                    validator: [ Validators.required, Validators.maxLength(64) ]
                },
                {
                    name: 'style',
                    title: 'Style',
                    picker:
                    {
                        populator: () => this.glasswareDB.getPicker('style'),
                        readWrite: true
                    },
                    validator: [ Validators.required, Validators.maxLength(32) ]
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    // hideTitle: true,
                    type: 'textarea'
                },
                {
                    name: 'quantity',
                    title: 'Quantity',
                    type: 'number'
                },
                {
                    name: 'image_front',
                    title: 'Image (front)',
                    type: 'file'
                },
                {
                    name: 'image_back',
                    title: 'Image (back)',
                    type: 'file'
                },
            ],
            uploadFormat: 'form',
            saveUrl: '/rate/glassware/update/updateGlassware',
            cache: this.glasswareDB.cache,
            postSave: (row, payload) =>
            {
                // If row supplied an image, then update the document cache with the new image
                if (row.image_front)
                {
                    this.gallery.cache.updated(payload.documentID1);
                }
                if (row.image_back)
                {
                    this.gallery.cache.updated(payload.documentID2);
                }

                return Promise.resolve('');
            }
        });

        // Defaults for new row
        if (this.isAdd)
        {
            this.bonesForm.setValue('brewery_id', this.launchOptions.moData.breweryID);
        }
    }

    private setBrewery(breweryID: number)
    {
        if (breweryID)
        {
            this.breweryDB.getBrewery(breweryID)
            .then(brewery => this.brewery = brewery)
            .catch(error => this.bes.errorHandler(error));
        }
    }
}
