<ion-header><bones-tt-page-header title="Versions"></bones-tt-page-header></ion-header>

<ion-content>

  <ion-card class="ion-padding">
    <ion-card-title>Frameworks</ion-card-title>
    <ion-list>
      <ion-item>
        <ion-label>Ionic</ion-label>
        <span slot="end">{{ ionicVersion }}</span>
      </ion-item>
      <ion-item>
        <ion-label>Angular</ion-label>
        <span slot="end">{{ angularVersion.full }}</span>
      </ion-item>
      <!-- <ion-item *ngIf="info?.nativeDevice">
        <ion-label>Cordova</ion-label>
        <span slot="end">{{ info.nativeDevice.cordova }}</span>
      </ion-item> -->
    </ion-list>
  </ion-card>

  <ion-card class="ion-padding">
    <ion-card-title>Bones</ion-card-title>
    <ion-list>
      <ion-item *ngFor="let moduleName of bonesModuleNames">
        <ion-label>@bones/{{ moduleName }}</ion-label>
        <span slot="end">{{ bonesVersionMap.get(moduleName) }}</span>
      </ion-item>
    </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="info?.appName">
    <ion-card-title>AppVersion</ion-card-title>
    <ion-list>
      <ion-item>
        <ion-label>AppName</ion-label>
        <span slot="end">{{ info.appName }}</span>
      </ion-item>
      <ion-item>
        <ion-label>PackageName</ion-label>
        <span slot="end">{{ info.packageName }}</span>
      </ion-item>
      <ion-item>
        <ion-label>VersionCode</ion-label>
        <span slot="end">{{ info.versionCode }}</span>
      </ion-item>
      <ion-item>
        <ion-label>VersionNumber</ion-label>
        <span slot="end">{{ info.versionNumber }}</span>
      </ion-item>
    </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="pluglist">
    <ion-card-title>Plugins</ion-card-title>
    <ion-list *ngFor="let plug of pluglist">
      <ion-item>
        <ion-label>{{ plug.name }}</ion-label>
        <span slot="end">{{ plug.version }}</span>
      </ion-item>
    </ion-list>
  </ion-card>

</ion-content>
