<ion-header><app-page-header subtitle="Search"></app-page-header></ion-header>

<ion-content>
  <ng-container *ngTemplateOutlet="searchTmpl8"></ng-container>

  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="inventoryTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="glasswareTmpl8"></ng-container>
    <!-- <ng-container *ngTemplateOutlet="noResultsTmpl8"></ng-container> -->

    <div *ngIf="!searchTerm">
      <ng-container *ngTemplateOutlet="recentSearchTermsTmpl8"></ng-container>
      <ng-container *ngTemplateOutlet="recentBreweriesTmpl8"></ng-container>
      <ng-container *ngTemplateOutlet="recentBeersTmpl8"></ng-container>
    </div>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">

    <ion-row>
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="inventoryTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="glasswareTmpl8"></ng-container>
        <!-- <ng-container *ngTemplateOutlet="noResultsTmpl8"></ng-container> -->
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!searchTerm">
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="recentSearchTermsTmpl8"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="recentBreweriesTmpl8"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="recentBeersTmpl8"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<ng-template #searchTmpl8>
  <ion-searchbar [(ngModel)]="searchTerm" (ionChange)="doSearch(searchTerm)" debounce="750"></ion-searchbar>
</ng-template>

<ng-template #breweryTmpl8>
  <brewery-list-card #breweryCard [search]="true" [breweries]="allBreweries" [searchTerm]="searchTerm"
                     [showBreweries]="breweryCard.filtered.length < 12">
  </brewery-list-card>
</ng-template>

<ng-template #beerTmpl8>
  <beer-list-card #beerCard [search]="true" [beers]="allBeers" [searchTerm]="searchTerm" [showBreweryName]="true"
                  [smallDeviceCollapse]="12">
  </beer-list-card>
</ng-template>

<ng-template #ratingTmpl8>
  <rating-list-card #ratingCard [search]="true" [ratings]="allRatings" [searchTerm]="searchTerm"
                    [showRatings]="ratingCard.filtered.length < 12">
  </rating-list-card>
</ng-template>

<ng-template #inventoryTmpl8>
  <inventory-list-card #inventoryCard [search]="true" [inventory]="allInventory" [searchTerm]="searchTerm"
                       [showZeros]="false" [showInventory]="inventoryCard.filtered.length < 12">
  </inventory-list-card>
</ng-template>

<ng-template #glasswareTmpl8>
  <glassware-list-card #glasswareCard [search]="true" [glassware]="allGlassware" [searchTerm]="searchTerm"
                       [showGlassware]="glasswareCard.filtered.length < 12">
  </glassware-list-card>
</ng-template>

<ng-template #noResultsTmpl8>
  <ion-card class="ion-padding" *ngIf="searchTerm && !breweryCard?.show && !beerCard?.show && !ratingCard?.show
      && !inventoryCard?.show && !glasswareCard?.show">
    <ion-card-title>No Results</ion-card-title>
    <p>No results matching your query</p>
  </ion-card>
</ng-template>

<ng-template #recentSearchTermsTmpl8>
  <ion-card class="ion-padding">
    <bones-card-toolbar #rkw [title]="'Recent Searches'" [expandable]="true" [expanded]="true"></bones-card-toolbar>
    <ion-list *ngIf="rkw.expanded">
      <ion-item [detail]="true" (click)="doSearch(searchTerm)" *ngFor="let searchTerm of recentSearchTerms">
        <ion-label>{{ searchTerm }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-card>
</ng-template>

<ng-template #recentBreweriesTmpl8>
  <brewery-list-card title="Recently Accessed Breweries" [breweries]="recentBreweries" [group]="recentBreweryGrouping"
    [showSearchbar]="true" [showBreweries]="true">
  </brewery-list-card>
</ng-template>

<ng-template #recentBeersTmpl8>
  <beer-list-card title="Recently Accessed Beers" [beers]="recentBeers" [showBreweryName]="true" [resort]="false"
    [showSearchbar]="true" [group]="recentBeerGrouping">
  </beer-list-card>
</ng-template>
