import { Injectable } from '@angular/core';

/**
 * Cache information about receint vews and searches
 */
@Injectable({
  providedIn: 'root',
})
export class RecentSearchService
{
    recentSearchTerms: string[];
    recentBreweries: number[];
    recentBeers: number[];

    private static searchTermStorageKey = 'beer.monkey.recentSearchTerms';
    private static breweryStorageKey = 'beer.monkey.recentBreweries';
    private static beerStorageKey = 'beer.monkey.recentBeers';

    constructor(
    )
    {
        // Get recent items saved to device
        this.recentSearchTerms =  JSON.parse(window.localStorage.getItem(RecentSearchService.searchTermStorageKey) || '[]');
        this.recentBreweries =  JSON.parse(window.localStorage.getItem(RecentSearchService.breweryStorageKey) || '[]');
        this.recentBeers =  JSON.parse(window.localStorage.getItem(RecentSearchService.beerStorageKey) || '[]');
    }

    //-----------------------------------------------------------------------

    /**
     * Add a search term to the list of recentl searches
     */
    addRecentSearchTerm(searchTerm: string) : void
    {
        this.add(this.recentSearchTerms, searchTerm, RecentSearchService.searchTermStorageKey);
    }

    /**
     * Get recently search terms (most recent first)
     */
    getRecentSearchTerms() : string[]
    {
        return this.recentSearchTerms;
    }

    //-----------------------------------------------------------------------

    /**
     * Add a breweryID to the list of recently accessed breweries
     */
    addRecentBrewery(breweryID: number) : void
    {
        this.add(this.recentBreweries, breweryID, RecentSearchService.breweryStorageKey);
    }

    /**
     * Get recently accessed breweries (most recent first)
     */
    getRecentBreweryIDs() : number[]
    {
        return this.recentBreweries;
    }

    /**
     * Add a beerID to the list of recently accessed beers
     */
    addRecentBeer(beerID: number) : void
    {
        this.add(this.recentBeers, beerID, RecentSearchService.beerStorageKey);
    }

    /**
     * Get recently accessed beers (most recent first)
     */
    getRecentBeerIDs() : number[]
    {
        return this.recentBeers;
    }

    //-----------------------------------------------------------------------

    /**
     * Add item to list and persist
     */
    private add(list: (typeof id)[], id: number | string, storageKey: string) : void
    {
        // Remove entry if it already exists
        const exists = list.indexOf(id);
        if (exists >= 0)
        {
            list.splice(exists, 1);
        }

        // Add new entry to beginning of list
        list.unshift(id);

        // Limit size of list
        list.length = Math.min(list.length, 50);

        // Save modified list to device
        window.localStorage.setItem(storageKey, JSON.stringify(list));
    }

    //-----------------------------------------------------------------------

}
