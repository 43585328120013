import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LoadingController, AlertController, ModalController, NavParams } from '@ionic/angular';

import { BonesErrorService } from '@bones/core';
import { BonesEditForm } from '../../class/BonesEditForm';
import { BonesRestService } from '@bones/network';

import { BonesTestToolsEditMockDataService, MyRowClass } from '../../service/test-tools-mock-data';

@Component({
  templateUrl: 'test-tools-edit-form.html'
})
export class BonesTestToolsEditFormPage extends BonesEditForm<MyRowClass>
{
    display: MyRowClass;

    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: BonesRestService,
        private data: BonesTestToolsEditMockDataService
    )
    {
        super();
        super.initialize(
        {
            columns:
            [
                {
                    name: 'FIELD1',
                    hidden: true
                },
                {
                    name: 'FIELD2',
                    title: 'Field Two',
                    validator: Validators.required
                },
                {
                    name: 'FIELD3',
                    title: 'Read-only field with picker',
                    picker: [ 'This', 'That', 'The other' ]
                },
                {
                    name: 'FIELD4',
                    title: 'Type or pick value',
                    picker:
                    {
                        readWrite: true,
                        values: [ 'More', 'Options', 'Shaking', 'Stick' ]
                    }
                },
                {
                    name: 'FIELD5',
                    title: 'Toggle',
                    toggle: { on: 'Y', off: 'N' }
                },
            ],
            preSave: (row: MyRowClass) : string =>
            {
                this.display = row;
                return 'Save disabled';
            },
            saveUrl: '/xxx/yyyy',
            // disableDelete: true,
            getEditRow: () : Promise<MyRowClass> =>
            {
                return this.data.getRow(this.pk);
            }
        });
    }
}
