import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

/**
 * Create app icon on a bones-app-launcher-pane.
 * 
 * @example
 * <bones-app-launcher-pane>
 *   <div class="app-launcher-icons">
 *     <bones-app-launcher-icon icon="beer" title="Breweries Around Here" #beer></bones-app-launcher-icon>
 *     <bones-app-launcher-icon icon="wine" title="Wineries Around Here" #wine></bones-app-launcher-icon>
 *   </div>
 *   <div class="app-launcher-body">
 *     <div *ngIf="beer.active">Here is beer!</div>
 *     <div *ngIf="wine.active">Here is wine!</div>
 *   </div>
 * </bones-app-launcher-pane>
 */
@Component({
  selector: 'bones-app-launcher-icon',
  templateUrl: 'bones-app-launcher-icon.html',
  styleUrls: [ 'bones-app-launcher-icon.scss' ]
})
export class BonesAppLauncherIconComponent implements OnInit
{
    /**
     * Title to display below the icon.
     */
    @Input() title: string;

    /**
     * Icon to be displayed @see ion-icon
     */
    @Input() icon: string;

    /**
     * Is the app currently active aka opened aka maximized?
     */
    @Input() active = false;

    /**
     * Should this icon be displayed?
     */
    // @Input() show = true;
    @Input() set show(show: boolean)
    {
        this._show = show;
        // Hide card when icon becomes hidden
        if (!this._show && this.active)
        {
            this.toggle();
        }
    }
    /**
     * Should this icon be displayed?
     */
    get show() : boolean
    {
        return this._show;
    }
    private _show = true;

    /**
     * Does this icon route to another page?
     */
    @Input() routerLink: any[];

    /**
     * Even for when the app is maximized.
     */
    @Output() maximize: EventEmitter<BonesAppLauncherIconComponent> = new EventEmitter();

    /**
     * Even for when the app is minimized.
     */
    @Output() minimize: EventEmitter<BonesAppLauncherIconComponent> = new EventEmitter();

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

    /**
     * @ignore
     */
    ngOnInit()
    {
    }

    /**
     * Toggle state of app between minimize and maximize.
     */
    toggle() : void
    {
        // This icon is used to route to another page
        if (this.routerLink)
        {
            this.maximize.emit(this);
            return;
        }

        // Toggle active state
        this.active = !this.active;

        if (this.active)
        {
            this.maximize.emit(this);
        }
        else
        {
            this.minimize.emit(this);
        }
    }

    /**
     * Close app aka minimize.
     */
    close() : void
    {
        this.active = false;
        this.minimize.emit(this);
    }
}
