import { Injectable } from '@angular/core';

import { BonesError, BonesSortOption, BonesSearch, BonesItemGroupFactory } from '@bones/core';
import { BonesCache, BonesCacheFactory } from '@bones/core';

import { ApeRest } from '@BeerMonkey/core/service/ApeRest';
import { BeerInfo } from '../class/BeerInfo';
import { Beer } from '../class/Beer';
import { BeerStyle } from '../class/BeerStyle';
import { BreweryService } from './BreweryService';
import { formatNumber } from '@angular/common';

/**
 * Access db information
 */
@Injectable({
  providedIn: 'root',
})
export class BeerService
{
    cache: BonesCache<number, BeerInfo, Beer>;
    stylesPromise?: Promise<BeerStyle[]>;

    constructor(
        private bcf: BonesCacheFactory,
        private rest: ApeRest,
        private breweryDB: BreweryService
    )
    {
        this.cache = this.bcf.create<number, BeerInfo, Beer>(
        {
            pk: 'beer_id',
            loadCache: () => this.rest.send('rate/beer/getBeers'),
            reloadOne: (id: number) => this.rest.send('rate/beer/getBeer', { beerID: id }),
            converter: async (info: BeerInfo) : Promise<Beer> =>
            {
                // const beer = new Beer(info, breweryDB);
                const beer = new Beer(info);

                beer.brewery = await this.breweryDB.getBrewery(beer.row.brewery_id);

                return beer;
            },
            sorter: (a: Beer, b: Beer) =>
            {
                return a.row.name.localeCompare(b.row.name);
            }
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Get beer details
     */
    async getBeer(beerID: number) : Promise<Beer | undefined>
    {
        return beerID ? this.cache.getEntry(beerID) : undefined;
    }

    /**
     * Get details for a list of beers
     */
    async getBeers(beerIDs: number[]) : Promise<Beer[]>
    {
        const beers: Beer[] = [ ];

        for (let i = 0; (i < beerIDs.length); ++i)
        {
            const beer = await this.getBeer(beerIDs[i]);
            if (beer)
            {
                beers.push(beer);
            }
        }

        return beers;
    }

    //-----------------------------------------------------------------------

    /**
     * Get popular styles
     */
    async getStyles() : Promise<BeerStyle[]>
    {
        if (!this.stylesPromise)
        {
            this.stylesPromise = this.rest.send('/rate/beer/getStyles')
            .catch(error =>
            {
                throw new BonesError(
                {
                    className: 'BeerService',
                    methodName: 'getStyles',
                    message: 'Unable to get styles',
                    error: error
                });
            });
        }

        return this.stylesPromise;
    }

    /**
     * Get a picker of popular styles
     */
    async getStylePicker() : Promise<string[]>
    {
        return this.getStyles()
        .then(styles => styles.map(s => s.style));
    }

    /**
     * Get a picker of all styles, even the one-offs
     */
    async getFullStylePicker() : Promise<string[]>
    {
        // Extract all styles from beer entries, use a set to reduce to unique values, extract keys into a list and sort
        return Array.from(new Set<string>((await this.cache.getList()).map(b => b.row.style ?? 'no style')).keys()).sort();
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

export const beerNameGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Beer, BeerInfo>('name', { sort: sort, byFirstLetter: true });

export const beerStyleGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Beer, BeerInfo>('style', { sort: sort });

export const beerBreweryGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Beer, BeerInfo>((item) => item.brewery?.name ?? 'no brewery', { sort: sort });

export const beerRatingGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Beer, BeerInfo>((item) => item.row.ratebeer_score ? String(item.row.ratebeer_score) : '', { sort: sort });

export const beerAbvGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Beer, BeerInfo>((item) => item.row.abv ? formatNumber(item.row.abv, 'en', '2.0-0') + '%' : '', { sort: sort });

export const beerNoneGroupFactory = (title = '') =>
    new BonesItemGroupFactory<Beer, BeerInfo>(() => title, { sort: 'none' });

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter beers
 */
export class BeerFilter
{
    constructor(public rows: Beer[])
    {
    }

    byBrewery(breweryID: number) : BeerFilter
    {
        if (breweryID)
        {
            this.rows = this.rows.filter(r => r.row.brewery_id === breweryID);
        }

        return this;
    }

    by(key: keyof BeerInfo, value: string) : BeerFilter
    {
        if (value)
        {
            this.rows = this.rows.filter(r => r.row[key] === value);
        }

        return this;
    }

    byKeyword(phrase: string) : BeerFilter
    {
        if (phrase)
        {
            this.rows = new BonesSearch<Beer>(this.rows, phrase)
            .execute(r => [ r.row.name, r.row.style, r.row.notes, r.brewery?.name ]);
        }

        return this;
    }
}

