import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BonesEditForm } from '../../class/BonesEditForm';

import { BonesTestToolsEditMockDataService, MyRowClass } from '../../service/test-tools-mock-data';
import { BonesTestToolsEditFormPage } from '../test-tools-edit-form/test-tools-edit-form';

/**
 * Display mock grid
 */
@Component({
  templateUrl: 'test-tools-edit-grid.html'
})
export class BonesTestToolsEditGridPage
{
    rows: MyRowClass[];

    constructor(
        private modalCtrl: ModalController,
        private data: BonesTestToolsEditMockDataService
    )
    {
        this.getData();
    }

    /**
     * Populate grid data
     */
    async getData()
    {
        this.rows = await this.data.getRows();
    }

    /**
     * Launch edit screen
     */
    edit(row?: MyRowClass)
    {
        BonesEditForm.open({
            modalCtrl: this.modalCtrl,
            editPage: BonesTestToolsEditFormPage,
            // cssClass: 'bones-edit-form-90-80',
            // cssClass: 'bones-edit-form-100',
            pk: row ? row.FIELD1 : undefined
        }).then(results =>
        {
            console.log('BonesEditForm results', results);
        });
    }

}
