import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

import { BonesGalleryService } from '@bones/gallery';

import { AppUser, UserService } from '@BeerMonkey/core';

import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';
import { Brewery } from '@BeerMonkey/rate/class/Brewery';
import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';

@Component({
  selector: 'brewery-list',
  templateUrl: 'brewery-list.html'
})
export class BreweryListComponent
{
    breweryList: Brewery[] = [ ];
    @Input() set breweries(list: Brewery[])
    {
        this.breweryList = list;
    }

    user: AppUser;

    breweryLogo = (brewery: Brewery) : number | undefined => this.breweryDB.getBreweryLogoID(brewery);

    constructor(
        private navCtrl: NavController,
        public gallery: BonesGalleryService,
        private mtus: UserService,
        public launch: LaunchEditModalService,
        private breweryDB: BreweryService
    )
    {
        this.user = this.mtus.getUser();
    }

    breweryDetail(brewery_id: number, event: Event)
    {
        this.navCtrl.navigateForward([ '/rate/brewery', brewery_id ]);
        event.stopPropagation();
    }

}
