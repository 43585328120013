import { Injectable } from '@angular/core';

import { BonesDeviceService } from './device.service';

/**
 * A collection of utilities dealing with opening browser windows.
 */
@Injectable({
  providedIn: 'root'
})
export class BonesOpenService
{
    /**
     * @ignore
     */
    constructor(
        private device: BonesDeviceService
    )
    {
    }

    //-----------------------------------------------------------------------

   /**
    * Open mailto: link on a variety of devices
    */
   openMailLink(url: string) : void
   {
        this.device.getInfo()
        .then(info =>
        {
            if (info.isBrowserDevice)
            {
                window.open(url, '_self');
            }
            else if (info.isIosDevice)
            {
                window.open(url, '_self');
            }
            else if (info.isAndroidDevice)
            {
                window.open(url, '_system');
            }
        });
   }

   /**
    * Open http:// web link on a variety of devices
    */
   openWebLink(url: string) : void
   {
       window.open(url, '_blank');  //Loads in the InAppBrowser
//                window.open(item.link, '_blank');  //Loads in the InAppBrowser
//                window.open(item.link, '_blank', 'location=no');  //Loads in the InAppBrowser
//                window.open(item.link, '_system'); //Loads in the system browser
//                window.open(item.link, '_self');   //Loads in the Cordova web view
   }

}
