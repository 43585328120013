import { Injectable, EventEmitter } from '@angular/core';
import { Platform } from '@ionic/angular';

/**
 * Monkey business
 */
@Injectable({
  providedIn: 'root',
})
export class MonkeyService
{
    constructor(
        private platform: Platform
    )
    {
    }

    //-----------------------------------------------------------------------

    get isSmallDevice() : boolean
    {
        return this.platform.width() < 1000;
    }

    get isLargeDevice() : boolean
    {
        return !this.isSmallDevice;
    }

    //-----------------------------------------------------------------------

}
