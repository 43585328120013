import { Component, Input, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

import { UserService } from '../../service/UserService';
import { AppUser } from '../../class/AppUser';
import { LoginPage } from '../../pages/login/login';

@Component({
    selector: 'app-page-header',
    templateUrl: 'page-header.html',
    styleUrls: [ 'page-header.scss' ]
})
export class AppPageHeaderComponent
{
    @Input() title?: string;
    @Input() subtitle?: string;

    user: AppUser;

    constructor(
        private nav: NavController,
        private modalCtrl: ModalController,
        private router: Router,
        private mtus: UserService
    )
    {
        this.user = this.mtus.getUser();
    }

    /**
     * Search
     */
    async search()
    {
        this.nav.navigateRoot('/rate/search');
    }

    /**
     * Go home
     */
    async home()
    {
        this.nav.navigateRoot('/home');
    }

    /**
     * Login
     */
    async login()
    {
        // Launch login modal and wait to see if the user becomes authenticated
        if (!await LoginPage.launch(this.modalCtrl))
        {
            // Route to the not authorized page if the user fails to authenticate
            // return this.router.parseUrl('/notauthorized');
        }
    }

}
