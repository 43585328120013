import { Injectable } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

import { BonesEditForm, BonesEditFormLaunchResults } from '@bones/edit';

import { UserService, AppUser, MonkeyService } from '@BeerMonkey/core';
import { Brewery } from '../class/Brewery';
import { Beer } from '../class/Beer';
import { Rating } from '../class/Rating';
import { InventoryInfo } from '../class/InventoryInfo';
import { InventoryEditModal } from '../clique/inventory/inventory-edit/inventory-edit';
import { BreweryEditModal } from '../clique/brewery/brewery-edit/brewery-edit';
import { BeerEditModal } from '../clique/beer/beer-edit/beer-edit';
import { RatingEditModal } from '../clique/rating/rating-edit/rating-edit';
import { GlasswareEditModal } from '../clique/glassware/glassware-edit/glassware-edit';
import { GlasswareInfo } from '../class/GlasswareInfo';
import { IdeaEditModal } from '../pages/idea/idea-edit/idea-edit';

/**
 * Launch edit modals
 */
@Injectable({
  providedIn: 'root',
})
export class LaunchEditModalService
{
    user: AppUser;

    constructor(
        private modalCtrl: ModalController,
        private navCtrl: NavController,
        public monkey: MonkeyService,
        private mtus: UserService
    )
    {
        this.user = this.mtus.getUser();
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public async editBrewery(row?: Brewery) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: BreweryEditModal,
            pk: row ? row.brewery_id : undefined
        })
        .then(results =>
        {
            if (results.action === 'delete')
            {
                this.navCtrl.back();
            }

            return results;
        });
    }

    /**
     * Add row
     */
    public async addBrewery() : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: BreweryEditModal
        })
        .then(results =>
        {
            if (results.action !== 'cancel')
            {
                this.navCtrl.navigateForward([ '/rate/brewery', results.payload.id ]);
            }

            return results;
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public async editBeer(row?: Beer) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: BeerEditModal,
            cssClass: this.monkey.isLargeDevice ? 'bones-edit-form-90-80' : undefined,
            pk: row?.beer_id
        })
        .then(results =>
        {
            if (results.action === 'delete')
            {
                this.navCtrl.back();
            }

            return results;
        });
    }

    /**
     * Add row
     */
    public async addBeer(breweryID: number) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: BeerEditModal,
            cssClass: this.monkey.isLargeDevice ? 'bones-edit-form-90-80' : undefined,
            moData:
            {
                breweryID
            }
        })
        .then(results =>
        {
            if (results.action !== 'cancel')
            {
                this.navCtrl.navigateForward([ '/rate/beer', results.payload.id ]);
            }

            return results;
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public async editRating(row?: Rating) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: RatingEditModal,
            cssClass: this.monkey.isLargeDevice ? 'bones-edit-form-90-80' : undefined,
            pk: row?.rating_id,
        })
        .then(results =>
        {
            if (results.action === 'delete')
            {
                this.navCtrl.back();
            }

            return results;
        });
    }

    /**
     * Add row
     */
    public async addRating(beerID: number) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: RatingEditModal,
            cssClass: this.monkey.isLargeDevice ? 'bones-edit-form-90-80' : undefined,
            moData:
            {
                beerID
            }
        })
        .then(results =>
        {
            if (results.action !== 'cancel')
            {
                this.navCtrl.navigateForward([ '/rate/rating', results.payload.id ]);
            }

            return results;
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public async editInventory(row?: InventoryInfo) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: InventoryEditModal,
            pk: row?.inventory_id,
            cssClass: this.monkey.isLargeDevice ? 'bones-edit-form-90-80' : undefined,
            moData:
            {
                initialQuantity: row?.quantity
            }
        });
    }

    /**
     * Add row
     */
    public async addInventory(breweryID: number) : Promise<BonesEditFormLaunchResults>;
    public async addInventory(beer?: Beer) : Promise<BonesEditFormLaunchResults>;
    public async addInventory(arg1?: Beer | number) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: InventoryEditModal,
            cssClass: this.monkey.isLargeDevice ? 'bones-edit-form-90-80' : undefined,
            moData: typeof arg1 === 'number' ? { breweryID: arg1 } : { breweryID: arg1?.brewery?.brewery_id, beerID: arg1?.beer_id }
        });
    }

    /**
     * Copy row
     */
    public async copyInventory(row: InventoryInfo) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: InventoryEditModal,
            cssClass: this.monkey.isLargeDevice ? 'bones-edit-form-90-80' : undefined,
            moData:
            {
                row
            }
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public async editGlassware(row?: GlasswareInfo) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: GlasswareEditModal,
            pk: row?.glassware_id
        });
    }

    /**
     * Add row
     */
    public async addGlassware(breweryID: number) : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: GlasswareEditModal,
            moData:
            {
                breweryID
            }
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Edit row
     */
    public async editIdea() : Promise<BonesEditFormLaunchResults>
    {
        return BonesEditForm.open(
        {
            modalCtrl: this.modalCtrl,
            editPage: IdeaEditModal,
            pk: 1,
        });
    }

    //-----------------------------------------------------------------------

    public getMenuItemAdd(action: () => any)
    {
        return {
            title: 'Add',
            icon: 'add-circle',
            action: action
        };
    }

    public getMenuItemEdit(action: () => any)
    {
        return {
            title: 'Edit',
            icon: 'pencil',
            action: action
        };
    }

    public getMenuItemViewAll(action: () => any)
    {
        return {
            title: 'View All',
            icon: 'eye',
            action: action
        };
    }

}
