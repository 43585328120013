import {Pipe, PipeTransform} from '@angular/core';

import { BonesGalleryDocument } from '../class/BonesGalleryDocument';

/**
 * Pipe to filter a list of documents to include only images
 */
@Pipe({name: 'bgdFilterImages'})
export class BonesGalleryDocumentFilterImagesPipe implements PipeTransform
{
    transform(documents: BonesGalleryDocument[]) : BonesGalleryDocument[]
    {
        return documents.filter(d => d.isImage);
    }
}

/**
 * Pipe to filter a list of documents to include only attachemnts (i.e. exclude images)
 */
@Pipe({name: 'bgdFilterAttachments'})
export class BonesGalleryDocumentFilterAttachmentsPipe implements PipeTransform
{
    transform(documents: BonesGalleryDocument[]) : BonesGalleryDocument[]
    {
        return documents.filter(d => d.isAttachment);
    }
}

/**
 * Pipe to filter a list of documents to include only featured documents
 */
@Pipe({name: 'bgdFilterFeatured'})
export class BonesGalleryDocumentFilterFeaturedPipe implements PipeTransform
{
    transform(documents: BonesGalleryDocument[]) : BonesGalleryDocument[]
    {
        return documents.filter(d => d.isFeatured);
    }
}

/**
 * Pipe to filter a list of documents to exclude featured documents
 */
@Pipe({name: 'bgdFilterNotFeatured'})
export class BonesGalleryDocumentFilterNotFeaturedPipe implements PipeTransform
{
    transform(documents: BonesGalleryDocument[]) : BonesGalleryDocument[]
    {
        return documents.filter(d => !d.isFeatured);
    }
}
