<ion-header><modal-header title="Edit Brewery" [editForm]="this"></modal-header></ion-header>

<ion-content [formGroup]="form" *ngIf="form">
  <ion-card class="ion-padding">
    <ion-card-title>Brewery</ion-card-title>
    <bones-form-items [form]="form" [items]="items"></bones-form-items>
  </ion-card>

</ion-content>

<ion-footer>
  <bones-edit-button-bar [editor]="this"></bones-edit-button-bar>
</ion-footer>
