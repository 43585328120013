import { Component, Input, OnInit } from '@angular/core';

import { RateBeerService } from '@BeerMonkey/rate/service/RateBeerService';
import { RateBeerBeer } from '@BeerMonkey/rate/class/RateBeerBeer';

// Sticky settings that are shared and persist across different components
const sticky = { showRB: true };

@Component({
  selector: 'rb-beer-info',
  templateUrl: 'rb-beer-info.html'
})
export class RbBeerInfoComponent implements OnInit
{
    _rbbeer?: RateBeerBeer;

    @Input() set rbbeer(rbbeer: RateBeerBeer | undefined)
    {
        this._rbbeer = rbbeer;
    }
    get rbbeer() : RateBeerBeer | undefined
    {
        return this._rbbeer;
    }

    @Input() set ratebeerID(ratebeerID: number | undefined)
    {
        if (ratebeerID)
        {
            this.rateBeerDB.getBeer(ratebeerID)
            .then(rbbeer => this._rbbeer = rbbeer)
            .catch(error =>
            {
                this.rbbeer = undefined;
                this.errorMessage = error.message;
            });
        }
        else
        {
            this.rbbeer = undefined;
            this.errorMessage = undefined;
        }

    }

    errorMessage?: string;
    sticky = sticky;

    constructor(
        public rateBeerDB: RateBeerService
    )
    {
    }

    ngOnInit()
    {
    }

}
