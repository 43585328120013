import { Component, Input, OnInit } from '@angular/core';

import { BonesMenuCardAction } from '@bones/core';

import { BonesGalleryDocument } from '../../class/BonesGalleryDocument';
import { BonesGalleryService } from '../../service/BonesGalleryService';

/**
 * Display a single document nested inside an ion-card.
 * 
 * <bones-gallery-document-card [document]="document" [editable]="user.canEdit">
 */
@Component({
    selector: 'bones-gallery-document-card',
    templateUrl: 'bones-gallery-document-card.html',
    styleUrls: [ 'bones-gallery-document-card.scss' ]
})
export class BonesGalleryDocumentCardComponent implements OnInit
{
    /**
     * The document to be displayed
     */
    @Input() document?: BonesGalleryDocument;
    /**
     * Should ths initial state of this card be expanded (document cards are always expandable)?
     */
    @Input() expanded = true;
    /**
     * Can the document be edited?
     */
    @Input() editable = false;

    /**
     * Menu items used to create a menu for the card
     */
    cardMenu: BonesMenuCardAction[] = [ ];

    /**
     * @ignore
     */
    constructor(
        public gallery: BonesGalleryService
    )
    {
    }

    /**
     * @ignore
     */
    ngOnInit()
    {
        if (this.editable)
        {
            this.cardMenu = [ { title: 'edit', icon: 'pencil', action: () => this.gallery.editDocument(this.document) } ];
        }
    }

    /**
     * Open document in new window/tab
     * 
     * @param document document to view
     */
    documentDetail(document: BonesGalleryDocument)
    {
        this.gallery.open(document.document_id);
    }

}
