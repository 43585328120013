import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, RouterEvent } from '@angular/router';
import { Location } from '@angular/common';

/**
 * Watch and filter navigation events
 */
class Watcher
{
    /**
     * Page using a watcher
     */
    page: any;

    /**
     * URL of page being watched to filter events for only this page
     */
    myUrl: string;

    /**
     * Method used to notify application when desired event has been triggered
     */
    notify: (e?: RouterEvent) => void;

    /**
     * Method used to filter desired navigation events
     */
    filter: (e?: RouterEvent) => void;

    /**
     * Create new watcher to filter navigation events and trigger application event
     * 
     * @param myUrl URL of page to observe
     * @param watch method to filter events
     */
    constructor(page: any, myUrl: string, notify: (e?: RouterEvent) => void, filter: (e?: RouterEvent) => void)
    {
        this.page = page;
        this.myUrl = myUrl;
        this.notify = notify;
        this.filter = filter;

        // console.log('BonesNavService: watch', this.myUrl);
    }
}

/**
 * Navigation utilities
 */
@Injectable({
    providedIn: 'root'
})
export class BonesNavService implements OnDestroy
{
    private navigationSubscription: any;
    private watchers: Watcher[] = [ ];

    /**
     * @ignore
     */
    constructor(
        private router: Router,
        private location: Location,
    )
    {
        // Subscribe to navigation events
        this.navigationSubscription = this.router.events.subscribe((e: RouterEvent) =>
        {
            // console.log('BonesNavService: router.event', e);

            this.watchers.forEach(watcher =>
            {
                watcher.filter(e);
            });
        });
    }

    /**
     * @ignore
     */
    ngOnDestroy()
    {
        // Unsubscribe to navigation events
        if (this.navigationSubscription)
        {
            this.navigationSubscription.unsubscribe();
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Watch for navigation events the end at the current page.
     * 
     * @param notify method to call when routing back to page
     */
    onWatchBack(page: any, notify: (e?: RouterEvent) => void) : void
    {
        // Use a timeout so the watch is setup after the current event cycle
        setTimeout(() =>
        {
            // Create new watcher
            const watcher = new Watcher(page, this.location.path(), notify, e =>
            {
                if (e instanceof NavigationEnd && e.urlAfterRedirects === watcher.myUrl)
                {
                    // console.log('BonesNavService: route back detected', watcher.myUrl, e);

                    // Notify application
                    watcher.notify(e);
                }
            });

            // Add watcher to list
            this.watchers.push(watcher);
        });
    }

    /**
     * Stop watching the current page
     */
    stopWatchBack(page: any) : void
    {
        // console.log('BonesNavService: don\'t watch', page);

        this.watchers.forEach((watcher, idx) =>
        {
            if (watcher.page === page)
            {
                this.watchers.splice(idx, 1);
                // console.log('BonesNavService: watcher deactivated');
            }
        });
    }

    //-----------------------------------------------------------------------

}
