import { Injectable } from '@angular/core';

import { BonesError } from '@bones/core';
import { BonesRestService } from './bones-rest';
import { BonesDocumentInfo } from '../class/BonesDocumentInfo';

/**
 * Use documents stored on server
 * Client side companion to server side StdDBDocument
 */
@Injectable({
    providedIn: 'root'
})
export class BonesDocumentService
{
    /**
     * @ignore
     */
    constructor(
        private brest: BonesRestService
    )
    {
    }

    //-----------------------------------------------------------------------

    /**
     * Get document content.
     * 
     * @param documentID Internal document ID of document to fetch.
     * @returns Document content as a Blob.
     */
    async getDocumentContent(documentID: number) : Promise<Blob>
    {
        // Download file
        return this.brest.send('/bones/document/get/' + documentID, undefined,
        {
            responseType: 'blob'
        })
        .then((payload: Blob) =>
        {
            return payload;
        })
        .catch(error =>
        {
            error = new BonesError(
            {
                className: 'BonesDocumentService',
                methodName: 'getDocumentContent',
                message: 'unable to download content',
                error: error
            })
            .add(
            {
                documentID: documentID
            });

            throw error;
        });
    }

    /**
     * Get document content as a data URL.
     * 
     * @param documentID Internal document ID of document to fetch.
     * @returns Document content as a data URL.
     */
    async getDocumentContentAsDataUrl(documentID: number) : Promise<string>
    {
        return this.blob2dataUrl(await this.getDocumentContent(documentID));
    }

    //-----------------------------------------------------------------------

    /**
     * Get document metadata.
     * 
     * @param documentID Internal document ID of document to fetch.
     * @returns Document information object. Blob and dataUrl properties will be left undefined.
     */
    async getDocumentInfo(documentID: number) : Promise<BonesDocumentInfo>
    {
        // Load document attributes from server
        return this.brest.send('/bones/document/attr', undefined,
        {
            queryParams:
            {
                DOCUMENT_ID: documentID
            }
        })
        .then((payload) =>
        {
            // console.log('eds.getDocumentInfo', payload);
            const docInfo: BonesDocumentInfo =
            {
                documentID: documentID,
                filename: payload.payload.FILE_NAME,
                contentType: payload.payload.CONTENT_TYPE,
                size: payload.payload.LENGTH,
                lastUpdate: new Date(payload.payload.LAST_UPDATE)
            };

            // console.log('eds.getDocumentInfo', docInfo);
            return docInfo;
        })
        .catch(error =>
        {
            error = new BonesError(
            {
                className: 'BonesDocumentService',
                methodName: 'getDocumentInfo',
                message: 'unable to get info',
                error: error
            })
            .add(
            {
                documentID: documentID
            });

            throw error;
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Get info and contents for a document ID.
     * 
     * @param documentID Internal document ID of document to fetch.
     * @returns Document information object with blob and dataUrl properties populated.
     */
    async getDocumentAndInfo(documentID: number) : Promise<BonesDocumentInfo>
    {
        const pInfo = this.getDocumentInfo(documentID);
        const pBlob = this.getDocumentContent(documentID);

        try
        {
            const [info, blob] = await Promise.all([pInfo, pBlob]);
            info.blob = blob;
            info.dataUrl = await this.blob2dataUrl(info.blob);

            return info;
        }
        catch (error)
        {
            error = new BonesError(
            {
                className: 'BonesDocumentService',
                methodName: 'getDocumentAndInfo',
                message: 'unable to get document and info',
                error: error
            })
            .add(
            {
                documentID: documentID
            });

            throw error;
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Convert a blob into a data url
     */
    private blob2dataUrl(blob: Blob) : Promise<string>
    {
        return new Promise<string>((resolve, reject) =>
        {
            const fd = new FileReader();

            // Load complete
            fd.onload = () =>
            {
                resolve(fd.result as string);
            };

            // Error handler
            fd.onerror = () =>
            {
                fd.abort();
                const error = new BonesError(
                {
                    className: 'BonesDocumentService',
                    methodName: 'blob2dataUrl',
                    message: 'unable to convert document blob into data url',
                    error: fd.error
                });
                reject(error);
            };

            // Read from blob into data url
            fd.readAsDataURL(blob);
        });
    }

    //-----------------------------------------------------------------------

}
