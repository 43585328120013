<ion-toolbar>
  <ion-card-title *ngIf="title && !expandable">{{ title }}</ion-card-title>
  <ion-card-title *ngIf="!title && !expandable">
    <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
  </ion-card-title>
  <ion-card-title *ngIf="expandable">
    <ion-icon name="caret-down" (click)="expanded = false" *ngIf="expanded"></ion-icon>
    <ion-icon name="caret-forward" (click)="expanded = true" *ngIf="!expanded"></ion-icon>
    <ion-text *ngIf="title">{{ title }}</ion-text>
    <ion-text *ngIf="!title">
      <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
    </ion-text>
  </ion-card-title>

  <ion-card-subtitle *ngIf="subtitle">{{ subtitle }}</ion-card-subtitle>

  <ion-note *ngIf="note">{{ note }}</ion-note>

  <ion-button class="ion-no-margin" slot="end" size="small" fill="clear" color="secondary"
    (click)="toggleMenu()"
    *ngIf="actions?.length > 0">
    <ion-icon name="menu" size="large"></ion-icon>
  </ion-button>
</ion-toolbar>

<div class="dropdown ion-margin-end" [hidden]="!menuVisible" #mm (click)="hideMenu()" *ngIf="actions">
  <ion-item lines="none" button="true" (click)="do(action)" *ngFor="let action of actions">
    <ion-icon slot="start" [name]="action.icon" *ngIf="action.icon"></ion-icon>
    <ion-label>{{ action.title }}</ion-label>
  </ion-item>
</div>

<ng-template #titleTemplate><ng-content></ng-content></ng-template>