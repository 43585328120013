import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';

import { BonesErrorService, BonesNavService } from '@bones/core';
import { BonesForm } from '@bones/form';

import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';
import { BeerService } from '@BeerMonkey/rate/service/BeerService';
import { Brewery } from '@BeerMonkey/rate/class/Brewery';
import { Beer } from '@BeerMonkey/rate/class/Beer';
import { RatingService } from '@BeerMonkey/rate/service/RatingService';
import { RateBeerService } from '@BeerMonkey/rate/service/RateBeerService';
import { RateBeerBreweryBeer } from '@BeerMonkey/rate/class/RateBeerBreweryBeer';
import { Rating } from '@BeerMonkey/rate/class/Rating';
import { removePrefix, MonkeyService } from '@BeerMonkey/core';

@Component({
    selector: 'beer-explorer',
    templateUrl: 'beer-explorer.html'
})
export class BeerExplorerPage implements OnInit, OnDestroy
{
    breweryID: number;
    brewery?: Brewery;
    allRatedBeers: Beer[] = [ ];
    filteredBeers: Beer[] = [ ];
    ratings: Rating[] = [ ];
    private nal: (() => void)[] = [ ];
    filterForm: BonesForm;
    rbbeers?: RateBeerBreweryBeer[];

    constructor(
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private es: BonesErrorService,
        private bnav: BonesNavService,
        public monkey: MonkeyService,
        private breweryDB: BreweryService,
        private beerDB: BeerService,
        private ratingDB: RatingService,
        private rateBeerDB: RateBeerService
    )
    {
        this.breweryID = +(this.route.snapshot.paramMap.get('breweryID') ?? 0);

        // Create filter form
        this.filterForm = new BonesForm(
        {
            formBuilder: this.formBuilder,
            columns:
            [
                {
                    name: 'rated',
                    title: 'Rated',
                    type: 'toggle',
                    help: 'Show beers that have been rated',
                    initialValue: true,
                    onChange: () => this.beerFilter()
                },
                {
                    name: 'unrated',
                    title: 'Unrated',
                    type: 'toggle',
                    help: 'Show beers from RateBeer that have not yet been rated',
                    initialValue: false,
                    onChange: () => this.beerFilter()
                },
                {
                    name: 'linked',
                    title: 'Linked',
                    type: 'toggle',
                    help: 'Show beers that are linked to RateBeer',
                    initialValue: true,
                    onChange: () => this.beerFilter()
                },
                {
                    name: 'unlinked',
                    title: 'Unlinked',
                    type: 'toggle',
                    help: 'Show beers that have not been linked to RateBeer, but may be eligable',
                    initialValue: true,
                    onChange: () => this.beerFilter()
                },
                {
                    name: 'versioned',
                    title: 'Versioned',
                    type: 'toggle',
                    help: 'Limit display to beers that have multiple versions',
                    initialValue: false,
                    onChange: () => this.beerFilter()
                },
                {
                    name: 'vintaged',
                    title: 'Vintaged',
                    type: 'toggle',
                    help: 'Limit display to beers that have multiple vintages',
                    initialValue: false,
                    onChange: () => this.beerFilter()
                },
            ]
        });

        // Refresh data when navigating back to this page from detail pages
        this.bnav.onWatchBack(this, () => this.beerFilter());
    }

    async ngOnInit()
    {
        // Load brewery info
        this.breweryDB.getBrewery(this.breweryID)
        .then(brewery => this.brewery = brewery)
        .catch(error => this.es.errorHandler(error));

        // Load and refresh beers as needed
        this.nal.push(this.beerDB.cache.nowAndLater(
        rows =>
        {
            this.allRatedBeers = rows.filter(p => p.row.brewery_id === this.breweryID);
            this.beerFilter();
        },
        error => this.es.errorHandler(error)));

        // Load and refresh ratings as needed
        this.nal.push(this.ratingDB.cache.nowAndLater(
        rows =>
        {
            this.ratings = rows.filter(p => p.beer?.row.brewery_id === this.breweryID);
            this.beerFilter();
        },
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
        this.bnav.stopWatchBack(this);
    }

    async beerFilter()
    {
        const showRated = this.filterForm.getValue('rated');
        const showUnrated = this.filterForm.getValue('unrated');
        const showLinked = this.filterForm.getValue('linked');
        const showUnlinked = this.filterForm.getValue('unlinked');
        const showVersioned = this.filterForm.getValue('versioned');
        const showVintaged = this.filterForm.getValue('vintaged');

        const filtered: Beer[] = [ ];

        // Filter rated beers
        this.allRatedBeers.filter(async beer =>
        {
            let match = true;

            if (!showRated && beer.inDB)
            {
                match = false;
            }

            if (!showLinked && beer.linkedToRateBeer)
            {
                match = false;
            }

            if (!showUnlinked && beer.linkableToRateBeer)
            {
                match = false;
            }

            if (showVersioned)
            {
                if (new Set<string>((await this.ratingDB.cache.getList())
                    .filter(r => r.beer?.beer_id === beer.beer_id)
                    .map(r => r.row.version))
                    .size < 2)
                {
                    match = false;
                }
            }

            if (showVintaged)
            {
                if (new Set<string>((await this.ratingDB.cache.getList())
                    .filter(r => r.beer?.beer_id === beer.beer_id)
                    .map(r => r.row.vintage))
                    .size < 2)
                {
                    match = false;
                }
            }

            if (match)
            {
                filtered.push(beer);
            }
        });

        // Add in unrated beers from RateBeer
        if (showUnrated)
        {
            // Fetch beers from RateBeer if we don't alredy have them
            if (!this.rbbeers && this.brewery)
            {
                this.rbbeers = await this.rateBeerDB.getBreweryBeerList(this.brewery.row.ratebeer_id);
            }

            if (this.rbbeers)
            {
                this.rbbeers.forEach(rbbeer =>
                {
                    // This beer has already been added to the db
                    if (this.allRatedBeers.find(b => b.row.ratebeer_id === rbbeer.BeerID)
                        || this.ratings.find(r => r.row.ratebeer_id === rbbeer.BeerID))
                    {
                        return;
                    }

                    // Create beer entry
                    const beer = new Beer(
                    {
                        brewery_id: this.breweryID,
                        ratebeer_id: rbbeer.BeerID,
                        beer_id: 0,
                        name: removePrefix(rbbeer.BrewerName, rbbeer.BeerName),
                        abv: rbbeer.Alcohol,
                        ratebeer_score: rbbeer.AverageRating
                    });

                    beer.brewery = this.brewery;

                    // Add to list
                    filtered.push(beer);
                });
            }
        }

        this.filteredBeers = filtered.sort((a, b) => a.row.name.localeCompare(b.row.name));
        // console.log('filteredBeers', this.filteredBeers);
    }

}
