<ion-card class="ion-padding" *ngIf="document">
  <bones-card-toolbar class="ion-padding-bottom" [actions]="cardMenu" [expandable]="true" [(expanded)]="expanded">
    <ion-text *ngIf="document.row.title">{{ document.row.title }}</ion-text>
    <ion-text *ngIf="!document.row.title">{{ document.row.name }}</ion-text>
  </bones-card-toolbar>

  <div *ngIf="expanded">
    <img [bonesGalleryDocumentID]="document.document_id" [bonesGalleryDocumentSize]="gallery.imageSizeCard" *ngIf="document.isImage">
    <ion-icon class="image" name="document" (click)="documentDetail(document)" *ngIf="document.isAttachment"></ion-icon>

    <p *ngIf="document.row.notes">{{ document.row.notes }}</p>
  </div>
</ion-card>
