import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
    selector: 'bones-tt-page-header',
    templateUrl: 'bones-tt-page-header.html',
    styleUrls: [ 'bones-tt-page-header.scss' ]
})
export class BonesTtPageHeaderComponent implements OnInit
{
    @Input() home: string;
    @Input() title: string;
    @Input() subtitle: string;

    constructor(
        private nav: NavController
    )
    {
    }

    async ngOnInit()
    {
    }

    /**
     * Go home
     */
    async gohome()
    {
        this.nav.navigateForward(this.home || '/bones/test-tools');
    }

}
