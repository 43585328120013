import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { BonesFormItem } from '../../class/BonesFormItem';

/**
 * Form textarea.
 * 
 * @example
 * <bones-form-item-textarea [form]="form" [item]="item"></bones-form-item-textarea>
 */
@Component({
    selector: 'bones-form-item-textarea',
    templateUrl: 'form-item-textarea.html'
})
export class BonesFormItemTextareaComponent
{
    /**
     * Form containing field.
     */
    @Input() form: UntypedFormGroup;

    /**
     * Object with details of form field to create.
     */
    @Input() item: BonesFormItem;

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

}
