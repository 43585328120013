import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { BonesFormItem } from '../../class/BonesFormItem';
import { BonesFormItemPickerPage } from '../../pages/form-item-picker-modal/form-item-picker-modal';
import { OverlayEventDetail } from '@ionic/core';
import { BonesFormItemPickerPageOptions } from '../../class/BonesFormItemPickerPageOptions';

/**
 * Pick form item content from a list of values using a modal.
 * 
 * @example
 * <bones-form-item-picker [form]="form" [item]="item" (change)="onPick($event)"></bones-form-item-picker>
 */
@Component({
    selector: 'bones-form-item-picker',
    templateUrl: 'form-item-picker.html',
    styleUrls: [ 'form-item-picker.scss' ]
})
export class BonesFormItemPickerComponent
{
    /**
     * Form containing field.
     */
    @Input() form: UntypedFormGroup;

    /**
     * Object with details of form field to create.
     */
    @Input() item: BonesFormItem;

    /**
     * Event callback when an value is picked from the list.
     * The selected value is passed back.
     */
    @Output() change: EventEmitter<any> = new EventEmitter();

    /**
     * @ignore
     */
    constructor(
        private modalCtrl: ModalController,
    )
    {
    }

    /**
     * Show picker
     */
    async showPicker(where: string, e: PointerEvent)
    {
        // Don't show picker for read-only form items
        if (this.item.readonly)
        {
            return;
        }

        // isTrusted:false has been shown to be a false duplicate event triggered by some unknown cause
        if (!e.isTrusted)
        {
            console.warn('ignoring untrusted and probable duplicated showPicker event', where, e);
            return;
        }

        // Create picker screen as a modal
        const modal = await this.modalCtrl.create(
        {
            component: BonesFormItemPickerPage,
            componentProps:
            {
                options:
                {
                    form: this.form,
                    item: this.item
                } as BonesFormItemPickerPageOptions
            }
        });

        // Action when screen exits
        modal.onDidDismiss().then((event: OverlayEventDetail) =>
        {
            // An item will be passed back unless the user cancels
            if (event.data !== undefined)
            {
                const patch = { };
                patch[this.item.name] = event.data;
                this.form.patchValue(patch);
                this.change.emit({ value: event.data });
            }
        });

        // Show lookup screen
        modal.present();
    }

}
