<ion-header><modal-header title="Edit Rating" [editForm]="this"></modal-header></ion-header>

<ion-content [formGroup]="form" *ngIf="form">

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="beerInfoTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="deetsTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="aafTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="scoreTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="wordsTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="notesTmpl8"></ng-container>
  </div>

  <ion-grid *ngIf="monkey.isLargeDevice">
    <ion-row>
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="beerInfoTmpl8"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="deetsTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="aafTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="scoreTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="notesTmpl8"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="wordsTmpl8"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<ion-footer>
  <bones-edit-button-bar [editor]="this"></bones-edit-button-bar>
</ion-footer>

<!-- beer info -->
<ng-template #beerInfoTmpl8>
  <beer-info [beer]="beer" [ratebeerID]="bonesForm.getValue('ratebeer_id')"></beer-info>
</ng-template>

<!-- beer -->
<ng-template #beerTmpl8>
  <ion-card class="ion-padding">
    <bones-card-toolbar #bt [expandable]="true">Beer</bones-card-toolbar>
    <ion-card-content class="ion-no-padding" *ngIf="bt.expanded">
      <bones-form-item [form]="bonesForm.form" [item]="bonesForm.getItem('beer_id')"></bones-form-item>
      <bones-form-item [form]="bonesForm.form" [item]="bonesForm.getItem('ratebeer_id')"></bones-form-item>
      <bones-form-item [form]="bonesForm.form" [item]="bonesForm.getItem('image')" *ngIf="isAdd"></bones-form-item>
      <!-- date control is required to be rendered to trigger proper date formatting -->
      <bones-form-item [form]="bonesForm.form" [item]="bonesForm.getItem('rating_date')" style="display: none;"></bones-form-item>
    </ion-card-content>
  </ion-card>
</ng-template>

<!-- deets -->
<ng-template #deetsTmpl8>
  <ion-card class="ion-padding">
    <ion-card-title>
      <ion-icon class="ion-float-right" name="open" (click)="deets()"></ion-icon>
      <ion-text>Deets</ion-text>
    </ion-card-title>
    <div class="ion-float-right ion-text-right">
      <div *ngIf="form.value.ratebeer_score > 0">{{ form.value.ratebeer_score }}</div>
      <div *ngIf="form.value.rating > 0">{{ form.value.rating }}</div>
      <div>{{ form.value.score }}</div>
    </div>
    <div>{{ form.value.version }} {{ form.value.vintage }}</div>
    <div>{{ form.value.mode }}<span *ngIf="form.value.mode && form.value.source"> from </span>{{ form.value.source }}</div>
    <div *ngIf="form.value.abv">{{ form.value.abv }}%</div>
    <div *ngIf="form.value.rating_date">Rated: {{ form.value.rating_date | date:'fullDate' }}</div>
    <div *ngIf="form.value.last_update && form.value.last_update.substr(0,10) != form.value.rating_date">Updated: {{ form.value.last_update | date:'fullDate' }}</div>
    <div *ngIf="form.value.last_upload">Uploaded: {{ form.value.last_upload | date:'fullDate' }}</div>
  </ion-card>
</ng-template>

<!-- aaf -->
<ng-template #aafTmpl8>
  <ion-card class="ion-padding">
    <ion-card-title>
      <ion-icon class="ion-float-right" name="open" (click)="aaf()"></ion-icon>
      <ion-text>AAF</ion-text>
    </ion-card-title>
  </ion-card>
</ng-template>

<!-- score -->
<ng-template #scoreTmpl8>
    <ion-card class="ion-padding">
      <ion-card-title>
        <ion-icon class="ion-float-right" name="open" (click)="score()"></ion-icon>
        <ion-text>Score</ion-text>
      </ion-card-title>
    </ion-card>
</ng-template>

<!-- words -->
<ng-template #wordsTmpl8>
  <div *ngFor="let section of sections">
    <ion-card class="ion-padding">
      <ion-card-title>
        <ion-icon class="ion-float-right" name="open" (click)="editSection(section)"></ion-icon>
        <ion-text>{{ section.title }}</ion-text>
        <span *ngIf="bonesForm.getValue(section.numbers)">
          <span> (</span>
          <span>{{ bonesForm.getValue(section.numbers) }}</span>
          <span>)</span>
        </span>
      </ion-card-title>
      <ion-item *ngIf="bonesForm.getValue(section.words)">
        <ion-label>
          <p class="ion-text-wrap">{{ bonesForm.getValue(section.words) }}</p>
        </ion-label>
      </ion-item>
    </ion-card>
  </div>
</ng-template>

<!-- notes -->
<ng-template #notesTmpl8>
    <ion-card class="ion-padding">
      <ion-card-title>
        <ion-icon class="ion-float-right" name="open" (click)="notes()"></ion-icon>
        <ion-text>Notes</ion-text>
      </ion-card-title>
      <p class="ion-text-wrap">{{ form.value.note }}</p>
      <div *ngIf="form.value.rerate">{{ bonesForm.getItem('rerate').title }}</div>
      <div *ngIf="form.value.auto_score">{{ bonesForm.getItem('auto_score').title }}</div>
    </ion-card>
</ng-template>
