import { Component, Input, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';

import { BonesPickerOptions } from '../../model/BonesPickerOptions';
import { BonesPickerReturn } from '../../model/BonesPickerReturn';
import { BonesPickerChoice } from '../../model/BonesPickerChoice';
import { BonesPickerValues } from '../../model/BonesPickerValues';
import { BonesSearch } from '../../model/BonesSearch';

/**
 * Modal to pick from a list of values
 */
@Component({
    templateUrl: 'bones-picker-modal.html',
    styleUrls: [ 'bones-picker-modal.scss' ]
})
export class BonesPickerPage implements OnInit
{
    /**
     * Options passed to picker page.
     */
    @Input() options: BonesPickerOptions;

    /**
     * Values filered by search bar.
     */
    filteredValues: BonesPickerChoice[];

    /**
     * Current item value from original form.
     */
    currentItem: BonesPickerChoice[] = [ ];

    /**
     * Unfiltered values available on picker
     */
    private allValues: BonesPickerChoice[];

    private popCount = 0;
    private populatorPromise: Promise<BonesPickerValues>;
    private unrequitedPopulatorPromise: boolean;

    /**
     * @ignore
     */
    constructor(
        private loadingCtrl: LoadingController,
        private modal: ModalController
    )
    {
    }

    /**
     * Initialize picker values and defaults.
     */
    async ngOnInit()
    {
        const values = await this.getValues();

        // Build PickerValue objects for the input values
        if (values instanceof Map)
        {
            this.allValues = [ ];
            values.forEach((v, k) => this.allValues.push({ key: k, value: v }));
        }
        else if (values instanceof Array)
        {
            this.allValues = values.map(v => ({ key: v, value: String(v) }));
        }
        else
        {
            console.warn('BonePickerPage.initValues: picker does not have any selectable values');
            this.allValues = [ ];
        }

        // Assign currently selected item to be the current value in the form
        // const formValue = this.options.initialValue;
        if (this.options.initialValue instanceof Array)
        {
            // Select all current items for multi select
            this.options.initialValue.forEach(fv => this.add2CurrentItem(fv));
        }
        else
        {
            // Single select has just the one current value (if any)
            this.add2CurrentItem(this.options.initialValue);
        }

        // Use search to initially assign filtered items
        this.search(undefined);
    }

    private add2CurrentItem(formValue: string | number)
    {
        const ci = this.allValues.find(v => v.key === formValue);
        if (ci)
        {
            this.currentItem.push(ci);
        }
    }

    /**
     * Format item for display in the list
     */
    formatItem(choice: BonesPickerChoice) : string
    {
        if (this.options.formatItem)
        {
            return this.options.formatItem(choice);
        }
        else
        {
            return choice.value;
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Filter items with search term
     */
    search(searchTerm: string)
    {
        // Show all results when the search box is cleared
        if (!searchTerm)
        {
            this.filteredValues = this.allValues;
            return;
        }

        // Preform search
        this.filteredValues = new BonesSearch<BonesPickerChoice>(this.allValues, searchTerm)
        .execute(r => [ this.formatItem(r) ]);
    }

    //-----------------------------------------------------------------------

    /**
     * Pick item
     */
    pick(choice: BonesPickerChoice)
    {
        // Clicking a selected item will unselect it
        if (this.chosen(choice))
        {
            this.currentItem.splice(this.currentItem.indexOf(choice), 1);
        }
        else
        {
            // Zero out current selection for single selects
            if (!this.options.multi)
            {
                this.currentItem.length = 0;
            }

            // Add selected item to current selections
            this.currentItem.push(choice);
        }

        if (!this.options.multi && this.currentItem.length > 0)
        {
            this.done();
        }
    }

    /**
     * Has this choice been chosen?
     * @param choice the choice to evaluate
     */
    chosen(choice: BonesPickerChoice) : boolean
    {
        return this.currentItem.indexOf(choice) >= 0;
    }

    /**
     * Clear current selection
     */
    clear()
    {
        this.currentItem.length = 0;
        if (!this.options.multi)
        {
            this.done();
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Selection complete, return value(s) and dismiss modal
     */
    done()
    {
        // Send back list of values for multi selects
        if (this.options.multi)
        {
            this.modal.dismiss(new BonesPickerReturn('value', this.currentItem.map(ci => ci.key)));
        }
        // Send back null if no items were selected
        else if (this.currentItem.length === 0)
        {
            this.modal.dismiss(new BonesPickerReturn('clear'));
        }
        // Send back first (should be only) item for single select
        else
        {
            this.modal.dismiss(new BonesPickerReturn('value', this.currentItem[0].key));
        }
    }

    /**
     * Cancel lookup screen
     */
    cancel()
    {
        this.modal.dismiss(new BonesPickerReturn('cancel'));
    }

    //-----------------------------------------------------------------------

    /**
     * Get the values used as choices for this picker.
     * @returns picker values.
     */
    private async getValues() : Promise<BonesPickerValues>
    {
        // console.log('picker.getValues', this.values, this.populator, this.nocache);
        if (this.options.populator && (!this.options.values || this.options.nocache))
        {
            await this.populate(true);
        }

        return this.options.values;
    }

    /**
     * Populate picker values
     * @param refresh true=trigger new populater, false=use old promise
     * @returns picker values
     */
    private async populate(refresh: boolean) : Promise<BonesPickerValues>
    {
        // console.log('picker.populator', refresh, this.defectivePopulator, this.populator, this.values);

        // if (++this.popCount > 3)
        // {
        //     console.log('picker.populator popped out', this.popCount);
        //     this.values = [ ];
        //     return Promise.resolve(this.values);
        // }

        // Asking for a refresh, but the old request is still active
        if (refresh && this.unrequitedPopulatorPromise)
        {
            // console.log('picker.populator unrequitedPopulatorPromise', refresh, this.populatorPromise);
            return this.populatorPromise;
        }

        // // App function is defective and returns errors; bypass it to avoid an endless error loop
        // if (this.defectivePopulator)
        // {
        //     console.log('picker.populator defectivePopulator', this.defectivePopulator);
        //     return this.populatorPromise;
        // }

        // Call app function to populate picker
        if (!this.populatorPromise || refresh)
        {
            // console.log('picker.populator trigger');
            this.unrequitedPopulatorPromise = true;

            // Display loading message
            const loading = await this.loadingCtrl.create();
            loading.present();

            this.populatorPromise = this.options.populator()
            .then(v =>
            {
                this.options.values = v;
                // console.log('picker.populator populated', this.values);
                return this.options.values;
            })
            .catch(error =>
            {
                // Flag app populator as defective
                // this.defectivePopulator = true;
                console.log('picker.populator failed', error);

                // Resolve as success because there is nobody to catch the error;
                // the app should have handled it but still throw an error so we are in the know and not waiting forever
                this.options.values = [ ];
                return Promise.resolve(this.options.values);
            })
            .finally(() =>
            {
                loading.dismiss();
                this.unrequitedPopulatorPromise = false;
            });
        }

        // Return new or existing promise
        return this.populatorPromise;
    }

}
