<ion-header><app-page-header subtitle="{{ brewery?.row?.name }}"></app-page-header></ion-header>

<ion-content>

  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="searchTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="inventoryTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="glasswareTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="featuredTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="galleryCardTmpl8"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">
    <ion-row>
      <ion-col>
        <ng-container *ngTemplateOutlet="searchTmpl8"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="inventoryTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="glasswareTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="galleryCardTmpl8"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="featuredTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
      </ion-col>
      
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<ng-template #breweryTmpl8>
  <ion-card class="ion-padding" *ngIf="brewery">
    <bones-card-toolbar [actions]="breweryCardMenu">
      <div class="brewery-title">
        <div class="lhs" *ngIf="logo">
          <img [bonesGalleryDocumentID]="logo.document_id" [bonesGalleryDocumentSize]="gallery.imageSizeLogo">
        </div>
        <div class="rhs">
          <div class="name">{{ brewery.row.name }}</div>
          <div class="location">{{ brewery.row.location }}</div>
          <!-- <rating-stars [score]="restaurant.score"></rating-stars> -->
          <!-- <span> ({{ restaurant.score | number:'0.1-1' }})</span> -->
          <!-- <span *ngIf="restaurant.average.count > 1"> ({{ restaurant.average.count }})</span> -->
        </div>
      </div>
    </bones-card-toolbar>

    <p class="kps-pre-line" *ngIf="brewery.row.notes">{{brewery.row.notes}}</p>
  </ion-card>

  <rb-brewery-info [ratebeerID]="brewery.row.ratebeer_id" *ngIf="brewery && brewery.row.ratebeer_id">
  </rb-brewery-info>
</ng-template>

<ng-template #beerTmpl8>
  <beer-list-card #beerCard [beers]="beers" [searchTerm]="searchTerm" [showSearchbar]="false" [smallDeviceCollapse]="6">
  </beer-list-card>
</ng-template>

<ng-template #ratingTmpl8>
  <rating-list-card #ratingCard [ratings]="ratings" [searchTerm]="searchTerm" [showSearchbar]="false"
    [showRatings]="ratingCard.filtered.length < 6 || monkey.isLargeDevice" *ngIf="ratings">
  </rating-list-card>
</ng-template>

<ng-template #inventoryTmpl8>
  <inventory-list-card #inventoryCard [inventory]="inventory" [searchTerm]="searchTerm"
    [showSearchbar]="false" [showBreweryName]="false" [showZeros]="false"
    [showInventory]="inventoryCard.filtered.length < 6"
    *ngIf="inventory">
  </inventory-list-card>
</ng-template>

<ng-template #glasswareTmpl8>
  <glassware-list-card #glasswareCard [glassware]="glassware" [searchTerm]="searchTerm" [showBreweryName]="false"
    [showGlassware]="glasswareCard.filtered.length < 6">
  </glassware-list-card>
</ng-template>

<ng-template #searchTmpl8>
  <ion-searchbar [(ngModel)]="searchTerm" [debounce]="500"></ion-searchbar>
</ng-template>

<!-- document templates -->

<ng-template #featuredTmpl8>
  <bones-gallery-document-card [document]="document" [editable]="user.canEdit" *ngFor="let document of documents | bgdFilterFeatured">
  </bones-gallery-document-card>
</ng-template>

<ng-template #galleryCardTmpl8>
  <bones-gallery-card [documents]="documents" [editable]="user.canEdit" [expanded]="false" [add]="[ 'brewery_id', breweryID ]"
    *ngIf="(documents | bgdFilterNotFeatured).length > 0">
  </bones-gallery-card>
</ng-template>
