/**
 * All public exports for module
 */

// Export the module itself
export { AppCoreModule } from './module';

// Export pages that are accessed externally, normally just the root page, may be better to use
// a component instead
export { LoginPage } from './pages/login/login';

// Services can be re-exported to import from the top level module instead of having to import from
// the nested component .ts file
export { AppUser } from './class/AppUser';
export { AppUserInfo } from './class/AppUserInfo';

export { ChartData } from './class/ngx-charts/ChartData';
export { ChartDataSeries } from './class/ngx-charts/ChartDataSeries';
export { chartColorScheme } from './class/ngx-charts/ChartColorScheme';
export { ChartDataSeri } from './class/ngx-charts/ChartDataSeri';

export { MonkeyService } from './service/MonkeyService';
export { UserService } from './service/UserService';
export { ApeRest } from './service/ApeRest';
export { removePrefix } from './service/removePrefix';
