import { Component, Input, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { DatePipe, formatDate } from '@angular/common';
import { IonInput } from '@ionic/angular';
import { Subscription } from 'rxjs';

import { BonesFormItem } from '../../class/BonesFormItem';

/**
 * Form date.
 * 
 * @example
 * <bones-form-item-date [form]="form" [item]="item"></bones-form-item-textarea>
 */
@Component({
    selector: 'bones-form-item-date',
    templateUrl: 'form-item-date.html'
})
export class BonesFormItemDateComponent implements OnInit, AfterViewInit, OnDestroy
{
    /**
     * Form containing field.
     */
    @Input() form: UntypedFormGroup;

    /**
     * Ionic input element used for date entry
     */
    @ViewChild('dateInput') dateInput: IonInput;

    /**
     * Ionic input element used for time entry
     */
    @ViewChild('timeInput') timeInput: IonInput;

    /**
     * Object with details of form field to create.
     */
    @Input() item: BonesFormItem;

    private changeSubscription: Subscription;
    private formControl: AbstractControl;

    /**
     * @ignore
     */
    constructor(
        private datePipe: DatePipe
    )
    {
    }

    ngOnInit()
    {
    }

    ngAfterViewInit()
    {
        // Get Angular form control
        this.formControl = this.form.get(this.item.name);

        // Set initial value
        this.setValue(this.formControl.value);

        // Trigger change event so that return value will be reformatted to consistent format
        this.onChange();

        // Subscribe to value changes that happen outside the form
        this.changeSubscription = this.formControl.valueChanges.subscribe(v => this.setValue(v));
    }

    ngOnDestroy()
    {
        // Unsubscribe from value changes
        this.changeSubscription.unsubscribe();
    }

    /**
     * Set form ion-input value
     * 
     * @param value New date value to display on input form
     */
    private setValue(value: string)
    {
        // console.log('form.setValue', this.item.name, value);
        // Ugly fix where Firefox parses some date formats (dd-MMM-yyyy) to a negative year value
        if (Date.parse(value) < 0)
        {
            const ffDate = new Date(value);
            ffDate.setFullYear(-ffDate.getFullYear());
            const newValue = ffDate.toDateString();

            // alert('form.setValue: value: ' + value
            //     + ', Date.parse: ' + Date.parse(value)
            //     + ', new Date(Date.parse): ' + new Date(Date.parse(value))
            //     // + ', parsed: ' + parsed
            //     + ', ffDate: ' + ffDate
            //     + ', newValue: ' + newValue
            //     + ', format: ' + this.item.date.format
            //     + ', formatDate: ' + formatDate(newValue, 'yyyy-MM-dd', 'en'));

            value = newValue;
        }

        // Format date as required for input element
        if (value)
        {
            const newValue = formatDate(value, 'yyyy-MM-dd', 'en');
            // console.log('form.setValue: newValue (date)', this.item.name, newValue);
            this.dateInput.value = newValue;
        }

        // Format time input element when used
        if (this.item.date.timeEntry && value)
        {
            const newValue = formatDate(value, 'HH:mm', 'en');
            // console.log('form.setValue: newValue (time)', this.item.name, newValue);
            this.timeInput.value = newValue;
            // console.log('set time', value, this.timeInput.value);
        }
    }

    /**
     * Update form value when input values are changed
     */
    onChange()
    {
        // Parse both date and time inputs
        let rv: string;
        if (this.item.date.timeEntry)
        {
            // console.log('form.onChange datetime: raw', this.item.name, this.dateInput.value, this.timeInput.value);
            const dt = this.dateInput.value && this.timeInput.value ?
                this.dateInput.value + 'T' + this.timeInput.value : undefined;
            rv = dt ? this.datePipe.transform(dt, this.item.date.format) : undefined;
            // console.log('form.onChange datetime: rv', this.item.name, this.item.date.format, rv);
            // alert('form.onChange: dateInput.value: ' + this.dateInput.value + ' t ' + this.timeInput.value + ', dt: ' + dt);
            // alert('form.onChange: format: ' + this.item.date.format + ', rv: ' + rv);
            // alert('form.onChange: with t: '
            //     + this.datePipe.transform(this.dateInput.value + 'T' + this.timeInput.value, this.item.date.format));
        }
        // Parse date only input
        else
        {
            rv = this.dateInput.value ? this.datePipe.transform(this.dateInput.value, this.item.date.format) : undefined;
            // console.log('form.onChange dateonly: rv', this.item.name, this.dateInput.value, this.item.date.format, rv);
            // alert('form.onChange: dateInput.value: ' + this.dateInput.value + ', format: ' + this.item.date.format + ', rv: ' + rv);
            // alert('form.onChange: form.get.value: ' + this.form.get(this.item.name).value);
        }

        // Change form control if the value did actually change
        // console.log('form.onChange: control value vs new value', this.item.name, this.formControl.value, rv);
        if (this.formControl.value !== rv)
        {
            // console.log('form.onChange: value changed, set control value', this.item.name, this.formControl.value, rv);
            this.formControl.setValue(rv);
        }
    }

}
