import { BonesFormItemDateOptions } from './BonesFormItemDateOptions';

/**
 * Configuration for date form items.
 */
export class BonesFormItemDate
{
    /**
     * Date format for returned date value.
     * Including time placeholders as part of date format will trigger time entry.
     * 
     * Date formats are per Angular DatePipe formatting.
     * 
     * Default is yyyy-MM-dd.
     */
    format: string;

    /**
     * Should both date and time be entered? Default is date only.
     */
    timeEntry: boolean;

    /**
     * Create a date form item.
     * 
     * @param config either format string or a full blown BonesFormItemDateOptions object.
     */
    constructor(config: BonesFormItemDateOptions | string)
    {
        if (!config)
        {
            this.format = 'yyyy-MM-dd';
        }
        else if (typeof config === 'string')
        {
            this.format = config;
        }
        else
        {
            this.format = config.format;
        }

        // Is this a datetime item?
        this.timeEntry = (this.format.toLowerCase().indexOf('h') >= 0);
    }
}
