import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { BonesErrorService } from '@bones/core';

import { BreweryService, breweryNoneGroupFactory } from '@BeerMonkey/rate/service/BreweryService';
import { BeerService, beerNoneGroupFactory } from '@BeerMonkey/rate/service/BeerService';
import { RatingService } from '@BeerMonkey/rate/service/RatingService';
import { Brewery } from '@BeerMonkey/rate/class/Brewery';
import { Beer } from '@BeerMonkey/rate/class/Beer';
import { Rating } from '@BeerMonkey/rate/class/Rating';
import { InventoryService } from '@BeerMonkey/rate/service/InventoryService';
import { Inventory } from '@BeerMonkey/rate/class/Inventory';
import { GlasswareService } from '@BeerMonkey/rate/service/GlasswareService';
import { Glassware } from '@BeerMonkey/rate/class/Glassware';
import { MonkeyService } from '@BeerMonkey/core';
import { RecentSearchService } from '@BeerMonkey/rate/service/RecentSearchService';
import { BreweryListCardComponent } from '@BeerMonkey/rate/clique/brewery/brewery-list-card/brewery-list-card';
import { BeerListCardComponent } from '@BeerMonkey/rate/clique/beer/beer-list-card/beer-list-card';
import { RatingListCardComponent } from '@BeerMonkey/rate/clique/rating/rating-list-card/rating-list-card';
import { InventoryListCardComponent } from '@BeerMonkey/rate/clique/inventory/inventory-list-card/inventory-list-card';
import { GlasswareListCardComponent } from '@BeerMonkey/rate/clique/glassware/glassware-list-card/glassware-list-card';

@Component({
    selector: 'search',
    templateUrl: 'search.html'
})
export class SearchPage implements OnInit, OnDestroy
{
    allBreweries?: Brewery[];
    allBeers?: Beer[];
    allRatings?: Rating[];
    allInventory?: Inventory[];
    allGlassware?: Glassware[];
    searchTerm: string = '';
    private nal: (() => void)[] = [ ];
    recentSearchTerms: string[] = [ ];
    recentBreweries: Brewery[] = [ ];
    recentBeers: Beer[] = [ ];

    recentBreweryGrouping = breweryNoneGroupFactory('Most recent first');
    recentBeerGrouping = beerNoneGroupFactory('Most recent first');

    breweryCard?: BreweryListCardComponent;
    beerCard?: BeerListCardComponent;
    ratingCard?: RatingListCardComponent;
    inventoryCard?: InventoryListCardComponent;
    glasswareCard?: GlasswareListCardComponent;

    constructor(
        private es: BonesErrorService,
        public monkey: MonkeyService,
        private searches: RecentSearchService,
        private breweryDB: BreweryService,
        private beerDB: BeerService,
        private ratingDB: RatingService,
        private inventoryDB: InventoryService,
        private glasswareDB: GlasswareService
    )
    {
    }

    async ngOnInit()
    {
        // Load and refresh breweries as needed
        this.nal.push(this.breweryDB.cache.nowAndLater(
        rows =>  this.allBreweries = rows,
        error => this.es.errorHandler(error)));

        // Load and refresh beers as needed
        this.nal.push(this.beerDB.cache.nowAndLater(
        rows =>  this.allBeers = rows,
        error => this.es.errorHandler(error)));

        // Load and refresh ratings as needed
        this.nal.push(this.ratingDB.cache.nowAndLater(
        rows =>  this.allRatings = rows,
        error => this.es.errorHandler(error)));

        // Load and refresh inventory as needed
        this.nal.push(this.inventoryDB.cache.nowAndLater(
        rows => this.allInventory = rows,
        error => this.es.errorHandler(error)));

        // Load and refresh glassware as needed
        this.nal.push(this.glasswareDB.cache.nowAndLater(
        rows => this.allGlassware = rows,
        error => this.es.errorHandler(error)));

        // Get recent searches
        this.getRecentSearches();
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

    async doSearch(searchTerm: string)
    {
        this.searchTerm = searchTerm;

        if (searchTerm)
        {
            this.searches.addRecentSearchTerm(searchTerm);
        }
        else
        {
            // Refresh list of recently accessed beers as it may have changed from drill down on search results
            this.getRecentSearches();
        }
    }

    /**
     * Get recent searches
     */
    async getRecentSearches()
    {
        this.recentSearchTerms = this.searches.getRecentSearchTerms();
        this.recentBreweries = await this.breweryDB.getBreweries(this.searches.getRecentBreweryIDs());
        this.recentBeers = await this.beerDB.getBeers(this.searches.getRecentBeerIDs());
    }

}
