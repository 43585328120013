import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController, AlertController, NavParams } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';

import { BonesEditForm } from '@bones/edit';
import { BonesErrorService } from '@bones/core';

import { ApeRest } from '@BeerMonkey/core';
import { BreweryInfo } from '@BeerMonkey/rate/class/BreweryInfo';
import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';
import { RateBeerService } from '@BeerMonkey/rate/service/RateBeerService';

@Component({
    selector: 'brewery-edit',
    templateUrl: 'brewery-edit.html'
})
export class BreweryEditModal extends BonesEditForm<BreweryInfo> implements OnInit
{
    constructor(
        protected formBuilder: FormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: ApeRest,
        private breweryDB: BreweryService,
        public rateBeerDB: RateBeerService
    )
    {
        super();
    }

    async ngOnInit()
    {
        super.initialize(
        {
            columns:
            [
                {
                    name: 'brewery_id',
                    hidden: true
                },
                {
                    name: 'name',
                    title: 'Name',
                    validator: [ Validators.required, Validators.maxLength(128) ]
                },
                {
                    name: 'location',
                    title: 'Location',
                    validator: Validators.maxLength(128)
                },
                {
                    // Paste a RateBeer URL for the brewery and the brewery ID will be extracted and populated
                    name: 'url',
                    title: 'RateBeer Brewery URL',
                    onChange: url =>
                    {
                        if (url)
                        {
                            const id = url.match(/\/(\d+)\/$/);
                            if (id)
                            {
                                this.bonesForm.setValue('ratebeer_id', id[1]);
                            }
                        }
                    }
                },
                {
                    name: 'ratebeer_id',
                    title: 'RateBeer ID',
                    type: 'number'
                },
                {
                    name: 'notes',
                    title: 'Notes',
                    type: 'textarea'
                }
            ],
            saveUrl: '/rate/brewery/update/updateBrewery',
            cache: this.breweryDB.cache
        });
    }

    searchRateBeer()
    {
        this.rateBeerDB.searchBreweryByName(this.bonesForm.getValue('name'));
    }

    copyRateBeer()
    {
        this.rateBeerDB.getBrewery(this.bonesForm.getValue('ratebeer_id'))
        .then(rbbrewery =>
        {
            this.bonesForm.setValue('name', rbbrewery.BrewerName);
            this.bonesForm.setValue('location', rbbrewery.BrewerCity);
        })
        .catch(error => this.bes.errorHandler(error));
    }
}
