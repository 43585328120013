import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Route } from '@angular/router';

import { BonesCoreModule, BonesVersion } from '@bones/core';
import { BonesTestToolsPiglets } from '@bones/core';
import { BonesFormModule } from '@bones/form';

import { BonesTestToolsEditMockDataService } from './service/test-tools-mock-data';
import { BonesTestToolsEditMockCacheService } from './service/test-tools-mock-cache';
import { BonesTestToolsEditMockRestService } from './service/test-tools-mock-rest';

import { BonesEditButtonBarComponent } from './components/bones-edit-button-bar/bones-edit-button-bar';

import { BonesTestToolsEditGridPage } from './pages/test-tools-edit-grid/test-tools-edit-grid';
import { BonesTestToolsEditFormPage } from './pages/test-tools-edit-form/test-tools-edit-form';
import { BonesTestToolsCacheEditGridPage } from './pages/test-tools-cache-edit-grid/test-tools-cache-edit-grid';
import { BonesTestToolsCacheEditFormPage } from './pages/test-tools-cache-edit-form/test-tools-cache-edit-form';

/**
 * Routes for edit test tools.
 */
const routes: Route[] =
[
    { path: 'bones/test-tools/edit/grid', component: BonesTestToolsEditGridPage },
    { path: 'bones/test-tools/edit/cache/grid', component: BonesTestToolsCacheEditGridPage }
];

@NgModule({
    declarations: [
        BonesEditButtonBarComponent,
        BonesTestToolsEditGridPage,
        BonesTestToolsEditFormPage,
        BonesTestToolsCacheEditGridPage,
        BonesTestToolsCacheEditFormPage
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule.forChild(routes),
        FormsModule, ReactiveFormsModule,
        BonesCoreModule,
        BonesFormModule
    ],
    // Use for services that are referenced only within this module
    // Put global provides in forRoot() method below
    providers: [
        BonesTestToolsEditMockDataService,
        BonesTestToolsEditMockCacheService,
        BonesTestToolsEditMockRestService
    ],
    // Use for directives, pipes, and components that are used as html elements eg <my-comp></my-comp>
    exports: [
        BonesEditButtonBarComponent
    ]
})
export class BonesEditModule
{
    static initialize()
    {
        BonesVersion.map.set('edit', '1.3.2023-10-20');

        BonesTestToolsPiglets.tools.push(
        {
            group: 'Form',
            title: 'Edit Grid / Form',
            icon: 'create',
            path: 'bones/test-tools/edit/grid'
        },
        {
            group: 'Form',
            title: 'Edit Grid / Form using BonesCache',
            icon: 'cube',
            path: 'bones/test-tools/edit/cache/grid'
        });
    }
}
BonesEditModule.initialize();
