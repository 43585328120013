<bones-gallery-large [items]="galleryItems" [cardWidth]="cardWidth" (select)="selected($event)">
  <ng-template #header let-item>
    <ion-card-title (click)="item.idea.titleLink($event)">{{ item.idea.title }}</ion-card-title>
    <ion-card-subtitle color="primary" (click)="item.idea.subtitleLink($event)" *ngIf="item.idea.subtitle">{{ item.idea.subtitle }}</ion-card-subtitle>

    <div class="header-info">
      <div *ngIf="item.idea.vv">{{ item.idea.vv }}</div>
      <div *ngIf="!item.idea.vv">&nbsp;</div>
    </div>
  </ng-template>
  
  <ng-template #footer let-item>
    <div class="footer-info">
      <div *ngIf="item.idea.ratedOn">{{ item.idea.ratedOn }}</div>
      <div>
        <span>{{ item.rating.row.mode }}</span>
        <span *ngIf="item.rating.row.mode && item.rating.row.source"> from </span>
        <span>{{ item.rating.row.source }}</span>
      </div>
    </div>
    <div *ngIf="item.rating.rsc > 0">
      <rating-stars [score]="item.rating.rsc" [fractional]="true"></rating-stars>
      <span class="rsc">{{ item.rating.rsc }}</span>
    </div>
  </ng-template>
</bones-gallery-large>
