<!-- rate beer information card -->
<ion-card class="ion-padding" *ngIf="rbbeer">
  <bones-card-toolbar title="RateBeer Information" [expandable]="true" [(expanded)]="sticky.showRB">
  </bones-card-toolbar>

  <ion-card-content class="ion-no-padding" *ngIf="sticky.showRB">
    <ion-item lines="none">
      <ion-label>
        <div>
          <img style="height: 1.25em;" src="/assets/icon/rate-beer-logo.png">
          <ion-text class="ion-text-wrap" color="primary" (click)="rateBeerDB.openBrewery(rbbeer.BrewerID)"> {{ rbbeer.BrewerName }}</ion-text>
        </div>
        <div>
          <img style="height: 1.25em;" src="/assets/icon/rate-beer-logo.png">
          <ion-text class="ion-text-wrap" color="primary" (click)="rateBeerDB.openBeer(rbbeer.BeerID)"> {{ rbbeer.BeerName }}</ion-text>
        </div>
      </ion-label>
    </ion-item>

    <ion-item lines="none">
      <ion-label>
        <div class="ion-text-wrap">{{ rbbeer.BeerStyleName }}</div>
        <div *ngIf="rbbeer.Alcohol > 0">{{ rbbeer.Alcohol }}%</div>
      </ion-label>
      <div class="ion-text-right" slot="end">
        <div *ngIf="rbbeer.OverallPctl">Overall: {{rbbeer.OverallPctl | number:'0.0-0' }}</div>
        <div *ngIf="rbbeer.StylePctl">Style: {{ rbbeer.StylePctl | number:'0.0-0' }}</div>
      </div>
    </ion-item>

    <p *ngIf="sticky.showRB && rbbeer.Description">{{ rbbeer.Description }}</p>
  </ion-card-content>
</ion-card>

<ion-card class="ion-padding" *ngIf="errorMessage">
  <ion-text color="danger" *ngIf="errorMessage">{{ errorMessage }}</ion-text>
</ion-card>
