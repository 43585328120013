import { Component, Input, OnInit } from '@angular/core';

type objectType = string | { [id: string]: string | number };
type listType = { id: string; value: string }[];

/**
 * Display the properties and values of an object in an ion-list.
 * 
 * Source can be either an object or a JSON string containing an object
 * 
 * @example
 * <bones-property-list [source]="source"></bones-property-list>
 */
@Component({
  selector: 'bones-property-list',
  templateUrl: 'bones-property-list.html'
})
export class BonesPropertyListComponent implements OnInit
{
    /**
     * Either an object or a JSON string containing an object. Required.
     */
    @Input() source: objectType;

    /**
     * Maximum length of a property value to be displayed at end of list vs inline. Default is 30.
     */
    @Input() maxLength = 30;

    /**
     * Message to display if the object has no properties. Default is to not show a message.
     */
    @Input() empty: string;

    /**
     * Object properties browen out into a list of id/value objects
     */
    propertyList: listType = [ ];

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

    /**
     * Convert object to list
     * @ignore
     */
    ngOnInit()
    {
        let obj: objectType;

        // Skip if no source list was provided
        if (!this.source)
        {
            return;
        }

        // Convert json string to object
        if (typeof this.source === 'string')
        {
            try
            {
                obj = JSON.parse(this.source);
            }
            catch (error)
            {
                // Create object to display original source and error information
                obj = { error: error, source: this.source };
            }
        }
        else
        {
            obj = this.source;
        }

        // Convert object to list
        Object.keys(obj).sort().forEach(k => this.propertyList.push({ id: k, value: obj[k] }));
    }

}
