<ion-header><app-page-header [subtitle]="beer?.name"></app-page-header></ion-header>

<ion-content>

  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="searchTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="inventoryTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="featuredTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="imageTmpl8"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">
    <ion-row>
      <ion-col>
        <ng-container *ngTemplateOutlet="searchTmpl8"></ng-container>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="inventoryTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="galleryCardTmpl8"></ng-container>
      </ion-col>

      <ion-col size="4" *ngIf="layout === 'list'">
        <ng-container *ngTemplateOutlet="featuredTmpl8"></ng-container>
        <ng-container *ngTemplateOutlet="imageTmpl8"></ng-container>
      </ion-col>
      <ion-col size="4" *ngIf="layout === 'list'">
        <ng-container *ngTemplateOutlet="ratingTmpl8"></ng-container>
      </ion-col>

      <ion-col size="8" *ngIf="layout === 'gallery'">
        <ng-container *ngTemplateOutlet="ratingGalleryTmpl8"></ng-container>
      </ion-col>

    </ion-row>
  </ion-grid>

</ion-content>

<!-- Templates -->

<ng-template #beerTmpl8>
  <beer-info [beer]="beer" [actions]="beerCardMenu"></beer-info>
</ng-template>

<ng-template #ratingTmpl8>
  <rating-list-card [ratings]="ratings" [searchTerm]="searchTerm"></rating-list-card>
</ng-template>

<ng-template #ratingGalleryTmpl8>
  <rating-gallery [ratings]="ratings" [searchTerm]="searchTerm"></rating-gallery>
</ng-template>

<ng-template #inventoryTmpl8>
  <inventory-list-card #inventoryCard [inventory]="inventory" [searchTerm]="searchTerm"
    [showSearchbar]="false" [showBreweryName]="false" [showBeerName]="false" [showZeros]="false"
    [defaultGroup]="defaultInventoryGroup"
    [showInventory]="inventoryCard.filtered.length < 6"
    *ngIf="inventory">
  </inventory-list-card>
</ng-template>

<ng-template #imageTmpl8>
  <ion-card class="ion-padding" *ngIf="featuredDocuments.length === 0 && beerImageID()">
    <bones-card-toolbar class="ion-padding-bottom" >Image from Latest Rating</bones-card-toolbar>
    <img style="width: 100%" [bonesGalleryDocumentID]="beerImageID()" [bonesGalleryDocumentSize]="gallery.imageSizeCard">
  </ion-card>
</ng-template>

<ng-template #searchTmpl8>
  <ion-searchbar [(ngModel)]="searchTerm" [debounce]="500"></ion-searchbar>
</ng-template>

<!-- document templates -->

<ng-template #featuredTmpl8>
  <!-- <bones-gallery-document-card [document]="document" [editable]="user.canEdit" *ngFor="let document of documents | bgdFilterFeatured"> -->
  <bones-gallery-document-card [document]="document" [editable]="user.canEdit" *ngFor="let document of featuredDocuments">
  </bones-gallery-document-card>
</ng-template>

<ng-template #galleryCardTmpl8>
  <bones-gallery-card [documents]="documents" [editable]="user.canEdit" [expanded]="false" [add]="[ 'beer_id', beerID ]"
    *ngIf="(documents | bgdFilterNotFeatured).length > 0">
  </bones-gallery-card>
</ng-template>
