import { Component, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { AfterViewInit } from '@angular/core';

import { BonesError, BonesErrorService } from '@bones/core';
import { BonesDocumentInfo } from '../../class/BonesDocumentInfo';
import { BonesDocumentService } from '../../service/document';
import { BonesDocumentOptions } from '../../class/BonesDocumentOptions';

/**
 * Component for displaying and updating documents
 */
@Component({
  selector: 'bones-document',
  templateUrl: 'bones-document.html',
  styleUrls: ['bones-document.css']
})
export class BonesDocumentComponent implements AfterViewInit
{
    /**
     * Set ID of an existing document to be displayed.
     */
    @Input() set documentID(id: number)
    {
        this._documentID = id;
        this.changeDocumentID();
    }
    /**
     * Get document ID.
     */
    get documentID()
    {
        return this._documentID;
    }
    private _documentID: number;

    /**
     * Document info.
     * Can be supplied as defaults for displaying an existing document.
     * Info object will be updated upon change for read-write documents.
     * Info object must not be undefined in order to be updated with document changes.
     * Info object can also be received upon changes by using the documentChange event.
     */
    @Input() info: BonesDocumentInfo;

    /**
     * Options for resizing images
     */
    @Input() options: BonesDocumentOptions;

    /**
     * Should document be read-only or can it be updated (via click or drag-drop)?
     */
    @Input() rw: boolean;

    /**
     * Event callback when the document is changed.
     */
    @Output() documentChange: EventEmitter<BonesDocumentInfo> = new EventEmitter();

    /**
     * Event callback when the document information has been loaded via documentID.
     */
    @Output() documentLoaded: EventEmitter<BonesDocumentInfo> = new EventEmitter();

    /**
     * Image to be shown as a thumbnail for the document.
     */
    backgroundImage: string;

    /**
     * Thumbnail overlay message for when no thumbnail image is displayed
     */
    textOverlay: string;

    /**
     * Label to display under thumbnail image
     */
    tnLabel: string;

    private binaryContent: string;
    @ViewChild('fileInput') private fileInput: ElementRef;
    private defaultOverlayMessage = 'Drag here or click to upload file';

    /**
     * constructor
     */
    constructor(
        private bes: BonesErrorService,
        private bds: BonesDocumentService
    )
    {
    }

    //-----------------------------------------------------------------------

    /**
     * Display thumbnail once component has been initialized
     */
    ngAfterViewInit()
    {
        if (!this.info)
        {
            this.info = { } as BonesDocumentInfo;
        }
        if (!this.options)
        {
            this.options = { };
        }
        setTimeout(() => this.showThumbnail(), 100);
    }

    //-----------------------------------------------------------------------

    /**
     * Document area clicked
     */
    documentClicked()
    {
        // Trigger input element file selection upon clicking the drop area
        if (this.rw)
        {
            const event = new MouseEvent('click', {bubbles: false});
            this.fileInput.nativeElement.dispatchEvent(event);
        }
        // Read only documents can be clicked to open the document in a new window
        else if (this.documentID)
        {
            window.open('../bones/document/get/' + this.documentID, '_blank');
        }
    }

    /**
     * Allow a document to be dropped when dragging a document over the area
     */
    allowDrop(event: DragEvent)
    {
        // console.log('allowDrop', event);
        if (this.rw)
        {
            event.preventDefault();
        }
    }

    /**
     * A document has been dropped
     */
    onDrop(event: DragEvent)
    {
        // console.log('drop', event);
        event.preventDefault();

        const dt = event.dataTransfer;
        if (dt.files.length > 0)
        {
            this.gotFile(dt.files[0]);
        }
        else
        {
            console.log('no file dropped', dt, event);
        }
    }

    /**
     * New file selected via input element
     */
    fileSelected()
    {
        this.gotFile(this.fileInput.nativeElement.files[0]);
    }

    //-----------------------------------------------------------------------

    /**
     * Watch for incoming document ID to change to load document info from server
     */
    private changeDocumentID()
    {
        // console.log('bones-document: documentIdChanged', this.documentID);

        if (this.documentID > 0)
        {
            // Clear overlay message
            this.textOverlay = undefined;

            // Get attributes
            this.bds.getDocumentInfo(this.documentID)
            .then(info =>
            {
                // Clear old properties while leaving the object reference intact
                Object.keys(this.info).forEach(key => delete this.info[key]);

                // Copy over new info
                Object.keys(info).forEach(key => this.info[key] = info[key]);

                // Thom
                this.showThumbnail();

                // Send load event
                // console.log('send documentLoaded', this.info);
                this.documentLoaded.emit(this.info);
            })
            .catch(error =>
            {
                this.bes.errorHandler(
                    new BonesError(
                    {
                        className: 'BonesDocumentComponent',
                        methodName: 'documentIdChanged',
                        message: 'Can\'t get document information',
                        error: error
                    })
                    .add(
                    {
                        documentID: this.documentID
                    }));
            });
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Got a file via drop or file selector
     */
    gotFile(file: File)
    {
        // console.log('gotFile', file.name, file);

        // Get file content
        const getData = (rfunc: (blob: Blob) => void) : Promise<string|ArrayBuffer> =>
        {
            // return new Promise((resolve, reject) =>
            return new Promise(resolve =>
            {
                const fileReader = new FileReader();
                fileReader.onload = (e) =>
                {
                    // console.log('gotFile.getData.reader.onload', e, fileReader.result);
                    resolve(fileReader.result);
                };

                rfunc.call(fileReader, file);
            });
        };

        // Get file content as a data URL - data:application/pdf;base64,xxxxxxxxx
        const getAsDataURL = () => getData(FileReader.prototype.readAsDataURL);

        // Get file content as a string of binary characters
        // Not supported by IE and deprecated as HTML5 standard
        const getAsBinaryString = () => getData(FileReader.prototype.readAsBinaryString);

        // Get file content as a string of binary characters via array buffer
        const getAsArrayBuffer = () : Promise<string> =>
        {
            return new Promise(resolve =>
            {
                const fileReader = new FileReader();
                fileReader.onload = (e: ProgressEvent) =>
                {
                    // console.log('getAsArrayBuffer', e, file, fileReader.result);
                    // Convert ArrayBuffer to binary string
                    let binary = '';
                    const bytes = new Uint8Array(fileReader.result as ArrayBuffer);
                    const len = bytes.byteLength;
                    for (let i = 0; i < len; i++)
                    {
                        binary += String.fromCharCode(bytes[i]);
                    }

                    resolve(binary);
                };

                fileReader.readAsArrayBuffer(file);
            });
        };

        // Load binary file contents
        getAsArrayBuffer().then(data =>
        {
            // console.log('gotFile: BinaryString', window.btoa(data.substr(0, 10)));
            this.binaryContent = data;
            const base64 = window.btoa(data);

            // Image needs to be resized or maybe just converted to jpg to reduce size
            if (file.type.substr(0, 5) === 'image' &&
                ((this.options.maxSize && file.size > this.options.maxSize)
                    || this.options.maxWidth
                    || this.options.maxHeight))
            {
                // Convert and resize image
                // console.log('gotFile: incoming image size too big', this.binaryContent.length);
                this.url2base64('data:' + file.type + ';base64,' + base64)
                .then((info: any) =>
                {
                    // Clear old properties (except documentID) while leaving the object reference intact
                    Object.keys(this.info).filter(key => key !== 'documentID').forEach(key => delete this.info[key]);

                    this.info.filename = file.name;

                    // New image info returned
                    if (info)
                    {
                        this.info.contentType = info.contentType;
                        this.info.content = info.base64;
                        this.info.size = info.binaryContent.length;
                        this.binaryContent = info.binaryContent;
    //                    console.log('converted info', this.info);
                    }
                    // Use original image
                    else
                    {
                        this.info.contentType = file.type;
                        this.info.content = base64;
                        this.info.size = this.binaryContent.length;
                    }

                    // console.log('gotFile: image processed', this.info);

                    // Send change event
                    // console.log('send documentChange', this.info);
                    this.documentChange.emit(this.info);

                    // Show the thumbnail
                    this.showThumbnail();
                },
                (error) =>
                {
                    this.bes.errorHandler(
                        new BonesError(
                        {
                            className: 'BonesDocumentComponent',
                            methodName: 'url2base64',
                            message: 'Can\'t get doc base64',
                            error: error
                        })
                        .add(
                        {
                            fileType: file.type
                        }));
                });
            }
            else
            {
                // Clear old properties (except documentID) while leaving the object reference intact
                Object.keys(this.info).filter(key => key !== 'documentID').forEach(key => delete this.info[key]);

                // Load info
                this.info.filename = file.name;
                this.info.contentType = file.type;
                this.info.content = base64;
                this.info.size = this.binaryContent.length;
                // console.log('info', this.info);

                // console.log('gotFile: non-image or not too big', this.info);

                // Send change event
                // console.log('send documentChange', this.info);
                this.documentChange.emit(this.info);

                // Show the thumbnail
                this.showThumbnail();
            }
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Convert an image URL to base64 after applying size restrictions
     */
    url2base64(url: string) : Promise<any>
    {
        return new Promise((resolve, reject) =>
        {
            const img = new Image();
            img.src = url;

            img.onload = () =>
            {
                // Set scare if necessary
                let wscale = 1, hscale = 1;

                if (this.options.maxWidth)
                {
                    if (img.width > this.options.maxWidth)
                    {
                        wscale = 1 - ((img.width - this.options.maxWidth) / img.width);
                    }
                }
                if (this.options.maxHeight)
                {
                    if (img.height > this.options.maxHeight)
                    {
                        hscale = 1 - ((img.height - this.options.maxHeight) / img.height);
                    }
                }

                // No resizing required
                if (wscale === 1 && hscale === 1)
                {
                    resolve(undefined);
                    return;
                }

                // Create a canvas for the target size of the image
                const canvas = document.createElement('canvas');
                const scale = (wscale < hscale) ? wscale : hscale;
                canvas.width = Math.round(img.width * scale);
                canvas.height = Math.round(img.height * scale);

                // Copy the image contents to the canvas
                const ctx = canvas.getContext('2d');
                ctx.fillStyle = '#ffffff';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.scale(scale, scale);
                ctx.drawImage(img, 0, 0);

                // Get the binary contents of the image and possibly convert to jpg
                const dataURL = canvas.toDataURL('image/jpeg');
                const matches = dataURL.match(/^data:(.+?);base64,(.+)$/);
                const contentType = matches[1];
                const base64 = matches[2];
                const binaryContent = window.atob(base64);

//                        console.log('resize', this.options,
//                                img.width, img.height,
//                                wscale, hscale, scale,
//                                canvas.width, canvas.height,
//                                contentType, binaryContent.length);

                // Check to see if file exceeds maximum document size
                if (this.options.maxSize)
                {
                    const k = Math.round(binaryContent.length / 1024);
                    if (k > this.options.maxSize)
                    {
                        reject('Image is too big (' + k + 'K). Try reducting size or using a different image.');
                        return;
                    }
                }

                resolve(
                {
                    base64: base64,
                    binaryContent: binaryContent,
                    contentType: contentType
                });
            };
        });
    }

    //-----------------------------------------------------------------------

    private showThumbnail()
    {
        // console.log('showThumbnail', this.info);

        // Clear old thumbnail info
        // this.clearThumbnail();
        this.setBackgroundImage('');
        this.textOverlay = undefined;
        this.tnLabel = undefined;

        // No content type, thus no document; revert back to default text overlay
        if (!this.info.contentType)
        {
            this.textOverlay = this.rw ? this.defaultOverlayMessage : undefined;
            return;
        }

        // Figure out document type
        let doctype = '';
        if (this.info.filename)
        {
            // Base document type off of filename extension
            let index = this.info.filename.lastIndexOf('.');
            if (index >= 0)
            {
                doctype = this.info.filename.substr(index + 1).toLowerCase();
            }
            // Base document type off of main content type
            else if (this.info.contentType)
            {
                index = this.info.contentType.lastIndexOf('/');
                if (index >= 0)
                {
                    doctype = this.info.contentType.substr(index + 1).toLowerCase();
                }
            }
        }

        // Sometimes the browser has no clue on contentType
        if (this.info.contentType === '')
        {
            // console.log('no contentType, doctype is', doctype);
            if (doctype === 'txt')
            {
                this.info.contentType = 'text/plain';
            }
            else
            {
                this.info.contentType = 'unknown';
            }
        }

        // If file is an image, change background to a thumbnail
        if (/^image\//.test(this.info.contentType))
        {
            // Use content to produce image background
            const imageBackground = () =>
            {
                // Convert contents to a data URL
                const dataURL = 'data:' + this.info.contentType + ';base64,' + this.info.content;

                // Show thumbnail of image
                this.setBackgroundImage('url(' + dataURL + ')');
            };

            // Clear any previous document type
            this.tnLabel = undefined;

            // We already have content for the background
            if (this.info.content)
            {
                imageBackground();
            }
            // We have an ID so fetch content from server
            else if (this.info.documentID)
            {
                // console.log('load content based upon ID for image thumbnail');

                this.bds.getDocumentContentAsDataUrl(this.info.documentID)
                .then(dataURL => this.setBackgroundImage('url(' + dataURL + ')'))
                .catch(error =>
                {
                    this.bes.errorHandler(
                        new BonesError(
                        {
                            className: 'BonesDocumentComponent',
                            methodName: 'showThumbnail',
                            message: 'Can\'t get document image for thumbnail',
                            error: error
                        })
                        .add(
                        {
                            documentID: this.info.documentID
                        }));
                });
            }
            else
            {
                console.log('No content and no ID for image thumbnail');
            }
        }
        // Documents have background of a document with "PDF" text overlay
        else
        {
            // Set background icon based upon document type
            if (/^video\//.test(this.info.contentType))
            {
                // Video
                this.setBackgroundImage('url(assets/bones/network/assets/image/video.png)');
            }
            else
            {
                // Show generic document icon as background
                // this.setBackgroundImage('url(/bones/image/document.png)');
                this.setBackgroundImage('url(assets/bones/network/assets/image/document.png)');
            }

            // Add document type caption
            this.tnLabel = doctype ? doctype.toUpperCase() : 'File';
            // console.log('non-image thumbnail', this.tnLabel, this.backgroundImage);
        }
    }

    /**
     * Set the background image of the drop element
     */
    private setBackgroundImage(css: string) : void
    {
        this.backgroundImage = css;
    }

}
