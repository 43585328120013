<ion-header><bones-tt-page-header title="Error Detail"></bones-tt-page-header></ion-header>

<ion-content>

  <ion-card class="ion-padding" *ngFor="let error of stack; let idx = index">
    <ion-card-title>
      <div *ngIf="idx > 0">Caused by</div>
      <div class="ion-text-wrap">{{ error.message || error }}</div>
      <div *ngIf="error.className">{{ error.className }}.{{ error.methodName }}</div>
    </ion-card-title>
      <ion-list>
        <ion-item *ngIf="error.name">
          <ion-label>
            <h2>Name</h2>
            <h3>{{ error.name }}</h3>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="idx === 0">
          <ion-label>
            <h2>Date</h2>
            <h3>{{ error.date | date:'medium' }}</h3>
          </ion-label>
        </ion-item>
        <ion-item *ngIf="error.otherData">
          <ion-label>
            <h2>Other Data</h2>
            <pre>{{ error.otherData | json }}</pre>
          </ion-label>
        </ion-item>
        <!-- <ion-item *ngIf="error.otherData && error.otherData.stacktrace">
          <pre>{{ error.otherData.stacktrace }}</pre>
        </ion-item>
        <ion-item *ngIf="error.stack">
          <pre>{{ error.stack }}</pre>
        </ion-item> -->
      </ion-list>
  </ion-card>

</ion-content>
