<ion-card class="ion-padding">
  <ion-card-title>
    <span>EDS Gateway</span>
    <ion-icon class="ion-float-right" name="open" (click)="configureEdsgw()"></ion-icon>
  </ion-card-title>
  <ion-item>
    <ion-label>
      <h2>Gateway Server</h2>
      <h3>{{ edsgw.edsGatewayServer }}</h3>
      <h3>{{ edsgw.edsGatewayUrl }}</h3>
    </ion-label>
  </ion-item>

  <ion-item>
    <ion-label>
      <h2>Application</h2>
      <h3>
        <span *ngIf="edsgw.attEDSApplication">{{ edsgw.attEDSApplication }}</span>
        <span *ngIf="!edsgw.attEDSApplication">No application has been set</span>
      </h3>
    </ion-label>
  </ion-item>

  <ion-item>
    <ion-label>
      <h2>Target</h2>
      <h3>
        <span *ngIf="edsgw.attEDSTarget">{{ edsgw.attEDSTarget }}</span>
        <span *ngIf="!edsgw.attEDSTarget">No target has been set</span>
      </h3>
    </ion-label>
  </ion-item>

  <ion-item>
    <ion-label>
      <h2>Authenticated</h2>
      <h3>
        <span *ngIf="edsgw.isAuthenticated">{{ edsgw.isAuthenticated }}</span>
        <span *ngIf="!edsgw.isAuthenticated">User has not been authenticated</span>
      </h3>
    </ion-label>
  </ion-item>

</ion-card>
