import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

import { BonesGalleryService } from '@bones/gallery';

import { AppUser, UserService } from '@BeerMonkey/core';

import { Beer } from '@BeerMonkey/rate/class/Beer';
import { RatingService } from '@BeerMonkey/rate/service/RatingService';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';

@Component({
    selector: 'beer-list',
    templateUrl: 'beer-list.html'
})
export class BeerListComponent
{
    beerList: Beer[] = [ ];
    @Input() set beers(list: Beer[])
    {
        this.beerList = list;
    }
    @Input() showBeerName = true;
    @Input() showBreweryName = false;

    user: AppUser;

    beerImage = (beer: Beer) : number | undefined => this.ratingDB.getBeerThumbnail(beer);

    constructor(
        private navCtrl: NavController,
        public gallery: BonesGalleryService,
        private mtus: UserService,
        public launch: LaunchEditModalService,
        private ratingDB: RatingService,
    )
    {
        this.user = this.mtus.getUser();
    }

    breweryDetail(brewery_id: number, event: Event)
    {
        this.navCtrl.navigateForward([ '/rate/brewery', brewery_id ]);
        event.stopPropagation();
    }

    beerDetail(beer_id: number, event: Event)
    {
        if (beer_id > 0)
        {
            this.navCtrl.navigateForward([ '/rate/beer', beer_id ]);
            event.stopPropagation();
        }
    }

}
