import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  templateUrl: 'test-tools-platform.html'
})
export class BonesTestToolsPlatformPage
{
    constructor(
        public platform: Platform,
    )
    {
    }

}
