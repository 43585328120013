import { ChartData } from './ChartData';

/**
 * Data structure passed to series based chart
 */
export class ChartDataSeries
{
    series: ChartData[] = [ ];

    constructor(public name: string)
    {

    }
}
