import { BonesError, BonesSort } from '@bones/core';

import { BonesTableFetchResponse, BonesTablePagedDataResponse } from './BonesTableFetchResponse';
import { BonesTableFetchRequest, BonesTablePagedDataRequest } from './BonesTableFetchRequest';
import { BonesTableLocalDataServices } from './BonesTableLocalDataServices';
import { BonesTableLocalDataOptions } from './BonesTableLocalDataOptions';

/**
 * Manage bones-table data that is stored locally
 */
export class BonesTableLocalData<T>
{
    /**
     * @ignore
     */
    constructor(
        private rows: T[],
        private services: BonesTableLocalDataServices,
        public options: BonesTableLocalDataOptions)
    {
    }

    //-----------------------------------------------------------------------

    /**
     * Fetch data from cached rows
     * 
     * @param request request object containing request options
     * @returns response object containing rows and metadata
     */
    async fetch(request: BonesTableFetchRequest) : Promise<BonesTableFetchResponse>
    {
        // console.log('BonesTableLocalData.fetch request', request);
        // console.log('fetchData local data', this.data);
        if (request.pagedDataRequest)
        {
            // return { pagedDataResponse: this.pagedDataRequest(request.pagedDataRequest) };
            return this.pagedDataRequest(request.pagedDataRequest)
            .then(r => ({ pagedDataResponse: r}))
            .catch(error =>
            {
                throw new BonesError(
                {
                    className: 'BonesTableLocalData',
                    methodName: 'fetch',
                    message: 'Unable to fetch paged data',
                    error: error
                });
            });
        }
    }

    /**
     * Fetch data from cached rows
     * 
     * @param request request object containing request options
     * @returns response object containing rows and metadata
     */
    private async pagedDataRequest(request: BonesTablePagedDataRequest) : Promise<BonesTablePagedDataResponse>
    {
        const response =
        {
            meta:
            {
                pageNumber: request.pageNumber,
                isFiltered: false
            },
            // Send state data
            state:
            {
                totalRowcount: this.rows.length,
                totalPagecount: Math.ceil(this.rows.length / request.pageSize)
            }
        } as BonesTablePagedDataResponse;

        // Default to unfiltered
        let effectiveRows: T[] = this.rows;
        let effectiveRowCount = response.state.totalRowcount;
        let effectivePageCount = response.state.totalPagecount;

        // Perform keyword search
        if (request.search)
        {
            effectiveRows = this.keywordSearch(request);
            response.meta.isFiltered = true;
            response.state.filteredRowcount = effectiveRows.length;
            response.state.filteredPagecount = Math.ceil(effectiveRows.length / request.pageSize);
            effectiveRowCount = response.state.filteredRowcount;
            effectivePageCount = response.state.filteredPagecount;
        }

        // Filtering reduced available rows to less than requested page; reset to first page
        if (request.pageNumber > effectivePageCount)
        {
            response.meta.pageNumber = 1;
        }

        // Calculate row range based on page number and page size
        response.meta.startingRowNumber = ((response.meta.pageNumber - 1) * request.pageSize) + 1;
        response.meta.endingRowNumber = response.meta.startingRowNumber + request.pageSize - 1;

        // Make sure last page does not go past available data
        if (response.meta.endingRowNumber >= effectiveRows.length)
        {
            response.meta.endingRowNumber = effectiveRows.length;
        }

        // Sort data
        BonesSort(effectiveRows, request.sortOrder);

        // Slice rows from local data source
        response.rows = effectiveRows.slice(response.meta.startingRowNumber - 1, response.meta.endingRowNumber);

        // Bools
        response.meta.isFirstPage = response.meta.pageNumber === 1;
        response.meta.isLastPage =  response.meta.pageNumber === effectivePageCount;

        // Return response
        // console.log('BonesTableLocalData.fetch response', response);
        return Promise.resolve(response);
    }

    /**
     * Search rows for keyword
     */
    private keywordSearch(request: BonesTablePagedDataRequest) : T[]
    {
        // Keyword as string, boolean, or number
        const lckw = request.search.keyword.toLowerCase();
        const btkw = lckw === 'true';
        const bfkw = lckw === 'false';
        const nkw = +lckw;

        const filtered: T[] = [ ];

        this.rows.forEach(row =>
        {
            let match = false;

            request.search.columnNames.forEach(name =>
            {
                const value = row[name];
                // console.log('keywordSearch', name, value, value.toString()); // btkw, bfkw, (value && btkw), (!value && bfkw)
                if (value !== undefined && value !== null)
                {
                    if (typeof value === 'string')
                    {
                        match = match || (value.toLowerCase().indexOf(lckw) >= 0);
                    }
                    else if (typeof value === 'number')
                    {
                        match = match || (value === nkw);
                    }
                    else if (typeof value === 'boolean')
                    {
                        match = match || ((value && btkw) || (!value && bfkw));
                    }
                    else if (typeof value === 'object' && 'toString' in value)
                    {
                        match = match || (value.toString().toLowerCase().indexOf(lckw) >= 0);
                    }
                    else
                    {
                        console.log('keywordSearch: unsuported match type', name, value, typeof value);
                    }
                }
            });

            if (match)
            {
                filtered.push(row);
            }
        });

        return filtered;
    }

}
