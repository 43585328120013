import { Injectable } from '@angular/core';

import { BonesNetworkLogEntry } from '../class/BonesNetworkLogEntry';

//-----------------------------------------------------------------------

/**
 * Track api requests and responses
 */
@Injectable({
  providedIn: 'root'
})
export class BonesNetworkLogService
{
    /**
     * Array of log entries.
     */
    private apiLog = new Array<BonesNetworkLogEntry>();

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

    /**
     * Create a new empty log entry.
     */
    public newEntry() : BonesNetworkLogEntry
    {
        // Create new entry
        const apiLogEntry = new BonesNetworkLogEntry();

        // Add it to the log
        this.apiLog.unshift(apiLogEntry);

        // Limit maximum size of log
        if (this.apiLog.length > 10)
        {
            this.apiLog.length = 10;
        }

        // Return entry to be fleshed out by caller
        return apiLogEntry;
    }

    /**
     * Get the log.
     */
    public getLog() : Array<BonesNetworkLogEntry>
    {
        return this.apiLog;
    }

    /**
     * Get log entry by ID.
     * 
     * @param id Log entry ID
     * 
     * @returns BonesNetworkLogEntry object.
     */
    public getLogEntry(id: number) : BonesNetworkLogEntry
    {
        return this.apiLog.find(entry => entry.id === id);
    }

    //-----------------------------------------------------------------------

}
