import { Directive, HostListener, Input, OnInit } from '@angular/core';

/**
 * Stop client event proprogation.
 * 
 * @example
 * <ion-item (click)="dontDoThisWhenButtonClicked()">
 *   <ion-button (click)="doThis()" bonesStopClick>Click Me</ion-button>
 * </ion-item>
 */
@Directive({
    selector: '[bonesStopClick]'
})
export class BonesStopClickDirective
{
    /**
     * Listen for click event.
     * 
     * @param event Click event
     */
    @HostListener('click', ['$event'])
    public onClick(event: any) : void
    {
        event.stopPropagation();
    }
}
