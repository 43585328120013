import { Component, Input } from '@angular/core';

import { BonesMenuCardAction, BonesItemGroup, BonesItemGroupFactory } from '@bones/core';

import { RatingFilter } from '@BeerMonkey/rate/service/RatingService';
import { ratingDateGroupFactory, ratingYearGroupFactory, ratingVvGroupFactory } from '@BeerMonkey/rate/service/RatingService';
import { ratingVersionGroupFactory, ratingVintageGroupFactory, ratingSourceGroupFactory } from '@BeerMonkey/rate/service/RatingService';
import { ratingBreweryGroupFactory, ratingBeerGroupFactory, ratingRbScoreGroupFactory } from '@BeerMonkey/rate/service/RatingService';
import { Rating } from '@BeerMonkey/rate/class/Rating';
import { nowShowing } from '@BeerMonkey/rate/class/nowShowing';
import { showCard } from '@BeerMonkey/rate/class/showCard';
import { RatingInfo } from '@BeerMonkey/rate/class/RatingInfo';

@Component({
    selector: 'rating-list-card',
    templateUrl: 'rating-list-card.html'
})
export class RatingListCardComponent
{
    @Input() showSearchbar = false;
    @Input() resort = true;
    @Input() showRatings = true;
    @Input() search = false;
    @Input() showBreweryName = true;
    @Input() showBeerName = true;

    _title: string = 'Ratings';
    @Input() set title (title: string | undefined)
    {
        this._title = title ?? 'Ratings';
    }
    get title() : string | undefined
    {
        return this._title;
    }

    _ratings?: Rating[];
    @Input() set ratings(ratings: Rating[] | undefined)
    {
        this._ratings = ratings;
        this.filterContent();
    }
    get ratings() : Rating[] | undefined
    {
        return this._ratings;
    }

    _searchTerm: string = '';
    @Input() set searchTerm(searchTerm: string)
    {
        this._searchTerm = searchTerm;
        this.filterContent();
    }
    get searchTerm() : string
    {
        return this._searchTerm;
    }

    groupFactory = ratingDateGroupFactory('desc');
    @Input() set group(groupFactory: BonesItemGroupFactory<Rating, RatingInfo> | undefined)
    {
        if (groupFactory)
        {
            this.groupFactory = groupFactory;
            this.filterContent();
        }
    }

    filtered: Rating[] = [ ];
    groups?: BonesItemGroup<Rating>[];
    numberToDisplay = 30;
    preTruncateLength?: number;
    truncated?: boolean;
    ratingCardMenu: BonesMenuCardAction[];

    constructor(
    )
    {
        this.ratingCardMenu =
        [
            { title: 'Group by Date Rated (a)', icon: 'funnel', action: () => this.doSort(ratingDateGroupFactory()) },
            { title: 'Group by Date Rated (d)', icon: 'funnel', action: () => this.doSort(ratingDateGroupFactory('desc')) },
            
            { title: 'Group by Year Rated (a)', icon: 'funnel', action: () => this.doSort(ratingYearGroupFactory()) },
            { title: 'Group by Year Rated (d)', icon: 'funnel', action: () => this.doSort(ratingYearGroupFactory('desc')) },
            
            { title: 'Group by RB Score (a)', icon: 'funnel', action: () => this.doSort(ratingRbScoreGroupFactory()) },
            { title: 'Group by RB Score (d)', icon: 'funnel', action: () => this.doSort(ratingRbScoreGroupFactory('desc')) },

            { title: 'Group by Brewery', icon: 'funnel', action: () => this.doSort(ratingBreweryGroupFactory()) },
            { title: 'Group by Beer', icon: 'funnel', action: () => this.doSort(ratingBeerGroupFactory()) },
            { title: 'Group by Version', icon: 'funnel', action: () => this.doSort(ratingVersionGroupFactory()) },

            { title: 'Group by Vintage (a)', icon: 'funnel', action: () => this.doSort(ratingVintageGroupFactory()) },
            { title: 'Group by Vintage (d)', icon: 'funnel', action: () => this.doSort(ratingVintageGroupFactory('desc')) },

            { title: 'Group by Vintage + Version (a)', icon: 'funnel', action: () => this.doSort(ratingVvGroupFactory()) },
            { title: 'Group by Vintage + Version (d)', icon: 'funnel', action: () => this.doSort(ratingVvGroupFactory('desc')) },

            { title: 'Group by Source', icon: 'funnel', action: () => this.doSort(ratingSourceGroupFactory()) },
        ];
    }

    async ngOnInit()
    {
    }

    ngOnDestroy()
    {
    }

    doSort(group: BonesItemGroupFactory<Rating, RatingInfo>)
    {
        this.groupFactory = group;
        this.filterContent();
    }

    /**
     * Filter, sort, and group ratings
     */
    filterContent()
    {
        if (!this.ratings)
        {
            this.filtered = [ ];
            this.groups = undefined;
            return;
        }

        // Filter by keyword
        this.filtered = new RatingFilter(this.ratings)
            .byKeyword(this.searchTerm)
            .rows;

        // Resort
        if (this.resort)
        {
            this.groupFactory.sortItems(this.filtered);
        }

        // Limit display size
        this.preTruncateLength = this.filtered.length;
        this.truncated = this.filtered.length > this.numberToDisplay;
        if (this.truncated)
        {
            this.filtered = this.filtered.slice(0, this.numberToDisplay);
        }

        // Group
        this.groups = this.groupFactory.group(this.filtered);
    }

    async showMore()
    {
        this.numberToDisplay += 30;
        this.filterContent();
    }

    showingText() : string
    {
        return nowShowing(this.showRatings, this.ratings?.length ?? 0, this.filtered.length, this.preTruncateLength ?? 0);
    }

    get show() : boolean
    {
        return showCard(this.search, this.searchTerm, this.filtered);
    }

}
