<ion-list>
  <ion-item-sliding *ngFor="let brewery of breweryList">

    <ion-item (click)="breweryDetail(brewery.brewery_id, $event)">
      <ion-thumbnail style="height: 4em;" slot="start">
        <img [bonesGalleryDocumentID]="breweryLogo(brewery)" [bonesGalleryDocumentSize]="gallery.imageSizeThumbnail" loading="lazy" *ngIf="breweryLogo(brewery)">
      </ion-thumbnail>

      <ion-label>
        <h2 class="ion-text-wrap">
          <ion-text color="secondary">{{ brewery.row.name }}</ion-text>
        </h2>

        <div>{{ brewery.row.location }}</div>

        <p class="truncated-notes two" *ngIf="brewery.row.notes">{{ brewery.row.notes }}</p>
        <p class="truncated-notes two" *ngIf="brewery.row.description && !brewery.row.notes">{{ brewery.row.description }}</p>
      </ion-label>
    </ion-item>

    <ion-item-options>
      <ion-item-option (click)="breweryDetail(brewery.brewery_id, $event)">
        <ion-icon slot="top" size="large" name="eye"></ion-icon>
        Detail
      </ion-item-option>
      <ion-item-option (click)="launch.editBrewery(brewery)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="pencil"></ion-icon>
        Edit
      </ion-item-option>
      <ion-item-option (click)="launch.addBeer(brewery.brewery_id)" *ngIf="user.isAdmin">
        <ion-icon slot="top" size="large" name="add-circle"></ion-icon>
        Beer
      </ion-item-option>
    </ion-item-options>

  </ion-item-sliding>
</ion-list>
