<div *ngFor="let group of _galleries">
  <ion-card class="group-card ion-padding" *ngIf="group.title">
    <ion-card-title [color]="'primary'">{{ group.title }}</ion-card-title>
  </ion-card>

  <ion-card class="gallery-card ion-padding" [ngStyle]="{width: cardWidth}" (click)="onClick(item)" *ngFor="let item of group.items">
    <ion-card-title *ngIf="item.title">{{ item.title }}</ion-card-title>

    <ng-template [ngTemplateOutlet]="headerTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>

    <div class="image">
      <img [bonesGalleryDocumentID]="item.imageID" [bonesGalleryDocumentSize]="size" loading="lazy" *ngIf="item.imageID">
      <img [src]="item.imageURL" loading="lazy" *ngIf="item.imageURL">
      <ng-template [ngTemplateOutlet]="blankTemplate" [ngTemplateOutletContext]="{ $implicit: item }" *ngIf="!item.imageID && !item.imageURL"></ng-template>
    </div>

    <ng-template [ngTemplateOutlet]="footerTemplate" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
  </ion-card>
</div>
