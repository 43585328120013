import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { BonesCoreModule } from '@bones/core';
import { BonesFormModule } from '@bones/form';
import { BonesGalleryModule } from '@bones/gallery';
import { BonesNetworkModule } from '@bones/network';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppCoreModule } from './modules/core';
import { AppRateModule } from '@BeerMonkey/rate';
import { AppStatsModule } from '@BeerMonkey/stats';

import { HomePage } from './home/home';

@NgModule({
  declarations:
  [
    AppComponent,
    HomePage
  ],
  imports:
  [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    FormsModule, ReactiveFormsModule,
    BonesCoreModule,
    BonesFormModule,
    BonesGalleryModule,
    BonesNetworkModule,
    AppRoutingModule,
    AppCoreModule,
    AppRateModule,
    AppStatsModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule
{
}
