<ion-header><modal-header title="Edit Inventory" [editForm]="this"></modal-header></ion-header>

<ion-content [formGroup]="form" *ngIf="form">

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="bnb"></ng-container>
    <ng-container *ngTemplateOutlet="demog"></ng-container>
    <ng-container *ngTemplateOutlet="qty"></ng-container>
    <ng-container *ngTemplateOutlet="notes"></ng-container>
  </div>

  <ion-grid *ngIf="monkey.isLargeDevice">
    <ion-row>
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="bnb"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="demog"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="qty"></ng-container>
        <ng-container *ngTemplateOutlet="notes"></ng-container>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>

<ion-footer>
  <bones-edit-button-bar [editor]="this"></bones-edit-button-bar>
</ion-footer>

<ng-template #bnb>
  <ion-card class="ion-padding">
    <ion-card-title>Brewery & Beer</ion-card-title>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('brewery_id')"></bones-form-item>
    <ion-item *ngIf="brewery">
      <ion-label>{{ brewery.row.location }}</ion-label>
    </ion-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('beer_id')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('beer_name')"></bones-form-item>
  </ion-card>
</ng-template>

<ng-template #demog>
  <ion-card class="ion-padding">
    <ion-card-title>Demographics</ion-card-title>
    <!-- <bones-form-items [form]="form" [items]="items.slice(4, 8)"></bones-form-items> -->
    <bones-form-item [form]="form" [item]="bonesForm.getItem('location')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('version')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('vintage')"></bones-form-item>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('size')"></bones-form-item>
  </ion-card>
</ng-template>

<ng-template #qty>
  <ion-card class="ion-padding">
    <ion-card-title>
      Quantity
      <span *ngIf="initialQuantity"> (was {{ initialQuantity }})</span>
    </ion-card-title>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('quantity')"></bones-form-item>
    <ion-button size="small" (click)="increment()">
      <ion-icon slot="start" name="add"></ion-icon>
      Increment
    </ion-button>
    <ion-button size="small" (click)="decrement()">
      <ion-icon slot="start" name="remove"></ion-icon>
      Decrement
    </ion-button>
  </ion-card>
</ng-template>

<ng-template #notes>
  <ion-card class="ion-padding">
    <ion-card-title>Notes</ion-card-title>
    <bones-form-item [form]="form" [item]="bonesForm.getItem('notes')"></bones-form-item>
  </ion-card>
</ng-template>
