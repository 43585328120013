import { PipeTransform, Pipe } from '@angular/core';

/**
 * Pipe to format seconds into hh:mm:ss
 * 
 * @example
 * {{ row.seconds | bonesElapsedTime }}
 */
@Pipe({
  name: 'bonesElapsedTime'
})
export class BonesElapsedTimePipe implements PipeTransform
{
    /**
     * Format seconds into hh:mm:ss
     * @param value seconds
     */
    transform(value: number) : string
    {
        if (value === undefined || value === null)
        {
            return '';
        }

        let hh = 0;
        let mm = Math.floor(value / 60);
        const ss = (value - mm * 60);

        let formatted = ':' + ss.toString().padStart(2, '0');

        if (mm > 59)
        {
            hh = Math.floor(mm / 60);
            mm -= hh * 60;

            formatted = hh + ':' + mm.toString().padStart(2, '0') + formatted;
        }
        else
        {
           formatted = mm + formatted;
        }

        return formatted;
    }

}
