/**
 * Class for items used on picker
 */
export class BonesPickerChoice
{
    /**
     * Item key returned when item is chosen.
     */
    key: (string | number);

    /**
     * Item value displayed in UI.
     */
    value: string;
}

