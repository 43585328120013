import { Component, Input } from '@angular/core';

/**
 * Display a link for an email address.
 * 
 * @example
 * <bones-webphone-link text="Webphone" [attuid]="variable"></bones-webphone-link>
 */
@Component({
  selector: 'bones-webphone-link',
  templateUrl: 'bones-webphone-link.html'
})
export class BonesWebphoneLinkComponent
{
    /**
     * Attuid.
     */
    @Input() attuid: string;

    /**
     * Display text (optional; defaults to address).
     */
    @Input() text: string;

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

}
