/**
 * Class to define side menu item for use with <bones-side-menu>.
 */
export class BonesSideMenuAction
{
    /**
     * Optional item group.
     */
    group?: string;

    /**
     * Menu title. Defaults to "Menu".
     */
    title: string;

    /**
     * Menu action to take.
     * 
     * push - route to this page.
     * root - route to this page, replacing the navigation stach.
     * modal - open a modal dialog box for this page.
     * function - call function.
     */
    action: 'push' | 'root' | 'modal' | ((item: BonesSideMenuAction) => void);

    /**
     * Routing path when action is 'push' or 'root'.
     */
    path?: string;

    /**
     * Component for when action is 'modal'.
     */
    component?: any;

    /**
     * Optional callback after modal action is dismissed.
     */
    onDidDismiss?: (data: any) => void;
}
