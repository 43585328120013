import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';

import { BonesSideMenuAction } from '@bones/core';
import { BonesGalleryService } from '@bones/gallery';

import { ApeRest, UserService, AppUser } from '@BeerMonkey/core';
import { cannedSearchBeerTopRated, cannedSearchBeerTopIPA } from '@BeerMonkey/rate/clique/beer/beer-page/beer-page';
import { cannedSearchRatingTopAllTime } from '@BeerMonkey/rate/clique/rating/ratings-page/ratings-page';

/**
 * App component is the main entry point for the application.
 */
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html'
})
export class AppComponent
{
    actions: BonesSideMenuAction[] = [ ];

    constructor(
        private platform: Platform,
        private gallery: BonesGalleryService,
        private mtus: UserService,
        private rest: ApeRest
    )
    {
        this.platform.ready().then(() => this.init());
    }

    async init()
    {
        this.rest.serverUrl = 'https://beer.monkey.kpstuff.com/BeerMonkey/ws';

        // Populate side menu based upon current or default access
        this.populateSideMenu(this.mtus.getUser());

        // Subscribe to user changes so menu items can be adjusted based upon user access
        this.mtus.userChange.subscribe(user => this.populateSideMenu(user));

        // Setup usage of BonesGalleryService and components
        this.configBonesGallery();
    }

    private populateSideMenu(user: AppUser)
    {
        // Build new array locally
        const actions: BonesSideMenuAction[] = [ ];

        // Actions available to all users
        this.pushy(true, actions, { title: 'Home', path: '/', action: 'root' });
        this.pushy(true, actions, { title: 'Search', path: '/rate/search', action: 'root' });
        this.pushy(true, actions, { title: 'Recent Ratings', path: '/rate/recent-ratings', action: 'root' });

        // Ideas
        this.pushy(user.isAdmin, actions, { title: 'Ideas', path: '/rate/idea', action: 'root' });

        // Canned searches
        actions.push(cannedSearchBeerTopRated.menu, cannedSearchBeerTopIPA.menu);
        actions.push(cannedSearchRatingTopAllTime.menu);

        // Stats
        this.pushy(true, actions, { group: 'Stats', title: 'Ratings By Year', path: '/stats/ratings-by-year', action: 'root' });
        this.pushy(true, actions, { group: 'Stats', title: 'RateBeer', path: '/stats/ratebeer', action: 'root' });

        const today = new Date().getFullYear();
        this.pushy(true, actions, { group: 'Stats', title: 'Ratings for ' + today, path: '/rate/yearly-ratings/' + today, action: 'root' });
        this.pushy(true, actions, { group: 'Stats', title: 'Ratings for ' + (today - 1), path: '/rate/yearly-ratings/' + (today - 1), action: 'root' });

        // Advanced Search
        this.pushy(true, actions, { group: 'Advanced Search', title: 'Breweries', path: '/rate/breweries', action: 'root' });
        this.pushy(true, actions, { group: 'Advanced Search', title: 'Beers', path: '/rate/beers', action: 'root' });
        this.pushy(true, actions, { group: 'Advanced Search', title: 'Ratings', path: '/rate/ratings', action: 'root' });
        this.pushy(true, actions, { group: 'Advanced Search', title: 'Inventory', path: '/rate/inventory', action: 'root' });
        this.pushy(true, actions, { group: 'Advanced Search', title: 'Glassware', path: '/rate/glassware', action: 'root' });

        // Testing
        this.pushy(user.isAdmin, actions,
        {
            group: 'Testing',
            title: 'Test Tools',
            path: '/bones/test-tools',
            action: 'push'
        });

        // The assignment of the new actions array is what triggers updating the menu
        this.actions = actions;
    }

    private pushy(doPush: boolean, actions: BonesSideMenuAction[], action: BonesSideMenuAction)
    {
        if (doPush)
        {
            actions.push(action);
        }
    }

    configBonesGallery()
    {
        this.gallery.init(
        {
            foreignKeyNames: [ 'brewery_id', 'beer_id', 'rating_id', 'inventory_id', 'glassware_id' ],
            rest: this.rest
        });
    }

}
