<ion-searchbar placeholder="Search breweries" [(ngModel)]="searchTerm" [debounce]="500" *ngIf="showSearchbar"></ion-searchbar>

<ion-card class="ion-padding" *ngIf="show">
  <bones-card-toolbar [title]="title" [note]="showingText()" [actions]="cardMenu" [expandable]="true" [(expanded)]="showBreweries">
  </bones-card-toolbar>

  <ion-card-content class="ion-no-padding" *ngIf="showBreweries && groups">
    <ion-item-group *ngFor="let group of groups">
      <ion-item-divider *ngIf="groups.length > 1 || groups[0].by">
        <ion-label>{{ group.by }}</ion-label>
      </ion-item-divider>
      <brewery-list [breweries]="group.items"></brewery-list>
    </ion-item-group>

  </ion-card-content>
</ion-card>
