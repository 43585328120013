import { Component, OnInit, OnDestroy } from '@angular/core';

import { BonesErrorService } from '@bones/core';

import { AppUser, UserService } from '@BeerMonkey/core';
import { BreweryService } from '@BeerMonkey/rate/service/BreweryService';
import { Brewery } from '@BeerMonkey/rate/class/Brewery';

@Component({
    selector: 'breweries-page',
    templateUrl: 'breweries-page.html'
})
export class BreweriesPage implements OnInit, OnDestroy
{
    user: AppUser;
    breweries?: Brewery[];
    searchTerm?: string;
    showBreweries = true;
    filteredBreweries: Brewery[] = [ ];
    private nal: (() => void)[] = [ ];

    constructor(
        private es: BonesErrorService,
        private mtus: UserService,
        private breweryDB: BreweryService
    )
    {
        this.user = this.mtus.getUser();
    }

    async ngOnInit()
    {
        // Load and refresh breweries as needed
        this.nal.push(this.breweryDB.cache.nowAndLater(
        rows => this.breweries = rows,
        error => this.es.errorHandler(error)));
    }

    ngOnDestroy()
    {
        this.nal.forEach(n => n());
    }

}
