import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { BonesFormItem } from '../../class/BonesFormItem';

/**
 * Use toggle to select on/off or yes/no form value.
 * 
 * @example
 * <bones-form-item-toggle [form]="form" [item]="item" (change)="onToggle($event)"></bones-form-item-toggle>
 */
@Component({
    selector: 'bones-form-item-toggle',
    templateUrl: 'form-item-toggle.html'
})
export class BonesFormItemToggleComponent implements OnInit
{
    /**
     * Form containing field.
     */
    @Input() form: UntypedFormGroup;

    /**
     * Object with details of form field to create.
     */
    @Input() item: BonesFormItem;

    /**
     * Event callback when an value is picked from the list.
     * The selected value is passed back.
     */
    @Output() change: EventEmitter<any> = new EventEmitter();

    /**
     * Actual true/false value.
     */
    toggleValue: boolean;

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

    /**
     * Initialize control
     */
    ngOnInit() : void
    {
        // Initialize toggle based upon current form control value
        this.setToggle(this.form.value[this.item.name]);

        // Update toggle if undeflying form value changes
        this.form.get(this.item.name).valueChanges.subscribe(value => this.setToggle(value));
    }

    /**
     * Set toggle state based upon string value.
     * 
     * @param value new value
     */
    setToggle(value: string)
    {
        if (value === this.item.toggle.on)
        {
            this.toggleValue = true;
        }
        else if (value === this.item.toggle.off)
        {
            this.toggleValue = false;
        }
        // Unknown value supplied; default to off and update form value
        else
        {
            this.toggleValue = false;
            this.onToggle();
        }

        // console.log('setToggle', value, this.toggleValue, this.item.toggle);
    }

    /**
     * Update form control when toggle is toggled
     */
    onToggle()
    {
        const value = this.toggleValue ? this.item.toggle.on : this.item.toggle.off;
        const patch = { };
        patch[this.item.name] = value;
        this.form.patchValue(patch);
        this.change.emit({ value: value });
    }

}
