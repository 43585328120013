/**
 * Should a list card be shown?
 * 
 * @param isSearch is this a search page
 * @param searchTerm current search term
 * @param items filtered list of items
 */
export function showCard(isSearch: boolean, searchTerm: string, items: any[]) : boolean
{
    // Hide card if doing search and there is no search term
    if (isSearch && !searchTerm)
    {
        return false;
    }
    // Hide card if there are no items to show
    else if (!items || items.length === 0)
    {
        return false;
    }
    // Otherwise show what we have
    else
    {
        return true;
    }

}
