import { BreweryInfo } from './BreweryInfo';

export class Brewery
{
    brewery_id: number;

    constructor(public row: BreweryInfo)
    {
        this.brewery_id = row.brewery_id;
    }

    // Needed to make edit modal picker happy
    public get name() : string
    {
        return this.row.name;
    }

    /**
     * Has this brewery been linked to RateBeer?
     */
    public get linkedToRateBeer() : boolean
    {
        return this.row.ratebeer_id > 0;
    }

    /**
     * Could this brewery be linked to RateBeer?
     */
    public get linkableToRateBeer() : boolean
    {
        return this.row.ratebeer_id === undefined;
    }

    /**
     * Could this brewery be linked to RateBeer?
     */
    public get notLinkableToRateBeer() : boolean
    {
        return this.row.ratebeer_id === 0;
    }
}
