import { Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { BonesErrorService, BonesError } from '@bones/core';
import { EDSGatewayService } from '../../service/eds-gateway';
import { BonesRestService } from '../../service/bones-rest';
import { BonesDocumentService } from '../../service/document';
import { BonesDocumentInfo } from '../../class/BonesDocumentInfo';

@Component({
  templateUrl: 'test-tools-bones-document.html'
})
export class TestToolsBonesDocumentPage
{
    form: UntypedFormGroup;
    docInfo: any;
    error: BonesError;
    documentID2: number;
    documentInfo1: BonesDocumentInfo = { } as BonesDocumentInfo;
    documentInfo2: BonesDocumentInfo; // = { } as BonesDocumentInfo;

    constructor(
        private loadingCtrl: LoadingController,
        private formBuilder: UntypedFormBuilder,
        private es: BonesErrorService,
        public edsgw: EDSGatewayService,
        public brest: BonesRestService,
        private bds: BonesDocumentService
    )
    {
        // Define edit form
        this.form = this.formBuilder.group(
        {
            documentId: [ '', Validators.required ],
            documentID2: [ '' ]
        });

        // Populate form using locally saved values
        this.form.patchValue({ documentId: window.localStorage.getItem('bones-tt-bdoc-document-id') || 1 });
    }

    //-----------------------------------------------------------------------

    /**
     * Get document info
     */
    async getInfo()
    {
        this.docInfo = undefined;
        this.error = undefined;

        try
        {
            const loading = await this.loadingCtrl.create();
            loading.present();

            // Save latest values to local storage for next time
            window.localStorage.setItem('bones-tt-bdoc-document-id', this.form.value.documentId);

            this.bds.getDocumentInfo(+this.form.value.documentId)
            .then(docInfo =>
            {
                loading.dismiss();
                this.docInfo = docInfo;
            })
            .catch(error =>
            {
                loading.dismiss();
                this.error = error;
                this.es.errorHandler(error);
            });
        }
        catch (error)
        {
            this.error = error;
            this.es.errorHandler(error);
        }
    }

    async getDocument()
    {
        this.docInfo = undefined;
        this.error = undefined;

        try
        {
            const loading = await this.loadingCtrl.create();
            loading.present();

            // Save latest values to local storage for next time
            window.localStorage.setItem('bones-tt-bdoc-document-id', this.form.value.documentId);

            this.bds.getDocumentAndInfo(+this.form.value.documentId)
            .then(docInfo =>
            {
                loading.dismiss();
                this.docInfo = docInfo;
            })
            .catch(error =>
            {
                loading.dismiss();
                this.error = error;
                this.es.errorHandler(error);
            });
        }
        catch (error)
        {
            this.error = error;
            this.es.errorHandler(error);
        }
    }

    //-----------------------------------------------------------------------

    /**
     * <bones-document> image was changed
     */
    onDocChange1(info: BonesDocumentInfo)
    {
        console.log('tt.onDocChange1', info);
    }

    /**
     * <bones-document> image was loaded
     */
    onDocumentLoaded2(info: BonesDocumentInfo)
    {
        console.log('tt.onDocumentLoaded2', info);
        this.documentInfo2 = info;
    }

    onIdChange2(event) // CustomEvent
    {
        console.log('tt.onIdChange2', event.target.value, event);
        this.documentID2 = event.target.value;
    }

}
