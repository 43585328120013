<ion-toolbar>
  <ion-buttons slot="start">
    <ion-back-button></ion-back-button>
    <ion-menu-button></ion-menu-button>
  </ion-buttons>
  <div class="title">BeerMonkey</div>
  <div class="subtitle" *ngIf="subtitle">{{ subtitle }}</div>
  <div slot="end">
    <ion-button class="home" size="small" fill="clear" color="light" (click)="search()">
      <ion-icon name="search"></ion-icon>
    </ion-button>
    <ion-button class="home" size="small" fill="clear" color="light" (click)="home()">
      <ion-icon name="home"></ion-icon>
    </ion-button>
    <div class="user" *ngIf="user.authenticated">
      <ion-text>{{ user.info?.name }}</ion-text>
      <ion-text *ngIf="user.isAdmin">*</ion-text>
    </div>
    <div class="user" *ngIf="!user.authenticated" (click)="login()">Guest</div>
  </div>
</ion-toolbar>
