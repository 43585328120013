import { Injectable } from '@angular/core';

import { BonesSort, BonesError, BonesSortOption, BonesSearch, BonesItemGroupFactory } from '@bones/core';
import { BonesCache, BonesCacheFactory } from '@bones/core';
import { BonesGalleryService } from '@bones/gallery';

import { ApeRest } from '@BeerMonkey/core/service/ApeRest';
import { BreweryService } from './BreweryService';
import { GlasswareInfo } from '../class/GlasswareInfo';
import { Glassware } from '../class/Glassware';
import { Brewery } from '../class/Brewery';

/**
 * Access db information
 */
@Injectable({
  providedIn: 'root',
})
export class GlasswareService
{
    cache: BonesCache<number, GlasswareInfo, Glassware>;

    constructor(
        private bcf: BonesCacheFactory,
        private gallery: BonesGalleryService,
        private rest: ApeRest,
        private breweryDB: BreweryService
    )
    {
        this.cache = this.bcf.create<number, GlasswareInfo, Glassware>(
        {
            pk: 'glassware_id',
            loadCache: () => this.rest.send('rate/glassware/getGlasswares'),
            reloadOne: (id: number) => this.rest.send('rate/glassware/getGlassware', { glasswareID: id }),
            converter: async (info: GlasswareInfo) : Promise<Glassware> =>
            {
                const glassware = new Glassware(info);

                glassware.brewery = await this.breweryDB.getBrewery(glassware.row.brewery_id);

                return glassware;
            },
            sorter: (a: Glassware, b: Glassware) =>
            {
                return a.row.glass.localeCompare(b.row.glass);
            }
        });
    }

    //-----------------------------------------------------------------------

    /**
     * Get glassware details
     */
    async getGlassware(glasswareID: number) : Promise<Glassware | undefined>
    {
        return glasswareID ? this.cache.getEntry(glasswareID) : undefined;
    }

    getThumbnail(glassware: Glassware) : number | undefined
    {
        return this.gallery.thumbnailPicker(
            { foreignKeyName: 'glassware_id', foreignKeyValue: glassware.glassware_id },
            { foreignKeyName: 'brewery_id', foreignKeyValue: glassware.brewery?.brewery_id }
        );
    }

    //-----------------------------------------------------------------------

    async getBreweryPicker() : Promise<Map<number, string>>
    {
        const pickerMap = new Map<number, string>();

         (await this.cache.getList())
        .map(i => i.brewery)
        .filter((b): b is Brewery => Boolean(b))
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach(b => pickerMap.set(b.brewery_id, b.name));

        return pickerMap;
    }

    async getPicker(key: keyof GlasswareInfo) : Promise<string[]>
    {
        return (await this.cache.getList())
        .map(i => '' + i.row[key])
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))
    }

}

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

export const glasswareNameGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Glassware, GlasswareInfo>('glass', { sort: sort, byFirstLetter: true });

export const glasswareBreweryNameGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Glassware, GlasswareInfo>((item) => item.brewery?.name ?? 'no brewery', { sort: sort });

export const glasswareStyleGroupFactory = (sort?: BonesSortOption) =>
    new BonesItemGroupFactory<Glassware, GlasswareInfo>('style', { sort: sort });

//-----------------------------------------------------------------------
//-----------------------------------------------------------------------

/**
 * Filter glasswareID
 */
export class GlasswareFilter
{
    constructor(public rows: Glassware[])
    {
    }

    byBrewery(breweryID: number) : GlasswareFilter
    {
        if (breweryID)
        {
            this.rows = this.rows.filter(r => r.row.brewery_id === breweryID);
        }

        return this;
    }

    by(key: keyof GlasswareInfo, value: string) : GlasswareFilter
    {
        if (value)
        {
            this.rows = this.rows.filter(r => r.row[key] === value);
        }

        return this;
    }

    byKeyword(phrase: string) : GlasswareFilter
    {
        if (phrase)
        {
            this.rows = new BonesSearch<Glassware>(this.rows, phrase)
            .execute(r => [ r.row.glass, r.row.style, r.row.notes, r.brewery?.name ]);
        }

        return this;
    }
}

