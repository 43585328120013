import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { BonesFormItem } from '../../class/BonesFormItem';

/**
 * Display multiple input items within a form.
 * 
 * @example
 * <bones-form-items [form]="form" [items]="formItemArray"></bones-form-items>
 */
@Component({
  selector: 'bones-form-items',
  templateUrl: 'form-items.html'
})
export class BonesFormItemsComponent
{
    /**
     * FormGroup containing form controls
     */
    @Input() form: UntypedFormGroup;

    /**
     * Items to display on form
     */
    @Input() items: BonesFormItem[];

    /**
     * @ignore
     */
    constructor()
    {
    }

}
