import { Component } from '@angular/core';

import { BonesError } from '../../model/bones-error';
import { BonesErrorService } from '../../service/bones-error.service';

import { BonesPickerService } from '../../service/bones-picker';
import { BonesPickerReturn } from '../../model/BonesPickerReturn';
import { BonesPickerOptions } from '../../model/BonesPickerOptions';
import { BonesPickerValues } from '../../model/BonesPickerValues';
import { BonesMenuCardAction } from '../../model/bones-menu-card-action';

@Component({
  templateUrl: 'test-tools-picker.html'
})
export class BonesTestToolsPickerPage
{
    options: BonesPickerOptions;
    returnValue: BonesPickerReturn;
    pickers: BonesPickerOptions[];
    actions: BonesMenuCardAction[] = [ ];
    rvActions: BonesMenuCardAction[] =
    [
        { title: 'One', icon: '', action: (a) => console.log('Option One', a), args: 1 },
        { title: 'Two', icon: '', action: () => console.log('Option 2') },
        { title: 'Three', icon: '', action: undefined }
    ];

    constructor(
        private bes: BonesErrorService,
        private bps: BonesPickerService
    )
    {
        // Create map with numbers as keys
        const picker3Map = new Map<number, string>();
        picker3Map.set(1, 'One');
        picker3Map.set(2, 'Two');
        picker3Map.set(3, 'Three');
        picker3Map.set(4, 'Four');

        // Create map with strings as keys
        const picker4Map = new Map<string, string>();
        picker4Map.set('A', 'Apple');
        picker4Map.set('B', 'Banana');
        picker4Map.set('C', 'Cherry');

        this.pickers =
        [
            {
                title: '1 - List of strings',
                values: [ 'This', 'That', 'The other' ]
            },
            {
                title: '2 - List of strings, initialValue',
                values: [ 'This', 'That', 'The other' ],
                initialValue: 'That'
            },
            {
                title: '3a - Map of numbers, initialValue',
                values: picker3Map,
                initialValue: 2
            },
            {
                title: '3b - Map of strings, initialValue',
                values: picker4Map,
                initialValue: 'B'
            },
            {
                title: '5a - Populator, map, cache',
                populator: () => this.getDeferredPickerValues1()
            },
            {
                title: '5b - Populator, map, nocache',
                populator: () => this.getDeferredPickerValues1(),
                nocache: true
            },
            {
                title: '5c - Populator, error, nocache',
                populator: () => this.badPopulator(),
                nocache: true
            },
            {
                title: '6a - Populator, map, cache, initialValue',
                populator: () => this.getDeferredPickerValues1(),
                initialValue: 'B'
            },
            {
                title: '6b - Populator, map, cache, bad initialValue',
                populator: () => this.getDeferredPickerValues1(),
                initialValue: 'D'
            },
            {
                title: '7a - Populator, map, nocache, initialValue, multi',
                populator: () => this.getDeferredPickerValues1(),
                initialValue: [ 'A', 'C' ],
                nocache: true,
                multi: true
            },
            {
                title: '7b - List of strings, multi',
                values: [ 'yacco', 'wacko', 'dot' ],
                multi: true
            },
            {
                title: '8 - Long searchable list with long values',
                values: [
'1. Westvleteren 12 (XII) – Westvleteren Abdij St. Sixtus(Belgium)',
'2. Russian River Pliny the Elder – Russian River Brewing(California)',
'3. Russian River Pliny the Younger – Russian River Brewing(California)',
'4. Founders KBS (Kentucky Breakfast Stout) – Founders Brewing Company(Michigan)',
'5. Cigar City Hunahpu’s Imperial Stout – Brandy Barrel Aged – Cigar City Brewing(Florida)',
'6. Bells Hopslam – Bells Brewery(Michigan)',
'7. Rochefort Trappistes 10 – Brasserie Rochefort(Belgium)',
'8. Goose Island Bourbon County Stout – Goose Island Beer Company(Illinois)',
'9. AleSmith Speedway Stout – AleSmith Brewing Company(California)',
'10. Bells Black Note Stout – Bells Brewery(Michigan)',
'11. Alchemist Heady Topper – The Alchemist(Vermont)',
'12. Hill Farmstead Ann – Hill Farmstead Brewery(Vermont)',
'13. Cigar City Pilot Series Miami Madness – Cigar City Brewing(Florida)',
'14. Firestone Walker Parabola – Firestone Walker Brewing Co.(California)',
'15. Deschutes The Abyss – Deschutes Brewery(Oregon)',
'16. Hill Farmstead Abner – Hill Farmstead Brewery(Vermont)',
'17. Westvleteren Extra 8 – Westvleteren Abdij St. Sixtus(Belgium)',
'18. Bells Expedition Stout – Bells Brewery(Michigan)',
'19. Cantillon Fou’ Foune – Cantillon(Belgium)',
'20. Mikkeller Beer Geek Brunch Weasel – Mikkeller(Denmark)',
'21. Three Floyds Dreadnaught Imperial IPA – Three Floyds Brewing Company(Indiana)',
'22. Three Floyds Zombie Dust – Three Floyds Brewing Company(Indiana)',
'23. 3 Fonteinen Framboos (Framboise) – 3 Fonteinen(Belgium)',
'24. Hill Farmstead Society & Solitude #5 – Hill Farmstead Brewery(Vermont)',
'25. Three Floyds Dark Lord Russian Imperial Stout (Bourbon Vanilla Bean) – Three Floyds Brewing(Indiana)',
'26. Hill Farmstead Everett – Hill Farmstead Brewery(Vermont)',
'27. Peg’s G.O.O.D. RareR DOS – Peg’s Cantina(Florida)',
'28. Founders Imperial Stout – Founders Brewing Company(Michigan)',
'29. Dieu du Ciel Péché Mortel – Dieu du Ciel(Quebec)',
'30. St. Bernardus Abt 12 – St. Bernardus Brouwerij(Belgium)',
'31. North Coast Old Rasputin Anniversary Bourbon Barrel Aged Stout – North Coast Brewing(California)',
'32. Oskar Blues Ten FIDY – Oskar Blues Grill & Brew(Colorado)',
'33. Surly Darkness – Surly Brewing Company(Minnesota)',
'34. Cigar City Marshal Zhukov’s Imperial Stout – Cigar City Brewing(Florida)',
'35. Lost Abbey Deliverance – Port Brewing/Lost Abbey(California)',
'36. Struise Pannepot – De Struise Brouwers(Belgium)',
'37. Närke Konjaks! Stormaktsporter – Närke Kulturbryggeri(Sweden)',
'38. Stone Imperial Russian Stout – Stone Brewing Co.(California)',
'39. 3 Fonteinen Oude Geuze (Armand & Tommy) – 3 Fonteinen(Belgium)',
'40. Dark Horse Bourbon Barrel Plead the 5th Imperial Stout – Dark Horse Brewing Company(Michigan)',
'41. Southern Tier Choklat – Southern Tier Brewing Company(New York)',
'42. New Glarus Serendipity – New Glarus Brewing Company(Wisconsin)',
'43. Great Divide Espresso Oak Aged Yeti Imperial Stout – Great Divide Brewing Company(Colorado)',
'44. Struise Black Albert – De Struise Brouwers(Belgium)',
'45. Avery Uncle Jacob’s Stout – Avery Brewing Company(Colorado)',
'46. Lost Abbey Barrel Aged Serpents Stout with Cacao Nibs and Coffee – Port Brewing/Lost Abbey(California)',
'47. Russian River Beatification (Batch 002 +) – Russian River Brewing(California)',
'48. Ballast Point Victory at Sea – Ballast Point Brewing Company(California)',
'49. Cantillon Lou Pepe Pure Kriek – Cantillon(Belgium)',
'50. Lost Abbey The Angels Share (Bourbon Barrel) – Port Brewing/Lost Abbey(California)',
                ],
            },
        ];
    }

    async getDeferredPickerValues1() : Promise<BonesPickerValues>
    {
        const map = new Map<string, string>();

        map.set('A', 'Apple');
        map.set('B', 'Banana');
        map.set('C', 'Cherry');

        console.log('getDeferredPickerValues1', map);
        return map;
    }

    async badPopulator() : Promise<BonesPickerValues>
    {
        const error = new BonesError('badPopulator always throws an error');
        this.bes.errorHandler(error);
        throw error;
    }

    async doPick(options: BonesPickerOptions)
    {
        this.options = options;
        this.returnValue = undefined;
        this.returnValue = await this.bps.pick(options);
    }
}
