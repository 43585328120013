<ion-header><modal-header title="Fermenting"></modal-header></ion-header>

<ion-content>
  <ion-card class="ion-padding ion-text-center">
    <ion-text color="primary">
      <h2>Please wait while we dig through the cellar for something special</h2>
    </ion-text>
    <div>
      <ion-spinner name="bubbles"></ion-spinner>
    </div>
    <div>
      <img style="max-height: 350px;" [bonesGalleryDocumentID]="0" [bonesGalleryDocumentSize]="gallery.imageSizeCard">
    </div>
  </ion-card>
</ion-content>
