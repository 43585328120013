import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BonesError } from '../../model/bones-error';
import { BonesErrorType } from '../../model/bones-error-type';
import { BonesErrorService } from '../../service/bones-error.service';

@Component({
  templateUrl: 'test-tools-error-detail.html'
})
export class BonesTestToolsErrorDetailPage
{
    error: BonesError;
    stack: BonesErrorType[] = [ ];

    constructor(
        private route: ActivatedRoute,
        private es: BonesErrorService
    )
    {
        // Get error passed from log page
        this.error = this.es.getError(+this.route.snapshot.params.id);

        // Build array from nested errors
        let nested: BonesErrorType = this.error;
        let deep = 0;
        while (nested && deep++ < 10)
        {
            this.stack.push(nested);
            nested = (nested instanceof BonesError) ? nested.error : undefined;
        }
    }
}
