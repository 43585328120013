import { Injectable } from '@angular/core';
import { OverlayEventDetail } from '@ionic/core';
import { ModalController } from '@ionic/angular';
import { BonesPickerOptions } from '../model/BonesPickerOptions';
import { BonesPickerPage } from '../pages/bones-picker-modal/bones-picker-modal';
import { BonesPickerReturn } from '../model/BonesPickerReturn';

/**
 * Present picker
 */
@Injectable({
    providedIn: 'root'
})
export class BonesPickerService
{
    /**
     * @ignore
     */
    constructor(
        private modalCtrl: ModalController,
    )
    {
    }

    /**
     * Display picker modal and get user's choice.
     * 
     * @param options BonesPickerOptions
     */
    pick(options: BonesPickerOptions) : Promise<BonesPickerReturn>
    {
        return new Promise<BonesPickerReturn>(async (resolve) =>
        {
            const modal = await this.modalCtrl.create(
            {
                component: BonesPickerPage,
                componentProps:
                {
                    options: options
                }
            });

            // Action when screen exits
            modal.onDidDismiss().then((event: OverlayEventDetail) => resolve(event.data));

            modal.present();
        });
    }

    //-----------------------------------------------------------------------

}
