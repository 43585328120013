import { Injectable } from '@angular/core';

import { BonesError } from '@bones/core';
import { BonesRestService } from './bones-rest';
import { TspaceAvatar } from '../class/TspaceAvatar';
import { TspaceProfile } from '../class/TspaceProfile';

/**
 * Access tspace to retrieve employee info
 */
@Injectable({
    providedIn: 'root'
})
export class TspaceService
{
    private profileCache = new Map<string, TspaceProfile>();
    private avatarCache = new Map<string, TspaceAvatar>();

    /**
     * @ignore
     */
    constructor(
        private rest: BonesRestService
    )
    {
    }

    /**
     * Get data url for avatar image.
     * 
     * @param avatar TspaceAvatar object.
     * @returns data url for image or a blank image.
     */
    public static getDataUrl(avatar: TspaceAvatar) : string
    {
        if (avatar)
        {
            return 'data:' + avatar.contentType + ';base64,' + avatar.content;
        }
        else
        {
            return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Get profile.
     * 
     * @param attuid attuid of user for which profile is desired.
     * @returns profile object.
     */
    public async getProfile(attuid: string) : Promise<TspaceProfile>
    {
        // Check cache
        if (this.profileCache.has(attuid))
        {
            return Promise.resolve(this.profileCache.get(attuid));
        }

        // Get from server
        return this.rest.send('/bones-tspace/getProfile', { attuid })
        .then(profile =>
        {
            this.profileCache.set(attuid, profile);
            return profile;
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'TspaceService',
                methodName: 'getProfile',
                message: 'Unable to lookup tspace profile',
                error: error
            })
            .add({ attuid });
        });
    }

    /**
     * Get avatar.
     * 
     * @param attuid attuid of user for which avatar is desired.
     * @returns avatar object.
     */
    public async getAvatar(attuid: string) : Promise<TspaceAvatar>
    {
        // Check cache
        if (this.avatarCache.has(attuid))
        {
            return Promise.resolve(this.avatarCache.get(attuid));
        }

        // Get from server
        return this.rest.send('/bones-tspace/getAvatar', { attuid })
        .then(avatar =>
        {
            this.avatarCache.set(attuid, avatar);
            return avatar;
        })
        .catch(error =>
        {
            throw new BonesError(
            {
                className: 'TspaceService',
                methodName: 'getAvatar',
                message: 'Unable to get tspace avatar',
                error: error
            })
            .add({ attuid });
        });
    }

    /**
     * Get avatar data url.
     * 
     * @param attuid attuid of user for which avatar is desired.
     * @returns data url for image or a blank image.
     */
    public async getAvatarDataUrl(attuid: string) : Promise<string>
    {
        return this.getAvatar(attuid)
        .then(a => TspaceService.getDataUrl(a))
        .catch(() => TspaceService.getDataUrl(undefined));
    }

    //-----------------------------------------------------------------------

}
