<ion-header><bones-tt-page-header [subtitle]="group" home="/"></bones-tt-page-header></ion-header>

<ion-content>
  <ion-list>
    <ion-item *ngFor="let tool of tools" (click)="route(tool)">
      <ion-icon slot="start" [name]="tool.icon" color="secondary"></ion-icon>
      <h1>{{ tool.title }}</h1>
    </ion-item>
  </ion-list>
</ion-content>
