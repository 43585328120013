import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

/**
 * Display an error message for invalid input value within a form.
 * 
 * @example
 * <bones-form-error [form]="form" control="someFieldName">Required</bones-form-error>
 * 
 * @example
 * <bones-form-error [form]="form" control="password1" error="minlength">Password is too short</bones-form-error>
 */
@Component({
    selector: 'bones-form-error',
    templateUrl: 'form-error.html',
    styleUrls: [ 'form-error.scss' ]
})
export class BonesFormErrorComponent
{
    /**
     * FormGroup containing form controls
     */
    @Input() form: UntypedFormGroup;
    /**
     * Name of form control
     */
    @Input() control: string;
    /**
     * Error message to be displayed when form control has an invalid value
     */
    @Input() error: string = 'required';

    /**
     * @ignore
     */
    constructor()
    {
    }

    /**
     * Should error be displayed?
     */
    show() : boolean
    {
        const fc = this.form.controls[this.control];
        // console.log(this.control, !fc.valid && fc.dirty && fc.errors[this.error], fc);
        return !fc.valid && fc.dirty && fc.errors[this.error];
    }

}
