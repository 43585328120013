import { Component, Input } from '@angular/core';
import { MenuController } from '@ionic/angular';

import { BonesSideMenuAction } from '../../model/bones-side-menu-action';
import { BonesMenuActionService } from '../../service/Bones-menu-action';

/**
 * Group of menu items
 */
class ActionGroup
{
    /**
     * Menu items.
     */
    actions: BonesSideMenuAction[] = [ ];

    /**
     * Is this group "opened" (being displayed)?
     */
    open = false;

    /**
     * Create new menu group.
     * 
     * @param name Group name to be displayed.
     */
    constructor(public name: string)
    {
    }
}

/**
 * Display a side menu with an array of menu items.
 * 
 * Actions is a list of BonesSideMenuAction objects.
 * 
 * @example
 * <bones-side-menu [actions]="actions"></bones-side-menu>
 */
@Component({
    selector: 'bones-side-menu',
    templateUrl: 'bones-side-menu.html'
})
export class BonesSideMenuComponent
{
    /**
     * Array of BonesSideMenuAction objects.
     */
    @Input() set actions(actions: BonesSideMenuAction[])
    {
        this.groupActions(actions);
    }

    /**
     * Title for menu. Defaults to "Menu".
     */
    @Input() title = 'Menu';

    /**
     * Menu contentId (for multiple menus). Defaults to "menu1".
     */
    @Input() contentId = 'menu1';

    /**
     * How is menu displayed? overlay, reveal, or push
     */
    @Input() type = 'overlay';

    /**
     * Groups of actions.
     */
    groups: ActionGroup[];

    /**
     * @ignore
     */
    constructor(
        private menuCtrl: MenuController,
        private menuService: BonesMenuActionService
    )
    {
    }

    /**
     * Split items into groups
     */
    private groupActions(actions: BonesSideMenuAction[])
    {
        this.groups = [ ];

        // Group actions
        if (actions && actions.length > 0)
        {
            actions.forEach(action =>
            {
                const groupName = action.group;

                // Find existing group or create a new one
                let group = this.groups.find(g => g.name === groupName);
                if (!group)
                {
                    group = new ActionGroup(groupName);
                    this.groups.push(group);
                }

                // Add item to group
                group.actions.push(action);
            });
        }
    }

    /**
     * Execute menu item.
     * 
     * @param action Action to execute.
     */
    async do(action: BonesSideMenuAction)
    {
        this.menuCtrl.toggle();
        this.menuService.execute(action);
    }

}
