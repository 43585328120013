import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { LoadingController, AlertController, ModalController, NavParams } from '@ionic/angular';

import { BonesErrorService } from '@bones/core';
import { BonesEditForm } from '../../class/BonesEditForm';

import { BonesTestToolsEditMockCacheService, RestRow } from '../../service/test-tools-mock-cache';
import { BonesTestToolsEditMockRestService } from '../../service/test-tools-mock-rest';

@Component({
  templateUrl: 'test-tools-cache-edit-form.html'
})
export class BonesTestToolsCacheEditFormPage extends BonesEditForm<RestRow>
{
    display: RestRow;

    constructor(
        protected formBuilder: UntypedFormBuilder,
        protected loadingCtrl: LoadingController,
        protected alertCtrl: AlertController,
        protected modalCtrl: ModalController,
        protected navParams: NavParams,
        protected bes: BonesErrorService,
        protected rest: BonesTestToolsEditMockRestService,
        private mcs: BonesTestToolsEditMockCacheService
    )
    {
        super();
        super.initialize(
        {
            columns:
            [
                {
                    name: 'FIELD1',
                    hidden: true
                },
                {
                    name: 'FIELD2',
                    title: 'Field Two',
                    initialValue: 'f2',
                    validator: Validators.required
                },
                {
                    name: 'FIELD3',
                    title: 'Read-only field with picker',
                    picker: [ 'This', 'That', 'The other' ],
                    initialValue: 'That',
                    validator: Validators.required,
                    onChange: (v, i) => console.log('onChange', i.name, v)
                },
                {
                    name: 'FIELD4',
                    title: 'Type or pick value',
                    picker:
                    {
                        readWrite: true,
                        values: [ 'More', 'Options', 'Shaking', 'Stick' ]
                    },
                    validator: Validators.required,
                    onChange: (v, i) => console.log('onChange', i.name, v)
                },
                {
                    name: 'FIELD5',
                    title: 'Toggle',
                    toggle: { on: 'Y', off: 'N' },
                    validator: Validators.required
                },
                {
                    name: 'FIELD6',
                    title: 'Date',
                    type: 'date',
                    validator: Validators.required
                },
            ],
            saveUrl: '/xxx/yyyy',
            uploadFormat: 'form',
            cache: this.mcs.cache
        });
    }
}
