import { Component } from '@angular/core';

import { BonesSort } from '../../model/BonesSort';
import { BonesSortOrder } from '../../model/BonesSortOrder';

interface SampleData
{
    C1: number;
    C2: Date;
    C3: boolean;
    C4: string;
    C5?: string;
}

@Component({
  templateUrl: 'test-tools-sort.html'
})
export class BonesTestToolsSortPage
{
    rows: SampleData[] = [ ];
    sortOrder1: BonesSortOrder[] = [ { propertyName: 'C1' }];
    sortOrder2: BonesSortOrder[] =
    [
        { propertyName: 'C4' },
        { propertyName: 'C3', sort: 'asc' },
        { propertyName: 'C2', sort: 'desc' },
    ];
    sortOrder3: BonesSortOrder[] = [ { propertyName: 'C5' }];
    currentSortOrder = this.sortOrder1;
    fruits = [ 'Apple', 'Banana', 'Cherry' ];

    constructor(
    )
    {
        // Generate local dataset
        for (let rowNumber = 1; (rowNumber <= 32); ++rowNumber)
        {
            const adate = new Date();
            adate.setDate(Math.round(Math.random() * 180) - 90);

            const row: SampleData =
            {
                C1: rowNumber,
                C2: adate,
                C3: Math.random() >= .5,
                C4: this.fruits[Math.floor(Math.random() * this.fruits.length)]
            };

            for (let colNumber = 5; (colNumber <= 5); ++colNumber)
            {
                row['C' + colNumber] = 'Row ' + rowNumber + ', column ' + colNumber;
            }

            this.rows.push(row);
        }

        BonesSort(this.rows, this.currentSortOrder);
    }

    sort1()
    {
        this.currentSortOrder = this.sortOrder1;
        BonesSort(this.rows, this.currentSortOrder);
    }

    sort2()
    {
        this.currentSortOrder = this.sortOrder2;
        BonesSort(this.rows, this.currentSortOrder);
    }

    sort3()
    {
        this.currentSortOrder = this.sortOrder3;
        BonesSort(this.rows, this.currentSortOrder);
    }

}
