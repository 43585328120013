import { Injectable } from '@angular/core';

export class MyRowClass
{
    FIELD1: number;
    FIELD2: string;
    FIELD3?: string;
    FIELD4?: string;
    FIELD5?: string;
}

/**
 * Track api requests and responses
 */
@Injectable()
export class BonesTestToolsEditMockDataService
{
    /**
     * Mock data.
     */
    private data: MyRowClass[] =
    [
        {
            FIELD1: 1,
            FIELD2: 'Data for field two',
            FIELD3: 'This',
            FIELD4: 'Shaking',
            FIELD5: 'Y'
        },
        {
            FIELD1: 2,
            FIELD2: 'Field two, row two',
        }
    ];

    /**
     * @ignore
     */
    constructor(
    )
    {
    }

    /**
     * Get all rows.
     */
    public getRows() : Promise<MyRowClass[]>
    {
        return Promise.resolve(this.data);
    }

    /**
     * Get row by ID.
     * 
     * @param id Row ID
     * 
     * @returns MyRowClass object.
     */
    public getRow(id: number) : Promise<MyRowClass>
    {
        return Promise.resolve(this.data.find(entry => entry.FIELD1 === id));
    }

    //-----------------------------------------------------------------------

}
