<div [formGroup]="form" *ngIf="form">
  <ion-item>
    <ion-button fill="clear" slot="end" (click)="trigger()">
        <ion-icon name="search"></ion-icon>
    </ion-button>
    <bones-form-label [item]="item"></bones-form-label>
    <input type="file" #fileInput [hidden]="true" (change)="onFileSelect($event)">
    <ion-input type="text" [name]="item.name" [hidden]="true"></ion-input>
    <ion-input type="text" #textInput [readonly]="true" (click)="trigger()"></ion-input>
  </ion-item>
</div>
