import { Component, Input } from '@angular/core';
import { NavController } from '@ionic/angular';

import { UserService, AppUser } from '@BeerMonkey/core';
import { BonesGalleryService } from '@bones/gallery';

import { Rating } from '@BeerMonkey/rate/class/Rating';
import { LaunchEditModalService } from '@BeerMonkey/rate/service/launch-edit-modal';
import { RatingService } from '@BeerMonkey/rate/service/RatingService';

@Component({
  selector: 'rating-list',
  templateUrl: 'rating-list.html'
})
export class RatingListComponent
{
    ratingList: Rating[] = [ ];
    @Input() set ratings(list: Rating[])
    {
        this.ratingList = list;
    }
    @Input() showBeerName = false;
    @Input() showBreweryName = false;

    user: AppUser;

    constructor(
        private navCtrl: NavController,
        public gallery: BonesGalleryService,
        private mtus: UserService,
        public launch: LaunchEditModalService,
        private ratingDB: RatingService
    )
    {
        this.user = this.mtus.getUser();
    }

    getThumbnail(rating: Rating) : number | undefined
    {
        return this.ratingDB.getThumbnail(rating);
    }

    breweryDetail(brewery_id: number, event: Event)
    {
        this.navCtrl.navigateForward([ '/rate/brewery', brewery_id ]);
        event.stopPropagation();
    }

    beerDetail(beer_id: number, event: Event)
    {
        this.navCtrl.navigateForward([ '/rate/beer', beer_id ]);
        event.stopPropagation();
    }

    /**
     * Show rating detail
     * @param rating 
     */
    showRating(rating: Rating)
    {
        this.navCtrl.navigateForward([ '/rate/rating', rating.rating_id ]);
    }

}
