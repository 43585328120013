<ion-header><app-page-header subtitle="Beer Explorer"></app-page-header></ion-header>

<ion-content>

  <!-- Small device -->

  <div *ngIf="monkey.isSmallDevice">
    <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="filterTmpl8"></ng-container>
    <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
  </div>

  <!-- Large device -->

  <ion-grid *ngIf="monkey.isLargeDevice">

    <ion-row>
      <ion-col size="4">
        <ng-container *ngTemplateOutlet="breweryTmpl8"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="filterTmpl8"></ng-container>
      </ion-col>

      <ion-col size="4">
        <ng-container *ngTemplateOutlet="beerTmpl8"></ng-container>
      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>

<ng-template #breweryTmpl8>
  <ion-card class="ion-padding" *ngIf="brewery && !brewery.row.ratebeer_id">
    <bones-card-toolbar>{{ brewery.row.name }}</bones-card-toolbar>
    <ion-item>
      <ion-label>{{brewery.row.location}}</ion-label>
    </ion-item>
  </ion-card>

  <rb-brewery-info [title]="brewery.row.name" [ratebeerID]="brewery.row.ratebeer_id" *ngIf="brewery">
  </rb-brewery-info>
</ng-template>

<ng-template #filterTmpl8>
  <ion-card class="ion-padding" *ngIf="filterForm">
    <bones-card-toolbar #bct title="Beer Filters" [expandable]="true" [expanded]="true"></bones-card-toolbar>
    <ion-card-content class="ion-no-padding" *ngIf="bct.expanded">
      <bones-form-items [form]="filterForm.form" [items]="filterForm.items"></bones-form-items>
      <div class="ion-text-right">
        <ion-button (click)="filterForm.resetValues()">Clear</ion-button>
      </div>
    </ion-card-content>
  </ion-card>
</ng-template>

<ng-template #beerTmpl8>
  <beer-list-card [beers]="filteredBeers" [showSearchbar]="true">
  </beer-list-card>
</ng-template>
