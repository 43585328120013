<ion-header><bones-tt-page-header title="Sort"></bones-tt-page-header></ion-header>

<ion-content>
  <ion-grid>
    <ion-row>

      <ion-col>
        <table class="bones-card-table full-width">
          <caption>A Table</caption>
          <thead>
            <tr>
              <th>ID</th>
              <th>Created</th>
              <th>Lucky</th>
              <th>Fruit</th>
              <th>Column Five</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of rows">
              <td class="ion-text-right">{{ row.C1 }}</td>
              <td>{{ row.C2 | date:'mediumDate' }}</td>
              <td class="ion-text-center"><ion-checkbox [disabled]="true" [checked]="row.C3"></ion-checkbox></td>
              <td>{{ row.C4 }}</td>
              <td>{{ row.C5 }}</td>
            </tr>
          </tbody>
        </table>
      </ion-col>

      <ion-col>
        <ion-card class="ion-padding">
          <ion-card-title>Sort Order</ion-card-title>
          <pre>{{ currentSortOrder | json }}</pre>
        </ion-card>
      </ion-col>

    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" (click)="sort1()">Sort by ID</ion-button>
      <ion-button fill="solid" color="primary" (click)="sort2()">Sort by Fruit, Lucky, Created (desc)</ion-button>
      <ion-button fill="solid" color="primary" (click)="sort3()">Sort by Column Five</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
