/**
 * Options for making web service calls.
 */
export class BnsOptions
{
    /**
     * Headers sent to server.
     */
    headers?: any;

    /**
     * Parameters sent to server as a posted JSON body.
     */
    params?: any;

    /**
     * Parameters sent to server as query string on the URL.
     */
    queryParams?: any;

    /**
     * Body sent on post request (instead of JSON parameters).
     */
    body?: any;

    /**
     * The response type expected back from the server.
     */
    responseType?: 'json' | 'text' | 'blob' = 'json';

    /**
     * The return value requested from the web service call.
     * Specifying 'bns' will return the full BnsResponse object.
     * The default is to return just the contents of the response (e.g. JSON object).
     */
    returnValue?: 'default' | 'bns' = 'default';
}
