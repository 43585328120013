import { InventoryInfo } from './InventoryInfo';
import { Brewery } from './Brewery';
import { Beer } from './Beer';

export class Inventory
{
    inventory_id: number;
    brewery?: Brewery;
    beer?: Beer;

    constructor(public row: InventoryInfo)
    {
        this.inventory_id = row.inventory_id;
    }

    get beerName()
    {
        return this.beer ? this.beer.name : this.row.beer_name;
    }

}
