<ion-header><bones-tt-page-header title="Platform"></bones-tt-page-header></ion-header>

<ion-content>

  <ion-card class="ion-padding">
    <ion-card-title>Platform Info</ion-card-title>
    <ion-list>
      <ion-item>
        <ion-label>
          <h2>Platforms</h2>
          <span>{{ platform.platforms() }}</span>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-label>Height</ion-label>
        <span slot="end">{{ platform.height() }}</span>
      </ion-item>
      <ion-item>
        <ion-label>Width</ion-label>
        <span slot="end">{{ platform.width() }}</span>
      </ion-item>
      <ion-item>
        <ion-label>isLandscape</ion-label>
        <span slot="end">{{ platform.isLandscape() }}</span>
      </ion-item>
      <ion-item>
        <ion-label>isPortrait</ion-label>
        <span slot="end">{{ platform.isPortrait() }}</span>
      </ion-item>
      <ion-item>
        <ion-label>
          <h2>url</h2>
          <p>{{ platform.url() }}</p>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-card>

</ion-content>
