<ion-header><bones-tt-modal-header title="EDS Gateway"></bones-tt-modal-header></ion-header>

<ion-content>

  <ion-card class="ion-padding">
    <ion-card-title>Configuration</ion-card-title>
    <ion-list>
      <bones-form-items [form]="configForm.form" [items]="configForm.items"></bones-form-items>
    </ion-list>
  </ion-card>

  <ion-card class="ion-padding" [formGroup]="authForm.form" *ngIf="isConfigured">
    <ion-card-title>
      <span>/auth</span>
      <ion-icon name="checkmark" *ngIf="isAuthorized"></ion-icon>
    </ion-card-title>

    <ion-list>
      <bones-form-items [form]="authForm.form" [items]="authForm.items"></bones-form-items>

      <ion-item *ngIf="isAuthorized">
        <ion-label>User has been authenticated</ion-label>
      </ion-item>
    </ion-list>

  </ion-card>

  <bones-tt-error-card [error]="error"></bones-tt-error-card>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" [disabled]="!configForm.form.valid" (click)="set()">Configure</ion-button>
      <ion-button fill="solid" color="primary" [disabled]="!isConfigured" (click)="auth()">Authenticate</ion-button>
      <ion-button fill="solid" color="primary" (click)="dismiss()">Close</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

