import { Component, Input, OnInit } from '@angular/core';

import { BonesMenuCardAction } from '@bones/core';

import { Beer } from '@BeerMonkey/rate/class/Beer';

@Component({
  selector: 'beer-info',
  templateUrl: 'beer-info.html'
})
export class BeerInfoComponent implements OnInit
{
    _beer?: Beer;
    _ratebeerID?: number;
    @Input('actions') beerCardMenu?: BonesMenuCardAction[];

    @Input() set beer(beer: Beer | undefined)
    {
        this._beer = beer;
        this.ratebeerID = this.beer?.row.ratebeer_id;
        // this.ratebeerID = this.beer ? this.beer.row.ratebeer_id : undefined;
    }
    get beer() : Beer | undefined
    {
        return this._beer;
    }

    /**
     * Change value of ratebeerID attribute
     */
    @Input() set ratebeerID(ratebeerID: number | undefined)
    {
        // console.log('BeerInfo.set ratebeerID from', this._ratebeerID, 'to', ratebeerID);

        // Override ratebeer if provided
        if (ratebeerID)
        {
            this._ratebeerID = ratebeerID;
        }
        // Revert back to beer's setting
        else if (this.beer && this.beer.row.ratebeer_id)
        {
            this._ratebeerID = this.beer.row.ratebeer_id;
        }
        // No ratebeer defined
        else
        {
            this._ratebeerID = undefined;
        }
    }
    get ratebeerID(): number | undefined
    {
        return this._ratebeerID;
    }

    constructor(
    )
    {
    }

    ngOnInit()
    {
    }

}
