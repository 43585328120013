import { Component, Input } from '@angular/core';

import { BonesMenuCardAction, BonesItemGroupFactory, BonesItemGroup } from '@bones/core';

import { nowShowing } from '@BeerMonkey/rate/class/nowShowing';
import { Glassware } from '@BeerMonkey/rate/class/Glassware';
import { GlasswareFilter } from '@BeerMonkey/rate/service/GlasswareService';
import { glasswareBreweryNameGroupFactory } from '@BeerMonkey/rate/service/GlasswareService';
import { glasswareNameGroupFactory } from '@BeerMonkey/rate/service/GlasswareService';
import { glasswareStyleGroupFactory } from '@BeerMonkey/rate/service/GlasswareService';
import { showCard } from '@BeerMonkey/rate/class/showCard';
import { GlasswareInfo } from '@BeerMonkey/rate/class/GlasswareInfo';

@Component({
    selector: 'glassware-list-card',
    templateUrl: 'glassware-list-card.html'
})
export class GlasswareListCardComponent
{
    @Input() title: string = 'Glassware';
    @Input() showSearchbar = false;
    @Input() resort = true;
    @Input() showBreweryName = true;
    @Input() showGlassware = true;
    @Input() search = false;

    _glassware?: Glassware[];
    @Input() set glassware(glassware: Glassware[] | undefined)
    {
        this._glassware = glassware;
        this.filterContent();
    }
    get glassware() : Glassware[] | undefined
    {
        return this._glassware;
    }

    _searchTerm: string = '';
    @Input() set searchTerm(searchTerm: string)
    {
        this._searchTerm = searchTerm;
        this.filterContent();
    }
    get searchTerm() : string
    {
        return this._searchTerm;
    }

    groupFactory: BonesItemGroupFactory<Glassware, GlasswareInfo> = glasswareBreweryNameGroupFactory();
    filtered: Glassware[] = [ ];
    groups?: BonesItemGroup<Glassware>[];
    numberToDisplay = 30;
    preTruncateLength?: number;
    truncated?: boolean;
    cardMenu: BonesMenuCardAction[];
    glasses?: number;

    constructor(
    )
    {
        this.cardMenu =
        [
            { title: 'Group by Brewery', icon: 'funnel', action: () => this.doSort(glasswareBreweryNameGroupFactory()) },
            { title: 'Group by Glass', icon: 'funnel', action: () => this.doSort(glasswareNameGroupFactory()) },
            { title: 'Group by Style', icon: 'funnel', action: () => this.doSort(glasswareStyleGroupFactory()) },
        ];
    }

    async ngOnInit()
    {
        this.groupFactory = this.showBreweryName ? glasswareBreweryNameGroupFactory() : glasswareNameGroupFactory();
        this.filterContent();
    }

    ngOnDestroy()
    {
    }

    doSort(group: BonesItemGroupFactory<Glassware, GlasswareInfo>)
    {
        this.groupFactory = group;
        this.filterContent();
    }

    /**
     * Filter, sort, and group ratings
     */
    filterContent()
    {
        if (this.glassware)
        {
            // Filter by keyword
            this.filtered = new GlasswareFilter(this.glassware).byKeyword(this.searchTerm).rows;

            // Glassware counts
            this.glasses = this.filtered.map(i => i.row.quantity ?? 0).reduce((a, cv) => a + cv, 0);

            // Resort
            if (this.resort)
            {
                this.groupFactory.sortItems(this.filtered);
            }

            // Limit display size
            this.preTruncateLength = this.filtered.length;
            this.truncated = this.filtered.length > this.numberToDisplay;
            if (this.truncated)
            {
                this.filtered = this.filtered.slice(0, this.numberToDisplay);
            }

            // Group
            this.groups = this.groupFactory.group(this.filtered);
        }
    }

    async showMore()
    {
        this.numberToDisplay += 30;
        this.filterContent();
    }

    showingText() : string
    {
        let text = nowShowing(this.showGlassware, this.glassware?.length ?? 0, this.filtered.length, this.preTruncateLength ?? 0);

        text += ' (unique)';

        if ((this.glasses ?? 0) > 0)
        {
            text += '\nTotal ' + this.glasses + ' (glasses)';
        }

        return text;
    }

    get show() : boolean
    {
        return showCard(this.search, this.searchTerm, this.filtered);
    }

}
