<ion-header><bones-tt-page-header title="EDS Gateway"></bones-tt-page-header></ion-header>

<ion-content [formGroup]="form">

  <bones-tt-edsgw-config-card></bones-tt-edsgw-config-card>

  <ion-card class="ion-padding" *ngIf="edsgw.isAuthenticated">
    <ion-card-title>/forward</ion-card-title>

    <ion-list>
      <ion-item>
        <ion-label position="stacked">forwardUrl</ion-label>
        <ion-input formControlName="forwardUrl"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label position="stacked">body</ion-label>
        <ion-textarea formControlName="forwardBody" rows="6"></ion-textarea>
      </ion-item>
    </ion-list>
  </ion-card>

  <ion-card class="ion-padding" *ngIf="forwardResults">
    <ion-card-title>Forward Results</ion-card-title>
    <pre>{{forwardResults | json}}</pre>
  </ion-card>

  <bones-tt-error-card [error]="error"></bones-tt-error-card>

</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" [disabled]="!edsgw.isAuthenticated" (click)="forward()">Forward</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
