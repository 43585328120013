import { Component } from '@angular/core';

import { BonesCookieService } from '../../service/cookie.service';

@Component({
  templateUrl: 'test-tools-cookies.html'
})
export class BonesTestToolsCookiesPage
{
    cookies: { key: string, value: string }[] = [ ];

    constructor(
        cookie: BonesCookieService
    )
    {
        cookie.getCookies().forEach((value, key) => this.cookies.push({ key: key, value: value }));
    }

}
