import { Injectable } from '@angular/core';

import { BonesDeviceService } from './device.service';

/**
 * Logging utilities
 */
@Injectable({
  providedIn: 'root'
})
export class BonesLogService
{
    /**
     * @ignore
     */
    constructor(
        private bds: BonesDeviceService
    )
    {
    }

    /**
     * Initialize logging. This should be called from the app.component constructor.
     */
    async initialize()
    {
        const info = await this.bds.getInfo();

        // console.log is broken on Android devices, so override it with our own
        if (info.isAndroidDevice || info.isIosDevice)
        {
            this.overrideConsoleLog();
        }
    }

    //-----------------------------------------------------------------------

    /**
     * Create custom console.log function to replace the broken one on Android devices.
     */
    private overrideConsoleLog()
    {
        const builtinConsoleLog = console.log;

        const logger = function()
        {
//            builtinConsoleLog.call(console, Array.prototype.slice.call(arguments).join(" "));

            // Build log message
            let message = '';
            for (let i = 0; (i < arguments.length); ++i)
            {
                const value = arguments[i];

                // Separate values with a space
                if (i > 0)
                {
                    message = message + ' ';
                }

                // Convert objects to JSON strings for display
                if (typeof value === 'object')
                {
                    try
                    {
                        const json = JSON.stringify(value);
                        message = message + (json.length > 300 ? json.substr(0, 300) + '...' : json);
                    }
                    catch (error)
                    {
                        message = message + value;
                    }
                }
                // All other types get implicit string conversion
                else
                {
                    message = message + value;
                }
            }

            // Use built-in console.log
            builtinConsoleLog.call(console, message);
        };

        // Redirect system logging
        console.log = logger;
        console.info = logger;
        console.warn = logger;
        console.error = logger;

        console.log('override console.log');
    }

    //-----------------------------------------------------------------------


}
