import { BonesError } from '@bones/core';
import { BnsRequestInfo } from './BnsRequestInfo';
import { BnsResponseInfo } from './BnsResponseInfo';

/**
 * Log entry.
 */
export class BonesNetworkLogEntry
{
    /**
     * Counter for assigning sequential IDs to new log entries.
     */
    static idCounter = 0;

    /**
     * ID assigned to each log entry.
     */
    id: number;

    /**
     * Timestamp of when request was initiated.
     */
    ts: Date;

    /**
     * Web service request.
     */
    bnsRequestInfo: BnsRequestInfo;

    /**
     * Web service response.
     */
    bnsResponseInfo: BnsResponseInfo;

    /**
     * Error (if any).
     */
    error?: BonesError;

    /**
     * Overall status (success, failure).
     */
    status: 'success' | 'failure';

    /**
     * Construct new log entry.
     */
    constructor()
    {
        this.ts = new Date();
        this.id = ++BonesNetworkLogEntry.idCounter;
    }
}

//-----------------------------------------------------------------------