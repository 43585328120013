<ion-header><bones-tt-page-header title="Bones Form Controls"></bones-tt-page-header></ion-header>

<ion-content [formGroup]="bf.form">
  <bones-app-launcher-pane>

    <div class="app-launcher-icons">
      <bones-app-launcher-icon title="Text" icon="text" [active]="true" #textApp></bones-app-launcher-icon>
      <bones-app-launcher-icon title="Number" icon="cash" #numberApp></bones-app-launcher-icon>
      <bones-app-launcher-icon title="File" icon="cloud-upload" #fileApp></bones-app-launcher-icon>
      <bones-app-launcher-icon title="Date" icon="calendar" [active]="true" #dateApp></bones-app-launcher-icon>
      <bones-app-launcher-icon title="Pickers" icon="list" #pickerApp></bones-app-launcher-icon>
      <bones-app-launcher-icon title="Toggles" icon="toggle" #toggleApp></bones-app-launcher-icon>
    </div>

    <div class="app-launcher-body">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col size="4">

        <ion-card class="ion-padding" *ngIf="textApp.active">
          <ion-card-title>Text Fields</ion-card-title>
          <ion-list>

            <!-- manually created form item -->
            <ion-item>
              <ion-label position="stacked">Field One</ion-label>
              <ion-input formControlName="field1"></ion-input>
            </ion-item>
            <bones-form-error [form]="bf.form" control="field1">Required</bones-form-error>

            <!-- generated form items -->
            <bones-form-items [form]="bf.form" [items]="bf.items.slice(1, 4)"></bones-form-items>

          </ion-list>
        </ion-card>

        <ion-card class="ion-padding" *ngIf="textApp.active">
          <ion-card-title>Paragraph Fields</ion-card-title>
          <bones-form-items [form]="bf.form" [items]="bf.items.slice(4, 7)"></bones-form-items>
        </ion-card>

        <ion-card class="ion-padding" *ngIf="numberApp.active">
          <ion-card-title>Numeric Fields</ion-card-title>
          <bones-form-items [form]="bf.form" [items]="bf.items.slice(7, 8)"></bones-form-items>
        </ion-card>

        <ion-card class="ion-padding" *ngIf="fileApp.active">
          <ion-card-title>File Upload</ion-card-title>
          <bones-form-items [form]="bf.form" [items]="bf.items.slice(16, 17)"></bones-form-items>
        </ion-card>

        <ion-card class="ion-padding" *ngIf="pickerApp.active">
          <ion-card-title>Pickers</ion-card-title>
          <bones-form-items [form]="bf.form" [items]="bf.items.slice(17, 29)"></bones-form-items>
        </ion-card>

        <ion-card class="ion-padding" *ngIf="toggleApp.active">
          <ion-card-title>Toggles</ion-card-title>
          <bones-form-items [form]="bf.form" [items]="bf.items.slice(29)"></bones-form-items>
        </ion-card>

      </ion-col>
      <ion-col size="4">

        <ion-card class="ion-padding" *ngIf="dateApp.active">
          <ion-card-title>Date Fields</ion-card-title>
          <bones-form-items [form]="bf.form" [items]="bf.items.slice(8, 14)"></bones-form-items>
        </ion-card>

        <ion-card class="ion-padding" *ngIf="dateApp.active">
          <ion-card-title>Elapsed Time Fields</ion-card-title>
          <bones-form-items [form]="bf.form" [items]="bf.items.slice(14, 16)"></bones-form-items>
        </ion-card>

      </ion-col>
      <ion-col size="4">

        <ion-card class="ion-padding" *ngIf="display">
          <ion-card-title>Form Contents</ion-card-title>
          <pre>{{ display | json }}</pre>
          <div>e1: {{ display.e1 | bonesElapsedTime }}</div>
          <div>e2: {{ display.e2 | bonesElapsedTime }}</div>
          <div class="ion-text-right ion-margin-top">
            <ion-button fill="solid" color="primary" (click)="debug()">Refresh</ion-button>
          </div>
        </ion-card>
        
      </ion-col>
    </ion-row>
  </ion-grid>

    </div>
  </bones-app-launcher-pane>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" [disabled]="bf.form.invalid" (click)="submit()">Submit</ion-button>
      <ion-button fill="solid" color="primary" (click)="hide()">Hide last item</ion-button>
      <ion-button fill="solid" color="primary" (click)="pickerValues()">Change picker values</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
